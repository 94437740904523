import React from 'react'
import { TextField } from '@mui/material'
import "./WindowMessage.css"
function CsTextField({ label, ...otherProps }) {
  return (
    <TextField
      {...otherProps}
      label={ <span className='globalTextLabel'>{label}</span>}
    />
  )
}

export default CsTextField