import React from 'react'
import LoginMob from './LoginMob'

function StartPage(props) {
  return (
    <>
    <LoginMob fromStart>
        {props.children}
    </LoginMob>
    </>
  )
}

export default StartPage