import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import { IconArchiveNav, IconChat, IconHome, IconNewForm, IconSelected, IconUserProfile } from "../../../../assets/css/icons";
import Context from "../../../Context";
import { ExtractedDataTable } from "../../../services/api-servics";
import css from "./navmob.module.scss";
import { showToaster } from "../../../services/helper";
function NavMob(props) {
    const[isActiveHome,setIsActiveHome] = useState(false);
    const { formDataState, setformDataState } = useContext(Context);
    const[isActiveChat,setIsActiveChat] = useState(false);
    const[isActiveUserProfile,setIsActiveUserProfile] = useState(false);
    const[isActiveAnalytics,setIsActiveAnalytics] = useState(false);
    const role = localStorage.getItem("role");
    const history = useHistory();
    const getExtractedDataTable = (e) => {
      props.handleLoading();
      ExtractedDataTable({}).then(async (rsp) => {
        props.handleLoading();
          const data300 = rsp.data.data300;
          const data301 = rsp.data.data301;
          const data300a = rsp.data.data300a;
          let path = "/process-tab";
          history.push({
            pathname: path,
            state:{
              data300:data300,
              data301:data301,
              data300a:data300a,
            }
          });
      });
    };
    const handleNewForm = () => {
      if(role === "admin"){
        showToaster("Admin have no access to view this","error")
      }
      else{
        setformDataState({
          type: "new",
          employeeName: "",
          jobTitle: "",
          street: "",
          dob: "",
          city: "",
          dateHired: "",
          state: null,
          gender: "",
          zip: "",
          empId: "",
          establishment: "",
          workOption: "",
          WorkTime: "",
          incidentDate: "",
          incidentPlace: "",
          incidentTime: "",
          incidentCause: "",
          deathTime: "",
          value: "",
          incident: "",
          injurySummary: "",
          injuryDetails: "",
          object: "",
          preIncidentActivity: "",
          reason: "",
          Physician: "",
          stateInfo: "",
          location: "",
          zipInfo: "",
          streetInfo: "",
          emergencyRoomOption: "",
          cityInfo: "",
          hospitalizedOption: "",
          treatmentInfo: "",
          incidentId: ""
        })
        setIsActiveHome(false);
        setIsActiveChat(false);
        setIsActiveUserProfile(false);
        setIsActiveAnalytics(false);
        history.push("/new-form")
      }
    }
    const handleHome = () => {
        setIsActiveHome(true);
        setIsActiveChat(false);
        setIsActiveUserProfile(false);
        setIsActiveAnalytics(false);
       setformDataState({
          ...formDataState,
          selectedMenu:null
        }) 
        history.push("/home");

    }
    const handleChat = () => {
      console.log(props);
        setIsActiveHome(false);
        setIsActiveChat(true);
        setIsActiveUserProfile(false);
        setIsActiveAnalytics(false);
        props.handleChatBot();
       
    }
    const handleUserProfile = () => {
        setIsActiveHome(false);
        setIsActiveChat(false);
        setIsActiveUserProfile(true);
        setIsActiveAnalytics(false);
        history.push("/profile_settings");
        setformDataState({
          ...formDataState,
          selectedMenu:"user"
        })
    }
    const handleAnalytics = () => {
      if(role === "admin"){
        showToaster("Admin have no access to view this","error")
      }
      else{
        setIsActiveHome(false);
        setIsActiveChat(false);
        setIsActiveUserProfile(false);
        setIsActiveAnalytics(true);
        getExtractedDataTable()
        setformDataState({
          ...formDataState,
          selectedMenu:"analytics"
        })
      }
    }
  return (
    <div className={css.navFooter}>
      <div className={css.navMenuDiv}>
        <div className={css.navContainer}>
          <div className={css.navSmallBox} onClick={handleHome}>
            <IconHome stroke={isActiveHome ? "#8756D4" : "rgb(41, 41, 41)"}/>
            {isActiveHome && <IconSelected/>}
            </div>
          <div className={css.navSmallBox} onClick={handleChat}>
            <IconChat fill ={formDataState.selectedMenu === "chat" ? "#8756D4" : "rgb(41, 41, 41)"}/>
            {formDataState.selectedMenu === "chat" && <IconSelected/>}
            </div>
          <div className={css.navLargerBox}>
            <div className={css.navNewFormMain}  onClick={handleNewForm}>
                <div className={css.navNewFormBody}>
                    <IconNewForm/>
                </div>
            </div>
            </div>
          <div className={css.navSmallBox} onClick={handleUserProfile}>
            <IconUserProfile  fill ={formDataState.selectedMenu === "user" ? "#8756D4" : "rgb(41, 41, 41)"}/>
            {formDataState.selectedMenu === "user" && <IconSelected/>}
            </div>
          <div className={css.navSmallBox} onClick={handleAnalytics} style={isActiveAnalytics ? {cursor:"not-allowed"} : {}}>
            <IconArchiveNav fill ={(formDataState.selectedMenu === "archive") ? "#8756D4" : "rgb(41, 41, 41)"}/>
            {formDataState.selectedMenu === "archive" && <IconSelected/>}
            </div>
        </div>
      </div>
    </div>
  );
}

export default NavMob;
