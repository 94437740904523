import React from "react";
import axiosInstance from "../../lib/axiosInstance";
import FormatDate from "../../lib/FormatDate";
// import chatLogo from "../../assets/images/chat_bg.png"
function ChatTypeSelection({
  btnSelected,
  setBtnSelected,
  selectedChatType,
  setSelectedChatType,
  messages,
  setMessages,
}) {
  const handleDB_Selection = () => {
    setBtnSelected(() => !btnSelected);
    setSelectedChatType("db");
    // try {
    //   axiosInstance
    //     .post("/chatbot/",{})
    //     .then((response) => {
    //     })
    //     .catch((error) => console.log("[Error - DB]: ", error));
    // } catch (error) {
    //   console.log("[Error]", error);
    // }
    setMessages((msg) => {
      const _messages = [...msg];
      _messages.push({
        type: "reply",
        chatType: "db",
        message: "Welcome to the Database Chatbot. Start by asking a question!",
        timeStamp: FormatDate(),
      });
      return [..._messages];
    });
  };
  const handleAI_Selection = () => {
    setBtnSelected(!btnSelected);
    setSelectedChatType("ai");
    // try {
    //   axiosInstance
    //     .post("/endpoint")
    //     .then((response) => {
    //       console.log("response: ", response);
    //     })
    //     .catch((error) => console.log("[Error - AI]: ", error));
    // } catch (error) {
    //   console.log("[Error]", error.message);
    // }
    setMessages((msg) => {
      const _messages = [...msg];
      _messages.push({
        type: "reply",
        chatType: "ai",
        message:
          "Get insurance predictions with Copilot Chat! Input policy details for a quick estimate.",
        timeStamp: FormatDate(),
      });
      return [..._messages];
    });
  };
  return (
    <div className="btnWrapper">
      <div className="col-md-12 chat_img_div">
        {/* <img
          src={chatLogo}
          className="d-inline-block lg-com-log com-log chat_img_fix"
          alt=""
        /> */}

        <div className="chat_btn_list" >
          <button onClick={handleDB_Selection} className="chat_btn_bot">
            Chat with DB
          </button>
          <button onClick={handleAI_Selection} className="chat_btn_bot">Chat with AI</button>
        </div>
      </div>
    </div>
  );
}

export default ChatTypeSelection;
