import {
  CardContent,
  List,
  ListItemButton,
  ListItemText,
  Card,
  CardHeader,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Tooltip,
  Switch,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import "./ProfileSettings.css";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import "./ProfileSettings.css";
import SettingsMenu from "../SettingsMenu/SettingsMenu";
import {
  SaveProfileData,
  UserProfileSettings,
  PasswordChange,
  ValidatePassword,
  TwoFactorAuth,
} from "../../services/api-servics";
import Context from "../../Context";
import { useContext } from "react";
import SuccessMessage from "../../GlobalComponents/SuccessMessage";
import CsTextField from "../../GlobalComponents/CsTextField";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import {
  IconTwofactor,
  IconLock,
  IconOshaInsider,
} from "../../../assets/css/icons";

import MainWrapper from "../../GlobalComponents/MainWrapper";
import MediaQuery from "react-responsive";
import MainContainer from "../../MobileUI/GlobalComponents/MainContainer";
import UserSettings from "./UserSettings";

const UserProfilePage = () => {
  const history = useHistory();
  const [selectedMenuItem, setSelectedMenuItem] = useState("General");
  const [denied, setDenied] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const { formDataState, setformDataState } = useContext(Context);
  const [mfaOpt, setMfaOpt] = useState(null);
  const [userName, setUserName] = useState(null);
  const [company, setCompany] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [mobNo, setMobNo] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [twofacCheck, setTwofacCheck] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [validTrue, setValidTrue] = useState(null);
  const [twofactorTrue, setTwoFactorTrue] = useState(null);
  const usermail = localStorage.getItem("email");
  const [isLengthValid, setLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [validNewPass, setValidNewPass] = useState(false);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [loader, setloader] = useState(false);
  const [enableAuth, setEnableAuth] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    company: "",
    designation: "",
    mobNo: "",
    newPassword: "",
    confirmPassword: "",
  });
  useEffect(() => {
    if (
      isLengthValid === true &&
      hasUpperCase === true &&
      hasLowerCase === true &&
      hasNumber === true
    ) {
      setValidNewPass(true);
    } else {
      setValidNewPass(false);
    }
  }, [newPassword]);

  useEffect(() => {
    getProfileDetails();
    setformDataState({
      ...formDataState,
      selectedMenu:"user"
    })
  }, []);

  useEffect(() => {
    if (!twoFactorEnabled) {
      setTwofacCheck("");
      setTwoFactorTrue(null);
    }
  }, [twoFactorEnabled]);

  const getProfileDetails = (e) => {
    UserProfileSettings({ email: localStorage.getItem("email") }).then(
      async (rsp) => {
        if (rsp.data.data) {
          const userdata = rsp.data.data;
          setUserName(userdata.name);
          setCompany(userdata.company);
          setDesignation(userdata.designation);
          setMobNo(userdata.mobNo);
          setUserDetails(userdata);
          const foundRole = mfaOptions.find(
            (obj) => obj.value === userdata.two_factor_choice.value
          );
          setMfaOpt(foundRole);
          setEnableAuth(userdata.two_factor_choice);
        }
      }
    );
  };

  const handleEnableAuth = () => {
    setEnableAuth(!enableAuth);
    TwoFactorAuth({
      email: localStorage.getItem("email"),
      two_factor_check: !enableAuth,
    }).then(async (rsp) => {
    });
  };
  const UserEditValidateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Validate Employee Name
    if (!userName) {
      newErrors.userName = "Name is required";
      setSnackbarMessage("Name is required");
      isValid = false;
    } else {
      newErrors.userName = "";
    }

    // Validate Job Title
    if (!company) {
      newErrors.company = "Company is required";
      setSnackbarMessage("Company is required");
      isValid = false;
    } else {
      newErrors.company = "";
    }

    // Validate Street
    if (!designation) {
      newErrors.designation = "Designation is required";
      setSnackbarMessage("Designation is required");
      isValid = false;
    } else {
      newErrors.designation = "";
    }

    // Validate Date of Birth

    // Validate City
    if (!mobNo) {
      newErrors.mobNo = "Mobile Number is required";
      setSnackbarMessage("Mobile Number is required");
      isValid = false;
    } else {
      newErrors.mobNo = "";
    }
    setErrors(newErrors);
    return isValid;
  };
  const passwordValidateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Validate Employee Name
    if (!newPassword) {
      newErrors.newPassword = "New Password is required";
      setSnackbarMessage("New Password is required");
      isValid = false;
    } else {
      newErrors.newPassword = "";
    }

    // Validate Job Title
    if (!confirmPassword) {
      newErrors.confirmPassword = "Confirm password is required";
      setSnackbarMessage("Confirm password is required");
      isValid = false;
    } else {
      newErrors.confirmPassword = "";
    }
    setErrors(newErrors);
    return isValid;
  };
  const handleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleSetPassword = (data) => {
    PasswordChange({
      email: localStorage.getItem("email"),
      password: data,
    }).then(async (rsp) => {
      if (rsp.data.status === "success") {
        logout();
      }
    });
  };
  const logout = async () => {
    localStorage.removeItem("power_bi");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    history.push("/");
  };
  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleNewPasswordChange = (event) => {
    const newPassword = event.target.value;
    setNewPassword(newPassword);
    setLengthValid(newPassword.length >= 6);
    setHasUpperCase(/[A-Z]/.test(newPassword));
    setHasLowerCase(/[a-z]/.test(newPassword));
    setHasNumber(/[0-9]/.test(newPassword));
  };
  const handleCurrentPasswordChange = (event) => {
    const newPassword = event.target.value;
    setCurrentPassword(newPassword);
    setValidTrue(null);
  };
  const handleTwofactorCheck = (event) => {
    const newPassword = event.target.value;
    setTwofacCheck(newPassword);
    setTwoFactorTrue(null);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setPasswordsMatch(event.target.value === newPassword);
  };
  const handleSubmit = (event) => {
    if (passwordValidateForm()) {
      event.preventDefault();

      if (newPassword === confirmPassword) {
        handleSetPassword(newPassword);
      } else {
        setNewPassword("");
        setConfirmPassword("");
      }
    }
  };

  const handleSave = () => {
    if (UserEditValidateForm()) {
      saveProfileData();
    }
  };
  const saveProfileData = (data) => {
    setDenied(true);
    SaveProfileData({
      email: localStorage.getItem("email"),
      name: userName,
      company: company,
      contactno: mobNo,
      manager: designation,
      two_factor_choice: mfaOpt,
    }).then(async (rsp) => {
      setDenied(false);
      if (rsp.data.status === "success") {
        setSuccessModal(true);
      }
    });
  };

  const handleEdit = () => {
    setSelectedMenuItem("Edit Profile");
  };
  const validatePassword = (event) => {
    setloader(true);
    ValidatePassword({
      username: usermail,
      password: event === "fromTwoFactor" ? twofacCheck : currentPassword,
    }).then(async (rsp) => {
      setloader(false);
      const data = rsp.data.status;
      if (data === "correct") {
        setValidTrue(true);
        if (data === "correct" && event === "fromTwoFactor") {
          setTwoFactorEnabled(true);
          setTwoFactorTrue(true);
        }
      } else {
        setValidTrue(false);
        setTwoFactorTrue(false);
      }
    });
  };

  
  const handleClose = () => {
    setSuccessModal(false);
    setTwoFactorEnabled(false);
  };

  const handleUserName = (event) => {
    setUserName(event.target.value);
  };
  const handleCompany = (event) => {
    setCompany(event.target.value);
  };
  const handleDesignation = (event) => {
    setDesignation(event.target.value);
  };
  const handleContactNumber = (event) => {
    setMobNo(event.target.value);
  };
  // Function to handle menu item clicks
  const handleMenuItemClick = (menuName) => {
    setSelectedMenuItem(menuName);
  };

  const mfaOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];





  return (
    <>
    
   <MediaQuery minDeviceWidth={1024}>
    <MainWrapper>
      {successModal && (
        <SuccessMessage show={successModal} handleConfirm={handleClose} />
      )}
      <Card className="card" style={{ borderRadius: "16px", height: "500px" }}>
        <CardHeader
          title={
            <div className="establishmentText">
              <i class="fa fa-user fa-2x" aria-hidden="true"></i>&nbsp;User
              Profile
            </div>
          }
        />
        <CardContent>
          <div style={{ gap: "20px", display: "flex" }}>
            {/* Left Menu */}
            <div className="listClass" style={{ width: "200px" }}>
              <List>
                <ListItemButton
                  button
                  selected={selectedMenuItem === "General"}
                  onClick={() => handleMenuItemClick("General")}
                >
                  <i
                    class="fa fa-cogs"
                    style={
                      selectedMenuItem === "General" ? { color: "#0b69c9" } : {}
                    }
                    aria-hidden="true"
                  ></i>
                  &nbsp;
                  <ListItemText
                    style={
                      selectedMenuItem === "General" ? { color: "#0b69c9" } : {}
                    }
                    primary="General"
                  />
                </ListItemButton>
                <ListItemButton
                  button
                  selected={selectedMenuItem === "Edit Profile"}
                  onClick={() => handleMenuItemClick("Edit Profile")}
                >
                  <i
                    class="fas fa-user-edit"
                    style={
                      selectedMenuItem === "Edit Profile"
                        ? { color: "#0b69c9" }
                        : {}
                    }
                  ></i>{" "}
                  &nbsp;
                  <ListItemText
                    style={
                      selectedMenuItem === "Edit Profile"
                        ? { color: "#0b69c9" }
                        : {}
                    }
                    primary="Edit Profile"
                  />
                </ListItemButton>
                <ListItemButton
                  button
                  selected={selectedMenuItem === "Change Password"}
                  onClick={() => handleMenuItemClick("Change Password")}
                >
                  <i
                    class="fa fa-unlock-alt"
                    style={
                      selectedMenuItem === "Change Password"
                        ? { color: "#0b69c9" }
                        : {}
                    }
                    aria-hidden="true"
                  ></i>
                  &nbsp;
                  <ListItemText
                    primary="Change Password"
                    style={
                      selectedMenuItem === "Change Password"
                        ? { color: "#0b69c9" }
                        : {}
                    }
                  />
                </ListItemButton>
                <ListItemButton
                  button
                  selected={selectedMenuItem === "Two-Factor Authentication"}
                  onClick={() =>
                    handleMenuItemClick("Two-Factor Authentication")
                  }
                >
                  <i
                    class="fa fa-key"
                    style={
                      selectedMenuItem === "Two-Factor Authentication"
                        ? { color: "#0b69c9" }
                        : {}
                    }
                    aria-hidden="true"
                  ></i>
                  &nbsp;
                  <ListItemText
                    primary="Two-Factor Authentication"
                    style={
                      selectedMenuItem === "Two-Factor Authentication"
                        ? { color: "#0b69c9" }
                        : {}
                    }
                  />
                </ListItemButton>
                {/* Add more menu items as needed */}
              </List>
            </div>
            {/* Right Content */}
            <div className="listDataClass" style={{ gap: "20px", flex: 1 }}>
              {/* General Settings===============> */}
              {selectedMenuItem === "General" && (
                <div>
                  <div className="profileEditPencil">
                    <div
                      className="establishmentText"
                      style={{ paddingBottom: "32px" }}
                    >
                      <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                      General Information
                    </div>

                    <div>
                      <Tooltip title="Edit" arrow placement="top">
                        <IconButton
                          color="primary"
                          size="small"
                          tip="hover"
                          onClick={handleEdit}
                        >
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <Row className="addUserRows" style={{ paddingTop: "10px" }}>
                    <Col md={6}>
                      <CsTextField
                        id="outlined-basic"
                        label="User Name"
                        variant="outlined"
                        fullWidth
                        onChange={handleUserName}
                        size="small"
                        value={userName ?? userDetails.name}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ backgroundColor: "#ebebeb", color: "black" }}
                        required
                        disabled
                      />
                    </Col>
                    <Col md={6}>
                      <CsTextField
                        id="outlined-basic"
                        label="Company"
                        variant="outlined"
                        fullWidth
                        onChange={handleCompany}
                        size="small"
                        value={company ?? userDetails.company}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                        style={{ backgroundColor: "#ebebeb", color: "black" }}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="addUserRows">
                    <Col md={6}>
                      <CsTextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={userDetails.email}
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ backgroundColor: "#ebebeb", color: "black" }}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <CsTextField
                        id="outlined-basic"
                        label="Designation"
                        variant="outlined"
                        fullWidth
                        onChange={handleDesignation}
                        size="small"
                        value={designation ?? userDetails.designation}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ backgroundColor: "#ebebeb", color: "black" }}
                        disabled
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="addUserRows">
                    <Col md={6}>
                      <CsTextField
                        label="Type Of Role"
                        variant="outlined"
                        fullWidth
                        value={userDetails.user_role}
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ backgroundColor: "#ebebeb", color: "black" }}
                        disabled
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <CsTextField
                        id="outlined-basic"
                        label="Contact Number"
                        variant="outlined"
                        fullWidth
                        type="text"
                        onChange={handleContactNumber}
                        size="small"
                        value={mobNo ?? userDetails.mobNo}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          maxLength: 9,
                        }}
                        required
                        style={{ backgroundColor: "#ebebeb", color: "black" }}
                        disabled
                      />
                    </Col>
                  </Row>
                </div>
              )}

              {/* Edit Profile===============> */}

              {selectedMenuItem === "Edit Profile" && (
                <div>
                  <div
                    className="establishmentText"
                    style={{ paddingBottom: "20px" }}
                  >
                    <i class="fas fa-user-edit"></i>&nbsp; Edit Profile
                  </div>
                  <Row className="editSave">
                    <Col md={1}>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                  <Row className="addUserRows" style={{ paddingTop: "10px" }}>
                    <Col md={6}>
                      <CsTextField
                        id="outlined-basic"
                        label="User Name"
                        variant="outlined"
                        fullWidth
                        onChange={handleUserName}
                        size="small"
                        value={userName ?? userDetails.name}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={denied}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <CsTextField
                        id="outlined-basic"
                        label="Company"
                        variant="outlined"
                        fullWidth
                        onChange={handleCompany}
                        size="small"
                        value={company ?? userDetails.company}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={denied}
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="addUserRows">
                    <Col md={6}>
                      <CsTextField
                        id="outlined-basic"
                        label="Designation"
                        variant="outlined"
                        fullWidth
                        onChange={handleDesignation}
                        size="small"
                        value={designation ?? userDetails.designation}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={denied}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <CsTextField
                        id="outlined-basic"
                        label="Contact Number"
                        variant="outlined"
                        fullWidth
                        type="text"
                        onChange={handleContactNumber}
                        size="small"
                        value={mobNo ?? userDetails.mobNo}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          maxLength: 9,
                        }}
                        disabled={denied}
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="justify-content-md-end">
                    <Col md={1} className="footerButton"></Col>
                  </Row>
                </div>
              )}

              {/* ChangePassword===============> */}

              {selectedMenuItem === "Change Password" && (
                <>
                <Row>
                     <div
                      className="establishmentText"
                      style={{ paddingBottom: "20px" }}
                    >
                      <i class="fa fa-unlock-alt"></i>&nbsp; Change Password
                    </div>
                </Row>
                <Row>
                  <Col md={6} style={{display:"flex",justifyContent:"space-around"}} >

                    <div className="passDetails">
                      <div
                        className="passDetheader"
                        style={{ color: "Grey", fontWeight: "800" }}
                      >
                        Passwords must contain :
                      </div>
                      <div>
                        <div
                          className="passDetheader"
                          style={
                            isLengthValid
                              ? {
                                  color: "#0B69C9",
                                  textDecoration: "line-through",
                                }
                              : { color: "Grey" }
                          }
                        >
                          At least 6 characters
                        </div>
                        <div
                          className="passDetheader"
                          style={
                            hasUpperCase
                              ? {
                                  color: "#0B69C9",
                                  textDecoration: "line-through",
                                }
                              : { color: "Grey" }
                          }
                        >
                          At least 1 upper case letter (A-Z)
                        </div>
                        <div
                          className="passDetheader"
                          style={
                            hasLowerCase
                              ? {
                                  color: "#0B69C9",
                                  textDecoration: "line-through",
                                }
                              : { color: "Grey" }
                          }
                        >
                          At least 1 lower case letter (a-z)
                        </div>
                        <div
                          className="passDetheader"
                          style={
                            hasNumber
                              ? {
                                  color: "#0B69C9",
                                  textDecoration: "line-through",
                                }
                              : { color: "Grey" }
                          }
                        >
                          At least 1 number (0-9)
                        </div>
                      </div>
                    </div>
                    <IconLock width="13rem" height="13rem"/>
                  </Col>
                  <Col md={6} style={{display:"flex",justifyContent:"center"}} >
                    <div className="pwdModalBody">
                      <div className="pwdTextfield">
                        <TextField
                          type="text"
                          label="Current Password"
                          variant="outlined"
                          value={currentPassword}
                          color={
                            validTrue
                              ? "success"
                              : validTrue === false
                              ? "error"
                              : "primary"
                          }
                          size="small"
                          fullWidth
                          helperText={
                            validTrue === false && "Password is incorrect"
                          }
                          onChange={handleCurrentPasswordChange}
                          focused
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {validTrue === false || validTrue === null ? (
                                  !loader ? <Button
                                    variant="contained"
                                    size="small"
                                    onClick={validatePassword}
                                    disabled={currentPassword === ""}
                                  >
                                    Verify
                                  </Button>
                                  :
                                  <CircularProgress
                                  size={24}
                                  style={{
                                    top: '50%',
                                    left: '50%',
                                  }}
                                />
                                ) : (
                                  <IconButton
                                    color="success"
                                    size="small"
                                    disableRipple
                                  >
                                    <i
                                      class="fa fa-check"
                                      aria-hidden="true"
                                    ></i>
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div className="pwdTextfield">
                        <TextField
                          type={showNewPassword ? "text" : "password"}
                          label="New Password"
                          variant="outlined"
                          value={newPassword}
                          size="small"
                          fullWidth
                          disabled={validTrue === false || validTrue === null}
                          style={
                            validTrue === false || validTrue === null
                              ? { backgroundColor: "#ebebeb", color: "black" }
                              : {}
                          }
                          onChange={handleNewPasswordChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleNewPasswordVisibility}
                                  edge="end"
                                  disabled={validTrue === false || validTrue === null}
                                >
                                  {showNewPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div className="pwdTextfield">
                        <TextField
                          error={passwordsMatch === false}
                          type={showConfirmPassword ? "text" : "password"}
                          label={
                            passwordsMatch === false
                              ? "Password and confirm password does not match"
                              : "Confirm Password"
                          }
                          variant="outlined"
                          size="small"
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                          fullWidth
                          disabled={
                            validTrue === false ||
                            validTrue === null ||
                            validNewPass === false
                          }
                          style={
                            validTrue === false ||
                            validTrue === null ||
                            validNewPass === false
                              ? { backgroundColor: "#ebebeb", color: "black" }
                              : {}
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleConfirmPasswordVisibility}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <div className="pwdConfirmButton">
                          <Button
                            variant="contained"
                            size="small"
                            onClick={handleSubmit}
                            fullWidth
                            disabled={
                              validTrue === false ||
                              validTrue === null ||
                              passwordsMatch === false
                            }
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                </>
              )}

              {selectedMenuItem === "Two-Factor Authentication" && (
                <Row>
                  <Col md={6}>
                    <div
                      className="establishmentText"
                      style={{ paddingBottom: "10px"}}
                    >
                      <i class="fa fa-key"></i>&nbsp; Secure Your Account
                    </div>
                    <div
                    style={{ paddingLeft:"20px",paddingBottom: "10px" ,fontSize:"16px",fontWeight:700 }}>
                      Two-Factor Authentication
                    </div>
                    <Row style={{ paddingLeft:"20px"}}>
                      <Col md={10}>
                        Two-factor authentication adds an additional layer of
                        security to your account by requiring more than just a
                        password to log in, &nbsp; &nbsp; <br clear="all"/>
                        <b>Ensure the password is verified prior to activating two-factor authentication.</b>
                        <div style={{paddingTop:"20px"}}>
                            <TextField
                          type={twofactorTrue ? "password" : "text"}
                          label="Enter your Password"
                          variant="outlined"
                          value={twofacCheck}
                          color={
                            twofactorTrue
                              ? "success"
                              : twofactorTrue === false
                              ? "error"
                              : "primary"
                          }
                          size="small"
                          fullWidth
                          helperText={
                            twofactorTrue === false && "Password is incorrect"
                          }
                          onChange={handleTwofactorCheck}
                          focused
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {twofactorTrue === false || twofactorTrue === null ? (
                                 !loader ? (
                                   <Button
                                    variant="contained"
                                    size="small"
                                    onClick={()=>{validatePassword("fromTwoFactor")}}
                                    disabled={twofacCheck === ""}
                                  >
                                    Verify
                                  </Button>
                                  ) :
                                  <CircularProgress
                                  size={24}
                                  style={{
                                    top: '50%',
                                    left: '50%',
                                  }}
                                />

                                ) : (
                                  <IconButton
                                    color="success"
                                    size="small"
                                    disableRipple
                                  >
                                    <i
                                      class="fa fa-check"
                                      aria-hidden="true"
                                    ></i>
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                        </div>

                             { twoFactorEnabled &&
                             <div style={{display:"flex",alignItems:"center",paddingTop:"10px"}}>

                             <InputLabel>Enable Two-Factor Authentication</InputLabel>
                             <Switch
                                checked={enableAuth}
                                onChange={handleEnableAuth}
                                color="primary"
                              />
                              </div>
                              }

                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} className="vectorTwofac">
                    <IconTwofactor width="350px" height="350px" />
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </MainWrapper>
    </MediaQuery>
    <MediaQuery maxDeviceWidth={1023}>
     <UserSettings/>
    </MediaQuery>
    </>
  );
};

export default UserProfilePage;
