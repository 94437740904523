import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./UserList.css";
import NavHome from "../../Nav/navHome";
import { Alert, Autocomplete, Button, Snackbar } from "@mui/material";
import { IconSettingSpanner } from '../../../assets/css/icons'
import "./UserList.css";
import { useState } from "react";
import { useEffect,useContext } from "react";
import { GetRoleDropdown, AddNewUser } from "../../services/api-servics";
import { useHistory } from "react-router";
import CsTextField from "../../GlobalComponents/CsTextField";
import MediaQuery from "react-responsive";
import MainWrapper from "../../GlobalComponents/MainWrapper";
import MainContainer from "../../MobileUI/GlobalComponents/MainContainer";
import MobTextField from "../../MobileUI/GlobalComponents/MobTextField"
import Context from "../../Context"
import MobButton from "../../MobileUI/GlobalComponents/Button";
function AddUser(props) {
  const [userRoles, setUserRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [userName, setUserName] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [userDesignation, setUserDesignation] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userContactNo, setUserContactNo] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [denied, setDenied] = useState(false);
  const { formDataState, setformDataState } = useContext(Context);

  const [errors, setErrors] = useState({
    selectedRole: "",
    userName: "",
    userCompany: "",
    userDesignation: "",
    userEmail: "",
    userPassword: "",
    userContactNo: "",
  });

  const deviceWidth= window.innerWidth;

  const history = useHistory();
  useEffect(() => {
    getUserRoles();
  }, []);

  useEffect(() => {
    if (props?.location.state) {
      const data = props?.location?.state;
      setUserName(data.userName);
      setUserCompany(data.userCompany);
      setUserDesignation(data.userDesignation);
      setUserEmail(data.userEmail);
      setUserPassword(data.userPassword);
      setUserContactNo(data.userContactNo);
    }
  }, [props.location.state]);

  const getUserRoles = (e) => {
    GetRoleDropdown({}).then(async (rsp) => {
      if (rsp.data.data) {
        const usersData = rsp.data.data;
        setUserRoles(usersData);
        if (props?.location?.state) {
          const foundRole = usersData.find(
            (obj) => obj.value === props?.location?.state.selectedRole
          );
          setSelectedRole(foundRole);
        }
      }
    });
  };

  const insertNewUser = (e) => {
    AddNewUser({
      name: userName,
      email: userEmail,
      contactnumber: +userContactNo,
      company: userCompany,
      manager: userDesignation,
      password: userPassword,
      user_role: selectedRole.value,
      editUser: props.location?.state
        ? props?.location?.state?.editUser
        : "false",
    }).then(async (rsp) => {
      const data = rsp.data
      if (data.message==="email already exist"){
        setDenied(true);
        setErrors({...errors,userEmail:"email already exist"})
      }
      else{
        setDenied(false);
        handleRedirect();
      }
    });
  };
  const valiadteEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!userName) {
      newErrors.userName = "Name is required";
      setSnackbarMessage("Name is required");
      isValid = false;
    } else {
      newErrors.userName = "";
    }

    if (!selectedRole) {
      newErrors.selectedRole = "Select a role from the list";
      setSnackbarMessage("Select a role from the list");
      isValid = false;
    } else {
      newErrors.selectedRole = "";
    }

    if (!userCompany) {
      newErrors.userCompany = "Company is required";
      setSnackbarMessage("Company is required");
      isValid = false;
    } else {
      newErrors.userCompany = "";
    }

    if (!userDesignation) {
      newErrors.userDesignation = "Designation is required";
      setSnackbarMessage("Designation is required");
      isValid = false;
    } else {
      newErrors.userDesignation = "";
    }

    if (!valiadteEmail(userEmail)) {
      newErrors.userEmail = "Enter valid Email address";
      setSnackbarMessage("Enter valid Email address");
      isValid = false;
    } else {
      newErrors.userEmail = "";
    }
    if (!userContactNo) {
      newErrors.userContactNo = "Contact No is required";
      isValid = false;
    } else {
      newErrors.userContactNo = "";
    }

    setErrors(newErrors);
    if (!isValid) {
      setSnackbarOpen(true);
    }

    return isValid;
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleName = (event) => {
    setUserName(event.target.value);
  };
  const handleCompany = (event) => {
    setUserCompany(event.target.value);
  };
  const handleEmail = (event) => {
    setUserEmail(event.target.value);
  };
  const handleDesignation = (event) => {
    setUserDesignation(event.target.value);
  };
  const handlePassword = (event) => {
    setUserPassword(event.target.value);
  };
  const handleContactNumber = (event) => {
    const inputValue = event.target.value.replace(/\D/g, "").slice(0, 10);
    setUserContactNo(inputValue);
  };

  const handleSave = () => {
    setDenied(true)
    if (validateForm()) {
      setDenied(false)
      insertNewUser();
    }
  };
  const handleRedirect = (item) => {
    if(deviceWidth>1023){
        let path = "/user_list";
        history.push({
          pathname: path,
        });
      } else {
        let path = "/settings-menu";
        history.push({
          pathname: path,
        });
      }
  };

  return (
    <>
     {/* -----------------Mob UI----------------- */}
     <MediaQuery maxDeviceWidth={1023}>
        <MainContainer isHeader>
            <div className="contentTab">
                <div className="contentHeader">
                    <div className="Top">
                      <IconSettingSpanner />
                      <span>Settings</span>
                    </div>
                    <div className="Bottom">
                      <span>User Information</span>
                    </div>
                </div>
                <div className="contentData">
                    
                <div className="formFields">
                <label className="formFieldLabels">
                  User Name<sup>*</sup>
                </label>
                <div>
                  <MobTextField
                    fullWidth
                    helperText={
                      denied &&
                      userName === "" &&
                      errors.userName.length > 0 && userName ==="" &&
                      <div>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        User Name is Required
                      </div>
                    }
                    value={userName}
                    onChange={handleName}
                  />
                </div>
              </div>

              <div className="formFields">
                <label className="formFieldLabels">
                  Email <sup>*</sup>
                </label>
                <div>
                  <MobTextField
                    fullWidth
                    helperText={
                      denied &&
                      (errors.userEmail.length > 0 || userEmail === "" ) &&
                      <div>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        {errors.userEmail}
                      </div>
                    }
                    value={userEmail}
                    onChange={handleEmail}
                  />
                </div>
              </div>

              <div className="formFields">
                <label className="formFieldLabels">
                  Contact Number<sup>*</sup>
                </label>
                <div>
                  <MobTextField
                    fullWidth
                    helperText={
                      denied &&
                      errors.userContactNo.length > 0 && userContactNo ==="" &&
                      <div>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        Contact Number is Required
                      </div>
                    }
                    value={userContactNo}
                    onChange={handleContactNumber}
                  />
                </div>
              </div>

              <div className="formFields">
                <label className="formFieldLabels">
                  Company <sup>*</sup>
                </label>
                <div>
                  <MobTextField
                    fullWidth
                    helperText={
                      denied &&
                      errors.userCompany.length > 0 && userCompany ==="" &&
                      <div>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        Company Name is Required
                      </div>
                    }
                    value={userCompany}
                    onChange={handleCompany}
                  />
                </div>
              </div>

              <div className="formFields">
                <label className="formFieldLabels">
                  Designation <sup>*</sup>
                </label>
                <div>
                  <MobTextField
                    fullWidth
                    helperText={
                      denied &&
                      errors.userDesignation.length > 0 && userDesignation ==="" &&
                      <div>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        Designation is Required
                      </div>
                    }
                    value={userDesignation}
                    onChange={handleDesignation}
                  />
                </div>
              </div>

              {/* <div className="formFields">
                <label className="formFieldLabels">
                  Type of Role<sup>*</sup>
                </label>
                <div>
                  <MobTextField
                    fullWidth
                    helperText={
                      denied &&
                      userName === "" &&
                      errors.userName.length > 0 &&
                      <div>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        Employee Name is Required
                      </div>
                    }
                    value={userName}
                    onChange={(e) =>
                      setformDataState({
                        ...formDataState,
                        userName: e.target.value,
                      })
                    }
                  />
                </div>
              </div> */}

              <div className="formFields">
                <label className="formFieldLabels">
                  Type of Rule<sup>*</sup>
                </label>
                <div>
                  <Autocomplete
                     options={userRoles}
                     getOptionLabel={(option) => option.label}
                     value={selectedRole}
                     onChange={(event, newValue) => {
                       setSelectedRole(newValue);
                     }}
                     renderInput={(params) => (
                      <MobTextField
                        {...params}
                        fullWidth
                        helperText={
                          denied &&
                          errors.selectedRole.length > 0 && selectedRole === null &&
                          <div>
                            <span className="infoSign">
                              <i class="fa fa-info-circle" aria-hidden="true"></i>
                              &nbsp;
                            </span>
                            Select a role from the list
                          </div>
                        }
                      />
                    )}
                    clearIcon={null}
                  />
                </div>
              </div>
            {/* frm end */}
            <span className="formMsg">Upon sucessful addition, the login details for the added user will be sent to their email address.</span>
           </div>
           <div className="saveTab">
              <MobButton className="save" children="Save" onClick={handleSave} />
           </div>
          
          </div>
        </MainContainer>
     </MediaQuery>

      {/* -----------------Desktop UI----------------- */}
     <MediaQuery minDeviceWidth={1024}>
      <MainWrapper>
        <Card>
          <Card.Header className="cardHeader">
            <div className="establishmentText">User Information</div>
          </Card.Header>
          <Card.Body>
            <Row className="addUserRows" style={{ paddingTop: "10px" }}>
              <Col md={6}>
                <CsTextField
                  id="outlined-basic"
                  label="User Name"
                  variant="outlined"
                  fullWidth
                  onChange={handleName}
                  size="small"
                  value={userName}
                  InputLabelProps={{
                    shrink: true,
                }}
                required
                />
              </Col>
              <Col md={6}>
                <CsTextField
                  id="outlined-basic"
                  label="Company"
                  variant="outlined"
                  fullWidth
                  onChange={handleCompany}
                  size="small"
                  value={userCompany}
                  InputLabelProps={{
                    shrink: true,
                }}
                required
                />
              </Col>
            </Row>
            <Row className="addUserRows">
              <Col md={6}>
                <CsTextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  onChange={handleEmail}
                  size="small"
                  value={userEmail}
                  disabled={props.location?.state?.editUser}
                  InputLabelProps={{
                    shrink: true,
                }}
                required
                />
              </Col>
              <Col md={6}>
                <CsTextField
                  id="outlined-basic"
                  label="Designation"
                  variant="outlined"
                  fullWidth
                  onChange={handleDesignation}
                  size="small"
                  value={userDesignation}
                  InputLabelProps={{
                    shrink: true,
                }}
                required
                />
              </Col>
            </Row>
            <Row className="addUserRows">
              <Col md={6}>
                <CsTextField
                  id="outlined-basic"
                  label="Contact Number"
                  variant="outlined"
                  fullWidth
                  onChange={handleContactNumber}
                  size="small"
                  value={userContactNo}
                  InputLabelProps={{
                    shrink: true,
                }}
                required
                />
              </Col>
              <Col md={6}>
                <Autocomplete
                  options={userRoles}
                  getOptionLabel={(option) => option.label}
                  value={selectedRole}
                  onChange={(event, newValue) => {
                    setSelectedRole(newValue);
                  }}
                  renderInput={(params) => (
                    <CsTextField
                      {...params}
                      label="Type Of Role"
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                    }}
                    required
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="instructionAdduser">
            Upon successful addition, the login details for the added user will be sent to their email address.
              </Col>
            </Row>
          </Card.Body>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={1000}
            onClose={handleCloseSnackbar}
            message={snackbarMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert severity="error">{snackbarMessage}</Alert>
            </Snackbar>
            
          <Card.Footer className="addUserCardFooter">
            
            <div>
              <Button variant="contained" size="small" onClick={handleSave}>
                Save
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </MainWrapper>
    </MediaQuery>
  
  </>
  );
}

export default AddUser;
