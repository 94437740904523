 const FormatDate =()=>{
    //TimeStamp
    // Get the current timestamp in milliseconds
    const currentTimestamp = Date.now();

    // Create a new Date object using the timestamp
    const currentDate = new Date(currentTimestamp);

    // Format the date to display only time in Indian format
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Use 12-hour format
    };

    const formattedTime = currentDate.toLocaleString("en-IN", options);

    return formattedTime;
} 

export default FormatDate;