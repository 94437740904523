import React, { useState } from "react";
import ChatBot from "../../Component/ChatBot/ChatBot"
import "./style.css";

export default function ChatBotBtn(props) {
  const [hide, setHide] = useState(true);
  const handleChatBotToggle = () => {
    setHide(!hide);
  };
  return (
    <>
      <div className="chatBotBtn" onClick={handleChatBotToggle}>
        <button className="chatBot_btn">
          {/* <span className="material-symbols-outlined">chat</span> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="25" viewBox="0 0 60 42" fill="none">
            <path d="M45.6517 41.7833C45.8529 41.925 46.0931 42.0008 46.3392 42.0002C46.5853 41.9995 46.8251 41.9226 47.0256 41.7799C47.2287 41.6316 47.3828 41.4262 47.4672 41.1888L47.9773 39.6221C48.1079 39.2292 48.3282 38.8721 48.6207 38.5792C48.9132 38.2863 49.27 38.0655 49.6627 37.9344L51.25 37.4209C51.4847 37.3359 51.6868 37.1794 51.8278 36.9735C51.9689 36.7676 52.0418 36.5227 52.0362 36.2731C52.0307 36.0236 51.947 35.7821 51.7969 35.5827C51.6469 35.3832 51.438 35.2359 51.1998 35.1615L49.6308 34.6502C49.2376 34.5199 48.8803 34.2997 48.5871 34.0071C48.294 33.7146 48.0731 33.3577 47.9419 32.9648L47.4261 31.381C47.3431 31.1476 47.1894 30.946 46.9864 30.804C46.7834 30.6621 46.5412 30.587 46.2935 30.5892C46.0458 30.5913 45.805 30.6706 45.6045 30.816C45.4039 30.9613 45.2537 31.1656 45.1747 31.4004L44.6532 32.9979C44.5226 33.3802 44.3068 33.7278 44.0221 34.0144C43.7375 34.3011 43.3914 34.5193 43.01 34.6525L41.4239 35.1626C41.1906 35.2458 40.9891 35.3996 40.8472 35.6026C40.7053 35.8056 40.6302 36.0477 40.6322 36.2954C40.6342 36.543 40.7133 36.7839 40.8584 36.9846C41.0035 37.1852 41.2076 37.3357 41.4421 37.4152L43.01 37.923C43.4048 38.0543 43.7633 38.2762 44.0568 38.5711C44.3503 38.866 44.5706 39.2255 44.7 39.6209L45.2158 41.2082C45.2979 41.441 45.4509 41.6418 45.6517 41.7833Z" fill="url(#paint0_linear_61_1057)" />
            <path d="M35.7076 9.07617H14.9725C12.2527 9.07617 10.0479 11.281 10.0479 14.0008V23.8647C16.83 26.4078 27.0013 27.3491 40.6322 26.0531V14.0008C40.6322 11.281 38.4274 9.07617 35.7076 9.07617Z" fill="url(#paint1_linear_61_1057)" />
            <path d="M39.4659 30.5889C38.9475 30.4593 15.5189 30.2999 10.0479 28.7746C10.0479 30.9518 12.8125 32.5329 14.1949 33.0512H18.6011V38.235C18.6011 39.5828 19.2923 39.4014 19.6379 39.1422L26.5064 33.0512H35.9668C37.7377 32.6793 39.9843 30.7185 39.4659 30.5889Z" fill="url(#paint2_linear_61_1057)" />
            <path d="M6.93742 15.1671V12.4457L4.3455 13.8712C-7.05886 22.3726 6.63562 28.3381 15.1025 30.3252C23.9724 31.2227 25.1418 31.0765 35.5784 31.1028C44.2861 30.93 49.0125 29.2066 50.3517 28.5154C66.8362 20.6361 57.2202 14.0008 50.3517 11.6681V6.2251C49.1076 1.04134 43.9583 -0.081866 41.5392 0.0045032H24.5623C20.5189 0.0045032 19.5081 4.15157 19.5081 6.2251C22.316 6.1819 29.5128 6.12142 35.8371 6.2251C42.1613 6.32878 44.0879 11.0201 44.2607 13.3528V23.9796C48.0967 23.9796 49.9197 20.6965 50.3517 19.055V14.26C52.0105 14.571 53.9803 15.9447 54.7579 16.5927C61.3932 22.8132 48.3646 26.7011 41.0209 27.8674C20.4153 28.5154 16.657 28.1266 6.93742 24.2388C-0.838275 21.1285 3.69755 16.8951 6.93742 15.1671Z" fill="url(#paint3_linear_61_1057)" />
            <defs>
              <linearGradient id="paint0_linear_61_1057" x1="46.3343" y1="30.5891" x2="46.3343" y2="42.0002" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2B65D6" />
                <stop offset="1" stop-color="#6FB5F7" />
              </linearGradient>
              <linearGradient id="paint1_linear_61_1057" x1="25.3401" y1="9.07617" x2="25.3401" y2="39.3244" gradientUnits="userSpaceOnUse">
                <stop stop-color="#B0C2FF" />
                <stop offset="1" stop-color="#2839D1" />
              </linearGradient>
              <linearGradient id="paint2_linear_61_1057" x1="25.3401" y1="9.07617" x2="25.3401" y2="39.3244" gradientUnits="userSpaceOnUse">
                <stop stop-color="#B0C2FF" />
                <stop offset="1" stop-color="#2839D1" />
              </linearGradient>
              <linearGradient id="paint3_linear_61_1057" x1="29.7268" y1="0" x2="29.7268" y2="31.1028" gradientUnits="userSpaceOnUse">
                <stop stop-color="#3BA8F7" />
                <stop offset="1" stop-color="#21DFD4" />
              </linearGradient>
            </defs>
          </svg>
        </button>
      </div>
      <ChatBot hide={hide} setHide={setHide} props={props}/>
    </>
  );
}

 
