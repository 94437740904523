import { React, useEffect, useState } from "react";
import { IconTwofactor } from "../../../assets/css/icons";
import styles from "./settingsMenu.module.scss";
import { Switch } from "@mui/material";
import { ValidatePassword, TwoFactorAuth } from "../../services/api-servics";
function TwoFactorMenu() {
  const [password, setPassword] = useState("");
  const [verify, setVerify] = useState(false);

  const [loader, setloader] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  const [enableAuth, setEnableAuth] = useState(false);
  const usermail = localStorage.getItem("email");
  const handleEnableAuth = () => {
    setEnableAuth(!enableAuth);
    TwoFactorAuth({
      email: localStorage.getItem("email"),
      two_factor_check: !enableAuth,
    }).then(async (rsp) => {
      console.log();
    });
  };
  const handleVerify = () => {
    setloader(true);
    ValidatePassword({
      username: usermail,
      password: password,
    }).then(async (rsp) => {
      setloader(false);
      const data = rsp.data.status;
      if (data === "correct") {
        setVerify(true);
        setWrongPassword(false);
      } else {
        setVerify(false);
        setWrongPassword(true);
        // setTimeout(() => {
        //   setWrongPassword(false);
        // }, 550);
      }
    });
  };

  return (
    <>
      <h1>Secure Your Account</h1>
      <div className={styles.menuWrapper}>
        <div className={styles.headBody}>
          <div className={styles.icon}>
            <IconTwofactor />
          </div>

          <div className={styles.instruction}>
            <h1>Two-Factor Authentication</h1>
            <p>
              A Two-factor authentication adds an additional layer of security
              to your account by requiring more than just a password to log in.
            </p>
          </div>
        </div>
      </div>

      <h1 id={styles.bHeading}>
        Ensure the password is verified prior to activating two-factor
        authentication.
      </h1>

      <h1
        id={styles.label}
        className={wrongPassword ? styles.wrongPassword : null}
      >
        Enter Your Password
      </h1>
      <input
        type="password"
        value={password}
        disabled={verify}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        className={styles.menuTextBox}
      />
      { wrongPassword && (<span className={styles.wrongPass}>password is wrong</span>)}
      <div className={styles.buttonWrapper}>
        {!verify ? (
          <button
            disabled={wrongPassword}
            className={styles.submit}
            onClick={() => {
              handleVerify();
            }}
          >
            Verify
          </button>
        ) : (
          <div className={styles.cancel}>
               <span>Enable Two-Factor Authentication  <Switch
              checked={enableAuth}
              onChange={handleEnableAuth}
              color="primary"
            /></span>
           

            <button
              disabled={wrongPassword}
              className={styles.submit}
              onClick={() => {
                setVerify(false)
                setEnableAuth(false)
                setPassword("")
              }}
            >
             cancel
            </button>
          </div>
         
          
        )}
      </div>
    </>
  );
}

export default TwoFactorMenu;
