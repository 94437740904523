import React from "react";
import { useHistory } from "react-router";
import { IconCardCalendar, IconLocation } from "../../../assets/css/icons";
import css from "./osha300.module.scss";
function Osha300Card(props) {
  const data = props.data;
  const formatDate = (inputDate) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    const day = new Date(inputDate).getDate();
    const month = new Date(inputDate).toLocaleDateString("en-US", {
      month: "short",
    });
    const year = new Date(inputDate).getFullYear();
    const daySuffix =
      day >= 11 && day <= 13
        ? "th"
        : day % 10 === 1
        ? "st"
        : day % 10 === 2
        ? "nd"
        : day % 10 === 3
        ? "rd"
        : "th";
    return `${day}${daySuffix} ${month} ${year}`;
  };
  const formattedDate = formatDate(data.InjuryDate);
  const history = useHistory();
  const viewDetails = () => {
    let path = `/osha_individual/${data.EmployeeName}/${data.CaseNo}/${data.Year}`;
    history.push({
      pathname: path,
      data: data,
      fromOsha300: true,
    });
  };
  return (
    <div className={css.oshacardMain} onClick={viewDetails}>
      <div className={css.oshaCardHeader}>
        <div className={css.oshaIncCategory}>{data.IllnessClassification}</div>
        <div className={css.oshaformHeader}>
          <div className={css.oshaNum}>{data.CaseNo}</div>
          <div className={css.oshaFormName}>300</div>
        </div>
      </div>
      <div className={css.oshaEmployeeName}>{data.EmployeeName}</div>
      <div className={css.oshaJob}>{data.JobTitle}</div>
      <div className={css.oshaDate}>
        <IconCardCalendar />
        <div style={{ width: "100px" }}>{formattedDate}</div>
        <IconLocation />
        {data.EventLocation}
      </div>
    </div>
  );
}

export default Osha300Card;
