import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./Establishment.css";
import NavHome from "../../Nav/navHome";
import getTableStyles from "../../../tablestyles";
import DataTable from "react-data-table-component";
import {
  Alert,
  Autocomplete,
  Button,
  InputLabel,
  MenuItem,
  Snackbar,
  TextField,
} from "@mui/material";
import {
  getEmployeeNumber,
  YearlyDataTable,
  YearlyDetailsList,
} from "../../services/api-servics";
import { useEffect } from "react";
import MainWrapper from "../../GlobalComponents/MainWrapper";
import MediaQuery from "react-responsive";
import MainContainer from "../../MobileUI/GlobalComponents/MainContainer";
import ManageYearlyDeatils from "../SettingsMenu/ManageYearlyDeatils";

function YearlyDetails(props) {
  const initialYear = new Date().getFullYear() - 1;
  const [yearlyData, setYearlyData] = useState([]);
  const [employeeNumbers, setEmployeeNumbers] = useState([]);
  const [selectedYear, setSelectedYear] = useState(initialYear);
  const [numOfEmployees, setNumOfEmployees] = useState(null);
  const [totalHour, setTotalHour] = useState("");
  const [avgEmployee, setAvgEmployee] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [errors, setErrors] = useState({
    numOfEmployees: '',
    totalHour: '',
    avgEmployee: '',
  });

  useEffect(() => {
    getYearlyData();
    getEmployeeNum();
  }, []);

  useEffect(() => {}, [yearlyData]);

  const getYearlyDetailsList = (e) => {
    YearlyDetailsList({
      establishmentID: +e.establishmentID,
      year_info: +selectedYear,
      annavgNumber: +avgEmployee,
      totalhrwkd: +totalHour,
      peakNumberOfEmployees: +numOfEmployees.value,
    }).then(async (rsp) => {
      if (rsp.data.data) {
        const yrDetail = rsp.data.data;
        setYearlyData(yrDetail);
        setSelectedYear(initialYear);
        setNumOfEmployees(null);
        setTotalHour("");
        setAvgEmployee("");
      }
    });
  };

  const getEmployeeNum = (e) => {
    getEmployeeNumber({}).then(async (rsp) => {
      if (rsp.data.data) {
        const empNum = rsp.data.data;
        setEmployeeNumbers(empNum);
      }
    });
  };
  const getYearlyData = (e) => {
    YearlyDataTable({
      establishmentID:props.location.state.establishmentID
    }).then(async (rsp) => {
      if (rsp.data.data) {
      const yrdata= rsp.data.data
      setYearlyData(yrdata)
      }
    });
  };

  
  const labelStyle = {
    color: "#4d514c",
    fontFamily: "Open Sans !important",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!numOfEmployees) {
      newErrors.numOfEmployees = 'Peak number of employees in the year is required';
      setSnackbarMessage('Peak number of employees in the year is required');
      isValid = false;
    } else {
      newErrors.numOfEmployees = '';
    }
  
    
    if (!totalHour) {
      newErrors.totalHour = 'Total hour is required';
      setSnackbarMessage('Total hour is required');
      isValid = false;
    } else {
      newErrors.totalHour = '';
    }
  
    
    if (!avgEmployee) {
      newErrors.avgEmployee = 'Average employee count is required';
      setSnackbarMessage('Average employee count is required');
      isValid = false;
    } else {
      newErrors.avgEmployee = '';
    }
    setErrors(newErrors);
    if (!isValid) {  
        setSnackbarOpen(true);
      }
    return isValid;
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const handleEmployeeLimit = (event) => {
    setNumOfEmployees(event.target.value);
  };
  const handleTotalHour = (event) => {
    setTotalHour(event.target.value);
  };
  const handleAvgEmployeee = (event) => {
    setAvgEmployee(event.target.value);
  };
  const addYearlyDetails = () => {
    if(validateForm()){
    getYearlyDetailsList(props.location.state);
  }
  };
  const startYear = 2000;
  const endYear = 2040;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );

  const data = [
    {
      year: "2015",
      annavgNumber: "89",
      totalhrwkd: "145899",
      peakNumberOfEmployees: "Between 20 And 99",
    },
    {
      year: "2020",
      annavgNumber: "75",
      totalhrwkd: "145000",
      peakNumberOfEmployees: "Between 20 And 99",
    },
    {
      year: "2018",
      annavgNumber: "90",
      totalhrwkd: "148899",
      peakNumberOfEmployees: "Between 20 And 99",
    },
    {
      year: "2014",
      annavgNumber: "150",
      totalhrwkd: "236649",
      peakNumberOfEmployees: "Between 20 And 99",
    },
  ];

  const columns = [
    {
      name: "Year",
      selector: (row) => row.year,
      width: "105px",
      style: {
        display: "flex",
        justifyContent: "center",
      },
    },

    {
      name: "Annual Average Number Of Employees",
      selector: (row) => row.annavgNumber,
      width: "300px",
      style: {
        display: "flex",
        justifyContent: "center",
      },
    },

    {
      name: "Total Hours Worked By All Employees",
      selector: (row) => row.totalhrwkd,
      width: "300px",
      style: {
        display: "flex",
        justifyContent: "center",
      },
    },
    {
      name: "Peak Number Of Employees",
      selector: (row) => row.peakNumberOfEmployees,
      width: "300px",
      style: {
        display: "flex",
        justifyContent: "center",
      },
    },
  ];

  return (
    <>
    <MediaQuery maxDeviceWidth={1024}>
      <MainContainer isHeader>
        <ManageYearlyDeatils
        yearlyData={yearlyData}
        establishmentData = {props.location.state}
        />
      </MainContainer>
    </MediaQuery>
    <MediaQuery minDeviceWidth={1024}>
      <MainWrapper>
        <Card>
          <Card.Header className="cardHeader">
            <div className="establishmentText">
              Establishment - Yearly Details
            </div>
          </Card.Header>
          <Card.Body className="yearCardHeader">
            <div className=" mainContainer">
              <div className="yearlyRow">
                <Col className=" yearlyRowCol">
                  <div>
                    <InputLabel style={labelStyle} required>Select Year</InputLabel>

                    <TextField
                      select
                      value={selectedYear}
                      onChange={handleYearChange}
                      variant="outlined"
                      style={{ width: "400px", marginTop: "10px" }}
                      required  
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </TextField>
                    <div style={{ marginTop: "30px" }}>
                      <InputLabel style={labelStyle} required>
                        Total hours worked by all employees last year
                      </InputLabel>
                      <TextField
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={totalHour}
                        style={{ width: "400px", marginTop: "10px" }}
                        onChange={handleTotalHour}
                        required  
                      />
                    </div>
                  </div>
                </Col>
                <Col className="yearlyRowCol">
                  <div>
                    <InputLabel style={labelStyle} required>
                      Peak Number of Employees in the Year
                    </InputLabel>
                    <Autocomplete
                      options={employeeNumbers}
                      getOptionLabel={(option) => option.label}
                      value={numOfEmployees}
                      onChange={(event, newValue) => {
                        setNumOfEmployees(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Option"
                          variant="outlined"
                          style={{ width: "400px", marginTop: "10px" }}
                          fullWidth
                          required  
                        />
                      )}
                    />
                    <div style={{ marginTop: "30px" }}>
                      <InputLabel style={labelStyle} required>
                        Annual average number of employees
                      </InputLabel>
                      <TextField
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={avgEmployee}
                        variant="outlined"
                        style={{ width: "400px", marginTop: "10px" }}
                        onChange={handleAvgEmployeee}
                        required  
                      />
                    </div>
                  </div>
                </Col>
              </div>
              <div className="buttoSave">
                <Button
                  variant="contained"
                  size="small"
                  onClick={addYearlyDetails}
                >
                  Add
                </Button>
              </div>
            </div>
          </Card.Body>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={1000}
            onClose={handleCloseSnackbar}
            message={snackbarMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
             <Alert severity="error">{snackbarMessage}</Alert>
            </Snackbar>
          <Card.Footer className="yearCardFooter">
            <div className="mainContainer">
              <DataTable
                data={yearlyData}
                columns={columns}
                customStyles={getTableStyles}
                striped
              />
            </div>
          </Card.Footer>
        </Card>
      </MainWrapper>
      </MediaQuery>
    </>
  );
}

export default YearlyDetails;
