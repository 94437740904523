import React, { useState, useEffect } from "react";
import { showToaster } from "../services/helper";
import { useHistory } from "react-router-dom";
// import pageLoader1 from "../services/pageloader1";
import PageLoader from "../services/pageloader";
import "./admin.css"
import { getAccessToken, getToken, getServerStatus, upgrateServerTime, suspendServerTime, azureAccessToken, getEmbededUrl } from "../services/api-servics";
export default function Adminmanagement() {
    const [serverState, setServerState] = useState("");
    const [label, setlabel] = useState("Capacity");
    const [pageLoad, setPageLoad] = useState(false);
    const history = useHistory();
    useEffect(() => {
        serverStatus()
    }, [])

    const [isToggled, setIsToggled] = useState(false);

    const handleToggle = () => {
        setIsToggled((isToggled) => !isToggled);
    };

    const serverStatus = () => {
        try {
            setPageLoad(true)
            azureAccessToken().then(async (response) => {
                if (
                    response !== "" &&
                    response !== undefined &&
                    response !== null &&
                    response.status == 200

                ) {
                    if (response.data !== undefined && response.data !== "") {
                        if (response.data !== undefined && response.data !== null && response.data !== "") {
                            let AccessToken = "Bearer " + response.data.access_token
                            await getServerStatus(AccessToken).then(async (response) => {
                                if (
                                    response !== "" &&
                                    response !== undefined &&
                                    response !== null && response.data !== undefined && response.data !== null && response.data !== "" &&
                                    response.data.properties !== undefined && response.data.properties !== null && response.data.properties !== ""
                                ) {
                                    if (response.data.properties.state !== undefined && response.data.properties.state !== null && response.data.properties.state !== "") {
                                        let serverStatus = response.data.properties.state;
                                        setServerState(serverStatus)
                                        
                                        if (serverStatus == "Succeeded" || serverStatus == "Resuming") {
                                            setIsToggled(true)
                                        } else {
                                            setIsToggled(false)
                                        }
                                        setPageLoad(false)
                                    }
                                }
                            });
                        }
                    }
                } else {
                    setPageLoad(false)
                }

            });
        } catch (e) {
            setPageLoad(false)
        }
    }

    const serverClick = async () => {
        if (isToggled) {
            serverSuspend()
        } else {
            serverUpgrade()
        }
    }
    const serverUpgrade = async () => {
        try {
            setPageLoad(true)
            await azureAccessToken().then(async (response) => {
                if (
                    response !== "" &&
                    response !== undefined &&
                    response !== null &&
                    response.status == 200

                ) {
                    if (response.data !== undefined && response.data !== "") {
                        if (response.data !== undefined && response.data !== null && response.data !== "") {
                            let AccessToken = "Bearer " + response.data.access_token
                            if (AccessToken !== undefined && AccessToken !== null && AccessToken !== "") {
                                await upgrateServerTime(AccessToken).then((response1) => {
                                    if (
                                        response !== "" &&
                                        response !== undefined &&
                                        response !== null

                                    ) {
                                        { setIsToggled(!isToggled) }
                                        serverStatus()
                                        showToaster(
                                            "Capacity upgrade successfully ",
                                            "success"
                                        );
                                        setPageLoad(false)
                                    }

                                });
                            }


                        }

                    }
                } else {
                    setPageLoad(false)
                }

            });
        } catch (e) {
            setPageLoad(false)
        }
    }

    const serverSuspend = async () => {
        try {
            setPageLoad(true)
            await azureAccessToken().then(async (response) => {
                if (
                    response !== "" &&
                    response !== undefined &&
                    response !== null &&
                    response.status == 200

                ) {
                    if (response.data !== undefined && response.data !== "") {
                        if (response.data !== undefined && response.data !== null && response.data !== "") {
                            let AccessToken = "Bearer " + response.data.access_token
                            await suspendServerTime(AccessToken).then((response1) => {
                                if (
                                    response !== "" &&
                                    response !== undefined &&
                                    response !== null

                                ) {
                                    { setIsToggled(!isToggled) }
                                    serverStatus()
                                    showToaster(
                                        "Capacity suspended successfully ",
                                        "success"
                                    );
                                    setPageLoad(false)
                                }

                            });

                        }

                    }
                } else {
                    setPageLoad(false)
                }

            });
        } catch (e) {
            setPageLoad(false)
        }
    }
    const logout = async () => {
        // localStorage.clear();
        localStorage.removeItem("power_bi");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/");

    };
    return (
        <>
            <div className="row mb-2">
                <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-center">
                        <h6 className="m-0 p-0">Azure Capacity is :</h6>
                        <div className="toggle-switch">
                            <input type="checkbox" className="checkbox" checked={isToggled}
                                name={label} id={label} onClick={() => {
                                    serverClick()
                                }} />
                            <label className="label" htmlFor={label}>
                                <span className="inner" />
                                <span className="switch" />
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <PageLoader showLoader={pageLoad} />
        </>
    )
}
