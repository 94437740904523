import React, { useState, useEffect, useContext } from "react";
import styles from "./settingsMenu.module.scss";
import {IconEditMob , IconDeleteMob} from '../../../assets/css/icons'
import { GetAllUser,DeleteUser,EditUser } from '../../services/api-servics'
import { useHistory ,useParams} from "react-router-dom";
import WindowMessage from '../../GlobalComponents/WindowMessage'
import Context from '../../Context'
import { ViewEmployeeDetails, DeleteEmployeeDetails, } from '../.././services/api-servics'
import PageLoader from "../../services/pageloader";
function UserSettings(props) {

  const [userData,setUserData]=useState([])
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [refreshTable, setRefreshtable] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const { formDataState, setformDataState } = useContext(Context);
  const [data, setdata] = useState({});
  const {incident_id_param,establishment_id_param}=useParams()

  const history = useHistory();
  // useEffect(() => {
  //   getUserList();
  //   console.log('userDatagg',userData);
  // }, [])

  useEffect(() => {
    getUserList()
    setRefreshtable(false)
  }, [refreshTable])

  const getUserList = (e) => {
    setLoading(true);
    GetAllUser({}).then(async (rsp) => {
      if (rsp.data.data) {
      const usersData= rsp.data.data
      setUserData(usersData)
      }
      setLoading(false);
    })
  }
 
  const val = (name, value) => (
    <div className={styles.dataBar}>
      <div className={styles.dataDetail}> {name}
      <div> : </div>
      </div>
      <div className={styles.dataDetail}>{value} </div>
    </div>
  );

  const handleClose = () => {
    setShowModal(false);
  };

  const deleteUser = (e) => {
    DeleteUser({email:e}).then(async (rsp) => {
      setRefreshtable(true);
    })
  }
  const handleDelete = (item) => {
    setItemIdToDelete(item);
    setShowModal(true);
    // alert(item)
  };
  const handleConfirm = () => {
    // alert(itemIdToDelete)
    if (itemIdToDelete) {
      deleteUser(itemIdToDelete);
    }
    handleClose();
  };

  const handleEdit = (e) => {
    editUserRole(e)
  };
  
  const editUserRole = (e) => {
    EditUser({ email: e }).then(async (rsp) => {
      if(rsp.data.data){
        const data= rsp.data.data
      let path = "/edit_user";
      history.push({
        pathname: path,
        state:{
          selectedRole:data.user_role,
          userName:data.name,
          userCompany:data.company,
          userDesignation:data.designation,
          userEmail:data.email,
          userPassword:data.password,
          userContactNo:data.mobNo,
          editUser:"true"
        }
      });
    }
    });
  };
  // const getDetails = () => {

  //   console.log( props)
  //   props.location.item &&
  //     setformDataState({
  //       ...formDataState,
  //       incident_id: incident_id_param,
  //       est_id: establishment_id_param,
  //     });
  //   const incident_id = incident_id_param;
  //   const establishment_id =establishment_id_param;

  //   ViewEmployeeDetails({ incident_id:incident_id_param, establishment_id:establishment_id_param }).then(async (rsp) => {
  //     if (rsp.data?.data) {
  //       setdata(rsp.data.data);
  //     }
  //   });
  // };

  return (
    <>
    {
    loading ? <PageLoader showLoader={loading} /> :
    userData.map((data, index) => (
     
         <div className={styles.userContent}>
        <div className={styles.actionTab}>
          <div className={styles.editBar}>
            <IconEditMob onClick={()=>{handleEdit(data.email)}} />
            <IconDeleteMob onClick={()=>{handleDelete(data.email)}} />
          </div>
        </div>
         <div className={styles.userDataBar}>
         {val("Name",  data.name)}
         {val("Email", data.email)}
         {val("Company", data.company)}
         {val("Manager", data.manager)}
         {val("Contact Number", data.contactnumber)}
         {val("Role", data.role)}
         </div>
      </div>
     
    ))}

    <WindowMessage
    show={showModal}
    handleClose={handleClose}
    windowMessage="User"
    handleConfirm={handleConfirm}
    // fromViewFiles
    />

  </>
   

  )
}

export default UserSettings