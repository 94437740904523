import React, { useState, useEffect } from "react";
import Navbar from '../Nav/navbar'
import NavHome from '../Nav/navHome'
import PageLoader from "../services/pageloader";
import { useHistory } from "react-router-dom";
import { getSettings, updateSettings } from "../services/api-servics";
import "./settings.css"
import { useFormik } from "formik";
import * as Yup from "yup";
import { showToaster } from "../services/helper";
import Capacitymanagement from "../Admin/adminmanagement";
export default function Settings() {
  const [pageLoad, setPageLoad] = useState(false);
  const [settingsData, setSettingData] = useState(undefined);
  const [settingsInput, setSettingInput] = useState(undefined);
  const [settingsOutput, setSettingOutput] = useState(undefined);
  const [settingsFrequency, setSettingFrequency] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    getSettingsDetails()
  }, [])


  useEffect(() => {
  }, [settingsData])
  const getSettingsDetails = async () => {
    try {
      setPageLoad(false)
      await getSettings().then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null

        ) {
          setPageLoad(false)

          if (response.data.status == true) {
            setSettingInput(response.data.settings.input_folder)
            setSettingOutput(response.data.settings.output_folder)
            setSettingFrequency(response.data.settings.frequency)
          }
          setSettingData(response.settings)
        } else {
          setPageLoad(false)
        }

      });
    } catch (e) {
      setPageLoad(false)
    }
  }
  const formik = useFormik({
    initialValues: {
      settingsInputs: settingsInput,
      settingsOutputs: settingsOutput,
      settingsFrequencys: settingsFrequency
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      settingsInputs: Yup.string()
        .required("Settings Input is required"),
      settingsOutputs: Yup.string()
        .required("Settings Output is required"),
      settingsFrequencys: Yup.string()
        .required("Settings Frequency is required"),
    }),
    onSubmit: (values) => {
      try {

        let updatedValue = {
          input_folder_path: values.settingsInputs,
          output_folder_path: values.settingsOutputs,
          frequency: values.settingsFrequencys
        }
        updateSettings(updatedValue).then(async (response) => {
          setPageLoad(true)
          if (
            response !== "" &&
            response !== undefined &&
            response !== null && response.status == 200

          ) {
            window.localStorage.setItem(
              "settings",
              true
            );
            history.push("/mainboard");
            showToaster(
              "Updated sucessfully ",
              "success"
            );
            setPageLoad(false)
          } else {
            showToaster(
              "Something went wrong",
              "warning"
            );
            setPageLoad(false)
          }
        });

      } catch (e) {
        setPageLoad(false)
      }
    },
  });

  const onEditChange = (ev, type) => {
    let value = ev.target.value;
    if (type == "settingsInputs") {
      formik.setFieldValue("settingsInputs", value)
    } else if (type == "settingsOutputs") {
      formik.setFieldValue("settingsOutputs", value)
    } else if (type == "settingsFrequencys") {
      formik.setFieldValue("settingsFrequencys", value)
    }
    formik.handleChange(ev);
  }

  // const handleSubmit = () => {
  //   window.localStorage.setItem(
  //     "settings",
  //     true
  //   );
  //   history.push("/home");
  // }
  return (
    <>
      {/* <PageLoader showLoader={pageLoad} /> */}
      <div className="d-flex align-items-center hb-100">
        <div className="col-md-8 config-cnt mx-auto">
          <div className="card card-inverse card-success pad-2 card-cs br-top">
            <h1 className="sub-title mb-3">Settings</h1>
            <section className="top-wrap az-bt mt-2 mb-4">

              <Capacitymanagement />

            </section>
            <div className="overflow-auto">
              <form onSubmit={formik.handleSubmit} >

                <div className="col mb-6 mt-2">
                  <div className="container-form">
                    <div className="material-textfield">
                      <input
                        // value={settingsInput !== undefined && settingsInput}
                        // handleChange={(e)=>{
                        //  setSettingInput(e.target.value)
                        // }}
                        onChange={(e) => {
                          onEditChange(e, "settingsInputs")
                        }}

                        onBlur={formik.handleBlur}
                        value={formik.values.settingsInputs}
                        type="text"
                        id="input_folder_path"
                        name="input_folder_path"
                        placeholder=" "

                        className="form-control form-control-lg lg-input osha-text-input"
                      />
                      <label>Input folder path</label>
                    </div>
                    {formik.touched.settingsInputs && formik.errors.settingsInputs ? (
                      <div className="invalid-msg">{formik.errors.settingsInputs}</div>
                    ) : null}
                  </div>
                </div>


                <div className="col mb-6">
                  <div className="container-form">
                    <div className="material-textfield">
                      <input
                        onChange={(e) => {
                          onEditChange(e, "settingsOutputs")
                        }}

                        onBlur={formik.handleBlur}
                        value={formik.values.settingsOutputs}
                        type="text"
                        id="output_folder_path"
                        name="output_folder_path"
                        placeholder=" "

                        className="form-control form-control-lg lg-input osha-text-input"
                      />
                      <label>Output folder path</label>
                    </div>
                    {formik.touched.settingsOutputs && formik.errors.settingsOutputs ? (
                      <div className="invalid-msg">{formik.errors.settingsOutputs}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col mb-6">
                  <div className="container-form">
                    <div className="material-textfield">
                      <input

                        onChange={(e) => {
                          onEditChange(e, "settingsFrequencys")
                        }}

                        onBlur={formik.handleBlur}
                        value={formik.values.settingsFrequencys}
                        type="text"
                        id="frequency"
                        name="frequency"
                        placeholder=" "

                        className="form-control form-control-lg lg-input osha-text-input"
                      />
                      <label>Frequency</label>
                    </div>
                    {formik.touched.settingsFrequencys && formik.errors.settingsFrequencys ? (
                      <div className="invalid-msg">{formik.errors.settingsFrequencys}</div>
                    ) : null}
                  </div>
                </div>


                <div className="text-center text-lg-start mt-4 pt-2 mb-2">
                  <button type="submit" className="btn-st btn-label m-auto" onClick={() => {

                  }} >Update</button>

                </div>
              </form>
            </div>


          </div>
        </div>
      </div>
    </>
  )
}
