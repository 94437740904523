import React, { useState, useEffect, useContext } from "react";
import "./NewForm.css";
import { Card, Row, Col } from "react-bootstrap";
import { Button, Autocomplete } from "@mui/material";
import { useHistory } from "react-router-dom";
import { incidentOption } from "../services/api-servics";
import { makeStyles } from "@material-ui/core/styles";
import Context from "../Context";
import CsTextField from "../GlobalComponents/CsTextField";
import MainWrapper from "../GlobalComponents/MainWrapper";
import { styled } from "styled-components";
import ReactDatePicker from "react-datepicker";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import MediaQuery from "react-responsive";
import MainContainer from "../MobileUI/GlobalComponents/MainContainer";
import ProgressBar from "../MobileUI/GlobalComponents/ProgressBar";
import {
  IconCalendar,
  IconChevLeft,
  IconChevRight,
  IconClock,
} from "../../assets/css/icons";
import FormButton from "../MobileUI/GlobalComponents/FormButton";
import MobTextField from "../MobileUI/GlobalComponents/MobTextField";
import ButtonOutline from "../MobileUI/GlobalComponents/ButtonOutline";
import css from "./incidentDetails.module.scss";
const useStyles = makeStyles((theme) => ({
  menu: {
    maxHeight: 200, // Adjust the height as needed
  },
  timepicker: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#c5c5c5",
        width: "100%", // Change to the desired color
      },
    },
  },
  label: {
    color: "#8697a5r", // Change to the color you prefer
  },
}));
const Styles = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container input {
    font-family: "Roboto", sans-serif;
  }

  .react-datepicker__header {
    background-color: #1976d2;
    color: #fff;
  }

  .react-datepicker__day {
    color: #333;
  }

  .react-datepicker__day--selected {
    background-color: #1976d2;
    color: #fff;
  }
`;

function IncidentDetails(props) {
  const classes = useStyles();
  const [incidentOptions, setincidentOptions] = useState([]);
  const { formDataState, setformDataState } = useContext(Context);
  const [value, setValue] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [wrkTime, setWrkTime] = useState();
  const [incTime, setIncTime] = useState();
  const [denied, setDenied] = useState(false);
  const [progress, setProgress] = useState(33);
  const [errors, setErrors] = useState({
    workOption: "",
    WorkTime: "",
    incidentDate: "",
    incidentPlace: "",
    incidentTime: "",
    incidentCause: "",
    deathTime: "",
    value: "",
  });
  const dateofIncident =
    formDataState.incidentDate !== ""
      ? dayjs(formDataState.incidentDate)
      : null;
  const deathTime =
    formDataState.deathTime !== "" ? dayjs(formDataState.deathTime) : null;
  const minSelectableDate = new Date(formDataState.dateHired);
  const minDeathDate = new Date(formDataState.incidentDate);

  useEffect(() => {
    const interval = 10; // Update interval in milliseconds
    const duration = 50; // Total duration in milliseconds
    const steps = duration / interval;
    let currentStep = 0;

    const progressIncrement = 66 / steps;

    const progressInterval = setInterval(() => {
      const currentProgress = Math.min(
        progressIncrement * (currentStep + 1),
        66
      );

      setProgress(currentProgress);
      currentStep++;
      if (currentStep >= steps) {
        clearInterval(progressInterval);
      }
    }, interval);

    // Cleanup function to clear the interval
    return () => clearInterval(progressInterval);
  }, []);

  useEffect(() => {
    getIncidentOptions();
  }, []);
  useEffect(() => {
    const workTime = dayjs(
      dayjs(`${formDataState.incidentDate}T${formDataState.WorkTime}`)
    );
    const incidentTime = dayjs(
      dayjs(`${formDataState.incidentDate}T${formDataState.incidentTime}`)
    );
    formDataState.WorkTime !== "" && setWrkTime(workTime);
    formDataState.incidentTime !== "" && setIncTime(incidentTime);
  }, [formDataState.type === "edit"]);

  useEffect(() => {
    if(formDataState.incidentDate !== "" && formDataState.incidentTime !== ""){
    const incidentTime = dayjs(
      dayjs(`${formDataState.incidentDate}T${formDataState.incidentTime}`)
    );
    setIncTime(incidentTime);
}

  if(formDataState.incidentDate !== "" && formDataState.WorkTime!==""){
    const workTime = dayjs(
      dayjs(`${formDataState.incidentDate}T${formDataState.WorkTime}`)
    );
    setWrkTime(workTime);
  }
  }, [formDataState.incidentTime, formDataState.WorkTime]);

  const getIncidentOptions = (e) => {
    incidentOption({}).then(async (rsp) => {
      if (rsp.data.data) {
        const data = rsp.data.data;
        const newArray = data.filter(
          (item) =>
            item.value !== "Near miss" &&
            item.value !== "Minor injury that required only first aid treatment"
        );
        const incidentOptions =
          formDataState.type === "upload" ? newArray : data;

        const stateOptions = incidentOptions.map((s) => s);
        setincidentOptions(stateOptions);
      }
    });
  };

  const options = [
    { value: "Y", label: "Yes" },
    { value: "N", label: "No" },
  ];
  const history = useHistory();
  const handleClick = () => {
    setDenied(true);
    if (validateForm()) {
      setDenied(false);
      if (
        formDataState.incidentCause.label === "Near miss" ||
        formDataState.incidentCause.label ===
          "Minor injury that required only first aid treatment" ||
        formDataState.workOption.label === "No"
      ) {
        let path = "/submit_page";
        history.push(path, { formDataState: formDataState });
      } else {
        let path = "/incident_additional_info";

        history.push(path, { formDataState: formDataState });
      }
    }
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleIncidentDate = (e) => {
    const value = e;
    const inputDate = new Date(value);
    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate
      .getDate()
      .toString()
      .padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setformDataState({ ...formDataState, incidentDate: formattedDate });
  };
  const handleDeathDate = (e) => {
    const value = e;
    const inputDate = new Date(value);
    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate
      .getDate()
      .toString()
      .padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setformDataState({ ...formDataState, deathTime: formattedDate });
  };
  const handleWorkTime = (e) => {
    const workTime = dayjs(
      dayjs(`${formDataState.incidentDate}T${formDataState.WorkTime}`)
    );
    setWrkTime(workTime);
    setformDataState({
      ...formDataState,
      WorkTime: e.format("HH:mm"),
    });
  };
  const handleIncidentTime = (e) => {
    const incidentTime = dayjs(
      dayjs(`${formDataState.incidentDate}T${formDataState.incidentTime}`)
    );
    setIncTime(incidentTime);
    setformDataState({
      ...formDataState,
      incidentTime: e.format("HH:mm"),
    });
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Validate Work Option
    if (!formDataState.workOption || formDataState.workOption === null) {
      newErrors.workOption = "Incident related to work is required";
      setSnackbarMessage("Incident related to work is required");
      isValid = false;
    } else {
      newErrors.workOption = "";
    }

    // Validate Work Time
    if (!formDataState.WorkTime) {
      newErrors.WorkTime = "Time employee began work is required";
      setSnackbarMessage("Time employee began work is required");
      isValid = false;
    } else {
      newErrors.WorkTime = "";
    }

    // Validate Incident Date
    if (!formDataState.incidentDate) {
      newErrors.incidentDate = "Date of Incident is required";
      setSnackbarMessage("Date of Incident is required");
      isValid = false;
    } else {
      newErrors.incidentDate = "";
    }

    // Validate Incident Place
    if (!formDataState.incidentPlace) {
      newErrors.incidentPlace = "Where the incident occured is required";
      setSnackbarMessage("Where the incident occured is required");
      isValid = false;
    } else {
      newErrors.incidentPlace = "";
    }

    // Validate Incident Time
    if (!formDataState.incidentTime) {
      newErrors.incidentTime = "Time of Incident is required";
      setSnackbarMessage("Time of Incident is required");
      isValid = false;
    } else {
      newErrors.incidentTime = "";
    }

    // Validate Incident Cause
    if (!formDataState.incidentCause) {
      newErrors.incidentCause = "Incident Cause is required";
      setSnackbarMessage("Incident Cause is required");
      isValid = false;
    } else {
      newErrors.incidentCause = "";
    }
    if (
      formDataState.incidentCause.value === "Death" &&
      !formDataState.deathTime.trim()
    ) {
      newErrors.deathTime = "Date of Death is required";
      setSnackbarMessage("Date of Death is required");
      isValid = false;
    } else {
      newErrors.deathTime = "";
    }
    if (
      (formDataState.incidentCause.value === "Days away from work" ||
        formDataState.incidentCause.value === "Job transfer or restriction") &&
      !formDataState.value
    ) {
      newErrors.value = "Value is required";
      setSnackbarMessage("Value is required");
      isValid = false;
    } else {
      newErrors.value = "";
    }

    setErrors(newErrors);

    if (!isValid) {
      setSnackbarOpen(true);
    }

    return isValid;
  };
  const handleBackClick = () => {
    window.history.back();
  };
  console.log("formDataState.incidentTime",formDataState.incidentTime);
  console.log("incTime",incTime);
  return (
    <>
      <MediaQuery minDeviceWidth={1024}>
        <MainWrapper>
          <Card>
            <Card.Header className="cardHeader">
              <div className="managerText">
                Incident Report-Incident Details
              </div>
            </Card.Header>

            <Row className="row_text">
              <Col md={6}>
                <Autocomplete
                  options={options}
                  value={formDataState.workOption}
                  onChange={(event, newValue) => {
                    setformDataState({
                      ...formDataState,
                      workOption: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <CsTextField
                      {...params}
                      label="Is The Incident Work-Related?(Yes/No)"
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      disabled={
                        formDataState.type === "edit" ||
                        formDataState.type === "upload"
                      }
                      error={
                        denied &&
                        (formDataState.workOption === "" ||
                          formDataState.workOption === null) &&
                        errors.workOption.length > 0
                      }
                      helperText={
                        denied &&
                        (formDataState.workOption === "" ||
                          formDataState.workOption === null) &&
                        errors.workOption.length > 0 &&
                        "The incident related to work is Required"
                      }
                    />
                  )}
                  clearIcon={null}
                  disabled={
                    formDataState.type === "edit" ||
                    formDataState.type === "upload"
                  }
                />
              </Col>

              <Col md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Time Employee Began Work"
                    value={
                      denied &&
                      (formDataState.WorkTime === "" ||
                        formDataState.WorkTime === "Invalid Date")
                        ? ""
                        : wrkTime && wrkTime
                    }
                    required
                    className="timepicker"
                    onChange={handleWorkTime}
                  />
                  {denied &&
                    (formDataState.WorkTime === "" ||
                      formDataState.WorkTime === "Invalid Date") && (
                      <label className="timeLabel">
                        Time employee began work is Required
                      </label>
                    )}
                </LocalizationProvider>
              </Col>
            </Row>

            <Row className="row_text">
              <Col md={6}>
                <Styles>
                  <ReactDatePicker
                    selected={formDataState.incidentDate}
                    style={{ padding: "0px 0px 0px 0px", fontSize: "8px" }}
                    onChange={handleIncidentDate}
                    popperPlacement="bottom"
                    required={true}
                    placeholderText="dd-mm-yyyy"
                    dateFormat="dd-MMM-yyyy"
                    icon="fa fa-calendar-o"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={minSelectableDate}
                    showIcon
                    customInput={
                      <CsTextField
                        label="Date of the Incident"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        size="small"
                        error={
                          denied &&
                          formDataState.incidentDate === "" &&
                          errors.incidentDate.length > 0
                        }
                        helperText={
                          denied &&
                          formDataState.incidentDate === "" &&
                          errors.incidentDate.length > 0 &&
                          "Incident date is Required"
                        }
                        required
                      />
                    }
                    shouldCloseOnSelect={false}
                  />
                </Styles>
              </Col>
              <Col md={6}>
                <CsTextField
                  label="Where the Incident occurred"
                  value={formDataState.incidentPlace}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      incidentPlace: e.target.value,
                    })
                  }
                  error={
                    denied &&
                    formDataState.incidentPlace === "" &&
                    errors.incidentPlace.length > 0
                  }
                  helperText={
                    denied &&
                    formDataState.incidentPlace === "" &&
                    errors.incidentPlace.length > 0 &&
                    "Incident place is Required"
                  }
                />
              </Col>
            </Row>
            <Row className="row_text">
              <Col md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Time of Incident"
                    value={
                      denied &&
                      (formDataState.incidentTime === "" ||
                        formDataState.incidentTime === "Invalid Date")
                        ? ""
                        : incTime && incTime
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    className="timepicker"
                    onChange={handleIncidentTime}
                  />
                  {denied &&
                    (formDataState.incidentTime === "" ||
                      formDataState.incidentTime === "Invalid Date") && (
                      <label className="timeLabel">
                        Time of Incident is Required
                      </label>
                    )}
                </LocalizationProvider>
              </Col>
              <Col md={6}>
                <Autocomplete
                  options={incidentOptions}
                  value={formDataState.incidentCause}
                  onChange={(event, newValue) => {
                    setformDataState({
                      ...formDataState,
                      incidentCause: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <CsTextField
                      {...params}
                      label="Did the Incident any of the following ?"
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      disabled={formDataState.type === "edit"}
                      error={
                        denied &&
                        formDataState.incidentCause === "" &&
                        errors.incidentCause.length > 0
                      }
                      helperText={
                        denied &&
                        formDataState.incidentCause === "" &&
                        errors.incidentCause.length > 0 &&
                        "Incident cause is Required"
                      }
                    />
                  )}
                  clearIcon={null}
                  disabled={formDataState.type === "edit"}
                />
              </Col>
            </Row>
            {formDataState.incidentCause.value === "Death" ? (
              <Row style={{ padding: "10px" }}>
                <Col md={6}></Col>
                <Col md={6}>
                  <div className="col">
                    <Styles>
                      <ReactDatePicker
                        selected={formDataState.deathTime}
                        style={{ padding: "0px 0px 0px 0px", fontSize: "8px" }}
                        onChange={handleDeathDate}
                        popperPlacement="bottom"
                        required={true}
                        placeholderText="dd-mm-yyyy"
                        dateFormat="dd-MMM-yyyy"
                        icon="fa fa-calendar-o"
                        minDate={minDeathDate}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        showIcon
                        customInput={
                          <CsTextField
                            label="Date of Death"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            size="small"
                            required
                            error={denied && formDataState.deathTime === ""}
                            helperText={
                              denied &&
                              formDataState.deathTime === "" &&
                              "Death date is Required"
                            }
                          />
                        }
                        shouldCloseOnSelect={false}
                      />
                    </Styles>
                  </div>
                </Col>
              </Row>
            ) : formDataState.incidentCause.value === "Days away from work" ||
              formDataState.incidentCause.value ===
                "Job transfer or restriction" ? (
              //   <div className="col">
              <Row style={{ padding: "10px" }}>
                <Col md={6}></Col>
                <Col md={6}>
                  <div className="col">
                    <CsTextField
                      // className="text_area"
                      label={
                        formDataState.incidentCause.value ===
                        "Days away from work"
                          ? "Days away from work"
                          : "Job transfer or days restricted"
                      }
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={formDataState.value}
                      required
                      size="small"
                      fullWidth
                      disabled={formDataState.type === "edit"}
                      onChange={(e) =>
                        setformDataState({
                          ...formDataState,
                          value: e.target.value,
                        })
                      }
                      error={denied && formDataState.value === ""}
                      helperText={
                        denied &&
                        formDataState.value === "" &&
                        `${
                          formDataState.incidentCause.value ===
                          "Days away from work"
                            ? "Days away from work"
                            : "Job transfer or days restricted"
                        } is Required`
                      }
                    />
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row>
              <Col md={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    margin: "40px",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleBackClick}
                    disabled={props.location.state.frormConfirm}
                  >
                    Back
                  </Button>
                </div>
              </Col>
              <Col md={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "40px",
                  }}
                >
                  <Button variant="contained" onClick={handleClick}>
                    Next
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </MainWrapper>
      </MediaQuery>


      <MediaQuery maxDeviceWidth={1023}>
        <MainContainer>
          <div className={css.incidentMain}>
            <div className={css.incidentHeader}>
              Incident Report-Incident Details
            </div>
            <div className={css.incidentFieldGroup}>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                  Is The Incident Work-Related?(Yes/No)<sup>*</sup>
                </label>
                <div>
                  <Autocomplete
                    options={options}
                    value={formDataState.workOption}
                    onChange={(event, newValue) => {
                      setformDataState({
                        ...formDataState,
                        workOption: newValue,
                      });
                    }}
                    renderInput={(params) => (
                      <MobTextField
                        {...params}
                        fullWidth
                        disabled={
                          formDataState.type === "edit" ||
                          formDataState.type === "upload"
                        }
                        helperText={
                          denied &&
                          (formDataState.workOption === "" ||
                            formDataState.workOption === null) &&
                          errors.workOption.length > 0 && (
                            <div>
                              <span className="infoSign">
                                <i
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                                &nbsp;
                              </span>
                              The incident related to work is Required
                            </div>
                          )
                        }
                      />
                    )}
                    clearIcon={null}
                    disabled={
                      formDataState.type === "edit" ||
                      formDataState.type === "upload"
                    }
                  />
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                  Date of Incident<sup>*</sup>
                </label>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      fullWidth
                      value={dateofIncident}
                      onChange={handleIncidentDate}
                      maxDate={dayjs(new Date())}
                      minDate={dayjs(minSelectableDate)}
                      slots={{
                        textField: (props) => (
                          <MobTextField
                            {...props}
                            InputProps={{
                              endAdornment: <IconCalendar />,
                            }}
                          />
                        ),
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                  Time of Incident<sup>*</sup>
                </label>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      fullWidth
                      slots={{
                        textField: (props) => (
                          <MobTextField
                            {...props}
                            InputProps={{
                              endAdornment: <IconClock />,
                            }}
                          />
                        ),
                      }}
                      value={
                        denied &&
                        (formDataState.incidentTime === "" ||
                          formDataState.incidentTime === "Invalid Date")
                          ? null
                          : incTime && incTime
                      }
                      onChange={handleIncidentTime}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                  Time Employee Began Work<sup>*</sup>
                </label>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      fullWidth
                      value={
                        denied &&
                        (formDataState.WorkTime === "" ||
                          formDataState.WorkTime === "Invalid Date")
                          ? ""
                          : wrkTime && wrkTime
                      }
                      onChange={handleWorkTime}
                      slots={{
                        textField: (props) => (
                          <MobTextField
                            {...props}
                            InputProps={{
                              endAdornment: <IconClock />,
                            }}
                            helperText={
                              denied &&
                              (formDataState.WorkTime === "" ||
                                formDataState.WorkTime === "Invalid Date") && (
                                <div>
                                  <span className="infoSign">
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;
                                  </span>
                                  Time employee began work is Required
                                </div>
                              )
                            }
                          />
                        ),
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                  Where the Incident occured?<sup>*</sup>
                </label>
                <div>
                  <MobTextField
                    value={formDataState.incidentPlace}
                    fullWidth
                    onChange={(e) =>
                      setformDataState({
                        ...formDataState,
                        incidentPlace: e.target.value,
                      })
                    }
                    helperText={
                      denied &&
                      formDataState.incidentPlace === "" &&
                      errors.incidentPlace.length > 0 && (
                        <div>
                          <span className="infoSign">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            &nbsp;
                          </span>
                          Incident place is Required
                        </div>
                      )
                    }
                  />
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                  Did the Incident any of the following??<sup>*</sup>
                </label>
                <div>
                  <Autocomplete
                    options={incidentOptions}
                    value={formDataState.incidentCause}
                    onChange={(event, newValue) => {
                      setformDataState({
                        ...formDataState,
                        incidentCause: newValue,
                        deathTime: "",
                        value: "",
                      });
                    }}
                    renderInput={(params) => (
                      <MobTextField
                        {...params}
                        fullWidth
                        disabled={formDataState.type === "edit"}
                        helperText={
                          denied &&
                          formDataState.incidentCause === "" &&
                          errors.incidentCause.length > 0 && (
                            <div>
                              <span className="infoSign">
                                <i
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                                &nbsp;
                              </span>
                              Incident cause is Required
                            </div>
                          )
                        }
                      />
                    )}
                    clearIcon={null}
                    disabled={formDataState.type === "edit"}
                  />
                </div>
              </div>
              {formDataState.incidentCause.value === "Death" ? (
                <div className={css.incidentFields}>
                  <label className={css.incidentFieldLabels}>
                    Date of Incident<sup>*</sup>
                  </label>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        fullWidth
                        value={deathTime}
                        onChange={handleDeathDate}
                        minDate={dayjs(minDeathDate)}
                        maxDate={dayjs(new Date())}
                        slots={{
                          textField: (props) => (
                            <MobTextField
                              {...props}
                              InputProps={{
                                endAdornment: <IconCalendar />,
                              }}
                              helperText={
                                denied &&
                                formDataState.deathTime === "" && (
                                  <div>
                                    <span className="infoSign">
                                      <i
                                        class="fa fa-info-circle"
                                        aria-hidden="true"
                                      ></i>
                                      &nbsp;
                                    </span>
                                    Death date is Required
                                  </div>
                                )
                              }
                            />
                          ),
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              ) : formDataState.incidentCause.value === "Days away from work" ||
                formDataState.incidentCause.value ===
                  "Job transfer or restriction" ? (
                <div className={css.incidentFields}>
                  <label className={css.incidentFieldLabels}>
                    {formDataState.incidentCause.value === "Days away from work"
                      ? "Days away from work"
                      : "Job transfer or days restricted"}
                    <sup>*</sup>
                  </label>
                  <div>
                    <MobTextField
                      type="number"
                      value={formDataState.value}
                      disabled={formDataState.type === "edit"}
                      onChange={(e) =>
                        setformDataState({
                          ...formDataState,
                          value: e.target.value,
                        })
                      }
                      helperText={
                        denied &&
                        formDataState.value === "" &&
                        (formDataState.incidentCause.value ===
                        "Days away from work" ? (
                          <div>
                            <span className="infoSign">
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                              ></i>
                              &nbsp;
                            </span>
                            Days away from work is Required
                          </div>
                        ) : (
                          <div>
                            <span className="infoSign">
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                              ></i>
                              &nbsp;
                            </span>
                            Job transfer or days restricted is Required
                          </div>
                        ))
                      }
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className={css.incidentFields}>
                <ProgressBar value={progress} />
              </div>
              <div className={css.incidentButtons}>
                <ButtonOutline
                  onClick={handleBackClick}
                  disabled={props.location.state.frormConfirm}
                >
                  <IconChevLeft /> &ensp;Previous
                </ButtonOutline>
                <FormButton onClick={handleClick}>
                  Next &ensp;
                  <IconChevRight />
                </FormButton>
              </div>
            </div>
          </div>
        </MainContainer>
      </MediaQuery>
    </>
  );
}

export default IncidentDetails;
