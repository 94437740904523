import React, { useState, useEffect, useContext,useRef } from "react";
import Navbar from "../Nav/navbar";
import NavHome from "../Nav/navHome";
import { useHistory ,useParams} from "react-router-dom";
import {
  ViewEmployeeDetails,
  DeleteEmployeeDetails,
} from "../services/api-servics";
import editIcon from '../../assets/Icons/edit.svg'
import deleteIcon from '../../assets/Icons/delete.svg'
import "./ViewDetails.scss";
import WindowMessage from "../GlobalComponents/WindowMessage";
import { Button } from "@mui/material";
import Context from "../Context";
import MainWrapper from "../GlobalComponents/MainWrapper";
import NavMob from "../MobileUI/GlobalComponents/NavMob/NavMob";
import MainContainer from "../MobileUI/GlobalComponents/MainContainer";
function ViewDetails(props) {
  const { formDataState, setformDataState } = useContext(Context);
  const [data, setdata] = useState({});
  const [showConfirmationc, setShowConfirmation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tabHeight, setTabHeight] = useState(500);
  const role = localStorage.getItem("role");
  const {incident_id_param,establishment_id_param}=useParams()
  const employRef=useRef()
  const incidentRef =useRef()
  const mobWidth =960;
  const [mobileView, setMobileView] = useState({
    view: false,
    tab1: false,
    tab2: false,
  });
  useEffect(() => {
    getDetails();
    if (window.innerWidth < mobWidth) {
      setMobileView((prev) => {
        return {
          view: true,
          tab1: true,
          tab2: false,
        };
      });
    } else {
      setMobileView((prev) => {
        return {
          view: false,
          tab1: true,
          tab2: true,
        };
      });
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < mobWidth) {
        setMobileView((prev) => {
          return {
            view: true,
            tab1: true,
            tab2: false,
          };
        });
      } else {

if(employRef.current!==null && incidentRef.current!==null){
  const tab1Width=employRef.current.offsetHeight;
  const tab2Width=incidentRef.current.offsetHeight;
  if(tab1Width>tab2Width){
    setTabHeight(tab1Width)
  }
  else{
    setTabHeight(tab2Width)
  }

}




        setMobileView((prev) => {
          return {
            view: false,
            tab1: true,
            tab2: true,
          };
        });
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const getDetails = () => {
    props.location.item &&
      setformDataState({
        ...formDataState,
        incident_id: incident_id_param,
        est_id: establishment_id_param,
      });
    const incident_id = incident_id_param;
    const establishment_id =establishment_id_param;

    ViewEmployeeDetails({ incident_id:incident_id_param, establishment_id:establishment_id_param }).then(async (rsp) => {
      if (rsp.data?.data) {
        setdata(rsp.data.data);
      }
    });
  };

  const handleDelete = () => {
    const incident_id = props.location.item.incident_id;
    const establishment_id = props.location.item.establishment_id;
    DeleteEmployeeDetails({ incident_id, establishment_id }).then(
      async (rsp) => {
        let path = "/mainboard";
        history.push({ pathname: path });
      }
    );
  };
  const history = useHistory();
  const handleEdit = () => {
    getDetails();
    let path = "/new-form";
    history.push({
      pathname: path,
      type: "edit",
      incident_id: incident_id_param,
      establishment_id: establishment_id_param,
    });
  };
  const val = (name, value) => (
    <div className="summary">
      <div className="summary_details"> {name}
      <div> : </div>
      </div>
      <div className="summary_details">{value} </div>
    </div>
  );
  const handleClose = () => {
    setShowModal(false);
  };
  const item = props.location.item;
  return (
    <>
    <MainContainer>
      <MainWrapper>
        <div className="mainBody">
          <div className="detailHeading">
            <div className="managerText">Employee Profile</div>
          </div>

          {mobileView.view ? (
            <>
            <div className="tabWrapper">
              <button
                className={mobileView.tab1 ? "tab activeTab" : "tab"}
                onClick={() => {
                  setMobileView((prev) => {
                    return { ...prev, tab1: true, tab2: false };
                  });
                }}
              >Employee Details
                
              </button>
              <button
                className={mobileView.tab2 ? "tab activeTab" : "tab"}
                onClick={() => {
                  setMobileView((prev) => {
                    return { ...prev, tab1: false, tab2: true };
                  });
                }}
              >
                 Job Details
              </button>
            </div>

            <div className="footeButton">
           { role !== "admin" && 

           <>
           <button
              className="mainbrdbtn"
             
              onClick={handleEdit}
            >
             <img src={editIcon} alt="edit"  />
            </button>

            <button
              className="mainbrdbtn"
             
              onClick={() => setShowModal(true)}
            >
             <img src={deleteIcon} alt="delete"  />
            </button>
            </>
            }
            {showModal && (
              <WindowMessage
                show={showModal}
                handleClose={handleClose}
                // windowMessage={windowPopupMessage}
                handleConfirm={handleDelete}
                fromViewFiles
              />
            )}
          </div></>
          ) : null}
          

          <div className="contentWrapper">
          
            {mobileView.tab1 ? (
              <div className="content" id="tab1" ref={employRef} style={!mobileView.view ? { height: tabHeight + 'px' } : {}}>
                {mobileView.view?null:<div className="subheader">Employee Details</div>}
                
                {val("FullName", item ? item.employee_name : data.full_name)}
                {val("Street", data.street)}
                {val("City", data.city)}
                {val("State", data.state)}
                {val("ZIP", data.zip_code)}
                {val("DOB", data.date_of_birth)}
                {val("Gender", data.Gender)}
                {/* { val ("Place" , data.incident_place)} */}
                <div className="summaryd">
                  <div className="summary_details"> {"Place"}
                  <p> : </p>
                  </div>
                  <div className="summary_details">{data.incident_place}</div>
                </div>
              </div>
            ) : null}

            {mobileView.tab2 ? (
              <div className="content" id="tab2"  ref={incidentRef} style={!mobileView.view ? { height: tabHeight + 'px' } : {}}>
                {mobileView.view?null:<div className="subheader"> Job Details</div>}
                {val("Job Title", data.job_title)}
                {val("Date Hired", data.date_hired)}
                {val("Date Of Incident", data.date_of_incident)}
                {val("Time Of Incident	", data.time_of_incident)}
                {val(
                  "Time Of Employee Began Work	",
                  data.time_employee_began_work
                )}
                {val("Incident Cause	", data.incident_outcome)}
                { !mobileView.view?val ("Incident Summary" , data.incident_summary):
                  <div className="incidentWrapper">
                  <div className="incidentHeading"> {"Incident Summary"}
                  </div>
                  <div className="incidentContent">
                    {data.incident_summary }
                  </div>
                </div>
                }
              
              </div>
            ) : null}
          </div>

          {
            mobileView.view?
            null:
            <div className="footeButton">
            <Button
              className="mainbrdbtn"
              variant="contained"
              onClick={handleEdit}
            >
              Edit
            </Button>

            <Button
              className="mainbrdbtn"
              variant="contained"
              color="error"
              onClick={() => setShowModal(true)}
            >
              Delete
            </Button>
            {showModal && (
              <WindowMessage
                show={showModal}
                handleClose={handleClose}
                // windowMessage={windowPopupMessage}
                handleConfirm={handleDelete}
                fromViewFiles
              />
            )}
          </div>
          }
        </div>
      </MainWrapper>
      </MainContainer>
    </>
  );
}

export default ViewDetails;
