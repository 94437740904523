import React, { useEffect, useState } from "react";
import {
  IconHomeCard,
  IconAllLog,
  IconHomeBot,
  IconHomeNewForm,
  IconHomeSettings,
  IconOshaLog,
  IconArchive,
} from "../../../assets/css/icons";
import css from "./homemob.module.scss";
import ReactApexChart from "react-apexcharts";
import { ExtractedDataTable, GetHomeData } from "../../services/api-servics";
import { useHistory } from "react-router";
import ChatBot from "../../ChatBot/ChatBot";
import HomeWrapper from "../GlobalComponents/HomeWrapper";
import PageLoader from "../../services/pageloader";
import Context from "../../Context";
import { useContext } from "react";
function HomeMob(props) {
  const usermail = localStorage.getItem("email");
  const [totalCases, setTotalCases] = useState(0);
  const [oshaCases, setOshaCases] = useState(0);
  const { formDataState, setformDataState } = useContext(Context);
  const [userName, setUserName] = useState("");
  const [isChatEnabled,setIsChatEnabled] = useState(true)
  const [loader,setLoader] = useState(false)
  const role = localStorage.getItem("role");
  const history = useHistory();
  useEffect(() => {
    getHomeData();
    setformDataState({
      ...formDataState,
      selectedMenu:null
    })
  }, []);

  const getHomeData = (e) => {
    GetHomeData({ email: usermail }).then(async (rsp) => {
      if (rsp.data.data) {
        const data = rsp.data.data;
        setTotalCases(data.total_incidents);
        setOshaCases(data.osha_incidents);
        setUserName(data.userName);
      }
    });
  };
  const getExtractedDataTable = (e) => {
    setLoader(true);
    ExtractedDataTable({}).then(async (rsp) => {
      setLoader(false);
        const data300 = rsp.data.data300;
        const data301 = rsp.data.data301;
        const data300a = rsp.data.data300a;
        let path = "/process-tab";
        history.push({
          pathname: path,
          state:{
            data300:data300,
            data301:data301,
            data300a:data300a,
          }
        });
    });
  };

  const GradientGaugeChart = () => {
    const options = {
      chart: {
        type: "radialBar",
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 3,
          opacity: 0.3,
        },
        borderRadius: 10,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 2,
            size: "55%",
            borderRadius: "20px",
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              formatter: function (val) {
                return oshaCases + "/" + totalCases;
              },
              color: "#0087C1",
              fontSize: "15px",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              offsetY: 5,
              lineHeight: "120%",
            },
          },
        },
      },
      fill: {
        colors: ["#50cbd6", "#97adee"],
        type: "gradient",
        gradient: {
          shade: "light", // Change this to 'dark' or other colors
          type: "horizontal",
          shadeIntensity: 0.2,
          gradientToColors: ["#a9a5ef", "#9748FF"], // Your end color, change as needed
          inverseColors: true,
          opacityFrom: 2,
          opacityTo: 1,
          stops: [0, 80, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: [""],
      background: {
        foreColor: "#a8a5ee", // Change this to the desired background color
        padding: 5, // Adjust padding as needed
      },
    };
    const completionPercentage = (oshaCases / totalCases) * 100;
    const series = [completionPercentage]; // The value for your gauge chart (e.g., 75% completion)

    return (
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={180}
      />
    );
  };
  const handleAllLog = () => {
    history.push("/mainboard");
  }
  const handleOshaLog = () => {
    history.push("/mainboard",{oshaLog:true});
  }
  const handleSettings = () => {
    history.push("/settings-menu");
  }
  const handleArchive = () => {
    setformDataState({
      ...formDataState,
      selectedMenu:"archive"
    })
    getExtractedDataTable()
  }
  const handleNewForm = () => {
    setformDataState({
      type: "new",
      employeeName: "",
      jobTitle: "",
      street: "",
      dob: "",
      city: "",
      dateHired: "",
      state: null,
      gender: "",
      zip: "",
      empId: "",
      establishment: "",
      workOption: "",
      WorkTime: "",
      incidentDate: "",
      incidentPlace: "",
      incidentTime: "",
      incidentCause: "",
      deathTime: "",
      value: "",
      incident: "",
      injurySummary: "",
      injuryDetails: "",
      object: "",
      preIncidentActivity: "",
      reason: "",
      Physician: "",
      stateInfo: "",
      location: "",
      zipInfo: "",
      streetInfo: "",
      emergencyRoomOption: "",
      cityInfo: "",
      hospitalizedOption: "",
      treatmentInfo: "",
      incidentId: ""
    })
    history.push("/new-form");
  }
  const handleChatBot = () => {
    setIsChatEnabled(!isChatEnabled)  
  }
  return (
    <>
    {<ChatBot hide={isChatEnabled} setHide={setIsChatEnabled} props={props}/>}
    <HomeWrapper>
      {loader ? <PageLoader showLoader={loader} /> :
          <div className={css.divHome}>
            <div className={css.divNameCard}>
              <div style={{ width: "350px" }}>
                <div className={css.name}>
                  <label style={{ color: "#4C4C4C)" }}>Hi , </label>{" "}
                  <label style={{ color: "#8756D4" }}>{userName}</label>
                </div>
                <div className={css.homeSvg}>
                  <IconHomeCard />
                </div>
              </div>
              <div className={css.homeGraph}>
                <GradientGaugeChart />
                <div className={css.graphCaption}>OSHA CASES</div>
              </div>
            </div>

            <div className={css.menuContainer}>
              <div className={css.menus} onClick={handleAllLog}>
                <IconAllLog />
                <div className={css.mainText}>
                  All Logs
                </div>
                <div className={css.subText}>
                  Stay OSHA-Compliant with Ease
                </div>
              </div>
              <div className={css.menus} onClick={handleOshaLog}>
                <IconOshaLog />
                <div className={css.mainText}>
                  OSHA Logs
                </div>
                <div className={css.subText}>
                  Stay OSHA-Compliant with Ease
                </div>
              </div>
              <div className={css.menus} onClick={handleSettings}>
                <IconHomeSettings />
                <div className={css.mainText}>
                  Settings
                </div>
                <div className={css.subText}>
                  Stay OSHA-Compliant with Ease
                </div>
              </div>
              {role !== "admin" &&  
              <div className={css.menus} onClick={handleArchive}>
                <IconArchive />
                <div className={css.mainText}>
                  Archive
                </div>
                <div className={css.subText}>
                  Stay OSHA-Compliant with Ease
                </div>
              </div>}
              {role !== "admin" && 
              <div className={css.menus} onClick={handleNewForm}>
                <IconHomeNewForm />
                <div className={css.mainText}>
                  New Form
                </div>
                <div className={css.subText}>
                  Stay OSHA-Compliant with Ease
                </div>
              </div>}
              <div className={css.menus} onClick={handleChatBot}>
                <IconHomeBot />
                <div className={css.mainText}>
                  Chat Bot
                </div>
                <div className={css.subText}>
                  Stay OSHA-Compliant with Ease
                </div>
              </div>
            </div>

            {/* <div>
            </div> */}
          </div>
}
          </HomeWrapper>
    </>
  );
}

export default HomeMob;
