import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@mui/material';
const useStyles = makeStyles((theme) => ({
    customButton: {
        border: '2px solid !important', // Set border to create the outline
        borderColor: `linear-gradient(90deg, #8756D4 -8.33%, #AE88EA 100%) !important`, // Set border color
        borderRadius: "8px", // Adjust border radius
        color: `#AE88EA !important`,
        fontFamily: 'Poppins, sans-serif !important',
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "600",
        textTransform: 'none',
        padding: " 6px 10px",
        
      },
  }));

function ButtonOutline(props) {
    const classes = useStyles();
    return (
      <Button
        className={classes.customButton}
        {...props}
      >
        {props.children}
      </Button>
    )
}

export default ButtonOutline