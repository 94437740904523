import { Search } from "@material-ui/icons";
import {
  alpha,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  styled,
  TextField,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  IconExport,
  IconExportDisable,
  IconFilter,
  IconOshaInsiderViolet,
} from "../../assets/css/icons";
import MainContainer from "../MobileUI/GlobalComponents/MainContainer";
import {
  Export300Excel,
  Export301CSV,
  ListYearEstablishment,
} from "../services/api-servics";
import DashBoardCard from "./DashBoardCard";
import css from "./dashboardMain.module.scss";
const FilterFiled = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px 8px 8px 8px !important",
    background: "#ECECED",
    "& fieldset": {
      borderColor: "#C4C4C4",
      borderRadius: "8px",
      fontfamily: "Poppins, sans-serif !important",
      border: "1px solid var(--Grey, #E5E5E5)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(135, 86, 212, 1)",
      fontfamily: "Poppins, sans-serif !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(135, 86, 212, 1)",
      boxShadow: `${alpha("rgba(135, 86, 212, 1)", 0.25)} 0 0 0 0.2rem`,
    },
    "&::before": {
      fontFamily: "Poppins, sans-serif !important",
    },
    "& input": {
      fontFamily: "Poppins, sans-serif !important",
      fontSize: "14px",
      height: "20px",
      fontStyle: "normal",
      fontWeight: "400",
      color: "#000000 !important ",
      background: "#ECECED",
      borderRadius: "8px 8px 8px 8px",
    },
  },
  "& .MuiInputAdornment-root": {
    background: "#ECECED !important",
  },

  "& .MuiFormHelperText-root": {
    color: "#000000",
    fontSize: "12px",
    fontFamily: "Poppins, sans-serif !important",
  },
  "&.Mui-error": {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(135, 86, 212, 1)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(135, 86, 212, 1)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(135, 86, 212, 1)",
        boxShadow: `${alpha("rgba(135, 86, 212, 1)", 0.25)} 0 0 0 0.2rem`,
      },
    },
  },
});
const transparentButtonStyle = makeStyles({
  transparentButton: {
    backgroundColor: "transparent",
    border: "none",
    color: "var(--majantha, #8756D4)",
    fontFamily: "'Poppins', sans-serif !important",
    fontSize: "13px !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    textTransform: "none !important",
    "&:active": {
      background: `linear-gradient(90deg, #8756D4 100%, #AE88EA -8.33%) !important`,
      transitionDuration: "0.1s", // Disable transition on active state
      color: "#FFF",
    },
    "&:disabled": {
      backgroundColor: "#e3e3e3 !important", // Set the background color for disabled state
    },
  },
});
const radioStyles = makeStyles((theme) => ({
  label: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
function DashboardMain(props) {
  const data = props?.data;
  const history = useHistory();
  const [filterData, setfilterdData] = useState();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [anchor, setAnchor] = useState("");
  const [years, setYears] = useState([]);
  const [yearsOsha, setYearsOsha] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [establishments, setEstablishments] = useState([]);
  const [selectedEsts, setEsts] = useState([]);
  const [yearList, setYearList] = useState(true);
  const [establishmentsList, setEstablishmentsList] = useState(false);
  const radioClasses = radioStyles();
  const transparentButtonClasses = transparentButtonStyle();
  //Exported state
  const [types, setTypes] = useState(false);
  const [exportedYear, setExportedYear] = useState("");
  const [exportedEst, setExportedEst] = useState("");
  const [exportedType, setExportedType] = useState("");
  const [expButtonNext, setExpButtonNext] = useState("Next");
  const [expButtonPrev, setExpButtonPrev] = useState("Close");
  const role = localStorage.getItem("role");

  useEffect(() => {
    getYearEstaBlishments();
  }, []);
  useEffect(() => {
    setfilterdData(data);
  }, [data]);

  const handleExportYearChange = (event) => {
    setExportedYear(event.target.value);
  };
  const handleExportEstChange = (event) => {
    setExportedEst(event.target.value);
  };
  const handleExportTypeChange = (event) => {
    setExportedType(event.target.value);
  };
  const getYearEstaBlishments = (e) => {
    ListYearEstablishment({}).then(async (rsp) => {
      const data = rsp.data;
      setEstablishments(data.establishment_all);
      setYears(data.year_all);
      setYearsOsha(data.year_osha_yes);
    });
  };
  const handleExport301_300CSV = () => {
    const year = parseInt(exportedYear);
    const establishment_id = parseInt(exportedEst);
    Export301CSV({ establishment_id, year });
  };
  const handleExport300Excel = () => {
    const year = parseInt(exportedYear);
    const establishment_id = parseInt(exportedEst);
    Export300Excel({ establishment_id, year });
  };
  const handleOsha300A = () => {
    const year = parseInt(exportedYear);
    let path = "/osha_details";
    history.push({
      pathname: path,
      establishment_id: parseInt(exportedEst),
      year: year,
    });
  };
  const handleCheckboxChange = (data) => {
    if (yearList) {
      setSelectedYears((prevSelectedYears) => {
        if (prevSelectedYears.includes(data)) {
          return prevSelectedYears.filter(
            (selectedYear) => selectedYear !== data
          );
        } else {
          return [...prevSelectedYears, data];
        }
      });
    } else if (establishmentsList) {
      setEsts((prevSelectedEstablishments) => {
        if (prevSelectedEstablishments.includes(data)) {
          return prevSelectedEstablishments.filter(
            (selectedEstablishments) => selectedEstablishments !== data
          );
        } else {
          return [...prevSelectedEstablishments, data];
        }
      });
    }
  };

  const handleDrawerOpen = (data) => {
    setAnchor(data === "left" ? "left" : "right");
    setDrawerOpen(true);
  };

  const handleClearAll = () => {
    setExportedEst("");
    setExportedType("");
    setExportedYear("");
    setSelectedYears([]);
    setEsts([]);
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setYearList(true);
    setSelectedYears([]);
    setEsts([]);
    setEstablishmentsList(false);
    setTypes(false);
    setfilterdData(data);
    setExportedEst("");
    setExportedType("");
    setExportedYear("");
  };
  const handleApply = () => {
    const filteredData = data.filter((item) => {
      if (selectedYears.length === 0 && selectedEsts.length === 0) {
        return true;
      }
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(item.year);
      const establishmentMatch =
        selectedEsts.length === 0 ||
        selectedEsts.includes(item.establishment_name);

      return yearMatch && establishmentMatch;
    });
    setfilterdData(filteredData);
    setDrawerOpen(false);
  };

  const handleButtonPrev = () => {
    if (yearList === false && establishmentsList === false && types === true) {
      setYearList(false);
      setEstablishmentsList(true);
      setTypes(false);
      setExpButtonNext("Next");
      setExpButtonPrev("Previous");
    }
    if (yearList === false && establishmentsList === true && types === false) {
      setYearList(true);
      setEstablishmentsList(false);
      setTypes(false);
      setExpButtonNext("Next");
      setExpButtonPrev("Close");
    }
  };
  const handleExport = () => {
    if (exportedType === "OSHA 300/301 CSV") {
      handleExport301_300CSV();
    } else if (exportedType === "OSHA 300 EXCEL") {
      handleExport300Excel();
    } else if (exportedType === "OSHA 300 A") {
      handleOsha300A();
    }
  };
  const handleButtonApply = () => {
    if (yearList === true && establishmentsList === false && types === false) {
      setYearList(false);
      setEstablishmentsList(true);
      setTypes(false);
      setExpButtonPrev("Previous");
      setExpButtonNext("Next");
    } else if (
      yearList === false &&
      establishmentsList === true &&
      types === false
    ) {
      setYearList(false);
      setEstablishmentsList(false);
      setTypes(true);
      setExpButtonPrev("Previous");
      setExpButtonNext("Export");
    } else if (expButtonNext === "Export") {
      handleExport();
    }
  };

  const handleMenuItemClick = (e) => {
    if (e === "year") {
      setYearList(true);
      setEstablishmentsList(false);
      setTypes(false);
    } else if (e === "est") {
      setYearList(false);
      setEstablishmentsList(true);
      setTypes(false);
    } else if (e === "type") {
      setTypes(true);
      setYearList(false);
      setEstablishmentsList(false);
    }
  };

  const handleButtonClick = (item) => {
    let path = `/view_details/${item.incident_id}/${item.establishment_id}`;
    history.push({
      pathname: path,
      item: item,
    });
  };
  return (
    <div>
      <div className={css.dashBoardHeader}>
        <div className={css.dashBoardIconOsha}>
          <IconOshaInsiderViolet />
        </div>
        <div className={css.dashboardSwitch}>
          <div
            className={
              props.oshaNo === true ? css.dashALlOshaSelected : css.dashALlOsha
            }
            onClick={props.allSelected}
          >
            All{" "}
          </div>
          <div
            className={
              props.oshayes === true ? css.dashALlOshaSelected : css.dashALlOsha
            }
            onClick={props.oshaSelected}
          >
            Osha
          </div>
        </div>
        <div className={css.dashboardFilterPanel}>
          <div
            className={css.dashboardFilter}
            onClick={() => {
              handleDrawerOpen("left");
            }}
          >
            <IconFilter />
          </div>
          <div className={css.dashBoardsearch}>
            <FilterFiled
              fullWidth
              size="small"
              onChange={props.instantSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              placeholder="Search for establishment, issues..."
            />
          </div>
          <div
            className={css.dashboardFilter}
            
          >
            <IconButton 
            disabled={props.disableExport || role==="admin"} 
            onClick={() => {
              handleDrawerOpen("right");
            }}>
             {(props.disableExport || role==="admin") ? <IconExportDisable/> : <IconExport />}
            </IconButton>
          </div>
        </div>
      </div>
      <div className={css.dashBoardCard}>
        {filterData &&
          filterData.map((item,index) => {
            return (
              <DashBoardCard
                handleButtonClick={handleButtonClick}
                item={item}
                key={index}
              />
            );
          })}
      </div>
      <Drawer
        anchor={anchor}
        open={isDrawerOpen}
        PaperProps={{
          sx: {
            boxShadow: "red",
          },
        }}
        transitionDuration={{ enter: 400, exit: 400 }}
        onClose={handleDrawerClose}
      >
        <MainContainer isDrawer>
          <div className={css.drawerMain}>
            <div className={css.dashBoardDrawerHeader}>
              <div className={css.dashBoardIconOsha}>
                <IconOshaInsiderViolet />
              </div>
              <div className={css.drwerHeaders}>
                <div className={css.drawerText}>Filters</div>
                <div className={css.drawerText} onClick = {handleClearAll}>CLEAR ALL</div>
              </div>
            </div>
            {anchor === "left" && (
              <div className={css.drawerLeftMenus}>
                <div className={css.drawerLeft}>
                  <List className={css.drawerList}>
                    <ListItemButton
                      button
                      selected={yearList}
                      onClick={() => handleMenuItemClick("year")}
                      style={
                        yearList
                          ? {
                              background: "var(--Lavender-ambient, #E5A8FB)",
                              border:
                                "var(--none, 1px) solid var(--Grey, #E5E5E5)",
                            }
                          : {
                              border:
                                "var(--none, 1px) solid var(--Grey, #E5E5E5)",
                            }
                      }
                    >
                      <ListItemText
                        disableTypography
                        className={
                          yearList
                            ? css.drawerOptionButtonSelected
                            : css.drawerOptionButton
                        }
                        primary="Year"
                      />
                    </ListItemButton>
                    <ListItemButton
                      button
                      selected={establishmentsList}
                      onClick={() => handleMenuItemClick("est")}
                      style={
                        establishmentsList
                          ? {
                              background: "var(--Lavender-ambient, #E5A8FB)",
                              border:
                                "var(--none, 1px) solid var(--Grey, #E5E5E5)",
                            }
                          : {
                              border:
                                "var(--none, 1px) solid var(--Grey, #E5E5E5)",
                            }
                      }
                    >
                      <ListItemText
                        disableTypography
                        className={
                          establishmentsList
                            ? css.drawerOptionButtonSelected
                            : css.drawerOptionButton
                        }
                        primary="Establishment"
                      />
                    </ListItemButton>
                  </List>
                </div>
                <div className={css.drawerRight}>
                  <FormGroup>
                    {yearList &&
                      years.map((year) => (
                        <div key={year} className={css.drawerCheckbox}>
                          <span>{year}</span>
                          <Checkbox
                            checked={selectedYears.includes(year)}
                            onChange={() => handleCheckboxChange(year)}
                          />
                        </div>
                      ))}
                    {establishmentsList &&
                      establishments.map((establishments) => (
                        <div
                          key={establishments.establishmentName}
                          className={css.drawerCheckbox}
                        >
                          <span>{establishments.establishmentName}</span>
                          <Checkbox
                            checked={selectedEsts.includes(
                              establishments.establishmentName
                            )}
                            onChange={() =>
                              handleCheckboxChange(
                                establishments.establishmentName
                              )
                            }
                          />
                        </div>
                      ))}
                  </FormGroup>
                </div>
              </div>
            )}
            {anchor === "right" && (
              <div className={css.drawerLeftMenus}>
                <div className={css.drawerLeft}>
                  <List className={css.drawerList}>
                    <ListItemButton
                      button
                      disableRipple
                      selected={yearList}
                      style={
                        yearList
                          ? {
                              background: "var(--Lavender-ambient, #E5A8FB)",
                              border:
                                "var(--none, 1px) solid var(--Grey, #E5E5E5)",
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                            }
                          : {
                              border:
                                "var(--none, 1px) solid var(--Grey, #E5E5E5)",
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                            }
                      }
                    >
                      <ListItemText
                        disableTypography
                        className={
                          yearList
                            ? css.drawerOptionButtonSelected
                            : css.drawerOptionButton
                        }
                        primary="Year"
                      />
                      {!yearList && exportedYear !== "" && (
                        <ListItemIcon className={css.tickIcon}>
                          <i class="fa fa-check" aria-hidden="true"></i>
                        </ListItemIcon>
                      )}
                    </ListItemButton>
                    <ListItemButton
                      button
                      disableRipple
                      disableGutters
                      selected={establishmentsList}
                      style={
                        establishmentsList
                          ? {
                              background: "var(--Lavender-ambient, #E5A8FB)",
                              border:
                                "var(--none, 1px) solid var(--Grey, #E5E5E5)",
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                            }
                          : {
                              border:
                                "var(--none, 1px) solid var(--Grey, #E5E5E5)",
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                            }
                      }
                    >
                      <ListItemText
                        disableTypography
                        className={
                          establishmentsList
                            ? css.drawerOptionButtonSelected
                            : css.drawerOptionButton
                        }
                        primary="Establishment"
                      />
                      {!establishmentsList && exportedEst !== "" && (
                        <ListItemIcon className={css.tickIcon}>
                          <i class="fa fa-check" aria-hidden="true"></i>
                        </ListItemIcon>
                      )}
                    </ListItemButton>
                    <ListItemButton
                      button
                      disableRipple
                      selected={types}
                      style={
                        types
                          ? {
                              background: "var(--Lavender-ambient, #E5A8FB)",
                              border:
                                "var(--none, 1px) solid var(--Grey, #E5E5E5)",
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                            }
                          : {
                              border:
                                "var(--none, 1px) solid var(--Grey, #E5E5E5)",
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                            }
                      }
                    >
                      <ListItemText
                        disableTypography
                        className={
                          types
                            ? css.drawerOptionButtonSelected
                            : css.drawerOptionButton
                        }
                        primary="Types"
                      />
                      {!types && exportedType !== "" && (
                        <ListItemIcon className={css.tickIcon}>
                          <i class="fa fa-check" aria-hidden="true"></i>
                        </ListItemIcon>
                      )}
                    </ListItemButton>
                  </List>
                </div>
                <div className={css.drawerRight}>
                  {yearList && (
                    <FormControl component="fieldset" style={{ width: "100%" }}>
                      <RadioGroup
                        aria-label="years"
                        name="years"
                        value={exportedYear}
                        onChange={handleExportYearChange}
                      >
                        {yearsOsha.map((year) => (
                          <div className={css.radioOpt}>
                            <div>{year}</div>
                            <FormControlLabel
                              key={year}
                              value={String(year)}
                              control={
                                <Radio
                                  className={radioClasses.radio}
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#9748fe",
                                    },
                                  }}
                                />
                              }
                            />
                          </div>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {establishmentsList && (
                    <FormControl component="fieldset" style={{ width: "100%" }}>
                      <RadioGroup
                        aria-label="Establishments"
                        name="Establishments"
                        value={exportedEst}
                        onChange={handleExportEstChange}
                      >
                        {establishments.map((est) => (
                          <div className={css.radioOpt}>
                            <div>{est.establishmentName}</div>
                            <FormControlLabel
                              key={est.establishmentName}
                              value={String(est.establishmentID)}
                              control={
                                <Radio
                                  className={radioClasses.radio}
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#9748fe",
                                    },
                                  }}
                                />
                              }
                            />
                          </div>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {types && (
                    <FormControl component="fieldset" style={{ width: "100%" }}>
                      <RadioGroup
                        aria-label="Type"
                        name="Type"
                        value={exportedType}
                        onChange={handleExportTypeChange}
                      >
                        <div className={css.radioOpt}>
                          <div>OSHA 300/301 CSV</div>
                          <FormControlLabel
                            value="OSHA 300/301 CSV"
                            control={
                              <Radio
                                className={radioClasses.radio}
                                sx={{
                                  "&.Mui-checked": {
                                    color: "#9748fe",
                                  },
                                }}
                              />
                            }
                          />
                        </div>
                        <div className={css.radioOpt}>
                          <div>OSHA 300 EXCEL</div>
                          <FormControlLabel
                            value="OSHA 300 EXCEL"
                            control={
                              <Radio
                                className={radioClasses.radio}
                                sx={{
                                  "&.Mui-checked": {
                                    color: "#9748fe",
                                  },
                                }}
                              />
                            }
                          />
                        </div>
                        <div className={css.radioOpt}>
                          <div>OSHA 300 A</div>
                          <FormControlLabel
                            value="OSHA 300 A"
                            control={
                              <Radio
                                className={radioClasses.radio}
                                sx={{
                                  "&.Mui-checked": {
                                    color: "#9748fe",
                                  },
                                }}
                              />
                            }
                          />
                        </div>
                      </RadioGroup>
                    </FormControl>
                  )}
                </div>
              </div>
            )}
            <div className={css.drawerButtonDiv}>
              {anchor === "left" && (
                <>
                  <div className={css.closeButton}>
                    <Button
                      fullWidth
                      className={transparentButtonClasses.transparentButton}
                      onClick={handleDrawerClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={css.applyButton}>
                    <Button
                      fullWidth
                      className={transparentButtonClasses.transparentButton}
                      onClick={handleApply}
                      disabled={
                        selectedYears.length === 0 && selectedEsts.length === 0
                      }
                    >
                      Apply
                    </Button>
                  </div>
                </>
              )}
              {anchor === "right" && (
                <>
                  <div className={css.closeButton}>
                    <Button
                      fullWidth
                      className={transparentButtonClasses.transparentButton}
                      onClick={
                        expButtonPrev === "Close"
                          ? handleDrawerClose
                          : handleButtonPrev
                      }
                    >
                      {expButtonPrev}
                    </Button>
                  </div>
                  <div className={css.applyButton}>
                    <Button
                      fullWidth
                      className={transparentButtonClasses.transparentButton}
                      onClick={handleButtonApply}
                      disabled={
                        expButtonNext === "Export" &&
                        (exportedYear === "" ||
                          exportedEst === "" ||
                          exportedType === "")
                      }
                    >
                      {expButtonNext}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </MainContainer>
      </Drawer>
    </div>
  );
}

export default DashboardMain;
