import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./login.css";
import comp_log from "../../assets/images/logo_com.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showToaster } from "../services/helper";
import { login, SendOTP } from "../services/api-servics";
import { useContext } from "react";
import UserContext from "../Context";
import PageLoader from "../services/pageloader";
import MediaQuery from "react-responsive";
import LoginMob from "../MobileUI/Login/LoginMob";
import MobTextField from "../MobileUI/GlobalComponents/MobTextField";
import { IconEmail, IconPassword } from "../../assets/css/icons";
import { IconButton, InputAdornment } from "@mui/material";
import MobButton from "../MobileUI/GlobalComponents/MobButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import StartPage from "../MobileUI/Login/StartPage";
import { ThreeDots } from "react-loader-spinner";
export default function Login(props) {
  const history = useHistory();

  const { formDataState, setformDataState } = useContext(UserContext);
  const [mfa, setMfa] = useState(false);
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(null);
  const [timer, setTimer] = useState(120);
  const [pageLoad, setPageLoad] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [denied, setDenied] = useState(false);
  const [startPage, setStartPage] = useState(false);
  const [redirected,setRedirected] = useState(false);
  const screenSize = window.screen.width;
  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });


  useEffect (()=>{
    if(props.location.redirected){
      setRedirected(true);
    }
  },[props.location.redirected]);

  const handleRedirect = () => {
    let path = "/signup";
    history.push(path);
  };
  useEffect(() => {
    if (timer === 0) {
      setMfa(false);
      setTimer(120);
    }
  }, [timer === 0]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (mfa === true && timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        clearInterval(intervalId);
        setMfa(false);
      }
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [mfa === true && timer]);
  const valiadteEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return emailRegex.test(email);
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!valiadteEmail(mail)) {
      newErrors.username = "Username is required";
      isValid = false;
    } else {
      newErrors.username = "";
    }

    if (!password.trim()) {
      newErrors.password = "Password is required";
      isValid = false;
    } else {
      newErrors.password = "";
    }
    setErrors(newErrors);
    return isValid;
  };
  const handleGetStart = () => {
    setStartPage(true);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      document.getElementById("loginButton").click();
    }
  };

  const handleLogin = (loginData) => {
    setPageLoad(true);
    login(loginData).then(async (response) => {
      if (
        response !== "" &&
        response !== undefined &&
        response !== null &&
        response.status == 200 &&
        response.data.role !== "no_access"
      ) {
        if (response.data.status == true) {
          window.localStorage.setItem("personal-safety", true);
          localStorage.setItem("email", response.data.username);
          localStorage.setItem("accessToken", response.data.token);
          localStorage.setItem("role", response.data.role);
          setformDataState({
            ...formDataState,
            userRole: response.data.role,
            userMail: response.data.username,
            userToken: response.data.token,
          });
          if(screenSize<1024){
          history.push({
            pathname: "/home",
          });
        }
        else{
          history.push({
            pathname: "/mainboard",
          });
        }
          showToaster("Login sucessfully ", "success");
          setPageLoad(false);
        } else if (response.data.status === "Invalid") {
          setPageLoad(false);
          showToaster("Invalid OTP", "warning");
        } else {
          setPageLoad(false);
          showToaster("Username and password is not valid", "warning");
        }
      } else if (response.data.role === "no_access") {
        setPageLoad(false);
        showToaster("Access approval pending");
      } else {
        setPageLoad(false);
        showToaster("Username and password is not valid", "warning");
      }
    });
  };

  const handleMail = (event) => {
    setMail(event.target.value);
  };
  const handlePass = (event) => {
    setPassword(event.target.value);
  };
  const handleOtp = (event) => {
    setOtp(event.target.value);
  };
  const handleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const signInMobile = (credentials) => {
    setDenied(true);
    if (validateForm()) {
      setDenied(false);
      let loginData = {
        username: mail,
        password: password,
      };
      let otpData = {
        username: mail,
        password: password,
        otp: otp,
      };
      if (!otp) {
        setPageLoad(true);
        SendOTP(loginData).then(async (rsp) => {
          setPageLoad(false);
          const data = rsp.data;
          if (data?.two_factor_choice === "Yes") {
            setMfa(true);
            setPageLoad(false);
          } else if (data?.two_factor_choice === "No") {
            setPageLoad(false);
            handleLogin(loginData);
          } else if (data.success === false) {
            setPageLoad(false);
            showToaster("Username and password is not valid", "warning");
          }
        });
      } else if (otp) {
        setPageLoad(false);
        handleLogin(otpData);
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      OTP: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("username is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      setPageLoad(true);
      try {
        let loginData = {
          username: mail,
          password: password,
        };
        let otpData = {
          username: mail,
          password: password,
          otp: otp,
        };
        if (!otp) {
          SendOTP(loginData).then(async (rsp) => {
            const data = rsp.data;
            if (data?.two_factor_choice === "Yes") {
              setMfa(true);
              setPageLoad(false);
            } else if (data?.two_factor_choice === "No") {
              setPageLoad(false);
              handleLogin(loginData);
            } else if (data.success === false) {
              setPageLoad(false);
              showToaster("Username and password is not valid", "warning");
            }
          });
        } else if (otp) {
          setPageLoad(false);
          handleLogin(otpData);
        }
      } catch (e) {
        setPageLoad(false);
        showToaster("Username and password is not valid", "warning");
      }
    },
  });
  return (
    <>
      {/* ----------------------Desktop UI------------------------ */}
      <MediaQuery minDeviceWidth={821}>
        <PageLoader showLoader={pageLoad} />
        <div className="login">
          <section className="hi-100">
            <div className="container-fluid h-custom">
              <div className="row d-flex justify-content-center align-items-center hi-100">
                <div className="col-md-6 col-lg-6 col-xl-4">
                  <div className="lg-head-div">
                    <h2 className="lg-head">welcome back!</h2>
                    <h4 className="lg-head-sub">sign in to continue</h4>
                  </div>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="col mb-6">
                      <div className="login-form">
                        <div className="material-textfield">
                          <input
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleMail(e);
                            }}
                            // onBlur={formik.handleBlur}
                            value={mail}
                            type="text"
                            id="username"
                            name="username"
                            placeholder=" "
                            className="form-control form-control-lg lg-input osha-text-input"
                          />
                          <label>Email address</label>
                        </div>
                        {formik.touched.username && formik.errors.username ? (
                          <div className="input-error-msg">
                            {formik.errors.username}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col mb-6">
                      <div className="login-form">
                        <div className="material-textfield">
                          <input
                            onChange={(e) => {
                              formik.handleChange(e);
                              handlePass(e);
                            }}
                            // onBlur={formik.handleBlur}
                            value={password}
                            type="password"
                            id="password"
                            name="password"
                            placeholder=" "
                            className="form-control form-control-lg lg-input osha-text-input"
                          />
                          <label>Password</label>
                        </div>
                        {formik.touched.password && formik.errors.password ? (
                          <div className="input-error-msg">
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* <div className="form-outline mb-4">
                  <label className="form-label lg-label">Email address</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    required
                    className="form-control form-control-lg lg-input"
                    placeholder="Enter a valid email"
                  />
                </div>


                <div className="form-outline mb-3">
                  <label className="form-label lg-label">Password</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="form-control form-control-lg lg-input"
                    placeholder="Enter password"
                  />
                </div> */}
                    {mfa && (
                      <div className="col mb-6">
                        <div className="login-form">
                          <div className="material-textfield">
                            <input
                              onChange={(e) => {
                                formik.handleChange(e);
                                handleOtp(e);
                              }}
                              onBlur={formik.handleBlur}
                              value={otp}
                              type="text"
                              id="otp"
                              name="OTP"
                              className="form-control form-control-lg lg-input osha-text-input"
                            />
                            <label>OTP</label>
                          </div>

                          <span className="otpHelperText">
                            An One-Time Password has been sent to your Email ID
                          </span>
                          {formik.touched.OTP && formik.errors.OTP ? (
                            <div className="input-error-msg">
                              {formik.errors.OTP}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <p className="otpTimer">
                            Time remaining : {Math.floor(timer / 60)}:
                            {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                          </p>
                          {/* Add other components or logic related to OTP verification */}
                        </div>
                      </div>
                    )}

                    <div className="text-center text-lg-start mt-4 pt-2 mb-2">
                      <button className="lg-btn btn-label" onClick={() => {}}>
                        {mfa ? "Verify" : "Login"}
                      </button>
                      {/* <p className="small fw-bold mt-2 pt-1 mb-0 lg-signup">
                    Don't have an account?
                    <a href="#" className="link-danger">Sign Up</a>
                  </p> */}
                    </div>
                  </form>
                  <div className="register">
                    Don't have an account? <a href="/signup">Register</a>
                  </div>
                </div>
                <div className="col-md-6"></div>
              </div>
            </div>

            <div className="col-md-12">
              <img
                src={comp_log}
                className="d-inline-block lg-com-log mr-rt-2"
                alt=""
              />
            </div>
          </section>
        </div>
      </MediaQuery>

      {/* --------------------------Mobile UI--------------------- */}
      <MediaQuery maxDeviceWidth={821}>
        <>
          {(!startPage && !redirected) ? (
            <StartPage>
              <div className="startInfo">
                <div>Data Driven Digital Transformation</div>
                <div className="startInfoSubText">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </div>
                <div style={{ marginTop: "50px", width: "100%" }}>
                  <MobButton
                    fullWidth
                    style={{ width: "314px" }}
                    onClick={handleGetStart}
                  >
                    Get Started
                  </MobButton>
                </div>
              </div>
            </StartPage>
          ) : (
            <LoginMob>
              <div className="mobLogfields">
                <MobTextField
                  placeholder="Email Address"
                  onChange={handleMail}
                  onKeyDown={handleKeyDown}
                  value={mail}
                  type="text"
                  disabled={pageLoad}
                  helperText={
                    denied &&
                    errors.username.length > 0 && (
                      <div>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        {mail.trim().length === 0
                          ? errors.username
                          : "Invalid Email Id"}
                      </div>
                    )
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <IconEmail />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <MobTextField
                  placeholder="Password"
                  onChange={handlePass}
                  value={password}
                  type={showNewPassword ? "text" : "password"}
                  onKeyDown={handleKeyDown}
                  disabled={pageLoad}
                  helperText={
                    denied &&
                    errors.password.length > 0 && (
                      <div>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        {errors.password}
                      </div>
                    )
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <IconPassword />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleNewPasswordVisibility}
                          edge="end"
                          style={{ color: "rgba(135, 86, 212, 1)" }}
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {mfa && (
                  <div>
                    <MobTextField
                      placeholder="Enter OTP"
                      type="text"
                      onChange={handleOtp}
                      onKeyDown={handleKeyDown}
                      autoFocus
                      helperText={
                        <div>
                          <div>An OTP has been sent to your Email ID</div>
                          <div className="mobOtp">
                            Time remaining :{" "}
                            <span className="otpTimer">
                              {" "}
                              {Math.floor(timer / 60)}:
                              {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                            </span>
                          </div>
                          {/* Add other components or logic related to OTP verification */}
                        </div>
                      }
                    />
                  </div>
                )}
              </div>
              <div className="mobLogButton">
                <MobButton fullWidth id="loginButton" onClick={signInMobile}>
                  {pageLoad ? (
                    <ThreeDots
                      visible={true}
                      height="50"
                      width="50"
                      color="#ffffff"
                      radius="3"
                      ariaLabel="three-dots-loading"
                    />
                  ) : mfa ? (
                    "Verify"
                  ) : (
                    "Login"
                  )}
                </MobButton>
                <div className="mobLogin">
                  Don’t have an account ?
                  <label
                    onClick={handleRedirect}
                    style={{ color: "#9747FF", cursor: "pointer" }}
                  >
                    &nbsp;Register
                  </label>
                </div>
              </div>
            </LoginMob>
          )}
        </>
      </MediaQuery>
    </>
  );
}
