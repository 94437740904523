import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./NewForm.css";
import NavHome from "../Nav/navHome";
import { Button, TextField } from "@mui/material";
import Context from "../Context";
import { employeeDetails, OshaCheck } from "../services/api-servics";
import { useHistory } from "react-router-dom";
import WindowMessage from "../GlobalComponents/WindowMessage";
import MainWrapper from "../GlobalComponents/MainWrapper";
import MediaQuery from "react-responsive";
import MainContainer from "../MobileUI/GlobalComponents/MainContainer";
import SubmitMob from "./SubmitMob";
function Submit(props) {
  const { formDataState, setformDataState } = useContext(Context);
  const [oshaDesc, setOshaDesc] = useState("");
  const [showModal, setShowModal] = useState(false);
  const awayFromWork =
    formDataState.incidentCause.value === "Days away from work"
      ? formDataState.value
      : "none";
  const jobTransfer =
    formDataState.incidentCause.value === "Job transfer or restriction"
      ? formDataState.value
      : "none";
  //   const formDataState = formDataState.details;
  useEffect(() => {
    if(oshaDesc){
    setformDataState({ ...formDataState, injurySummary: oshaDesc });}
  }, [oshaDesc]);
  const SubmitEmployeeDetails = () => {
    const employeeDetailsed = {
      FullName: formDataState.employeeName,
      Street: formDataState.street,
      State: formDataState.state.code,
      City: formDataState.city,
      Zip: +formDataState.zip,
      JobTitle: formDataState.jobTitle,
      DOB: formDataState.dob,
      DateHired: formDataState.dateHired,
      Gender: formDataState.gender.value,
      workOption: formDataState.workOption.value,
      incidentDate: formDataState.incidentDate,
      incidentPlace: formDataState.incidentPlace,
      incidentTime: formDataState.incidentTime,
      WorkTime: formDataState.WorkTime,
      incidentCause: formDataState.incidentCause.value,
      deathTime: formDataState.deathTime,
      incident: formDataState.incident.value ?? "",
      injurySummary: formDataState.injurySummary ?? "",
      preIncidentActivity: formDataState.preIncidentActivity ?? "",
      injuryDetails: formDataState.injuryDetails ?? "",
      reason: formDataState.reason ?? "",
      object: formDataState.object ?? "",
      physician: formDataState.Physician ?? "",
      street: formDataState.streetInfo ?? "",
      city: formDataState.cityInfo ?? "",
      state: formDataState.stateInfo ?? "",
      zip: +formDataState.zipInfo ?? "",
      emergencyRoomOption: formDataState.emergencyRoomOption?.value ?? "",
      hospitalizedOption: formDataState.hospitalizedOption?.value ?? "",
      establishment_id: +formDataState.establishment.establishmentID,
      value: +formDataState.value,
      emp_id: +formDataState.empId,
      treatment_info: formDataState.location ?? "",
      flag: formDataState.type,
      incident_id: formDataState.incidentId,
    };

    const jsonEmployeeDetails = employeeDetailsed;

    employeeDetails({ jsonEmployeeDetails }).then(async (rsp) => {
      if (rsp.data.status === "success") {
        setformDataState({
          type: "new",
          employeeName: "",
          jobTitle: "",
          street: "",
          dob: "",
          city: "",
          dateHired: "",
          state: null,
          gender: "",
          zip: "",
          empId: "",
          establishment: "",
          workOption: "",
          WorkTime: "",
          incidentDate: "",
          incidentPlace: "",
          incidentTime: "",
          incidentCause: "",
          deathTime: "",
          value: "",
          incident: "",
          injurySummary: "",
          injuryDetails: "",
          object: "",
          preIncidentActivity: "",
          reason: "",
          Physician: "",
          stateInfo: "",
          location: "",
          zipInfo: "",
          streetInfo: "",
          emergencyRoomOption: "",
          cityInfo: "",
          hospitalizedOption: "",
          treatmentInfo: "",
          incidentId: ""
        })
        let path = "/mainboard";
        history.push(path);
      }
    });
  };
  const handleConfirm = () => {
    let path = "/new-form/incident_details";
    history.push(path, { frormConfirm: true });
  };
  const handleClose = () => {
    setformDataState({ ...formDataState, injurySummary: oshaDesc });
    SubmitEmployeeDetails();
    setShowModal(false);
  };

  const handleSubmit = () => {
    SubmitEmployeeDetails();
  };
  const handleOshacheck = () => {
    OshaCheck({ oshaDescription: oshaDesc }).then(async (rsp) => {
      const data = rsp.data;
      if (data.osha === "Yes") {
        setShowModal(true);
      } else if (data.osha === "No") {
        SubmitEmployeeDetails();
      }
    });
  };
  const val = (name, value) => (
    <div className="summary">
      <div className="summary_details"> {name}</div>{" "}
      <div className="summary_details">:&nbsp;&nbsp;&nbsp;{value} </div>
    </div>
  );
  function getValueOrDefault(value, defaultValue = "none") {
    return value !== undefined && value !== null && value !== ""
      ? value
      : defaultValue;
  }
  const history = useHistory();
  const handleClickBack = () => {
    // if (validateForm()) {
    // localStorage.setItem('formDataState', JSON.stringify(formDataState));
    let path = "/new-form/incident_details";
    // const previousformDataState = JSON.parse(localStorage.getItem('previousformDataState')) || {};
    // localStorage.setItem('previousformDataState', JSON.stringify(previousformDataState));

    history.push(path, { formDataState: formDataState });
  };
  const handleOshaDesc = (event) => {
    setOshaDesc(event.target.value);
    setformDataState({ ...formDataState, injurySummary: oshaDesc });
    // formDataState.type==="edit" ? setformDataState({...formDataState,injurySummary:oshaDesc}) : console.log();
  };
  const windowPopupMessage =
    "It seems this incident qualifies for OSHA recordability, but the selection of " +
    formDataState.incidentCause.value +
    " may be inaccurate. Would you like to correct these details?";

    const truncateText = (text) => {
      if (text.length <= 50) {
        return text;
      }
      return text.slice(0, 50) + "...";
    };
  return (
    <>
      <WindowMessage
        show={showModal}
        handleClose={handleClose}
        windowMessage={windowPopupMessage}
        handleConfirm={handleConfirm}
        fromSubmit
      ></WindowMessage>

      {/* Mob UI */}
      <MediaQuery maxDeviceWidth={1023}>
      <MainContainer>
        <SubmitMob
        handleOshaDesc ={handleOshaDesc}
        handleOshacheck={handleOshacheck}
        handleSubmit={handleSubmit}
        />
      </MainContainer>
      </MediaQuery>

      {/* Desktop UI */}
      <MediaQuery minDeviceWidth={1024}>
      <MainWrapper>
        <Card>
          <Card.Header className="CardHeader">
            <div className="managerText">Incident Report</div>
          </Card.Header>
          {/* <div > */}
          <Row style={{ padding: "65px" }}>
            <Col md={6}>
              <div class="left-sections">
                <div class="subheader"> Employee Details</div>
                {val("FullName", getValueOrDefault(formDataState.employeeName))}
                {val("Street", getValueOrDefault(formDataState.street))}
                {val("State", getValueOrDefault(formDataState.state?.name))}
                {val("City", getValueOrDefault(formDataState.city))}
                {val("Zip", getValueOrDefault(+formDataState.zip))}
                {val("Job Title", getValueOrDefault(formDataState.jobTitle))}
                {val("DOB", getValueOrDefault(formDataState.dob))}
                {val("Date Hired ", getValueOrDefault(formDataState.dateHired))}
                {/* { val ("Gender" , formDataState.gender)} */}
                <div className="summaryd">
                  <div className="summary_details"> {"Gender"}</div>{" "}
                  <div className="summary_details">
                    :&nbsp;&nbsp;&nbsp;
                    {getValueOrDefault(formDataState.gender.value)}{" "}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div class="left-sections">
                <div class="subheader"> Incident Details </div>
                {val(
                  "Is It Work-Related",
                  getValueOrDefault(formDataState.workOption.label)
                )}
                {val(
                  "Date Of Incident",
                  getValueOrDefault(formDataState.incidentDate)
                )}
                {val("Place", getValueOrDefault(formDataState.incidentPlace))}
                {val(
                  "Time Of Incident",
                  getValueOrDefault(formDataState.incidentTime)
                )}
                {val(
                  "Time Of Employee Began Work	",
                  getValueOrDefault(formDataState.WorkTime)
                )}
                {val(
                  "Incident Cause	",
                  getValueOrDefault(formDataState.incidentCause.value)
                )}
                {val(
                  "Date Of Death	",
                  getValueOrDefault(formDataState.deathTime)
                )}
                {val("Days Away From Work	 ", getValueOrDefault(awayFromWork))}
                {/* {val("Job Transfer Or Days Restricted", "AK==>Days Restricted")} */}
                {/* { val ("Incident Category" , formDataState.gender)} */}
                <div className="summaryd">
                  <div className="summary_details">
                    {" "}
                    {"Job Transfer Or Days Restricted"}
                  </div>{" "}
                  <div className="summary_details">
                    :&nbsp;&nbsp;&nbsp;{getValueOrDefault(jobTransfer)}{" "}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {(formDataState.incidentCause.value === "Near miss" || formDataState.incidentCause.value === "Minor injury that required only first aid treatment") &&
          formDataState.workOption.label === "Yes" ? null : formDataState
              .workOption.label === "No" ? null : (
            <Row style={{ padding: "65px" }}>
              <Col md={6}>
                <div class="left-sections">
                  <div class="subheader"> Injury/Illness Description </div>
                  {val(
                    "Incident Category",
                    getValueOrDefault(formDataState.incident.value)
                  )}
                  {val(
                    "Summarize Injury, Affected Parts, And Causing Object/Substance",
                    getValueOrDefault(truncateText(formDataState.injurySummary))
                  )}
                  {val(
                    "Employee's Pre-Incident Activity",
                    getValueOrDefault(truncateText(formDataState.preIncidentActivity))
                  )}
                  {val(
                    "Detail How The Injury Occurred",
                    getValueOrDefault(truncateText(formDataState.injuryDetails))
                  )}
                  {val(
                    "Define Injury, Affected Body Part, And How It Was Affected",
                    getValueOrDefault(truncateText(formDataState.reason))
                  )}
                  {/* { val ("Specify The Harming Object Or Substance" ,formDataState.object)} */}
                  <div className="summaryd">
                    <div className="summary_details">
                      {" "}
                      {"Specify The Harming Object Or Substance"}
                    </div>{" "}
                    <div className="summary_details">
                      :&nbsp;&nbsp;&nbsp;
                      {getValueOrDefault(truncateText(formDataState.object))}{" "}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div class="left-sections">
                  <div class="subheader"> Treatment Details </div>
                  {val(
                    "Physician Name",
                    getValueOrDefault(formDataState.Physician)
                  )}
                  {val("Facility", getValueOrDefault(formDataState.location))}
                  {val("Street", getValueOrDefault(formDataState.streetInfo))}
                  {val("City", getValueOrDefault(formDataState.cityInfo))}
                  {val("State", getValueOrDefault(formDataState.stateInfo))}
                  {val("Zip", getValueOrDefault(formDataState.zipInfo))}
                  {val(
                    "Was The Employee Treated In An Emergency Room?",
                    getValueOrDefault(formDataState.emergencyRoomOption?.label)
                  )}
                  {/* { val ("Was The Employee Hospitalized Overnight As An In-Patient?" ,formDataState.hospitalizedOption)} */}
                  <div className="summaryd">
                    <div className="summary_details">
                      {" "}
                      {
                        "Was The Employee Hospitalized Overnight As An In-Patient?"
                      }
                    </div>{" "}
                    <div className="summary_details">
                      :&nbsp;&nbsp;&nbsp;
                      {getValueOrDefault(
                        formDataState.hospitalizedOption.label
                      )}{" "}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}

          {(formDataState.incidentCause.value === "Near miss" || formDataState.incidentCause.value === "Minor injury that required only first aid treatment") &&
          formDataState.workOption.label === "Yes" ? (
            <Row className="submitBtnBox">
              <Col md={5}>
                <div className="detailFont">
                  Details <span style={{ color: "red" }}> *</span>
                </div>
                <div>
                  Describe Injury Or Illness, Parts Of Body Affected, And
                  Object/Substance That Directly Injured Or Made Person Ill
                </div>
              </Col>
              <Col md={7} className="detCheckFeild">
                <TextField
                  onChange={handleOshaDesc}
                  fullWidth
                  label="Summary"
                  value={formDataState.type==="edit" ? formDataState.injurySummary : console.log()}
                  variant="outlined"
                  type="text"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <div style={{ alignSelf: "flex-end" }}>
                  <Button
                    variant="contained"
                    onClick={
                      formDataState.type === "edit"
                        ? handleSubmit
                        : handleOshacheck
                    }
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          ) : formDataState.workOption.label === "No" ? (
            <Row className="submitBtnBox">
              <Col md={5}>
                <div className="detailFont">
                  Details <span style={{ color: "red" }}> *</span>
                </div>
                <div>
                  Describe Injury Or Illness, Parts Of Body Affected, And
                  Object/Substance That Directly Injured Or Made Person ll
                </div>
              </Col>
              <Col md={7} className="detCheckFeild">
                <TextField
                  onChange={handleOshaDesc}
                  fullWidth
                  label="Summary"
                  variant="outlined"
                  value={formDataState.type==="edit" ? formDataState.injurySummary : console.log()}
                  type="text"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <div style={{ alignSelf: "flex-end" }}>
                  <Button
                    variant="contained"
                    onClick={
                      formDataState.type === "edit"
                        ? handleSubmit
                        : handleOshacheck
                    }
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          ) : (
            <div style={{ alignSelf: "flex-end",margin:"50px" }}>
              <Button variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          )}

          {/* </div> */}
        </Card>
      </MainWrapper>
      </MediaQuery>
    </>
  );
}
export default Submit;
