import { Autocomplete, MenuItem } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { IconSettingSpanner } from "../../../assets/css/icons";
import MainContainer from "../../MobileUI/GlobalComponents/MainContainer";
import MobButton from "../../MobileUI/GlobalComponents/MobButton";
import MobTextField from "../../MobileUI/GlobalComponents/MobTextField";
import { getEmployeeNumber, YearlyDetailsList } from "../../services/api-servics";
import css from "./yearlydetails.module.scss";
function AddYearlyDetails(props) {
  const initialYear = new Date().getFullYear() - 1;
  const [employeeNumbers, setEmployeeNumbers] = useState([]);
  const [selectedYear, setSelectedYear] = useState(initialYear);
  const [numOfEmployees, setNumOfEmployees] = useState(null);
  const [totalHour, setTotalHour] = useState("");
  const [avgEmployee, setAvgEmployee] = useState("");
  const history = useHistory();
  const [errors, setErrors] = useState({
    numOfEmployees: '',
    totalHour: '',
    avgEmployee: '',
  });
  const establishmentData = props.location.state;
  useEffect(() => {
    getEmployeeNum();
  }, []);

  const startYear = 2000;
  const endYear = 2040;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );
  const getEmployeeNum = (e) => {
    getEmployeeNumber({}).then(async (rsp) => {
      if (rsp.data.data) {
        const empNum = rsp.data.data;
        setEmployeeNumbers(empNum);
      }
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!numOfEmployees) {
      newErrors.numOfEmployees = 'Peak number of employees in the year is required';
      isValid = false;
    } else {
      newErrors.numOfEmployees = '';
    }
  
    
    if (!totalHour) {
      newErrors.totalHour = 'Total hour is required';
      isValid = false;
    } else {
      newErrors.totalHour = '';
    }
  
    
    if (!avgEmployee) {
      newErrors.avgEmployee = 'Average employee count is required';
      isValid = false;
    } else {
      newErrors.avgEmployee = '';
    }
    setErrors(newErrors);
    return isValid;
  };

  const getYearlyDetailsList = (e) => {
    YearlyDetailsList({
      establishmentID: +e.establishmentID,
      year_info: +selectedYear,
      annavgNumber: +avgEmployee,
      totalhrwkd: +totalHour,
      peakNumberOfEmployees: +numOfEmployees.value,
    }).then(async (rsp) => {
      if (rsp.data.data) {
        let path = "/yearly_details";
        history.push({
          pathname: path,
          state: e,
        });
      }
    });
  };

  const addYearlyDetails = () => {
    if(validateForm()){
    getYearlyDetailsList(establishmentData);
  }
  };
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const handleTotalHour = (event) => {
    setTotalHour(event.target.value);
  };
  const handleAvgEmployeee = (event) => {
    setAvgEmployee(event.target.value);
  };
  return (
    <MainContainer isHeader>
      <div className={css.yrlyMain}>
        <div className={css.yrlyCardHeader}>
          <div className={css.yrlyHeader}>
            <IconSettingSpanner />
            Settings
          </div>
          <div className={css.yrlySubHeaders}>
            <div className={css.yrlyDetails}>Establishment- Yearly Details</div>
          </div>
        </div>
        <div className={css.yrlyAddCard}>
          <div className={css.yrlyAddFields}>
            <label className={css.yrlyformFieldLabels}>
              Select Year<sup>*</sup>
            </label>
            <div>
              <Autocomplete
                value={selectedYear}
                options={years}
                onChange={(event, newValue) => {
                  setSelectedYear(newValue);
                }}
                renderInput={(params) => <MobTextField 
                  {...params} 
                  fullWidth
                  placeholder="Select year"
                  />}
                clearIcon={null}
              />
            </div>
          </div>
          <div className={css.yrlyAddFields}>
            <label className={css.yrlyformFieldLabels}>
              Peak Number of Employees in the Year<sup>*</sup>
            </label>
            <div>
              <Autocomplete
                options={employeeNumbers}
                value={numOfEmployees}
                onChange={(event, newValue) => {
                  setNumOfEmployees(newValue);
                }}
                renderInput={(params) => <MobTextField 
                  {...params} 
                  fullWidth 
                  placeholder="Select number of Employees"
                  />}
                clearIcon={null}
              />
            </div>
          </div>
          <div className={css.yrlyAddFields}>
            <label className={css.yrlyformFieldLabels}>
            Total hours worked by all employees last year<sup>*</sup>
            </label>
            <div>
              <MobTextField
                type="number"
                value={totalHour}
                onChange={handleTotalHour}  
                required
                placeholder="Accept only numbers"
              />
            </div>
          </div>
          <div className={css.yrlyAddFields}>
            <label className={css.yrlyformFieldLabels}>
            Annual average number of employees<sup>*</sup>
            </label>
            <div>
              <MobTextField
                type="number"
                value={avgEmployee}
                onChange={handleAvgEmployeee}  
                required
                placeholder="Accept only numbers"
              />
            </div>
          </div>
          <div className={css.addEstButton}>
                  <MobButton
                  style={{width:"50%"}}
                  onClick={addYearlyDetails}
                  >
                    Save
                  </MobButton>
              </div>
        </div>
      </div>
    </MainContainer>
  );
}

export default AddYearlyDetails;
