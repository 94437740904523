import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../Nav/navbar";
import getTableStyles from "../../tablestyles";
import NavHome from "../Nav/navHome";
// import { Button } from "flowbite-react";
import { Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageLoader from "../services/pageloader";
import {
  OshaRecordList,
  establishmentDetails,
  SelectYear,
  ExportDownloadAction,
  Export300Excel,
  Export301CSV,
} from "../services/api-servics";
import MenuItem from "@mui/material/MenuItem";
import { Select, TextField, Button, Autocomplete, IconButton, CircularProgress, Box  } from "@mui/material";
import MainWrapper from "../GlobalComponents/MainWrapper";
// import handleCSVDownload from "./Osha300/CSV";

function OshaLog() {
  const [oshaList, setoshaList] = useState("");
  const [establishmentOption, setestablishmentOption] = useState([]);
  const [establishment, setestablishment] = useState();
  const [yearOption, setyearOption] = useState("");
  const [year, setyear] = useState();
  const [loadindData,setLoadingData]=useState(false);
  const [loadingEst,setLoadingEst]=useState(false);
  const [loadingYear,setLoadingYear]=useState(false);
  const role = localStorage.getItem("role");
  useEffect(() => {
    getOshaRecord();
  }, []);
  const getOshaRecord = () => {
    setLoadingData(true);
    OshaRecordList({}).then(async (rsp) => {
      if (rsp.data.data) {
        setoshaList(rsp.data.data);
      }
      setLoadingData(false);
    });
    establishmentDetails({}).then(async (rsp) => {
      if (rsp.data.data) {
        setestablishmentOption(rsp.data.data);
      }
      
    });

    SelectYear({}).then(async (rsp) => {
      if (rsp.data.data) {
        setLoadingYear(false)
        setyearOption(rsp.data.data);
      }
      
    });
  };
  const handleExcelDownloads = () => {
    const establishment_id = establishment;
    Export300Excel({ establishment_id, year });
  };

  const handleButtonClick = () => {
    const establishment_id = establishment;
    Export301CSV({ establishment_id, year });
  };

  const history = useHistory();
  const handleOshaDetails = () => {
    let path = "/osha_details";
    history.push({
      pathname: path,
      establishment_id: establishment,
      year: year,
    });
  };

  const handleActionButtonClick = (r) => {
    const case_number = r.case_number;
    const establishment_id = r.establishment_id;
    ExportDownloadAction({ case_number, establishment_id });
  };

  const WrappedCell = (children, width) => (
    <div style={{ maxWidth: width, wordWrap: "break-word" }}>{children}</div>
  );

  const columns = [
    {
      name: (
        <div>
          <span style={{ display: "block" }}>Unique</span>
          <span style={{ display: "block" }}>ID</span>
        </div>
      ),
      selector: (row) => row.case_number,
      sortable: true,
      width: "105px",
      customFilter: (filterValue, row) =>
        row.case_number.toLowerCase().includes(filterValue.toLowerCase()),
    },

    {
      name: "Establishment Name",
      selector: (row) => row.establishment_name,
      width: "170px",
      cell: (row) => WrappedCell(row.establishment_name, "170px"),
      customFilter: (filterValue, row) =>
        row.establishment_name
          .toLowerCase()
          .includes(filterValue.toLowerCase()),
    },

    {
      name: "Employee Name",
      selector: (row) => row.employee_name,
      style: {
        overflow: "visible",
      },
      sortable: true,
      width: "160px",
      customFilter: (filterValue, row) =>
        row.employee_name.toLowerCase().includes(filterValue.toLowerCase()),
    },

    {
      name: (
        <div>
          <span style={{ display: "block" }}>Job</span>
          <span style={{ display: "block" }}>Title</span>
        </div>
      ),
      selector: (row) => row.employee_job,
      sortable: true,
      width: "100px",
      customFilter: (filterValue, row) =>
        row.employee_job.toLowerCase().includes(filterValue.toLowerCase()),
    },

    {
      name: (
        <div>
          <span style={{ display: "block" }}>Date of</span>
          <span style={{ display: "block" }}>Incident</span>
        </div>
      ),
      selector: (row) => row.incident_date,
      width: "130px",
      customFilter: (filterValue, row) =>
        row.incident_date.toLowerCase().includes(filterValue.toLowerCase()),
    },

    {
      name: (
        <div>
          <span style={{ display: "block" }}>Where The</span>
          <span style={{ display: "block" }}>Incident Occured</span>
        </div>
      ),
      selector: (row) => row.incident_location,
      width: "150px",
      cell: (row) => WrappedCell(row.incident_location, "150px"),
      customFilter: (filterValue, row) =>
        row.incident_location.toLowerCase().includes(filterValue.toLowerCase()),
    },

    {
      name: " About Incident",
      selector: (row) => row.detailed_info,
      width: "380px",
      cell: (row) => WrappedCell(row.detailed_info, "380px"),
      customFilter: (filterValue, row) =>
        row.detailed_info.toLowerCase().includes(filterValue.toLowerCase()),
    },

    {
      name: (
        <div>
          <span style={{ display: "block" }}>Incident</span>
          <span style={{ display: "block" }}>Category</span>
        </div>
      ),
      selector: (row) => row.incident_category,
      width: "100px",
      cell: (row) => WrappedCell(row.incident_category, "100px"),
      customFilter: (filterValue, row) =>
        row.incident_category.toLowerCase().includes(filterValue.toLowerCase()),
    },

    {
      name: "Action",
      cell: (row) => {
        return (
          <IconButton
            onClick={() => handleActionButtonClick(row)}
            color="primary"
            size="small"
            >
              <i class="fa fa-download" aria-hidden="true"></i>
            </IconButton>
        );
      },
      width: "100px",
      center:true
    },
  ];
  const columns1 = [
    {
      name: (
        <div>
          <span style={{ display: "block" }}>Unique</span>
          <span style={{ display: "block" }}>ID</span>
        </div>
      ),
      selector: (row) => row.case_number,
      sortable: true,
      width: "105px",
      customFilter: (filterValue, row) =>
        row.case_number.toLowerCase().includes(filterValue.toLowerCase()),
    },

    {
      name: "Establishment Name",
      selector: (row) => row.establishment_name,
      width: "170px",
      cell: (row) => WrappedCell(row.establishment_name, "170px"),
      customFilter: (filterValue, row) =>
        row.establishment_name
          .toLowerCase()
          .includes(filterValue.toLowerCase()),
    },
    {
      name: (
        <div>
          <span style={{ display: "block" }}>Job</span>
          <span style={{ display: "block" }}>Title</span>
        </div>
      ),
      selector: (row) => row.employee_job,
      sortable: true,
      width: "170px",
      customFilter: (filterValue, row) =>
        row.employee_job.toLowerCase().includes(filterValue.toLowerCase()),
    },

    {
      name: (
        <div>
          <span style={{ display: "block" }}>Date of</span>
          <span style={{ display: "block" }}>Incident</span>
        </div>
      ),
      selector: (row) => row.incident_date,
      width: "130px",
      customFilter: (filterValue, row) =>
        row.incident_date.toLowerCase().includes(filterValue.toLowerCase()),
    },

    {
      name: (
        <div>
          <span style={{ display: "block" }}>Where The</span>
          <span style={{ display: "block" }}>Incident Occured</span>
        </div>
      ),
      selector: (row) => row.incident_location,
      width: "200px",
      cell: (row) => WrappedCell(row.incident_location, "200px"),
      customFilter: (filterValue, row) =>
        row.incident_location.toLowerCase().includes(filterValue.toLowerCase()),
    },

    {
      name: " About Incident",
      selector: (row) => row.detailed_info,
      width: "380px",
      cell: (row) => WrappedCell(row.detailed_info, "380px"),
      customFilter: (filterValue, row) =>
        row.detailed_info.toLowerCase().includes(filterValue.toLowerCase()),
    },

    {
      name: (
        <div>
          <span style={{ display: "block" }}>Incident</span>
          <span style={{ display: "block" }}>Category</span>
        </div>
      ),
      selector: (row) => row.incident_category,
      width: "200px",
      cell: (row) => WrappedCell(row.incident_location, "200px"),
      customFilter: (filterValue, row) =>
        row.incident_category.toLowerCase().includes(filterValue.toLowerCase()),
    },
  ];

  return (
    <>
      <MainWrapper>
        <Card>
          <Card.Header className="cardHeader">
            <div className="managerText">OSHA Record</div>
            {role!=="admin" && 
            (<div className="searchArea" style={{ gap: "10px" }}>
              {/* <div> */}
              <Autocomplete
                options={establishmentOption}
                getOptionLabel={(option) => option.establishmentName}
                //  value={establishment}
                onChange={(event, newValue) => {
                  setestablishment(newValue.establishmentID);
                }}
                renderInput={(params) => (
                  <TextField
                    style={{ width: "250px" }}
                    {...params}
                    label="Select Establishment"
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                clearIcon={null}
                
              />

              <Autocomplete
                options={yearOption}
                //    getOptionLabel={(option) => option.label}
                value={year}
                onChange={(event, newValue) => {
                  setyear(newValue.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ width: "250px" }}
                    label="Select Year"
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                clearIcon={null}
              />

              <Button
                variant="contained"
                size="small"
                disabled={!(establishment&& year)}
                onClick={handleButtonClick}
              >
                OSHA300/301CSV
              </Button>
              <Button
                variant="contained"
                size="small"
                disabled={!(establishment&& year)}
                onClick={handleExcelDownloads}
              >
                OSHA300Excel
              </Button>
              <Button
                variant="contained"
                size="small"
                disabled={!(establishment&& year)}
                onClick={handleOshaDetails}
              >
                OSHA300A
              </Button>

              {/* </div> */}
            </div>)}
          </Card.Header>
          <Card.Body>
            {/* {loadindData ? 
            (<Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
            
          </Box>) */}
          {loadindData ? <PageLoader showLoader={loadindData} />
            :
            (<DataTable
              data={oshaList}
              columns={role==="admin"? columns1:columns}
              customStyles={getTableStyles}
              pagination
              striped
              paginationPerPage={10}
            />)}
          </Card.Body>
        </Card>
      </MainWrapper>
    </>
  );
}
export default OshaLog;
