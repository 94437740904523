import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { showToaster } from "../services/helper";
import PageLoader from "../services/pageloader";
import { getAccessToken, getToken, getServerStatus, upgrateServerTime, suspendServerTime, azureAccessToken, getEmbededUrl, PowerbiRefresh } from "../services/api-servics";
import Navbar from "../Nav/navbar";
import NavHome from "../Nav/navHome";
import "./dashboard.css"
import WindowMessage from "../GlobalComponents/WindowMessage";
export default function Dashboard(props) {
    const history = useHistory();
    const [accessToken, setGetToken] = useState(undefined);
    const [embededUrl, setEmbededUrl] = useState(false);
    const [serverToken, setServerToken] = useState(undefined);
    const [serverState, setServerState] = useState("");
    const [pageLoad, setPageLoad] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const MINUTE_MS = 60000;
    const [report, setReport] = useState(null);

    // window.addEventListener('beforeunload', (event) => {
    //     event.preventDefault();
    //     event.returnValue = '';
    // });
    // const powerbirefresh = () => {
    //     let ddd = {
    //         dataset_id: REACT_APP_DATASETID,
    //         group_id: "77c9dbc6-7914-4b91-bffc-7401d0cd7540"

    //     }
    //     PowerbiRefresh({

    //     }).then(async (ddd) => {

    //     });
    // }


    const powerbirefresh = () => {
        try {
            setPageLoad(true)
            let details = {
                dataset_id:REACT_APP_DATASETID,
                group_id: REACT_APP_GROUP_ID
    
            }
            PowerbiRefresh(details).then(async (response) => {
                if (
                    response !== "" &&
                    response !== undefined &&
                    response !== null &&
                    response.status == 200

                ) {
                    console.log(response,"response");
                    setPageLoad(false) 

                }

            });
        } catch (e) {
            setPageLoad(false)
        }
    }
    const salesVisualFilterq = {
        logicOperator: 'And',
        filters: [
            {
                field: 'Region',
                operator: 'In',
                values: ['North America', 'Europe']
            },
            {
                field: 'SalesAmount',
                operator: 'GreaterThan',
                value: 10000
            }
        ]
    };
    const salesVisualFilter = {
        field: 'Hispanic',
        operator: 'GreaterThan',
        values: 10000
    };

    const serverStatus = () => {
        try {
            setPageLoad(true)
            azureAccessToken().then(async (response) => {
                if (
                    response !== "" &&
                    response !== undefined &&
                    response !== null &&
                    response.status == 200

                ) {
                    if (response.data !== undefined && response.data !== "") {
                        if (response.data !== undefined && response.data !== null && response.data !== "") {
                            let AccessToken = "Bearer " + response.data.access_token
                            await getServerStatus(AccessToken).then(async (response) => {
                                if (
                                    response !== "" &&
                                    response !== undefined &&
                                    response !== null && response.data !== undefined && response.data !== null && response.data !== "" &&
                                    response.data.properties !== undefined && response.data.properties !== null && response.data.properties !== ""
                                ) {
                                    if (response.data.properties.state !== undefined && response.data.properties.state !== null && response.data.properties.state !== "") {
                                        let serverStatus = response.data.properties.state;
                                        console.log(serverStatus,"serverStatus");
                                        setServerState(serverStatus)
                                        setShowModal(true)
                                        setPageLoad(false)
                                    }
                                }
                            });
                        }
                    }
                } else {
                    setPageLoad(false)
                }

            });
        } catch (e) {
            setPageLoad(false)
        }
    }
    useEffect(() => {
        // window.addEventListener("beforeunload", (event) => {
        //     console.log(event, "events");
        //     // Show a confirmation dialog to the user.
        //     const confirmationDialog = window.confirm("Are you sure you want to close this window or tab?");

        //     // If the user clicks "OK" in the confirmation dialog, allow the window or tab to close.
        //     if (confirmationDialog) {
        //         event.returnValue = undefined;
        //     } else {
        //         // If the user clicks "Cancel" in the confirmation dialog, prevent the window or tab from closing.
        //         event.preventDefault();
        //     }
        // });
    }, []);
    useEffect(() => {
        powerbirefresh()

        retreivePowerBiCredentials()
        serverStatus()
        const interval = setInterval(() => {
            try {
                azureAccessToken().then(async (response) => {
                    if (
                        response !== "" &&
                        response !== undefined &&
                        response !== null &&
                        response.status == 200

                    ) {
                        if (response.data !== undefined && response.data !== "") {
                            if (response.data.result !== undefined && response.data.result !== null && response.data.result !== "") {
                                let AccessToken = "Bearer " + response.data.result.access_token
                                setServerToken(AccessToken)
                                await getServerStatus(AccessToken).then(async (response) => {
                                    if (
                                        response !== "" &&
                                        response !== undefined &&
                                        response !== null && response.data !== undefined && response.data !== null && response.data !== "" &&
                                        response.data.properties !== undefined && response.data.properties !== null && response.data.properties !== ""

                                    ) {
                                        if (response.data.properties.state !== undefined && response.data.properties.state !== null && response.data.properties.state !== "") {
                                            let serverStatus = response.data.properties.state;

                                            if (serverStatus == "Paused") {

                                                // upgradeServerTime("triger")
                                            } else {

                                                if (report == null) return;
                                                await report.refresh();

                                            }
                                        }
                                        // window.location.reload()
                                    }

                                });

                            }

                        }
                    } else {
                    }

                });
            } catch (e) {
            }
        }, MINUTE_MS);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])
    useEffect(() => {
        try {
            azureAccessToken().then(async (response) => {
                if (
                    response !== "" &&
                    response !== undefined &&
                    response !== null &&
                    response.status == 200

                ) {
                    if (response.data !== undefined && response.data !== "") {
                        if (response.data.result !== undefined && response.data.result !== null && response.data.result !== "") {
                            let AccessToken = "Bearer " + response.data.result.access_token
                            setServerToken(AccessToken)
                            await getServerStatus(AccessToken).then(async (response) => {


                                if (
                                    response !== "" &&
                                    response !== undefined &&
                                    response !== null && response.data !== undefined && response.data !== null && response.data !== "" &&
                                    response.data.properties !== undefined && response.data.properties !== null && response.data.properties !== ""

                                ) {

                                    if (response.data.properties.state !== undefined && response.data.properties.state !== null && response.data.properties.state !== "") {
                                        let serverStatus = response.data.properties.state;



                                        if (serverStatus == "Succeeded") {
                                            setTimeout(() => {
                                                // retreivePowerBiCredentials()
                                            }, 5000)

                                        } else
                                            if (serverStatus == "Paused") {
                                                setTimeout(() => {
                                                    // upgradeServerTime()
                                                }, 5000)
                                            } else {
                                                setTimeout(() => {
                                                    // upgradeServerTime()
                                                }, 5000)
                                            }



                                    }
                                    // window.location.reload()
                                }

                            });

                        }

                    }
                } else {
                }

            });
        } catch (e) {
        }
    }, [])
    const REACT_APP_DATASETID = props.location?.state?.fromProcessLog ? process.env.REACT_APP_DATASETID : process.env.REACT_APP_DATASETID_OSHA
    const REACT_APP_REPORT_ID = props.location?.state?.fromProcessLog ? process.env.REACT_APP_REPORT_ID : process.env.REACT_APP_REPORT_ID_OSHA
    const REACT_APP_GROUP_ID = props.location?.state?.fromProcessLog ? process.env.REACT_APP_GROUP_ID : process.env.REACT_APP_GROUP_ID
   
    const retreivePowerBiCredentials = async () => {
        try {
            let tid = process.env.REACT_APP_TENANTID
            var details = {
                'client_id': process.env.REACT_APP_CLIENT_ID,
                "grant_type": process.env.REACT_APP_GRAND_TYPE,
                "scope": process.env.REACT_APP_SCOPE,
                "client_secret": process.env.REACT_APP_CLIENT_SECRET,
            };
            var formBody = [];
            for (var property in details) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(details[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");


            await getToken().then(async (response) => {
                if (
                    response !== "" &&
                    response !== undefined &&
                    response !== null
                ) {
                    if (response.data !== undefined && response.data !== null && response.data !== "") {
                        let AccessToken = "Bearer " + response.data.access_token


                        let data = {
                            "datasets": [
                                {
                                    "id": REACT_APP_DATASETID
                                }
                            ],
                            "reports": [
                                {
                                    "id": REACT_APP_REPORT_ID
                                }
                            ],

                        }

                        await getAccessToken(data, AccessToken).then(async (response) => {
                            if (
                                response !== "" &&
                                response !== undefined &&
                                response !== null &&
                                response.status == 200

                            ) {
                                if (response.data !== undefined && response.data !== "") {
                                    setGetToken(response.data.token)
                                }
                            } else {

                            }

                        });
                        await getEmbededUrl(AccessToken, REACT_APP_REPORT_ID).then((response) => {
                            if (
                                response !== "" &&
                                response !== undefined &&
                                response !== null &&
                                response.status == 200

                            ) {
                                if (response.data !== undefined && response.data !== "") {
                                    setEmbededUrl(response.data.embedUrl)
                                }
                            } else {
                            }

                        });
                    }

                } else {
                }

            });
        } catch (e) {
        }
    }

    const upgradeServerTime = async (type) => {

        try {
            await azureAccessToken().then(async (response) => {
                if (
                    response !== "" &&
                    response !== undefined &&
                    response !== null &&
                    response.status == 200

                ) {
                    if (response.data !== undefined && response.data !== "") {
                        if (response.data.result !== undefined && response.data.result !== null && response.data.result !== "") {
                            let AccessToken = "Bearer " + response.data.result.access_token
                            await upgrateServerTime(AccessToken).then((response1) => {
                                if (
                                    response !== "" &&
                                    response !== undefined &&
                                    response !== null

                                ) {
                                    retreivePowerBiCredentials()
                                    if (type == "triger") {
                                        window.location.reload()
                                    }
                                    // window.location.reload()
                                }

                            });

                        }

                    }
                } else {
                }

            });
        } catch (e) {
        }

    }


    const logout = async () => {
        // localStorage.clear();
        // try {
        //     setPageLoad(true)
        //     await azureAccessToken().then(async (response) => {
        //         if (
        //             response !== "" &&
        //             response !== undefined &&
        //             response !== null &&
        //             response.status == 200

        //         ) {
        //             if (response.data !== undefined && response.data !== "") {
        //                 if (response.data.result !== undefined && response.data.result !== null && response.data.result !== "") {
        //                     let AccessToken = "Bearer " + response.data.result.access_token
        //                     await suspendServerTime(AccessToken).then((response1) => {
        //                         if (
        //                             response !== "" &&
        //                             response !== undefined &&
        //                             response !== null

        //                         ) {
        //                         }

        //                     });

        //                 }

        //             }
        //         } else {
        //         }

        //     });
        // } catch (e) {
        // }
        // setTimeout(() => {
        //     setPageLoad(false)
        //     localStorage.removeItem("power_bi");
        //     history.push("/");
        // }, 8000);
        localStorage.removeItem("power_bi");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/");
    };
    const handleBack = () => {
        let path = "/process-tab";
        history.push({
            pathname: path
        });
    }
    const windowPopupMessage = (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                fontWeight: "800",
            }}
        >
            {" "}
            <div>To view analytics, activate Azure Capacity in the Settings tab.</div>
            <div>Would you like to proceed with the Settings tab</div>
        </div>
    );
    const handleEmptyClose = () => {
        setShowModal(false);
        let path = "/mainboard";
        history.push(path);
    };
    const handleConfirmEst = () => {
        let path = "/settings";
        history.push(path);
    };
    return (
        <>
            <PageLoader showLoader={pageLoad} />
            {props.location?.state?.fromProcessLog && <div
                className="dashBackButton"
                onClick={handleBack}
            >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </div>}
            <section>
                <div className="col-12 w-100">
                    {/* <iframe  title="GSEP-SchoolsUnderServiceUnit_New" width="100%" height="541.25"
                  src="https://app.powerbi.com/groups/77c9dbc6-7914-4b91-bffc-7401d0cd7540/reports/366c1bc3-6c90-4bcc-8e44-08613048cca3/ReportSection?experience=power-bi"
                       frameborder="0" allowFullScreen="true"></iframe> */}
                    {/* <iframe title="GSEP-SchoolsUnderServiceUnit_New" width="600" height="373.5"
                    src="https://app.powerbi.com/view?r=eyJrIjoiNGJhZDJhZTYtMGE4OS00N2E5LThmYzYtNGRlZDIwNWRlZDkzIiwidCI6ImIxZjc3YjY3LWU2NjktNDI1ZS04NTkwLTdhMjBhYjNiYThmMCIsImMiOjEwfQ%3D%3D"></iframe> */}
                    {serverState == "Paused" ||serverState=="Pausing" ?
                        showModal && (
                            <WindowMessage
                                show={showModal}
                                onClose={handleEmptyClose}
                                windowMessage={windowPopupMessage}
                                handleConfirm={handleConfirmEst}
                                keepMounted
                                fromNewForm
                            />)
                        :
                        <PowerBIEmbed
                            embedConfig={{
                                type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                                id: REACT_APP_REPORT_ID,
                                embedUrl: embededUrl,
                                // embedUrl:"https://app.powerbi.com/groups/77c9dbc6-7914-4b91-bffc-7401d0cd7540/reports/366c1bc3-6c90-4bcc-8e44-08613048cca3/ReportSection?experience=power-bi",
                                accessToken: accessToken,
                                tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                                settings: {
                                    panes: {
                                        filters: {
                                            expanded: false,
                                            visible: false,
                                        },
                                    },
                                    // visualizations: {
                                    //     salesVisual: {
                                    //         filters: [salesVisualFilter]
                                    //     }
                                    // },
                                    background: models.BackgroundType.Transparent,
                                },
                            }}
                            eventHandlers={
                                new Map([
                                    [
                                        "loaded",
                                        function () {
                                            console.log("Report loaded");
                                        },
                                    ],
                                    [
                                        "rendered",
                                        function () {
                                            console.log("Report rendered");
                                        },
                                    ],
                                    [
                                        "error",
                                        function (event) {
                                            console.log(event.detail);
                                        },
                                    ],
                                    ["visualClicked", () => console.log("visual clicked")],
                                    ["pageChanged", (event) => console.log(event)],
                                ])
                            }
                            cssClassName={props.location?.state?.fromProcessLog ? "report-style-class" : "report-style-osha"}
                            // cssClassName={"reportClass"}

                            getEmbeddedComponent={(embeddedReport) => {
                                window.report = embeddedReport;
                                console.log("getEmbeddedComponent", report);
                                setReport(embeddedReport);
                            }}
                        />}
                </div>
            </section>
            {/* <section className="footer-wrap">
                <div className="container">
                    <div className="copyright">
                        <p>© 2021 Techvantage All rights reserved</p>
                    </div>
                </div>
            </section> */}
        </>
    );
}