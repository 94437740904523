import React from 'react'
import css from './mainwrapper.module.scss';
function MainWrapper(props) {
  return (
    <div className={css.wrapper}>
			{props.children}
		</div>
  )
}

export default MainWrapper