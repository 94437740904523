import React from "react";
import css from "./yearlydetails.module.scss";
function YearlyDetailsCard(props) {
    const data = props.data
  return (
    <div className={css.yrlyCard}>
      <div className={css.yrlyCardDetails}>
        <div className={css.yrlyRows}>
          <div className={css.yrlyCardDetailColL}>Year</div>
          <div className={css.yrlyCardDetailColR}>{data.year}</div>
        </div>

        <div className={css.yrlyRows}>
          <div className={css.yrlyCardDetailColL}>Peak Number of employees in an year</div>
          <div className={css.yrlyCardDetailColR}>{data.peakNumberOfEmployees}</div>
        </div>

        <div className={css.yrlyRows}>
          <div className={css.yrlyCardDetailColL}>Total hours worked by all employees last year</div>
          <div className={css.yrlyCardDetailColR}>{data.totalhrwkd}</div>
        </div>

        <div className={css.yrlyRows}>
          <div className={css.yrlyCardDetailColL}>Annual average number of employees</div>
          <div className={css.yrlyCardDetailColR}>{data.annavgNumber}</div>
        </div>
      </div>
    </div>
  );
}

export default YearlyDetailsCard;
