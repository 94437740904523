import React from 'react'
import { Card } from 'react-bootstrap';
import { IconILE, IconOshaInsider } from '../../../assets/css/icons'
import css from "./registermob.module.scss";
function RegisterMob(props) {
  return (
    <div className={css.contentWrapper}>
    <div className={css.oshaInsiderIconDiv}>
      <IconOshaInsider className={css.oshaInsiderIcon}/>
    </div>
    <div className={css.mainCardDiv}>
        <div className={css.behindCard}>
        </div>
        <Card className={css.detailCardDiv}>
            <Card.Header className={css.regCardheader}>
                <div className={css.cardHeaderDiv}>
                    <label className={css.getStarted}>Get Started Now</label>
                    <label className={css.detailText}>Enter your details below to create an account.</label>
                </div>
            </Card.Header>
            <Card.Body className={css.regCardBody}>
                {props.children}
            </Card.Body>
            <Card.Footer className={css.regCardFooter}>
            <IconILE/>
            </Card.Footer>
        </Card>
    </div>
  </div>
  )
}

export default RegisterMob