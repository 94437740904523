import React from "react";
import { useHistory } from "react-router-dom";
import "./login.css";
import { showToaster } from "../services/helper";
import { styled } from "@material-ui/styles";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { SignUp } from "../services/api-servics";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Col } from "react-bootstrap";
import MediaQuery from "react-responsive";
import RegisterMob from "../MobileUI/Register/RegisterMob";
import MobTextField from "../MobileUI/GlobalComponents/MobTextField";
import {
  IconCompany,
  IconDesignation,
  IconEmail,
  IconName,
  IconPassword,
  IconPhone,
} from "../../assets/css/icons";
import MobButton from "../MobileUI/GlobalComponents/MobButton";
import { ThreeDots } from "react-loader-spinner";
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white", // Set the border color
    },
    "&:hover fieldset": {
      borderColor: "white", // Set the hover border color
    },
    "&.Mui-focused fieldset": {
      borderColor: "#5999ff", // Set the focused border color
    },
  },
  "& .MuiInputBase-input": {
    color: "white", // Set the font color
  },
  "& .MuiInputLabel-root": {
    color: "#e3e3e3", // Set the label color
  },

  backgroundColor: "transparent", // Set the background color
});
function Register() {
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [mobNo, setMobNo] = useState("");
  const [designation, setDesignation] = useState("");
  const [password, setPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [denied, setDenied] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    company: "",
    mobNo: "",
    designation: "",
    password: "",
  });

  const valiadteEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return emailRegex.test(email);
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!name.trim()) {
      newErrors.name = "Employee Name is required";
      isValid = false;
    } else {
      newErrors.name = "";
    }
    if (!valiadteEmail(email)) {
      newErrors.email = "Enter valid Email Id";
      isValid = false;
    } else {
      newErrors.email = "";
    }
    if (!company.trim()) {
      newErrors.company = "Company is required";
      isValid = false;
    } else {
      newErrors.company = "";
    }

    if (!mobNo.trim()) {
      newErrors.mobNo = "Contact number is required or Incorrect";
      isValid = false;
    } else {
      newErrors.mobNo = "";
    }

    // Validate State
    if (!designation.trim()) {
      newErrors.designation = "Designation is required";
      isValid = false;
    } else {
      newErrors.designation = "";
    }

    // Validate Gender
    if (!password.trim()) {
      newErrors.password = "Password is required";
      isValid = false;
    } else {
      newErrors.password = "";
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleSignup = () => {
    SignUp({
      name: name,
      email: email,
      company: company,
      mobNo: mobNo,
      designation: designation,
      password: password,
    }).then(async (rsp) => {
      const data = rsp.data;
      if(data.message === "email already exist"){
        setErrors({...errors,email:"email already exist"});
      }
      else{
      setDenied(false);
      handleRedirect();
      }
    });
  };

  const handlemobsignUp = () => {
    setDenied(true);
    if (validateForm()) {
      // setDenied(false);
      handleSignup();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      company: "",
      mobNo: "",
      designation: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Email is invalid!")
        .required("Email Required!"),
      company: Yup.string().required("Company is required"),
      mobNo: Yup.string().required("Mobile number is required"),
      designation: Yup.string().required("Designation is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      try {
        handleSignup();
      } catch (e) {
        // setPageLoad(false);
        showToaster("Username and password is not valid", "warning");
      }
    },
  });
  const handleRedirect = () => {
    let path = "/";
    history.push({
      pathname: path,
      redirected: true,
    });
  };
  const handleName = (event) => {
    setName(event.target.value);
  };
  const handleMail = (event) => {
    setEmail(event.target.value);
  };
  const handelMob = (event) => {
    const inputValue = event.target.value.replace(/\D/g, "").slice(0, 10);
    setMobNo(inputValue);
  };
  const handleCompany = (event) => {
    setCompany(event.target.value);
  };
  const handleDesignaton = (event) => {
    setDesignation(event.target.value);
  };
  const handlePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const required = (label) => {
    return (
      <span>
        {label}
        <sup style={{ color: "red" }}>*</sup>
      </span>
    );
  };

  const handleError = (error) => {
    return error === null || error === "";
  };
  return (
    <>
      {/* Desktop UI */}
      <MediaQuery minDeviceWidth={821}>
        <div
          className="login"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>
            <h2 className="lg-head">Register</h2>
          </div>
          <form className="registerForm" onSubmit={formik.handleSubmit}>
            <div className="col ">
              <div>
                <div className="material-textfield">
                  <CustomTextField
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleName(e);
                    }}
                    // onBlur={formik.handleBlur}
                    value={name}
                    type="text"
                    id="name"
                    label={required("Name")}
                    placeholder=" "
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    error={formik.touched.name && formik.errors.name}
                  />
                </div>
                {formik.touched.name && formik.errors.name ? (
                  <div className="input-error-msg">{formik.errors.name}</div>
                ) : null}
              </div>
            </div>

            <div className="col ">
              <div>
                <div className="material-textfield">
                  <CustomTextField
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleMail(e);
                    }}
                    // onBlur={formik.handleBlur}
                    value={email}
                    type="text"
                    id="email"
                    label={required("Email")}
                    placeholder=" "
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    error={formik.touched.email && formik.errors.email}
                  />
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div className="input-error-msg">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>

            <div className="col ">
              <div>
                <div className="material-textfield">
                  <CustomTextField
                    onChange={(e) => {
                      formik.handleChange(e);
                      handelMob(e);
                    }}
                    // onBlur={formik.handleBlur}
                    value={mobNo}
                    type="text"
                    id="mobNo"
                    label={required("Contact Number")}
                    placeholder=" "
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    error={formik.touched.mobNo && formik.errors.mobNo}
                  />
                </div>
                {formik.touched.mobNo && formik.errors.mobNo ? (
                  <div className="input-error-msg">{formik.errors.mobNo}</div>
                ) : null}
              </div>
            </div>

            <div className="col ">
              <div>
                <div className="material-textfield">
                  <CustomTextField
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleCompany(e);
                    }}
                    // onBlur={formik.handleBlur}
                    value={company}
                    type="text"
                    id="company"
                    label={required("Company")}
                    placeholder=" "
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    error={formik.touched.company && formik.errors.company}
                  />
                </div>
                {formik.touched.company && formik.errors.company ? (
                  <div className="input-error-msg">{formik.errors.company}</div>
                ) : null}
              </div>
            </div>

            <div className="col ">
              <div>
                <div className="material-textfield">
                  <CustomTextField
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleDesignaton(e);
                    }}
                    // onBlur={formik.handleBlur}
                    value={designation}
                    type="text"
                    id="designation"
                    label={required("Designation")}
                    placeholder=" "
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    error={
                      formik.touched.designation && formik.errors.designation
                    }
                  />
                </div>
                {formik.touched.designation && formik.errors.designation ? (
                  <div className="input-error-msg">
                    {formik.errors.designation}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col ">
              <div>
                <div className="material-textfield">
                  <CustomTextField
                    onChange={(e) => {
                      formik.handleChange(e);
                      handlePassword(e);
                    }}
                    // onBlur={formik.handleBlur}
                    value={password}
                    type={showNewPassword ? "text" : "password"}
                    id="password"
                    label={required("Password")}
                    placeholder=" "
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    error={formik.touched.password && formik.errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleNewPasswordVisibility}
                            edge="end"
                            style={{ color: "white" }}
                          >
                            {showNewPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="input-error-msg">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>
            </div>

            <Col>
              <button className="lg-btn btn-label" onClick={() => {}}>
                Register
              </button>
            </Col>
          </form>
        </div>
      </MediaQuery>

      {/* Mobile UI */}
      <MediaQuery maxDeviceWidth={820}>
        <RegisterMob>
          <div className="mobregfields">
            <MobTextField
              placeholder="Full Name"
              onChange={handleName}
              type="text"
              value={name}
              helperText={
                denied && errors.name.length > 0 && name === "" ? 
                <div style={{textTransform:"capitalize"}}>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        {errors.name}
                      </div> : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <IconName />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <MobTextField
              placeholder="Email Address"
              onChange={handleMail}
              value={email}
              type="text"
              helperText={
                denied && (errors.email.length > 0 || email === "") ? 
                <div style={{textTransform:"capitalize"}}>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        {errors.email}
                      </div> : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <IconEmail />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <MobTextField
              placeholder="Contact Number"
              onChange={handelMob}
              value={mobNo}
              type="text"
              helperText={
                denied && errors.mobNo.length > 0 && mobNo === "" ? 
                <div style={{textTransform:"capitalize"}}>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        {errors.mobNo}
                      </div> : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <IconPhone />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <MobTextField
              placeholder="Company"
              onChange={handleCompany}
              value={company}
              type="text"
              helperText={
                denied && errors.company.length > 0 && company === "" ? 
                <div style={{textTransform:"capitalize"}}>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        {errors.company}
                      </div> : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <IconCompany />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <MobTextField
              placeholder="Designation"
              onChange={handleDesignaton}
              value={designation}
              type="text"
              helperText={
                denied && errors.designation.length > 0 && designation === "" ? 
                <div style={{textTransform:"capitalize"}}>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        {errors.designation}
                      </div> : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <IconDesignation />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <MobTextField
              placeholder="Password"
              onChange={handlePassword}
              value={password}
              type={showNewPassword ? "text" : "password"}
              helperText={
                denied && errors.password.length > 0 && password === "" ? 
                <div style={{textTransform:"capitalize"}}>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        {errors.password}
                      </div> : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <IconPassword />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleNewPasswordVisibility}
                      edge="end"
                      style={{ color: "rgba(135, 86, 212, 1)" }}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className="divButton">
              <MobButton
                fullWidth
                onClick={handlemobsignUp}
              >
                {denied &&
                (errors.name === "" &&
                  errors.email === "" &&
                  errors.company === "" &&
                  errors.mobNo === "" &&
                  errors.designation === "" &&
                  errors.password === "") ? (
                  <ThreeDots
                    visible={true}
                    height="50"
                    width="50"
                    color="#ffffff"
                    radius="3"
                    ariaLabel="three-dots-loading"
                  />
                ) : (
                  "Sign Up"
                )}
              </MobButton>
              <div className="mobSignup">
                Already have an account ?
                <label
                  onClick={handleRedirect}
                  style={{ color: "#9747FF", cursor: "pointer" }}
                >
                  &nbsp;Log in
                </label>
              </div>
            </div>
          </div>
        </RegisterMob>
      </MediaQuery>
    </>
  );
}

export default Register;
