import { UserProvider } from './Component/Context'
import Login from './Component/Auth/login'
import Dashboard from './Component/Dashboard/dashboard'
import Landing from './Component/Landing/landing'
import Settings from './Component/Settings/settings'
import ProcessTab from './Component/Processtab/process_tab'
import NewForm from './Component/NewForm/NewForm'
import './App.css'
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Route, Switch, BrowserRouter } from "react-router-dom";
import PrivateRoute from './lib/privateRoute'
import MainBoard from './Component/MainBoard/mainBoard'
import IncidentDetails from './Component/NewForm/IncidentDetails';
import Submit from "./Component/NewForm/Submit"
import Description from './Component/NewForm/Description'
import OshaLog from './Component/OshaLog/OshaLog'
import ViewDetails from './Component/MainBoard/ViewDetails'
import UserList from './Component/Settings/UserList/UserList'
import Establishment from './Component/Settings/Establishment/Establishment'
import YearlyDetails from './Component/Settings/Establishment/YearlyDetails'
import AddEstablishment from './Component/Settings/Establishment/AddEstablishment'
import AddUser from './Component/Settings/UserList/AddUser'
import ExtractedData from './Component/Processtab/ExtractedData'
import OshaDetails from './Component/OshaLog/OshaDetails'
import Register from './Component/Auth/Register'
import UserProfilePage from './Component/Settings/ProfileSettings/UserProfilePage'
import { Home } from '@material-ui/icons'
import HomeMob from './Component/MobileUI/Home/HomeMob'
import SettingsMenu from './Component/Settings/SettingsMenu/SettingsMenu'
import UserSettings from './Component/Settings/SettingsMenu/UserSettings'
import AddYearlyDetails from './Component/Settings/SettingsMenu/AddYearlyDetails'
import Osha301 from "./Component/Processtab/ExtractedForms/Osha301"
import Osha300A from './Component/Processtab/ExtractedForms/Osha300A'
import IndividualDetails from './Component/Processtab/ExtractedForms/IndividualDetails'
import Osha300 from './Component/Processtab/ExtractedForms/Osha300'
function App() {
 
  return (
    <BrowserRouter >
      <Switch>
    <UserProvider>
        <Route path="/" exact component={Login} />
        <Route path="/signup" exact component={Register} />
          <PrivateRoute path="/view_analysis" exact component={Dashboard} />
          <PrivateRoute path="/view_archive" exact component={Dashboard} />
          <PrivateRoute path="/home" exact component={HomeMob} />
          <PrivateRoute path="/settings" exact component={Settings} />
          <PrivateRoute path="/settings-menu" exact component={SettingsMenu} />
          <PrivateRoute path="/process-tab" exact component={ProcessTab} />
          <PrivateRoute path="/mainboard" exact component={MainBoard} />
          <PrivateRoute path="/new-form" exact component={NewForm} />
          <PrivateRoute path="/new-form/incident_details" exact component={IncidentDetails}/>
          <PrivateRoute path="/incident_additional_info" exact component={Description}/>
          <PrivateRoute path="/submit_page" exact component={Submit}/>
          <PrivateRoute path="/osha_log" exact component={OshaLog}/>
          <PrivateRoute path="/view_details/:incident_id_param/:establishment_id_param" exact component={ViewDetails}/>
          <PrivateRoute path="/user-Settings/:incident_id_param/:establishment_id_param" exact component={UserSettings}/>
          <PrivateRoute path="/user_list" exact component={UserList}/>
          <PrivateRoute path="/view_establishment" exact component={Establishment}/>
          <PrivateRoute path="/yearly_details" exact component={YearlyDetails}/>
          <PrivateRoute path="/add_yearly_details" exact component={AddYearlyDetails}/>
          <PrivateRoute path="/add_establishment" exact component={AddEstablishment}/>
          <PrivateRoute path="/edit_establishment" exact component={AddEstablishment}/>
          <PrivateRoute path="/add_user" exact component={AddUser}/>
          <PrivateRoute path="/osha_details" exact component={OshaDetails}/>
          <PrivateRoute path="/edit_user" exact component={AddUser}/>
          <PrivateRoute path="/extracted_data" exact component={ExtractedData}/>
          <PrivateRoute path="/profile_settings" exact component={UserProfilePage}/>
          <PrivateRoute path="/extracted_data/osha-301" exact component={Osha301}/>
          <PrivateRoute path="/extracted_data/osha300A" exact component={Osha300A}/>
          <PrivateRoute path="/extracted_data/osha300" exact component={Osha300}/>
          <PrivateRoute path="/osha_individual/:est_name/:est_year/:away_from_work" exact component={IndividualDetails}/>
          </UserProvider>
       </Switch>
     </BrowserRouter >
  );
}

export default (App);