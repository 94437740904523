import React, { useEffect, useState } from "react";
import {
  IconEditMob,
  IconGeneralInformation,
  IconSave,
} from "../../../assets/css/icons";
import MainContainer from "../../MobileUI/GlobalComponents/MainContainer";
import MobButton from "../../MobileUI/GlobalComponents/MobButton";
import MobTextField from "../../MobileUI/GlobalComponents/MobTextField";
import {
  SaveProfileData,
  UserProfileSettings,
} from "../../services/api-servics";
import css from "./usersettings.module.scss";

function UserSettings(props) {
  const [userDetails, setUserDetails] = useState([]);
  const [denied, setDenied] = useState(false);
  const [userName, setUserName] = useState(null);
  const [company, setCompany] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [mobNo, setMobNo] = useState(null);
  const [immutable, setImmutable] = useState(true);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    company: "",
    designation: "",
    mobNo: "",
    newPassword: "",
    confirmPassword: "",
  });
  useEffect(() => {
    getProfileDetails();
  }, []);
  const getProfileDetails = (e) => {
    UserProfileSettings({ email: localStorage.getItem("email") }).then(
      async (rsp) => {
        if (rsp.data.data) {
          const userdata = rsp.data.data;
          setUserName(userdata.name);
          setCompany(userdata.company);
          setDesignation(userdata.designation);
          setMobNo(userdata.mobNo);
          setUserDetails(userdata);
        }
      }
    );
  };
  const saveProfileData = (data) => {
    setSaving(true);
    SaveProfileData({
      email: localStorage.getItem("email"),
      name: userName,
      company: company,
      contactno: mobNo,
      manager: designation,
    }).then(async (rsp) => {
      setImmutable(true);
      setSaving(false);
    });
  };
  const UserEditValidateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!userName) {
      newErrors.name = "Name is required";
      isValid = false;
    } else {
      newErrors.name = "";
    }
    if (!company) {
      newErrors.company = "Company is required";
      isValid = false;
    } else {
      newErrors.company = "";
    }
    if (!designation) {
      newErrors.designation = "Designation is required";
      isValid = false;
    } else {
      newErrors.designation = "";
    }

    if (!mobNo) {
      newErrors.mobNo = "Mobile Number is required";
      isValid = false;
    } else {
      newErrors.mobNo = "";
    }
    setErrors(newErrors);
    return isValid;
  };
  const handleSave = () => {
    if (UserEditValidateForm()) {
      saveProfileData();
    }
  };
  const handleUserName = (event) => {
    setUserName(event.target.value);
  };
  const handleCompany = (event) => {
    setCompany(event.target.value);
  };
  const handleDesignation = (event) => {
    setDesignation(event.target.value);
  };
  const handleContactNumber = (event) => {
    setMobNo(event.target.value);
  };
  const handleEdit = () => {
    setImmutable(false);
  };
  return (
    <MainContainer isHeader fromProfileSettings>
      <div className={css.formMain}>
        <div className={css.formHeader}>
          <IconGeneralInformation />
          &ensp;Profile Settings
        </div>
        {immutable ? (
          <div className={css.edit} onClick={handleEdit}>
            <IconEditMob />
          </div>
        ) : (
          <div className={css.edit} onClick={handleSave}>
            <MobButton className={css.save}>
              <IconSave /> &nbsp;Save
            </MobButton>
          </div>
        )}
        <div className={css.formFieldGroup}>
          <div className={css.formFields}>
            <label className={css.formFieldLabels}>
              User Name<sup>*</sup>
            </label>
            <div>
              <MobTextField
                onChange={handleUserName}
                required
                value={userName ?? userDetails.name}
                disabled={immutable || saving}
                helperText={
                  !immutable &&
                  userName === "" &&
                  errors.name !== "" && (
                    <div>
                      <span className={css.infoSign}>
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                        &nbsp;
                      </span>
                      {errors.name}
                    </div>
                  )
                }
              />
            </div>
          </div>

          {immutable && (
            <div className={css.formFields}>
              <label className={css.formFieldLabels}>
                Email<sup>*</sup>
              </label>
              <div>
                <MobTextField value={userDetails.email} disabled required />
              </div>
            </div>
          )}

          {immutable && (
            <div className={css.formFields}>
              <label className={css.formFieldLabels}>
                Type of Role<sup>*</sup>
              </label>
              <div>
                <MobTextField value={userDetails.user_role} disabled required />
              </div>
            </div>
          )}

          <div className={css.formFields}>
            <label className={css.formFieldLabels}>
              Company<sup>*</sup>
            </label>
            <div>
              <MobTextField
                onChange={handleCompany}
                value={company ?? userDetails.company}
                required
                disabled={immutable || saving}
                helperText={
                  !immutable &&
                  errors.company !== "" &&
                  company === "" && (
                    <div>
                      <span className={css.infoSign}>
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                        &nbsp;
                      </span>
                      {errors.company}
                    </div>
                  )
                }
              />
            </div>
          </div>

          <div className={css.formFields}>
            <label className={css.formFieldLabels}>
              Designation<sup>*</sup>
            </label>
            <div>
              <MobTextField
                onChange={handleDesignation}
                value={designation ?? userDetails.designation}
                disabled={immutable || saving}
                required
                helperText={
                  !immutable &&
                  errors.designation !== "" &&
                  designation === "" && (
                    <div>
                      <span className={css.infoSign}>
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                        &nbsp;
                      </span>
                      {errors.designation}
                    </div>
                  )
                }
              />
            </div>
          </div>

          <div className={css.formFields}>
            <label className={css.formFieldLabels}>
              Contact Number<sup>*</sup>
            </label>
            <div>
              <MobTextField
                onChange={handleContactNumber}
                value={mobNo ?? userDetails.mobNo}
                inputProps={{
                  maxLength: 10,
                }}
                required
                disabled={immutable || saving}
                helperText={
                  !immutable &&
                  errors.mobNo !== "" &&
                  mobNo === "" && (
                    <div>
                      <span className={css.infoSign}>
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                        &nbsp;
                      </span>
                      {errors.mobNo}
                    </div>
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
}

export default UserSettings;
