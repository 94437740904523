import axios from "axios";
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// // Add a request interceptor
// instance.interceptors.request.use(
//   (config) => {
//     // You can modify the request config here (e.g., adding headers, authentication)
//     return config;
//   },
//   (error) => {
//     // Handle request errors
//     return Promise.reject(error);
//   }
// );

// // Add a response interceptor
// instance.interceptors.response.use(
//   (response) => {
//     // You can modify the response data here (e.g., handling success)
//     return response;
//   },
//   (error) => {
//     // Handle response errors
//     return Promise.reject(error);
//   }
// );
