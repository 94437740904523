import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./Establishment.css";
import css from  "../SettingsMenu/manageEstablishment.module.scss";
import NavHome from "../../Nav/navHome";
import {
  NaicsData,
  stateList,
  EstablishmentType,
  AddEstablishmentData,
} from "../../services/api-servics";
import {
  Alert,
  Autocomplete,
  Button,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import CsTextField from "../../GlobalComponents/CsTextField";
import MainWrapper from "../../GlobalComponents/MainWrapper";
import MediaQuery from "react-responsive";
import MainContainer from "../../MobileUI/GlobalComponents/MainContainer";
import { IconSettingSpanner } from "../../../assets/css/icons";
import MobTextField from "../../MobileUI/GlobalComponents/MobTextField";
import MobButton from "../../MobileUI/GlobalComponents/MobButton";

function AddEstablishment(props) {
  const [naicsCode, setNaicsCode] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedEstType, setSelectedEstType] = useState({});
  const [estName, setEstName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [estCompany, setEstCompany] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [industry, setIndustry] = useState("");
  const [einNo, setEinNo] = useState("");
  const [estType, setEstType] = useState(null);

  const [naicsCodesList, setNaicsCodesList] = useState([]);
  const [stateLists, setstateLists] = useState([]);
  const [estTypeList, setEstTypeList] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [denied,setDenied] = useState(true);
  const [errors, setErrors] = useState({
    estName: "",
    emailId: "",
    street: "",
    city: "",
    estCompany: "",
    zipCode: "",
    industry: "",
    einNo: "",
    naicsCode: "",
    estType: "",
    selectedState:""
  });
  const history = useHistory();

  useEffect(() => {
    getNaicsData();
    getStateList();
    getEstablishmentType();
  }, []);

  useEffect(() => {
    
    if (props?.location.state) {
      const data = props?.location?.state;

      //   setSelectedState();

      setEstName(data.estName);
      setEmailId(data.emailId);
      setStreet(data.street);
      setCity(data.city);
      setEstCompany(data.estCompany);
      setZipCode(data.zipCode);
      setIndustry(data.industry);
      setEinNo(data.einNo);
      // setEstType()
    }
  }, [props.location.state]);

  const valiadteEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return emailRegex.test(email);
  };
  const valiadteZip = (zip) => {
    const zipCodeRegex = /^\d{5}(?:-\d{4})?$/;
    return zipCodeRegex.test(zip);
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Validate Establishment Name
    if (!estName) {
      newErrors.estName = "Establishment Name is required";
      setSnackbarMessage("Establishment Name is required");
      isValid = false;
    } else {
      newErrors.estName = "";
    }

    // Validate Email
    if (!valiadteEmail(emailId)) {
      newErrors.emailId = "Email is not valid";
      setSnackbarMessage("Email is not valid");
      isValid = false;
    } else {
      newErrors.emailId = "";
    }

    // Validate Street
    if (!street) {
      newErrors.street = "Street is required";
      setSnackbarMessage("Street is required");
      isValid = false;
    } else {
      newErrors.street = "";
    }

    // Validate City
    if (!city) {
      newErrors.city = "City is required";
      setSnackbarMessage("City is required");
      isValid = false;
    } else {
      newErrors.city = "";
    }

    // Validate Company Name
    if (!estCompany) {
      newErrors.estCompany = "Company Name is required";
      setSnackbarMessage("Company Name is required");
      isValid = false;
    } else {
      newErrors.estCompany = "";
    }

    // Validate ZIP Code
    if (!valiadteZip(zipCode)) {
      newErrors.zipCode = "ZIP Code must be 5 digit";
      setSnackbarMessage("ZIP Code must be 5 digit");
      isValid = false;
    } else {
      newErrors.zipCode = "";
    }

    // Validate Industry
    if (!industry) {
      newErrors.industry = "Industry is required";
      setSnackbarMessage("Industry is required");
      isValid = false;
    } else {
      newErrors.industry = "";
    }

    // Validate EIN Number
    if (einNo.length < 9) {
      newErrors.einNo = "EIN must be 9 digit Number";
      setSnackbarMessage("EIN must be 9 digit Number");
      isValid = false;
    } else {
      newErrors.einNo = "";
    }

    // Validate NAICS Code
    if (!naicsCode) {

      newErrors.naicsCode = 'NAICS Code is required';
      setSnackbarMessage("NAICS Code is required")
      isValid = false;
    } else {
      newErrors.naicsCode = '';
    }
    if (!selectedState) {

      newErrors.selectedState = 'State is required';
      setSnackbarMessage("State is required")
      isValid = false;
    } else {
      newErrors.selectedState = '';
    }

    // Validate Establishment Type
    if (!estType) {
      newErrors.estType = 'Establishment Type is required';
      setSnackbarMessage('Establishment Type is required')
      isValid = false;
    } else {
      newErrors.estType = '';
    }
    setErrors(newErrors);
    if (!isValid) {
      setSnackbarOpen(true);
    }

    return isValid;
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleEstName = (event) => {
    setEstName(event.target.value);
  };
  const handleEmailId = (event) => {
    setEmailId(event.target.value);
  };
  const handleStreet = (event) => {
    setStreet(event.target.value);
  };
  const handleCity = (event) => {
    setCity(event.target.value);
  };
  const handleEstCompany = (event) => {
    setEstCompany(event.target.value);
  };
  const handleZipCode = (event) => {
    const inputValue = event.target.value.replace(/\D/g, "").slice(0, 5);
    setZipCode(inputValue);
  };
  const handleIndustry = (event) => {
    setIndustry(event.target.value);
  };
  const handleEinNo = (event) => {
    const inputValue = event.target.value.replace(/\D/g, "").slice(0, 9);
    setEinNo(inputValue);
  };

  const getNaicsData = (e) => {
    NaicsData({}).then(async (rsp) => {
      if (rsp.data.NAICS) {
        const naicsCodes = rsp.data.NAICS;
        setNaicsCodesList(naicsCodes);
        if (props.location.state) {
          const foundNaics = naicsCodes.find(
            (obj) =>
              obj.NAICSCode === props?.location?.state.naicsCode.NAICSCode
          );
          setNaicsCode(foundNaics);
        }
      }
    });
  };

  const getStateList = (e) => {
    stateList({}).then(async (rsp) => {
      if (rsp.data.States) {
        const statelist = rsp.data.States;
        setstateLists(statelist);
        if (props.location.state) {
          const foundstate = statelist.find(
            (obj) =>
              obj.name === props?.location?.state.estStateLabel &&
              obj.code === props?.location?.state.estStateCode
          );
          setSelectedState(foundstate);
        }
      }
    });
  };

  const getEstablishmentType = (e) => {
    EstablishmentType({}).then(async (rsp) => {
      if (rsp.data.data) {
        const estTypeList = rsp.data.data;
        setEstTypeList(estTypeList);
        if (props.location.state) {
          const foundestType = estTypeList.find(
            (obj) => obj.value === props?.location?.state.estType
          );
          setEstType(foundestType);
        }
      }
    });
  };
  const insertEstData = (e) => {
    AddEstablishmentData({
      establishment_name: estName,
      street_name: street,
      city_name: city,
      state_name: selectedState.name,
      state_code: selectedState.code,
      zip_info: +zipCode,
      industry_info: industry,
      NAICS_info: +naicsCode.NAICSCode,
      email_info: emailId,
      EIN_no: +einNo,
      company_name: estCompany,
      establishment_type: estType.value,
      editEst: props.location?.state ? props.location?.state?.editEst : "false",
      establishmentID: props.location?.state
        ? props.location.state.establishmentID
        : "",
    }).then(async (rsp) => {
      handleRedirect();
    });
  };

  const handleSave = () => {
    setDenied(true)
    if (validateForm()) {
      setDenied(false)
      insertEstData();
    }
  };

  const handleRedirect = (item) => {
    let path = "/view_establishment";
    history.push({
      pathname: path,
    });
  };

  return (
    <>
    <MediaQuery maxDeviceWidth={1023}>
    <MainContainer isHeader>
    <div className={css.estMain}>
          <div className={css.estCardHeader}>
            <div className={css.estHeader}>
              <IconSettingSpanner />
              Settings
            </div>
            <div className={css.estSubHeaders}>
              <div className={css.estDetails}>Establishment Information</div>
              </div>
              </div>
              <div className={css.allFields}>
              <div className={css.estAddFields}>
                <label className={css.estformFieldLabels}>
                Establishment Name<sup>*</sup>
                </label>
                <div>
                  <MobTextField 
                  fullWidth
                  value={estName}
                  onChange={handleEstName}
                  helperText={
                    denied && errors.estName.length > 0 && estName === "" &&
                    <div>
                    <span className="infoSign">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      &nbsp;
                    </span>
                    {errors.estName}
                  </div>
                  }
                  />
                </div>
              </div>

              <div className={css.estAddFields}>
                <label className={css.estformFieldLabels}>
                Email<sup>*</sup>
                </label>
                <div>
                  <MobTextField 
                  fullWidth
                  value={emailId}
                  onChange={handleEmailId}
                  helperText={
                    denied && (errors.emailId.length > 0 || emailId === "") &&
                    <div>
                    <span className="infoSign">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      &nbsp;
                    </span>
                    {errors.emailId}
                  </div>
                  }
                  />
                </div>
              </div>

              <div className={css.estAddFields}>
                <label className={css.estformFieldLabels}>
                Street<sup>*</sup>
                </label>
                <div>
                  <MobTextField 
                  fullWidth
                  value={street}
                  onChange={handleStreet}
                  helperText={
                    denied && errors.street.length > 0 && street === "" &&
                    <div>
                    <span className="infoSign">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      &nbsp;
                    </span>
                    {errors.street}
                  </div>
                  }
                  />
                </div>
              </div>

              <div className={css.estAddFields}>
                <label className={css.estformFieldLabels}>
                City<sup>*</sup>
                </label>
                <div>
                  <MobTextField 
                  fullWidth
                  value={city}
                  onChange={handleCity}
                  helperText={
                    denied && errors.city.length > 0 && city === "" &&
                    <div>
                    <span className="infoSign">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      &nbsp;
                    </span>
                    {errors.city}
                  </div>
                  }
                  />
                </div>
              </div>

              <div className={css.estAddFields}>
                <label className={css.estformFieldLabels}>
                Name of the Company that owns the Establishment<sup>*</sup>
                </label>
                <div>
                  <MobTextField 
                  fullWidth
                  value={estCompany}
                  onChange={handleEstCompany}
                  helperText={
                    denied && errors.estCompany.length > 0 && estCompany === "" &&
                    <div>
                    <span className="infoSign">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      &nbsp;
                    </span>
                    {errors.estCompany}
                  </div>
                  }
                  />
                </div>
              </div>

              <div className={css.estAddFields}>
                <label className={css.estformFieldLabels}>
                Type of Establishment<sup>*</sup>
                </label>
                <div>
                <Autocomplete
                  options={estTypeList}
                  getOptionLabel={(option) => option.label}
                  value={estType}
                  onChange={(event, newValue) => {
                    setEstType(newValue);
                  }}
                  renderInput={(params) => (
                    <MobTextField
                      {...params}
                      fullWidth
                      helperText={
                        denied && errors.estType.length > 0 && estType === null &&
                        <div>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        {errors.estType}
                      </div>
                      }
                    />
                  )}
                />
                </div>
              </div>

              <div className={css.estAddFields}>
                <label className={css.estformFieldLabels}>
                Select State<sup>*</sup>
                </label>
                <div>
                <Autocomplete
                  options={stateLists}
                  getOptionLabel={(option) => option.name}
                  value={selectedState}
                  onChange={(event, newValue) => {
                    setSelectedState(newValue);
                  }}
                  renderInput={(params) => (
                    <MobTextField
                      {...params}
                      fullWidth
                      helperText={
                        denied && errors.selectedState.length > 0 && selectedState === null &&
                        <div>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        {errors.selectedState}
                      </div>
                      }
                    />
                  )}
                />
                </div>
              </div>

              <div className={css.estAddFields}>
                <label className={css.estformFieldLabels}>
                ZIP<sup>*</sup>
                </label>
                <div>
                  <MobTextField 
                  fullWidth
                  value={zipCode}
                  onChange={handleZipCode}
                  helperText={
                    denied && errors.zipCode.length > 0 && (zipCode === "" || zipCode.length < 5) &&
                    <div>
                    <span className="infoSign">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      &nbsp;
                    </span>
                    {errors.zipCode}
                  </div>
                  }
                  />
                </div>
              </div>

              <div className={css.estAddFields}>
                <label className={css.estformFieldLabels}>
                Industry description(E.G.,Manufacture Of Motor Truck Trailers)<sup>*</sup>
                </label>
                <div>
                  <MobTextField 
                  fullWidth
                  value={industry}
                  onChange={handleIndustry}
                  helperText={
                    denied && errors.industry.length > 0 && industry === "" &&
                    <div>
                    <span className="infoSign">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      &nbsp;
                    </span>
                    {errors.industry}
                  </div>
                  }
                  />
                </div>
              </div>

              <div className={css.estAddFields}>
                <label className={css.estformFieldLabels}>
                NAICS Code<sup>*</sup>
                </label>
                <div>
                <Autocomplete
                  options={naicsCodesList}
                  getOptionLabel={(option) =>
                    option.NAICSCode + " - " + option.NAICSTitle
                  }
                  value={naicsCode}
                  onChange={(event, newValue) => {
                    setNaicsCode(newValue);
                  }}
                  renderInput={(params) => (
                    <MobTextField
                      {...params}
                      fullWidth
                      helperText={
                    denied && errors.naicsCode.length > 0 && naicsCode === "" &&
                    <div>
                    <span className="infoSign">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      &nbsp;
                    </span>
                    {errors.naicsCode}
                  </div>
                  }
                    />
                  )}
                />
                </div>
              </div>

              <div className={css.estAddFields}>
                <label className={css.estformFieldLabels}>
                Employer Identification Number (EIN)<sup>*</sup>
                </label>
                <div>
                  <MobTextField 
                  fullWidth
                  value={einNo}
                  onChange={handleEinNo}
                  inputProps={{ maxLength: 9}}
                  helperText={
                    denied && (errors.einNo.length > 0 || einNo === "") &&
                    <div>
                    <span className="infoSign">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      &nbsp;
                    </span>
                    {errors.einNo}
                  </div>
                  }
                  />
                </div>
              </div>

              <div className={css.addEstButton}>
                  <MobButton
                  style={{width:"50%"}}
                  onClick={handleSave}
                  >
                    Save
                  </MobButton>
              </div>


              </div>
              </div>
    </MainContainer>
    </MediaQuery>

    <MediaQuery minDeviceWidth={1024}>
    <MainWrapper>
        <Card>
          <Card.Header className="cardHeader">
            <div className="establishmentText">Establishment Information</div>
          </Card.Header>
          <Card.Body>
            <Row className="addEsRows" style={{ paddingTop: "10px" }}>
              <Col md={6}>
                <CsTextField
                  id="outlined-basic"
                  label="Establishment Name"
                  variant="outlined"
                  fullWidth
                  value={estName}
                  onChange={handleEstName}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                }}
                />
              </Col>
              <Col md={6}>
                <Autocomplete
                  options={stateLists}
                  getOptionLabel={(option) => option.name}
                  value={selectedState}
                  onChange={(event, newValue) => {
                    setSelectedState(newValue);
                  }}
                  renderInput={(params) => (
                    <CsTextField
                      {...params}
                      label="Select State"
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                    }}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row className="addEsRows">
              <Col md={6}>
                <CsTextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  value={emailId}
                  onChange={handleEmailId}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                }}
                />
              </Col>
              <Col md={6}>
                <CsTextField
                  label="ZIP"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={zipCode}
                  onChange={handleZipCode}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                }}
                />
              </Col>
            </Row>
            <Row className="addEsRows">
              <Col md={6}>
                <CsTextField
                  label="Street"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={street}
                  onChange={handleStreet}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                }}
                />
              </Col>
              <Col md={6}>
                <CsTextField
                  label="Industry description(E.G.,Manufacture Of Motor Truck Trailers"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={industry}
                  onChange={handleIndustry}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                }}
                />
              </Col>
            </Row>
            <Row className="addEsRows">
              <Col md={6}>
                <CsTextField
                  label="City"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={city}
                  onChange={handleCity}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                }}
                />
              </Col>
              <Col md={6}>
                <Autocomplete
                  options={naicsCodesList}
                  getOptionLabel={(option) =>
                    option.NAICSCode + " - " + option.NAICSTitle
                  }
                  value={naicsCode}
                  onChange={(event, newValue) => {
                    setNaicsCode(newValue);
                  }}
                  renderInput={(params) => (
                    <CsTextField
                      {...params}
                      label="NAICS Code"
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                    }}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row className="addEsRows">
              <Col md={6}>
                <CsTextField
                  label="Name Of The Company That Owns The Establishment"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={estCompany}
                  onChange={handleEstCompany}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                }}
                />
              </Col>
              <Col md={6}>
                <CsTextField
                  label="Employer Identification Number (EIN)"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={einNo}
                  onChange={handleEinNo}
                  size="small"
                  inputProps={{ maxLength: 9}}
                  InputLabelProps={{
                    shrink: true,
                }}
                />
              </Col>
            </Row>
            <Row className="addEsRows">
              <Col md={6}>
                <Autocomplete
                  options={estTypeList}
                  getOptionLabel={(option) => option.label}
                  value={estType}
                  onChange={(event, newValue) => {
                    setEstType(newValue);
                  }}
                  renderInput={(params) => (
                    <CsTextField
                      {...params}
                      label="Type Of Establishment"
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                    }}
                    />
                  )}
                />
              </Col>
            </Row>
          </Card.Body>
          <Snackbar
          // anchorOrigin= "top" 
            open={snackbarOpen}
            autoHideDuration={1000}
            onClose={handleCloseSnackbar}
            message={snackbarMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert severity="error">{snackbarMessage}</Alert>
          </Snackbar>
          <Card.Footer className="addEsCardFooter">
            <div>
              <Button variant="contained" size="small" onClick={handleSave}>
                Save
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </MainWrapper>
    </MediaQuery>
    </>
  );
}

export default AddEstablishment;
