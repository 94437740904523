import React from "react";
import { useHistory, useLocation } from "react-router";
import { IconSettingSpanner } from "../../../assets/css/icons";
import FormButton from "../../MobileUI/GlobalComponents/FormButton";
import css from "./yearlydetails.module.scss";
import YearlyDetailsCard from "./YearlyDetailsCard";
function ManageYearlyDeatils(props) {
    const location = useLocation();
    
    const YearlyData = props.yearlyData
    const history = useHistory();
    const handleAdd = () => {
        const path = "/add_yearly_details"
        history.push({
            pathname: path,
            state:props.establishmentData
          });
    }
  return (
    <div className={css.yrlyMain}>
      <div className={css.yrlyCardHeader}>
        <div className={css.yrlyHeader}>
          <IconSettingSpanner />
          Settings
        </div>
        <div className={css.yrlySubHeaders}>
          <div className={css.yrlyDetails}>Yearly Details</div>
          <FormButton
            height="24px"
            width="70px"
              onClick={handleAdd}
          >
            + Add
          </FormButton>
        </div>
      </div>
      <div className={css.yrlyDetailCard}>
       {YearlyData.map((item) => {
        return <YearlyDetailsCard data={item}/>
       })
       }
      </div>
    </div>
  );
}

export default ManageYearlyDeatils;
