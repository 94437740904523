import { React, useEffect, useState } from "react";
import { IconPasswordChange,IconLockPad, IconPasswordHeader, IconPasswordLabel } from "../../../assets/css/icons";
import styles from "./settingsMenu.module.scss";
import {

  UserProfileSettings,
  PasswordChange,
  ValidatePassword,
  TwoFactorAuth,
} from "../../services/api-servics";
function PasswordSettings() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [enable, setEnable] = useState(false);
  const [loader, setloader] = useState(false);
  const [validate, setValidate] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  const [wrongPw, setWrongPw] = useState(false);

  const usermail = localStorage.getItem("email");
  const validatePassword = () => {
    if (
      confirmPassword === newPassword &&
      newPassword !== "" &&
      confirmPassword !== ""
    ) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  };

  useEffect(() => {
    validatePassword();
  }, [confirmPassword]);

  const handleVerify = () => {
    setloader(true);
    ValidatePassword({
      username: usermail,
      password: currentPassword,
    }).then(async (rsp) => {
      setloader(false);
      const data = rsp.data.status;
      if (data === "correct") {
        setValidate(true);
        setWrongPw(false);
        // if (data === "correct" && event === "fromTwoFactor") {
        //   setTwoFactorEnabled(true);
        //   setTwoFactorTrue(true);
        // }
      } else {
        setValidate(false);
        setWrongPw(true);
        // setTwoFactorTrue(false);
      }
    });
  };

  return (
    <>
<div className={styles.changePassword}><IconPasswordHeader/> Change Password</div>
      
      <div className={styles.info}>
        <IconPasswordChange />
      </div>

      <h1 id={styles.label}><IconPasswordLabel/> current password</h1>

      <div className={styles.inputWrapper}>
        <input
          type="password"
          style={validate && !wrongPw ? { border: '1px solid rgb(63 192 27)' } :  (wrongPw ? {border: '1px solid #ed340a'}:{})  }
          className={styles.menuTextBox}
          value={currentPassword}
          onChange={(e) => {
            setCurrentPassword(e.target.value);
          }}
        />
        {currentPassword.length>7?<button
        id={styles.hover}
          disabled={enable}
          className={styles.submit}
          onClick={() => {
            handleVerify();
          }}
        >
          validate
        </button>:null}
        
      </div>

      <h1
        id={styles.label}
        className={wrongPassword ? styles.wrongPassword : null}
      >
        <IconPasswordLabel/> new password
      </h1>

      <input
        type="password"
        autoComplete="false"
        className={styles.menuTextBox}
        value={newPassword}
        onChange={(e) => {
          if (validate) {
            setNewPassword(e.target.value);
          } else {
            setWrongPassword(true);
            setTimeout(() => {
              setWrongPassword(false);
            }, 550);
          }
        }}
      />

      <h1
        id={styles.label}
        className={wrongPassword && !enable ? styles.wrongPassword : null}
      >
       <IconPasswordLabel/> confirm password
      </h1>
      <input
        type="password"
        disabled={validate}
        className={styles.menuTextBox}
        value={confirmPassword}
        onChange={(e) => {
          if (validate) {
            setConfirmPassword(e.target.value);
          } else {
            setWrongPassword(true);
            setTimeout(() => {
              setWrongPassword(false);
            }, 550);
          }
        }}
      />

      <div className={styles.buttonWrapper}>
        <button
          disabled={enable}
          className={enable && validate ? styles.submit : styles.disabledBtn}
          onClick={() => {
            if (enable) {
              console.log();
            } else {
              setWrongPassword(true);
              setTimeout(() => {
                setWrongPassword(false);
              }, 550);
            }
          }}
        >
          update
        </button>
      </div>
    </>
  );
}

export default PasswordSettings;
