import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./navbar.css";
import comp_log from "../../assets/images/logo_com.png";
import pss_log from "../../assets/images/pss_trans.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Context from "../Context"
import { useContext } from "react"; 
export default function NavHome() {
  const history = useHistory();
  const { formDataState, setformDataState } = useContext(Context);
  const role = localStorage.getItem("role");
  const handleSubmit = () => {
    window.localStorage.setItem("personal-safety", true);
    history.push("/dashboard");
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSubMenuClick = (event) => {
    // setAnchorEl(null);
    setSubMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
  };
  const handleNewForm = () => {
    setformDataState({
      type: "new",
      employeeName: "",
      jobTitle: "",
      street: "",
      dob: "",
      city: "",
      dateHired: "",
      state: null,
      gender: "",
      zip: "",
      empId: "",
      establishment: "",
      workOption: "",
      WorkTime: "",
      incidentDate: "",
      incidentPlace: "",
      incidentTime: "",
      incidentCause: "",
      deathTime: "",
      value: "",
      incident: "",
      injurySummary: "",
      injuryDetails: "",
      object: "",
      preIncidentActivity: "",
      reason: "",
      Physician: "",
      stateInfo: "",
      location: "",
      zipInfo: "",
      streetInfo: "",
      emergencyRoomOption: "",
      cityInfo: "",
      hospitalizedOption: "",
      treatmentInfo: "",
      incidentId: ""
    })
  }
  const logout = async () => {
    // localStorage.clear();
    // try {
    //     setPageLoad(true)
    //     await azureAccessToken().then(async (response) => {
    //         if (
    //             response !== "" &&
    //             response !== undefined &&
    //             response !== null &&
    //             response.status == 200

    //         ) {
    //             if (response.data !== undefined && response.data !== "") {
    //                 if (response.data.result !== undefined && response.data.result !== null && response.data.result !== "") {
    //                     let AccessToken = "Bearer " + response.data.result.access_token
    //                     await suspendServerTime(AccessToken).then((response1) => {
    //                         if (
    //                             response !== "" &&
    //                             response !== undefined &&
    //                             response !== null

    //                         ) {
    //                         }

    //                     });

    //                 }

    //             }
    //         } else {
    //         }

    //     });
    // } catch (e) {
    // }
    // setTimeout(() => {
    //     setPageLoad(false)
    //     localStorage.removeItem("power_bi");
    //     history.push("/");
    // }, 8000);
    localStorage.removeItem("power_bi");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    history.push("/");
  };
  return (
    <>
      <nav className="navbar navbar-light innr-nav-home">
        <Link className="navbar-brand" to="#">
          <img
            src={pss_log}
            className="d-inline-block align-top nav-img nav-ml-5"
            alt=""
          />
        </Link>
        <ul className="nav justify-content-end">
          {/* <li class="nav-item">
            <a
              href="/mainboard"
              class="nav-link nave-title-home nav-link-home"
            >
              <i class="fa fa-database"></i> Dashboard
            </a>
          </li> */}
          <li class="nav-item">
          <Link
            to="/mainboard"
            className="nav-link nave-title-home nav-link-home"
          >
            <i className="fa fa-bar-chart"></i> Dashboard
          </Link>
          </li>

          {role !== "admin" && (
            // <li className="nav-item">
            //   <a
            //     href="/new-form"
            //     className="nav-link nave-title-home nav-link-home"
            //   >
            //     <i className="fa fa-plus-square"></i> New Form
            //   </a>
            // </li>
            <li className="nav-item" onClick={handleNewForm}>
            <Link
              to="/new-form"
              className="nav-link nave-title-home nav-link-home"
            >
              <i className="fa fa-bar-chart"></i> New Form
            </Link>
            </li>
          )}
          <li className="nav-item">
            <Link
              to="/osha_log"
              className="nav-link nave-title-home nav-link-home"
            >
              <i className="fa fa-bar-chart"></i> Osha log
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="/view_analysis"
              className="nav-link nave-title-home nav-link-home"
            >
              <i className="fa fa-plus-square"></i> Analytics
            </Link>
          </li>

          {role !== "admin" &&
            (<li className="nav-item">
              <Link
                to="/process-tab"
                className="nav-link nave-title-home nav-link-home"
              >
                <i className="fa fa-bar-chart"></i> Archive
              </Link>
            </li>)}

          <li className="nav-item" style={{ cursor: "pointer" }}>
            <a
              className="nav-link nave-title-home nav-link-home"
              onClick={handleClick}
            >
              <i class="fa fa-bars" aria-hidden="true"></i>{" "}
            </a>
          </li>

          {/* <li className="nav-item">
                        <Link onClick={() => { logout(); }} className="nav-link nave-title">
                            <i className="fa fa-sign-out"></i> Log out</a
                        >
                    </li> */}
        </ul>

        <Menu
          id="main-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <Link
              to="/profile_settings"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <i class="fa fa-user" aria-hidden="true"
              style={{ color: "#0b69c9" }}
              ></i>
              &nbsp;Profile
            </Link>
          </MenuItem>
          <MenuItem onClick={handleSubMenuClick}>
            <i class="fa fa-angle-left fa-lg" style={{ color: "#0b69c9" }} aria-hidden="true"></i>{" "}
            &nbsp;Settings
          </MenuItem>
          <MenuItem>
            <a
              onClick={() => {
                logout();
              }}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <i className="fa fa-sign-out" style={{ color: "#0b69c9" }}></i>&nbsp;Log out
            </a>
          </MenuItem>
        </Menu>

        <Menu
          id="main-menu"
          anchorEl={subMenuAnchorEl}
          open={Boolean(subMenuAnchorEl)}
          onClose={() => setSubMenuAnchorEl(null)}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
        >
          {role !== "admin" &&
            (<MenuItem onClick={() => {handleClose();
              history.push("/user_list")
           }}>
              {/* <Link
                to="/user_list"
                style={{ color: "inherit", textDecoration: "none" }}
              > */}
                <i class="fa fa-gear" style={{ marginRight: "8px",color: "#0b69c9" }}></i>
                User
              {/* </Link> */}
            </MenuItem>)}
          {role !== "admin" &&
            (<MenuItem onClick={() => {handleClose();
              history.push("/settings")
           }}>
              {/* <Link
                to="/settings"
                style={{ color: "inherit", textDecoration: "none" }}
              > */}
                <i class="fa fa-archive" style={{ marginRight: "8px",color: "#0b69c9" }}></i>
                Archive
              {/* </Link> */}
            </MenuItem>)}
          <MenuItem onClick={() => {handleClose();
              history.push("/view_establishment")
           }}>
            <Link
              to="/view_establishment"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <i class="fa fa-tasks" style={{ marginRight: "8px",color: "#0b69c9" }}></i>
              Establishment{" "}
            </Link>
          </MenuItem>
        </Menu>
      </nav>
    </>
  );
}
