import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../Nav/navbar";
import NavHome from "../Nav/navHome";
import { Card, Col, Row } from "react-bootstrap";
import { OshaDetail } from "../services/api-servics";
import { showToaster } from "../services/helper";
import {
  Select,
  TextField,
  Button,
  Autocomplete,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  getEmployeeNumber,
  ExportOsha300AExcel,
  Osha300Mail,
  ExportOsha300ACSV,
} from "../services/api-servics";
import MainWrapper from "../GlobalComponents/MainWrapper";
import MediaQuery from "react-responsive";
import MainContainer from "../MobileUI/GlobalComponents/MainContainer";
import css from "./oshadetails.module.scss";
import MobTextField from "../MobileUI/GlobalComponents/MobTextField";
import FormButton from "../MobileUI/GlobalComponents/FormButton";
import { useRef } from "react";

function OshaDetails(props) {
  const [oshaDtl, setoshaDtl] = useState("");
  const [noofemployees, setnoofemployees] = useState("");
  const [Totalhours, setTotalhours] = useState("");
  const [MaximumnoYear, setMaximumnoYear] = useState("");
  const [submit, setsubmit] = useState(false);
  const [employeeNumber, setemployeeNumber] = useState([]);
  const [employeeValue, setemployeeValue] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const lastElementRef = useRef(null);  
  useEffect(() => {
    getDetails();
  }, []);
  useEffect(() => {
    if (submit && lastElementRef.current) {
      lastElementRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [submit]);

  const getDetails = () => {
    const establishment_id = props.location.establishment_id;
    const year = props.location.year;
    OshaDetail({ establishment_id, year }).then(async (rsp) => {
      if (rsp.data) {
        setoshaDtl(rsp.data);
        setemployeeValue(rsp.data.emp_size_label);
        setnoofemployees(rsp.data.emp_size);
        setTotalhours(rsp.data.add_hr);
      }
    });

    getEmployeeNumber({}).then(async (rsp) => {
      if (rsp.data) {
        setemployeeNumber(rsp.data.data);
      }
    });
  };
  const validateForm = () => {
    let isValid = true;
    if (!noofemployees) {
      setSnackbarMessage("Average number of employees is required");
      isValid = false;
    }
    if (!Totalhours) {
      setSnackbarMessage("Total hours is required");
      isValid = false;
    }
    if (!employeeValue) {
      setSnackbarMessage("Maximum number of employees is required");
      isValid = false;
    }
    if (!isValid) {
      setSnackbarOpen(true);
    }
    return isValid;
  };
  const handleSubmit = () => {
    if (validateForm()) {
      setsubmit(true);
    }
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const OshaExcel = () => {
    const year = oshaDtl.year;
    const establishment_id = oshaDtl.establishment_id;
    const emps_no = noofemployees;
    const total_hr = Totalhours;
    ExportOsha300AExcel({ year, establishment_id, emps_no, total_hr });
  };

  const OshaCSV = () => {
    const year = oshaDtl.year;
    const establishment_id = oshaDtl.establishment_id;
    const emps_no = noofemployees;
    const total_hr = Totalhours;
    ExportOsha300ACSV({ year, establishment_id, emps_no, total_hr });
  };
  const OshaMail = () => {
    const year = oshaDtl.year;
    const establishment_id = oshaDtl.establishment_id;
    Osha300Mail({ year, establishment_id }).then(async (rsp) => {
      if (rsp) {
        showToaster("Email Sent Successfully");
      }
    });
  };

  const val = (name, value) => (
    <div className="summary">
      <div className="summary_details"> {name}</div>{" "}
      <div className="summary_details">:&nbsp;&nbsp;&nbsp;{value} </div>
    </div>
  );
  return (
    <>
      <MediaQuery maxDeviceWidth={1023}>
        <MainContainer>
          <div className={css.oshamainContainer}>
            <div className={css.oshaDetailHeader}>
              OSHA 300A - Additional Details
            </div>
            <div className={css.submitDetails}>
              <>
                <div className={css.dataRows}>
                  <div className={css.cardDetailColL}>
                    Establishment Name<div>:</div>
                  </div>
                  <div className={css.cardDetailColR}>
                    {oshaDtl.establishment_name}
                  </div>
                </div>
                <div className={css.dataRows}>
                  <div className={css.cardDetailColL}>
                    Street<div>:</div>
                  </div>
                  <div className={css.cardDetailColR}>
                    {oshaDtl.street_name}
                  </div>
                </div>
                <div className={css.dataRows}>
                  <div className={css.cardDetailColL}>
                    City<div>:</div>
                  </div>
                  <div className={css.cardDetailColR}>{oshaDtl.city_name}</div>
                </div>
                <div className={css.dataRows}>
                  <div className={css.cardDetailColL}>
                    State<div>:</div>
                  </div>
                  <div className={css.cardDetailColR}>{oshaDtl.state_name}</div>
                </div>
                <div className={css.dataRows}>
                  <div className={css.cardDetailColL}>
                    Zip<div>:</div>
                  </div>
                  <div className={css.cardDetailColR}>{oshaDtl.zip_info}</div>
                </div>
                <div className={css.dataRows}>
                  <div className={css.cardDetailColL}>
                    Industry Description<div>:</div>
                  </div>
                  <div className={css.cardDetailColR}>
                    {oshaDtl.industry_info}
                  </div>
                </div>
                <div className={css.dataRows}>
                  <div className={css.cardDetailColL}>
                    NAICS<div>:</div>
                  </div>
                  <div className={css.cardDetailColR}>{oshaDtl.NAICS_info}</div>
                </div>
                <div className={css.dataRows}>
                  <div className={css.cardDetailColL}>
                    Email<div>:</div>
                  </div>
                  <div className={css.cardDetailColR}>{oshaDtl.email_info}</div>
                </div>
              </>
            </div>
            <div className={css.fieldForms}>
              <div className={css.textFields}>
                <div className={css.labels}>
                  Annual average number of employees
                </div>
                <MobTextField
                  value={noofemployees}
                  onChange={(e) => setnoofemployees(e.target.value)}
                />
              </div>
              <div className={css.textFields}>
                <div className={css.labels}>
                  Total hours worked by all employees last year
                </div>
                <MobTextField
                  value={Totalhours}
                  onChange={(e) => setTotalhours(e.target.value)}
                />
              </div>
              <div className={css.textFields}>
                <div className={css.labels}>
                  Maximum number of employees at any point in the year
                </div>
                <Autocomplete
                  options={employeeNumber}
                  value={employeeValue}
                  onChange={(event, newValue) => {
                    setemployeeValue(newValue);
                  }}
                  renderInput={(params) => <MobTextField {...params} />}
                />
              </div>
              <div className={css.textFields}>
                <FormButton onClick={handleSubmit}>Submit</FormButton>
              </div>
            </div>
            {submit && (
              <div className={css.buttonForms}>
                <FormButton onClick={OshaExcel}>Download excel</FormButton>
                <FormButton onClick={OshaMail}>Send Mail</FormButton>
                <FormButton onClick={OshaCSV}>Download CSV</FormButton>
              </div>
            )}
            <div ref={lastElementRef}></div>
          </div>
        </MainContainer>
      </MediaQuery>
      <MediaQuery minDeviceWidth={1024}>
        <MainWrapper>
          <Card>
            <Card.Header className="cardHeader">
              <div className="managerText">OSHA 300A - Additional Details</div>
            </Card.Header>

            <div
              class="left-section"
              style={{ marginLeft: "250px", marginRight: "300px" }}
            >
              {/* <div className="subheader">Employee Details</div>  */}
              {val("Establishment Name", oshaDtl.establishment_name)}
              {val("Street", oshaDtl.street_name)}
              {val("City", oshaDtl.city_name)}
              {val("State", oshaDtl.state_name)}
              {val("Zip", oshaDtl.zip_info)}
              {val("Industry Description", oshaDtl.industry_info)}
              {val("NAICS", oshaDtl.NAICS_info)}
              <div className="summaryd">
                <div className="summary_details"> {"Email"}</div>{" "}
                <div className="summary_details">
                  :&nbsp;&nbsp;&nbsp;{oshaDtl.email_info}{" "}
                </div>
              </div>
            </div>
            <div
              class="left-section"
              style={{
                marginLeft: "250px",
                marginRight: "300px",
                marginTop: "5px",
              }}
            >
              <Row className="row_text">
                <Col md={6}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Annual average number of employees"
                    fullWidth
                    required
                    value={noofemployees}
                    onChange={(e) => setnoofemployees(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Total hours worked by all employees last year"
                    fullWidth
                    required
                    value={Totalhours}
                    onChange={(e) => setTotalhours(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="row_text">
                <Col md={6}>
                  <Autocomplete
                    options={employeeNumber}
                    //    getOptionLabel={(option) => option.label}
                    value={employeeValue}
                    onChange={(event, newValue) => {
                      setemployeeValue(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Maximum number of employees at any point in the year"
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Col>
                <Col md={6}>
                  <Button variant="contained" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
            {submit && (
              <div
                class="left-section"
                style={{
                  marginLeft: "250px",
                  marginRight: "300px",
                  marginTop: "5px",
                }}
              >
                <Button
                  style={{ margin: "5px", marginLeft: "150px" }}
                  variant="contained"
                  onClick={OshaExcel}
                >
                  Download excel
                </Button>
                <Button
                  style={{ margin: "5px" }}
                  variant="contained"
                  onClick={OshaMail}
                >
                  Send Mail
                </Button>
                <Button
                  style={{ margin: "5px" }}
                  variant="contained"
                  onClick={OshaCSV}
                >
                  Download CSV
                </Button>
              </div>
            )}
            <Snackbar
              // anchorOrigin= "top"
              open={snackbarOpen}
              autoHideDuration={1000}
              onClose={handleCloseSnackbar}
              message={snackbarMessage}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert severity="error">{snackbarMessage}</Alert>
            </Snackbar>
          </Card>
        </MainWrapper>
      </MediaQuery>
    </>
  );
}
export default OshaDetails;
