import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import NavHome from "../Nav/navHome";
import { useHistory, useLocation } from "react-router-dom";
import "./mainBoard.css";
import { MainBoardDetails } from "../services/api-servics";
import ChatBotBtn from "../ChatBot";
import { Button, IconButton, CircularProgress,Box } from "@mui/material";
import PageLoader from "../services/pageloader";
import MainWrapper from "../GlobalComponents/MainWrapper";
import getTableStyles from "../../tablestyles";
import MediaQuery from "react-responsive";
import MainContainer from "../MobileUI/GlobalComponents/MainContainer";
import DashboardMain from "./DashboardMain";
function MainBoard() {
  const [filteredData, setFilteredData] = useState("");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState([]);
  const role = localStorage.getItem("role");
  const [all,setAll] = useState(true);  
  const [osha,setOsha] = useState(false);
  const [disableExport,setDisableExport] = useState(true);
  const location = useLocation();
  const loactionData = location.state
  const allSelected = () => {
    setAll(true);
    setOsha(false);
    setDisableExport(true);
  }
  const oshaSelected = () => {
    setAll(false);
    setOsha(true);
    setDisableExport(false);
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if(loactionData && loactionData?.oshaLog===true){
      setOsha(true);
      setDisableExport(false);
      setAll(false);
      const data = newData.filter((item) => item.osha.toLowerCase() === "yes");
      setFilteredData(data);
    }
  }, [loactionData?.oshaLog,newData]);

  useEffect (()=>{
    if((osha === true && all === false)){
      const data = newData.filter((item) => item.osha.toLowerCase() === "yes");
      setFilteredData(data);
    }
    else if (osha === false && all === true){
      setFilteredData(newData);
    }
  },[all, osha])

  const getData = (e) => { 
    setLoading(true);
    MainBoardDetails({}).then(async (rsp) => {
      if (rsp.data.data) {
        setFilteredData(rsp.data.data);
        setNewData(rsp.data.data)
        
      }
      setLoading(false);
    });
  };

  const history = useHistory();

  const handleButtonClick = (item) => {
    let path = `/view_details/${item.incident_id}/${item.establishment_id}`;
    history.push({
      pathname: path,
      item: item,
    });
  };

  const handleSearch = () => {
    const filteredItem = newData.filter((item) => {
      return columns.some((column) => {
        if (column.customFilter) {
          return column.customFilter(searchText, item);
        }
      });
    });
    setFilteredData(filteredItem);
  };
  const instantSearch = (e) => {
    const search = e.target.value
    const filteredItem = newData.filter((item) => Object.values(item).some(value =>
      String(value).toLowerCase().includes(search.toLowerCase())
    ));
    setFilteredData(filteredItem);
  };

  const WrappedCell = (children, width) => (
    <div style={{ maxWidth: width, wordWrap: "break-word" }}>{children}</div>
  );
  const truncateText = (text,maxlength) => {
    if (text&&text.length <= maxlength) {
      return text;
    }
    return text && text.slice(0, maxlength) + "...";
  };

  const columns = [
    {
      name: "Unique ID",
      selector: (row) => row.incident_id,
      sortable: true,
      width: "120px",
      center:true
    },

    {
      name: "Establishment Name",
      selector: (row) => row.establishment_name,
      width: "220px",
      sortable: true,
      cell: (row) => WrappedCell(row.establishment_name, "220px"),
      customFilter: (filterValue, row) =>
        row.establishment_name
          ?.toLowerCase()
          .includes(filterValue.toLowerCase()),
    },

    {
      name: "Employee Name",
      selector: (row) => row.employee_name,
      sortable: true,
      width: "160px",
      cell: (row) => WrappedCell(row.employee_name, "160px"),
      customFilter: (filterValue, row) =>
        row.employee_name?.toLowerCase().includes(filterValue?.toLowerCase()),
    },

    {
      name: "Job Title",
      selector: (row) => row.employee_job,
      sortable: true,
      width: "170px",
      cell: (row) => WrappedCell(row.employee_job, "170px"),
      customFilter: (filterValue, row) =>
        row.employee_job?.toLowerCase().includes(filterValue?.toLowerCase()),
    },

    {
      name: "Date",
      selector: (row) => row.incident_date,
      width: "100px",
      customFilter: (filterValue, row) =>
        row.incident_date?.toLowerCase().includes(filterValue?.toLowerCase()),
    },

    {
      name: "Time",
      selector: (row) => row.incident_time,
      width: "100px",
      customFilter: (filterValue, row) =>
        row.incident_time?.toLowerCase().includes(filterValue?.toLowerCase()),
    },

    {
      name: "Incident Summary",
      selector: (row) => row.detailed_info,
      width: "320px",
      cell: (row) => WrappedCell(truncateText(row.detailed_info,100), "320px"),
      customFilter: (filterValue, row) =>
        row.detailed_info?.toLowerCase().includes(filterValue?.toLowerCase()),
    },

    {
      name: "OSHA",
      selector: (row) => row.osha,
      sortable: true,
      width: "90px",
      customFilter: (filterValue, row) =>
        row.osha?.toLowerCase().includes(filterValue?.toLowerCase()),
    },
   {
      name: "Action",
      cell: (row) => {
        return (
          <IconButton  
          color="primary"
          size="small"
          onClick={() => handleButtonClick(row)}
          > 
          <i class="fa fa-eye" aria-hidden="true"></i>
          </IconButton>
        );
      },
      width: "100px",
      center: true,
      style: { cursor: "pointer" },
    },
  ];
  const columns1 = [
    {
      name: "Unique ID",
      selector: (row) => row.incident_id,
      sortable: true,
      width: "130px",
      center:true
    },

    {
      name: "Establishment Name",
      selector: (row) => row.establishment_name,
      width: "250px",
      cell: (row) => WrappedCell(row.establishment_name, "250px"),
      customFilter: (filterValue, row) =>
        row.establishment_name
          ?.toLowerCase()
          .includes(filterValue.toLowerCase()),
    },
    {
      name: "Job Title",
      selector: (row) => row.employee_job,
      sortable: true,
      width: "200px",
      cell: (row) => WrappedCell(row.employee_job, "200px"),
      customFilter: (filterValue, row) =>
        row.employee_job?.toLowerCase().includes(filterValue?.toLowerCase()),
    },

    {
      name: "Incident Date",
      selector: (row) => row.incident_date,
      width: "150px",
      customFilter: (filterValue, row) =>
        row.incident_date?.toLowerCase().includes(filterValue?.toLowerCase()),
        center:true
    },

    {
      name: "Incident Time",
      selector: (row) => row.incident_time,
      width: "150px",
      customFilter: (filterValue, row) =>
        row.incident_time?.toLowerCase().includes(filterValue?.toLowerCase()),
        center:true
    },

    {
      name: "Incident Summary",
      selector: (row) => row.detailed_info,
      width: "400px",
      cell: (row) => WrappedCell(truncateText(row.detailed_info,100), "400px"),
      customFilter: (filterValue, row) =>
        row.detailed_info?.toLowerCase().includes(filterValue?.toLowerCase()),
    },

    {
      name: "OSHA",
      selector: (row) => row.osha,
      sortable: true,
      width: "100px",
      center:true
    },
  ];
  return (
    <>
      <MediaQuery minDeviceWidth={1024}>
      <MainWrapper>
       {loading ? <PageLoader showLoader={loading} />
        :
        (<Card>
          <Card.Header className="cardHeader">
            <div className="managerText">Manager Dashboard</div>
            <div className="searchArea" style={{ gap: "10px" }}>
              <div>
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2 testArea"
                    aria-label="Search"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <Button 
                  variant="contained"
                  size="small"
                  className="serachButton" 
                  onClick={handleSearch}>
                    Search
                  </Button>
                </Form>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <DataTable
              data={filteredData}
              columns={role==="admin" ? columns1 : columns}
              customStyles={getTableStyles}
              pagination
              striped
              paginationPerPage={10}
            />
          </Card.Body>
        </Card>)}
        </MainWrapper>
      <ChatBotBtn />
      </MediaQuery>

      <MediaQuery maxDeviceWidth={1023}>
      <MainContainer>
      {loading ? <PageLoader showLoader={loading} /> :
        <DashboardMain
        data={filteredData}
        instantSearch = {instantSearch}
        allSelected = {allSelected}
        oshaSelected = {oshaSelected}
        disableExport = {disableExport}
        oshayes = {osha}
        oshaNo = {all}
        oshaLog = {loactionData?.oshaLog}
        />}
      </MainContainer> 
      </MediaQuery>
    </>
  );
}

export default MainBoard;
