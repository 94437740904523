import React from 'react'
import { styled as mkstyle } from "@mui/material/styles";
import { LinearProgress, linearProgressClasses } from '@mui/material';

const BorderLinearProgress = mkstyle(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[300],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      background: `linear-gradient(90deg, #8756D4 -8.33%, #AE88EA 100%)`,
    },
  }));
function ProgressBar(props) {
  return (
    <BorderLinearProgress variant="determinate" value={props.value} />
  )
}

export default ProgressBar