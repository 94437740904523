import { IconButton } from "@mui/material";
import React from "react";
import { IconCardCalendar, IconDownload } from "../../assets/css/icons";
import { ExportDownloadAction } from "../services/api-servics";
import css from "./dashboardMain.module.scss";

function DashBoardCard(props) {
  const role = localStorage.getItem("role");
  const data = props.item;
  const formatDate = (inputDate) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    const day = new Date(inputDate).getDate();
    const month = new Date(inputDate).toLocaleDateString("en-US", {
      month: "short",
    });
    const year = new Date(inputDate).getFullYear();
    const daySuffix =
      day >= 11 && day <= 13
        ? "th"
        : day % 10 === 1
        ? "st"
        : day % 10 === 2
        ? "nd"
        : day % 10 === 3
        ? "rd"
        : "th";
    return `${day}${daySuffix} ${month} ${year}`;
  };
  const formattedDate = formatDate(data.incident_date);

  const handleActionButtonClick = (e) => {
    e.stopPropagation();
    const case_number = data.incident_id;
    const establishment_id = data.establishment_id;
    ExportDownloadAction({ case_number, establishment_id });
  };

  return (
    <div
      className={css.cardMain}
      onClick={()=>{props.handleButtonClick(data)}}
    >
      <div className={css.cardOshaCase}>
        <div className={css.cardCaseNo}>{data.incident_id}</div>
        {data.osha === "Yes" && <div className={css.cardIsOsha}>OSHA</div>}
      </div>
      <div className={css.cardFixture}>
        <IconCardCalendar />
        <div className={css.cardDate}>{formattedDate},&nbsp;{data.time_of_incident}</div>
      </div>
      <div className={css.cardEstName}>{data.establishment_name}</div>
      <div className={css.cardSummary}>{data.detailed_info}</div>
      {data.osha === "Yes" && role !== "admin" && (
        <div className={css.cardIconDownload}>
          <IconButton onClick={handleActionButtonClick} >
          <IconDownload />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default DashBoardCard;
