import { IconButton } from '@material-ui/core'
import { Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { IconGeneralInformation, IconLogout, IconOshaInsider, IconProfile } from '../../../assets/css/icons'
import css from "./homewrapper.module.scss"
function HomeWrapper(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuCss = {
    color: 'var(--majantha, #8756D4)',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16.728px',
    letterSpacing: '0.7px',
  }
const handleMenuClick = (event) => {
  setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
  };
  const logout = async () => {
    localStorage.removeItem("personal-safety");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    history.push("/");
  };
  const handleClose = () => {
    console.log("hgsfda");
    setAnchorEl(null);
  };
  const history = useHistory();
  const handleUserSettings = () =>{
    history.push("/profile_settings",{
      fromHomeMoB:true
    })
  }
  return (
    <div className={css.contentWrapper}>
      <div className={css.oshaInsiderIconDiv}>
        <IconOshaInsider className={css.oshaInsiderIcon} />
        <div className={css.profileIconDiv} onClick={handleMenuClick}>
        <IconProfile className={css.profileIcon} />
              <Menu
                id="main-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {!props.fromProfileSettings && <MenuItem
                sx={menuCss}
                  onClick={() => {
                    handleUserSettings();
                  }}
                >
                  <IconGeneralInformation />
                  &nbsp;Profile Settings
                </MenuItem>}
                <MenuItem
                sx={menuCss}
                  onClick={() => {
                    logout();
                  }}
                >
                  <IconLogout/>
                  &nbsp;Logout
                </MenuItem>
              </Menu>
        </div>
      </div>
      <div className={css.mainCardDiv}>
        <div className={css.behindCard}></div>
        <Card className={css.detailCardDiv}>
         {props.children}
        </Card>
      </div>
    </div>
  )
}

export default HomeWrapper