import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@mui/material';
const useStyles = makeStyles((theme) => ({
    customButton: {
      // backgroundColor: '#9747FF !important', // Change background color
      background: `linear-gradient(90deg, #8756D4 -8.33%, #AE88EA 100%) !important`,
      borderRadius: '10px !important', // Adjust border radius
      color: "#FFF !important",
        fontFamily: "'Poppins', sans-serif !important", 
        fontSize: "13px !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        textTransform: 'none !important', 
        height:"2.6rem !important",
        '&:active': {
          background: `linear-gradient(90deg, #8756D4 100%, #AE88EA -8.33%) !important`,
            transitionDuration: '0.1s' // Disable transition on active state
          },
          '&:disabled': {
            backgroundColor: 'grey !important', // Set the background color for disabled state
            
          },
          
    },
  }));
function MobButton(props) {
    const classes = useStyles();
    const { className: additionalClassName, ...rest } = props;
  return (
    <Button
    className={`${classes.customButton} ${additionalClassName || ''}`}
      {...props}
    >
      {props.children}
    </Button>
  )
}

export default MobButton