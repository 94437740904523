import { alpha, styled, TextField } from "@mui/material";
import React from "react";
const CssTextField = styled(TextField)({
   
    '& .MuiInput-underline:after': {
      borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: "#C4C4C4" ,
        borderRadius: "8px",
        fontfamily: 'Poppins, sans-serif !important', 
      },
      '&:hover fieldset': {
        borderColor: 'rgba(135, 86, 212, 1)',
        fontfamily: 'Poppins, sans-serif !important', 
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(135, 86, 212, 1)',
        boxShadow: `${alpha('rgba(135, 86, 212, 1)', 0.25)} 0 0 0 0.2rem`,
      },
      '&::before': {
        // color: '#yourDesiredColor', // Specify your desired color for the placeholder text
        fontFamily: 'Poppins, sans-serif !important',
      },
      '& input': {
        fontFamily: 'Poppins, sans-serif !important',
        fontSize: "14px",
        height: "32px",
        fontStyle: "normal",
        fontWeight: "400",
        color:"#000000 !important "
      },
    },
    
    '& .MuiFormHelperText-root': {
        // Customize the default styles for helper text
        color: '#000000', // Set your desired color
        fontSize:"12px",
        fontFamily: 'Poppins, sans-serif !important',
      },
    '&.Mui-error': {
        // Customize the error color for TextField
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'rgba(135, 86, 212, 1)', // Specify your custom error color
          },
          '&:hover fieldset': {
            borderColor: 'rgba(135, 86, 212, 1)',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'rgba(135, 86, 212, 1)',
            boxShadow: `${alpha('rgba(135, 86, 212, 1)', 0.25)} 0 0 0 0.2rem`,
          },
        },
    },
  });
function MobTextField(props) {
  return (
    <>
  <CssTextField 
  InputLabelProps={{
    shrink: false,
  }}
  fullWidth
  size="small"
  {...props}
  />
  </>
  )
}

export default MobTextField;
