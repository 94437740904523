import {
  AxioslibUser,
  AxioslibToken,
  AxiosBackendApi,
  AxiosAzureAccessToken,
  AxioslibGenerateToken,
  AxioslibServerUpgrade,
} from "../../lib/axiolib-api";
import queryString from "query-string";
import axios from "axios";

let baseUrl="https://oshainsiderapi.techvantagesystems.com";
let extractedDataUrl = "https://pearsonoshaextract-sandbox.techvantagesystems.com"

export const checkUserLogin = (body) => {
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/LoginDetails`, body)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const getToken = (body) => {
  let credentials = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    grant_type: process.env.REACT_APP_GRAND_TYPE,
    scope: process.env.REACT_APP_SCOPE,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    tenant_id: process.env.REACT_APP_TENANTID,
  };
  return new Promise((resolve, reject) => {
    AxioslibUser.post(
      `${baseUrl}/getTokenDetails`,
      JSON.stringify(credentials)
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const azureAccessToken = (body) => {
  let credentials = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    grant_type: process.env.REACT_APP_GRAND_TYPE,
    scope: process.env.REACT_APP_AZURE_SCOPE,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    tenant_id: process.env.REACT_APP_TENANTID,
  };
  return new Promise((resolve, reject) => {
    AxioslibUser.post(
      `${baseUrl}/getTokenDetails`,
      JSON.stringify(credentials)
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
// export const azureAccessToken = (body, tid) => {
//   let details = queryString.stringify(body)
//   return new Promise((resolve, reject) => {
//     AxiosAzureAccessToken.post(`${tid}/oauth2/v2.0/token`, body, {
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded"
//       },

//     }).then((response) => {
//       if (response) {
//         resolve(response);
//       } else {
//         resolve({ status: 101, message: "No response from the server" });
//       }
//     })
//       .catch((e) => {
//         resolve({ status: 500, message: "No response from the server" });
//       });
//   });
// };
export const getAccessToken = (body, authToken) => {
  return new Promise((resolve, reject) => {
    AxioslibGenerateToken.post(`GenerateToken`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: authToken,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const getEmbededUrl = (authToken, reportId) => {
  return new Promise((resolve, reject) => {
    AxioslibGenerateToken.get(
      `groups/${process.env.REACT_APP_GROUP_ID}/reports/${reportId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: authToken,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const upgrateServerTime = (authToken, AccessToken) => {
  return new Promise((resolve, reject) => {
    AxioslibServerUpgrade.post(
      `${process.env.REACT_APP_SUBSCRIPTION_ID}/resourceGroups/${process.env.REACT_APP__APP_RESOURCE_GROUP_NAME}/providers/Microsoft.PowerBIDedicated/capacities/${process.env.REACT_APP_DEDICATED_CAPACITY_NAME}/resume?api-version=2021-01-01`,
      AccessToken,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: authToken,
        },
      }
    )
      .then((response) => {
        resolve({ status: 200, message: "Server started" });
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const suspendServerTime = (authToken, AccessToken) => {
  return new Promise((resolve, reject) => {
    AxioslibServerUpgrade.post(
      `${process.env.REACT_APP_SUBSCRIPTION_ID}/resourceGroups/${process.env.REACT_APP__APP_RESOURCE_GROUP_NAME}/providers/Microsoft.PowerBIDedicated/capacities/${process.env.REACT_APP_DEDICATED_CAPACITY_NAME}/suspend?api-version=2021-01-01`,
      AccessToken,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: authToken,
        },
      }
    )
      .then((response) => {
        resolve({ status: 200, message: "Server stoped" });
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const getServerStatus = (authToken) => {
  return new Promise((resolve, reject) => {
    AxioslibServerUpgrade.get(
      `/a3875509-b954-4ce4-9631-cd67d6f519e8/resourceGroups/GSEP-POC/providers/Microsoft.PowerBIDedicated/capacities/pbea1gsep?api-version=2021-01-01`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: authToken,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const getSettings = (authToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${extractedDataUrl}/get-settings/`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "User-Agent": "ngrok-skip-browser-warning",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const updateSettings = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${extractedDataUrl}/update-settings/`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const login = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/userlogin`, body, {})
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const SendOTP = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/send_otp`, body, {})
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const PowerbiRefresh = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/powerBIDatasetRefresh`, body, {})
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const processSettings = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${extractedDataUrl}/process-files/`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const stateList = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_state_dropdown`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const incidentOption = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_incident_options`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const employeeDetails = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/employee_details `,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const establishmentDetails = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_establishments`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const categoryList = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_incident_category`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const MainBoardDetails = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_dashboard`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const ViewEmployeeDetails = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/view_individual_incident`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const DeleteEmployeeDetails = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/delete_individual_incident`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const EditEmployeeDetails = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/edit_individual_incident`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const EstablishmentList = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_establishments`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const NaicsData = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_naics_data`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const EstablishmentType = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_establishment_type`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const YearlyDetailsList = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/add_yearly_details`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const YearlyDataTable = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_yearly_details`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const EditEstablishment = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/edit_establishment`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const getEmployeeNumber = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_employee_number`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const DeleteEstablishment = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/delete_establishment`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const AddEstablishmentData = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/config_establishment`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const GetAllUser = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_user_role`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const GetRoleDropdown = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/user_role_dropdown`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const AddNewUser = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/add_user_role`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const DeleteUser = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/delete_user_role`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const EditUser = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/edit_user_role`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const ExtractedDataTable = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/extracted_data_301`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const SelectYear = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_distinct_years`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const OshaDetail = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_details_300A`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const OshaRecordList = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/get_oshalog`, body, {})
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const Export301CSV = async (body) => {
  let updatedDetails = {
    establishment_id: body.establishment_id,
    year: body.year,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updatedDetails),
  };

  await fetch(
    `${baseUrl}/download_batch300`,
    requestOptions
  )
    .then((result) => result.blob())
    .then((blob) => {
      var downloadLink = document.createElement("a");

      downloadLink.href = window.URL.createObjectURL(blob);

      // Set the download attribute
      downloadLink.download = `osha301_${body.establishment_id}.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })

    .catch((error) => console.log("error", error));
};

export const Export300Excel = async (body) => {
  let updatedDetails = {
    establishment_id: body.establishment_id,
    year: body.year,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updatedDetails),
  };

  await fetch(
    `${baseUrl}/export_300_excel`,
    requestOptions
  )
    .then((result) => result.blob())
    .then((blob) => {
      var downloadLink = document.createElement("a");

      downloadLink.href = window.URL.createObjectURL(blob);

      // Set the download attribute
      downloadLink.download = `OSHA300_${body.establishment_id}_${body.year}.xlsx`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })

    .catch((error) => console.log("error", error));
};

export const ExportDownloadAction = async (body) => {
  let updatedDetails = {
    establishment_id: body.establishment_id,
    case_number: body.case_number,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updatedDetails),
  };

  await fetch(
    `${baseUrl}/export_301_excel`,
    requestOptions
  )
    .then((result) => result.blob())
    .then((blob) => {
      var downloadLink = document.createElement("a");

      downloadLink.href = window.URL.createObjectURL(blob);

      // Set the download attribute
      downloadLink.download = `osha301_${body.case_number}.xlsx`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })

    .catch((error) => console.log("error", error));
};

export const ExportOsha300AExcel = async (body) => {
  let updatedDetails = {
    establishment_id: body.establishment_id,
    year: body.year,
    emps_no: body.emps_no,
    total_hr: body.total_hr,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updatedDetails),
  };

  await fetch(
    `${baseUrl}/export_osha_300A`,
    requestOptions
  )
    .then((result) => result.blob())
    .then((blob) => {
      var downloadLink = document.createElement("a");

      downloadLink.href = window.URL.createObjectURL(blob);

      // Set the download attribute
      downloadLink.download = `osha300A.xlsx`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })

    .catch((error) => console.log("error", error));
};
export const ExportOsha300ACSV = async (body) => {
  let updatedDetails = {
    establishment_id: body.establishment_id,
    year: body.year,
    emps_no: body.emps_no,
    total_hr: body.total_hr,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updatedDetails),
  };

  await fetch(
    `${baseUrl}/download_batch300A`,
    requestOptions
  )
    .then((result) => result.blob())
    .then((blob) => {
      var downloadLink = document.createElement("a");

      downloadLink.href = window.URL.createObjectURL(blob);

      // Set the download attribute
      downloadLink.download = `osha300A.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })

    .catch((error) => console.log("error", error));
};

export const Osha300Mail = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/send_mail_request`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const OshaCheck = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/osha_check`, body, {})
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const GetUploadData = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_upload_data`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const UserProfileSettings = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_user_profile`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const PasswordChange = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_password`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const SaveProfileData = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/user_profile`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const SummaryFetch = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/generate_incident_summary`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const SignUp = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/signup`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const TwoFactorAuth = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/two_factor_auth`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const ValidatePassword = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/validate_password`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const ListYearEstablishment = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/list_year_establishment`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const GetHomeData = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/user_details`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const ExtractedDataYear = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}/get_extracted_data_year`,
        body,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
