import { useState, useEffect } from "react";
import React from "react";
import {
  IconCardCalendar,
  IconOshaInsiderViolet,
} from "../../../assets/css/icons";
import MainContainer from "../../MobileUI/GlobalComponents/MainContainer";
import style from "./osha301.module.scss";
import { useHistory } from "react-router-dom";

function Osha301(props) {
  const [fdata301, setFData301] = useState(props.location.state.data301);
  const history = useHistory();

  const data301 = props.location.state.data301;
  function formatDate(dateString) {
    const [day, month, year] = dateString.split(".");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Create an array to hold the suffix for each day
    const suffixes = [
      "th",
      "st",
      "nd",
      "rd",
      "th",
      "th",
      "th",
      "th",
      "th",
      "th",
    ];

    // Determine the suffix for the day
    const suffix = suffixes[parseInt(day, 10)] || suffixes[0];

    // Format the date
    const formattedDate = `${parseInt(day, 10)}${suffix} ${
      monthNames[parseInt(month, 10) - 1]
    } ${year}`;

    return formattedDate;
  }
  const extractView = (data) => {
    let path = `/osha_individual/${data.Full_Name}/${data.CaseNo}/${data.Treatment_Facility_Zip}`;
    history.push({
      pathname: path,
      data: data,
      fromOsha301: true,
    });
  };

  return (
    <MainContainer>
      <div className={style.cParent}>
        <div className={style.cHeader}>
          <IconOshaInsiderViolet />

          <div className={style.subHead}>EXTRACTED DATA</div>
        </div>
        <div className={style.cArea}>
          {data301.map((data, index) => (
            <div
              className={style.card}
              onClick={() => {
                extractView(data);
              }}
            >
              <div className={style.cdHeader}>
                <span className={style.eName}>{data.Full_Name}</span>
                <div className={style.rightHead}>
                  <span className={style.fSpan}>{data.CaseNo}</span>
                  <span className={style.sSpan}>301</span>
                </div>
              </div>
              <div className={style.cdContent}>
                <span className={style.cdSummary}>
                  {data.What_was_the_injury_or_illness_}
                </span>
                <span className={style.cdFooter}>
                  <IconCardCalendar />
                  {formatDate(data.Date_of_injury_or_illness) +
                    ", " +
                    data.Time_of_event}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MainContainer>
  );
}

export default Osha301;
