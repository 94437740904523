import React, { useState } from "react";
import { useEffect } from "react";
import { IconSettingSpanner } from "../../../assets/css/icons";
import FormButton from "../../MobileUI/GlobalComponents/FormButton";
import MainContainer from "../../MobileUI/GlobalComponents/MainContainer";
import { EstablishmentList } from "../../services/api-servics";
import EstablishmentCard from "./EstablishmentCard";
import PageLoader from "../../services/pageloader";
import css from "./manageEstablishment.module.scss";
import { useHistory } from "react-router";
function ManageEstablishment(props) {
  const [loading, setLoading] = useState(false);
  const [esData, setEsData] = useState([]);
  const [refreshTable, setRefreshtable] = useState(false);
  const role = localStorage.getItem("role");
  const history = useHistory();
  useEffect(() => {
    getEstablishmentList();
    setRefreshtable(false);
  }, [refreshTable]);
  useEffect(() => {
    getEstablishmentList();
  }, []);
  const getEstablishmentList = (e) => {
    setLoading(true);
    EstablishmentList({}).then(async (rsp) => {
      if (rsp.data.data) {
        const estData = rsp.data.data;
        setEsData(estData);
      }
      setLoading(false);
    });
  };
  const handleAdd = () => {
    let path = "/add_establishment";
    history.push({
      pathname: path,
    });
  };
  return (
    <MainContainer isHeader>
      {loading ? (
        <PageLoader showLoader={loading} />
      ) : (
        <div className={css.estMain}>
          <div className={css.estCardHeader}>
            <div className={css.estHeader}>
              <IconSettingSpanner />
              Settings
            </div>
            <div className={css.estSubHeaders}>
              <div className={css.estDetails}>Establishment Details</div>
              {role !== "admin" && <FormButton height="24px" width="70px" onClick={handleAdd}>
                + Add
              </FormButton>}
            </div>
          </div>
          <div className={css.estDetailCard}>
            {esData.map((item) => {
              return (
                <EstablishmentCard
                  item={item}
                  setRefreshtable={setRefreshtable}
                />
              );
            })}
          </div>
        </div>
      )}
    </MainContainer>
  );
}

export default ManageEstablishment;
