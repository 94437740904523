import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import Context from "../Context";
import { GetUploadData } from "../services/api-servics";
import { useEffect } from "react";
import { showToaster } from "../services/helper";
import './upload.css'
import { LinearProgress, Typography } from "@mui/material";
import { IconUploadCloud } from "../../assets/css/icons";

const UploadPDF = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('upload file ...');
  const [filePreview, setFilePreview] = useState(null);
  const [progressVisible, setProgressVisible] = useState(false);
  const [progressWidth, setProgressWidth] = useState(0);
  const [progressMessage, setProgressMessage] = useState("");
  const [uploadData, setuploadData] = useState("");
  const [uploadCancel, setUploadCancel] = useState(false);
  const [statusFail, setStatusFail] = useState(false);
  const { formDataState, setformDataState } = useContext(Context);

  
  const linearProgressStyle = {
    height: "10px",
    borderRadius: "5px",
  };

  // useEffect(() => {
  //   console.log('file1112333',file );
  // }, [file]);

  

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setFileName(file.name);
    // setFile(file);
    setProgressVisible(false);
    // Preview the file
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFilePreview(null);
    }
  };
  const handleCancelUpload = () => {
    setUploadCancel(false)
    props.hide(false);
  };

  const uploadPDF = () => {
    if (selectedFile) {
      setUploadCancel(true);
      const formData = new FormData();
      formData.append("file", selectedFile);

      setProgressVisible(true);

      fetch("https://pearsonoshaextract-sandbox.techvantagesystems.com/process_301_upload/", {
        method: "POST",
        body: formData,
      })
        .then(async (response) => {
          // if (!response.ok) {
          //   throw new Error(`HTTP error! Status: ${response.status}`);
          // }

          // return response.json();
          if (response) {
            const reader = response.body.getReader();
            while (true) {
              const { done, value } = await reader.read();
              const newProgress = value && new TextDecoder().decode(value);
              if(newProgress && newProgress.includes("{'message': 'error'}")){
                setStatusFail(true);
                break;
              }
              else if (newProgress && newProgress.length > 800) {
                console.log(uploadCancel);
                setProgressWidth(100);
                setProgressMessage("Process Completed (100%)");
                  GetUploadData({ data: newProgress }).then(async (rsp) => {
                  if (rsp.data.status === "success") {
                    setStatusFail(false);
                    const data = rsp.data.data;
                    props.handlersp(data);
                     setformDataState({
                      ...formDataState,
                      // Assuming data contains fields like full_name, street, city, gender, etc.

                      employeeName: data.Full_Name,
                      jobTitle: data.Title,
                      street: data.Employee_Street,
                      dob: data.Date_of_birth,
                      city: data.Employee_City,
                      dateHired: data.Date_hired,
                      state: data.Employee_State,
                      gender: data.Gender,
                      zip: data.Employee_Zip,
                      WorkTime: data.Time_employee_began_work,
                      workOption: { value: "Y", label: "Yes" },
                      incidentDate: data.Date_of_injury_or_illness,
                      incidentTime: data.Time_of_event,
                      deathTime: data.Date_of_death,
                      injuryDetails: data.What_was_the_injury_or_illness_,
                      object:
                        data.What_object_or_substance_directly_harmed_the_employee_,
                      preIncidentActivity:
                        data.What_was_the_employee_doing_just_before_the_incident_occurred_,
                      reason: data.What_happened,
                      Physician: data.Name_of_Physician,
                      stateInfo: data.Treatment_Facility_State,
                      location: data.Treatment_Facility,
                      zipInfo: data.Treatment_Facility_Zip,
                      streetInfo: data.Treatment_Facility_Street,
                      emergencyRoomOption:
                        data.Was_employee_treated_in_an_emergency_room_,
                      cityInfo: data.Treatment_Facility_City,
                      hospitalizedOption:
                        data.Was_employee_hospitalized_overnight_as_an_in_patient_,
                      type: "upload",
                    });
                    setuploadData(data);
                  } else if (rsp.data.status === "failed") {
                    setStatusFail(true);
                  }
                });
                break;
              } else if (newProgress && newProgress.length < 100) {
                const match = newProgress.replace(/\D/g, "");
                const width = parseInt(match, 10);
                const prgWidth = progressWidth + width;
                if (prgWidth === 0) {
                  setProgressWidth(20);
                  setProgressMessage("Process started (20%)");
                } else if (prgWidth === 20) {
                  setProgressWidth(40);
                  setProgressMessage("Form detected (40%)");
                } else if (prgWidth === 30) {
                  setProgressWidth(75);
                  setProgressMessage("Extraction started (75%)");
                }
              }
            }
          }
        })
        // .then((data) => {
          
        //   if (data && data.message && data.message.toLowerCase() === "error") {
        //     setuploadData(data);
        //   }
        // })
        .catch((error) => {
          console.error("Error:", error);
          // Handle error message display
        });

      // Start progress bar animation
      // updateProgressBarUntilResponse();
    } else {
      console.error("No file selected");
    }
  };
  // const uploadPDF = async () => {
  return (
    <>
    {props.device && props.device === 'Desktop' ? (
    <div>
      <div className="upload" style={{ display: "flex", padding: "10px" }}>
        <div style={{ paddingRight: "10px" }} >
          <input
            type="file"
            accept=".pdf, .jpg, .jpeg, .png"
            onChange={handleFileChange}
            disabled={uploadCancel === true}
          />
        </div>
        <div style={{ paddingRight: "10px" }}>
          <Button
            variant="contained"
            disabled={filePreview === null || uploadCancel === true}
            onClick={uploadPDF}
          >
            Upload
          </Button>
        </div>
        <div style={{ paddingRight: "10px" }}>
          <Button
            variant="contained"
            // disabled={progressWidth === 0}
            onClick={handleCancelUpload}
          >
            Cancel
          </Button>
        </div>
      </div>
      {filePreview && (
        <div>
          {selectedFile.type === "application/pdf" ? (
            <object
              data={filePreview}
              type="application/pdf"
              width="100%"
              height="500px"
            >
              <p>Preview is not available</p>
            </object>
          ) : (
            <img
              src={filePreview}
              alt="Preview"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          )}
        </div>
      )}
      {statusFail === false && progressVisible && (
        <div id="progressContainer" style={{ display: "block",marginTop:"10px" }}>
          {/* <div
            id="progressBar"
            style={{
              width: `${progressWidth}%`,
              height: "10px",
              backgroundColor: "green",
            }}
          ></div> */}
          <LinearProgress
            variant="determinate"
            value={progressWidth}
            style={linearProgressStyle}
          />
          <div>
            <Typography variant="body2" color="text.secondary">
              {progressMessage}
            </Typography>
          </div>
        </div>
      )}
      {statusFail === true && (
        <div id="progressContainer" style={{ display: "block" }}>
          <div style={{ padding: "10px", color: "red", fontWeight: "700" }}>
            Please upload the appropriate 301 OSHA form, as the provided
            information does not align with the required document.
          </div>
        </div>
      )}
    </div>
    ):(
      <div className="uploadMob">
          <div className="upBar">
            <input
              type="file"
              accept=".pdf, .jpg, .jpeg, .png"
              style={{ display: "none" }}
              // ref={inputRef}
              onChange={handleFileChange}
              disabled={uploadCancel === true}
              id="fileInput"
            />
      <label htmlFor="fileInput">
            <IconUploadCloud />
      </label>
    <span className="UpSpan">{fileName.substr(0, 20)}</span>
          </div>
          <div className="upBottom">
              <Button
                variant="contained"
                // disabled={progressWidth === 0}
                onClick={handleCancelUpload}
                sx={{
                  backgroundColor: "#fff",
                  color: "rgb(135, 86, 212)",
                  borderRadius:'10px',
                  border: '1.2px solid var(--bg-grad, #8756D4)',
                }}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                disabled={filePreview === null || uploadCancel === true}
                onClick={uploadPDF}
                sx={{
                  backgroundColor: "rgb(135, 86, 212)",
                  color: "white",
                  borderRadius:'10px',
                }}
              >
                Upload
              </Button>
          </div>

          {statusFail === false && progressVisible && (
        <div id="progressContainer" style={{ display: "block",marginTop:"10px" }}>
          <LinearProgress
            variant="determinate"
            value={progressWidth}
            style={linearProgressStyle}
          />
          <div>
            <Typography variant="body2" color="text.secondary">
              {progressMessage}
            </Typography>
          </div>
        </div>
      )}
         {statusFail === true && (
        <div id="progressContainer" style={{ display: "block" }}>
          <div style={{ padding: "10px", color: "red", fontWeight: "600", fontSize:"11px" }}>
            Please upload the appropriate 301 OSHA form, as the provided
            information does not align with the required document.
          </div>
        </div>
      )}
      </div>

      
    )}
    </>
  );
};

export default UploadPDF;
