import React from "react";
import { useHistory } from "react-router";
import { IconEstablishments, IconPasswordSettings, IconTwoFactorSettings, IconUserSettings } from "../../../assets/css/icons";
import ManageEstablishment from "./ManageEstablishment";
import styles from "./settingsMenu.module.scss";
function MainMenu(props) {
  const role = localStorage.getItem("role");
const history = useHistory();
  const handleEstablishment = () => {
    history.push('/view_establishment');
  }
  

  return (
    <>
      <div className={styles.row}>
        <button className={styles.menuBtn} onClick={()=>{props.menuChange("passwordSettings")}}>
        <IconPasswordSettings/>
          Change Password
          </button>
        <button className={styles.menuBtn} onClick={()=>{props.menuChange("twoFactorMenu")}}>
          <IconTwoFactorSettings/>
          Two Factor Authentication
          </button>
      </div>

      <div className={styles.row}>
        {role !== "admin" &&
        <button className={styles.menuBtn} onClick={()=>{props.menuChange("userDetails")}}>
        <IconUserSettings/>
          User Details
          </button>}

        <button className={styles.menuBtn} onClick={handleEstablishment}> 
        <IconEstablishments/> 
        Manage Establishments</button>
      </div>
    </>
  );
}

export default MainMenu;
