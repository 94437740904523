import React from 'react'
import { Card } from 'react-bootstrap';
import { IconFirstPage, IconILE, IconOshaInsider } from '../../../assets/css/icons';
import css from "./loginmobile.module.scss";

function LoginMob(props) {
  return (
    <div className={css.logincontentWrapper}>
    <div className={css.loginoshaInsiderIconDiv}>
      <IconOshaInsider/>
    </div>
    <div className={css.loginmainCardDiv}>
        <div className={css.loginbehindCard}>
        </div>
        <Card className={css.logindetailCardDiv}>
            <Card.Header className={css.loginregCardheader}>
            {props.fromStart ?
            <div className={css.logincardHeaderDiv}>
            <IconFirstPage/>
            </div>
            :
            <div className={css.logincardHeaderDiv}>
                    <label className={css.welcomeBack}>Welcome Back</label>
                    <label className={css.logindetailText}>Enter your details below</label>
                </div>}
            </Card.Header>
            <Card.Body className={props.fromStart ? css.StartCardBody : css.loginregCardBody}>
                {props.children}
            </Card.Body>
            <Card.Footer className={css.loginCardFooter}>
            <IconILE/>
            </Card.Footer>
        </Card>
    </div>
  </div>
  )
}

export default LoginMob