import React, { useState } from 'react';
import PowerBiIcon from "./PowerBiIcon";
import { axiosInstance } from "../../lib/axiosInstance";

import { useLocation } from "react-router-dom";


function ListMenu({ setBtnSelected, setMessages, handleRefreshSelection1 }) {



    const location = useLocation();
    const { url } = "https://app.powerbi.com/view?r=eyJrIjoiOWRhMDY2MWEtYmZkZC00ZmE5LTkxYzMtZDMyM2JlMmM1MGJlIiwidCI6ImIxZjc3YjY3LWU2NjktNDI1ZS04NTkwLTdhMjBhYjNiYThmMCIsImMiOjEwfQ%3D%3D";
    // const [btnSelected, setBtnSelected] = useState(false);
    // const [messages, setMessages] = useState([]);
    const handleRefreshSelection = () => {
        // setBtnSelected(false);
        setMessages([]);
        try {
            axiosInstance
                .post("/refresh/")
                .then((_response) => { })
                .catch((err) => console.error("[Error Refresh /refresh/]", err?.message));
        } catch (error) {
            console.error("[Error - Refresh /refresh/]: ", error?.message);
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true)
        // console.log("handleOpenModal", isOpen)
        // setIsOpen(true)
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);

    }


    const toggleMenu = () => {
        // console.log("toggleMenu", isOpen)
        setIsOpen(!isOpen);
    }



    return (
        <div className="menu-container" onClick={toggleMenu}>
            {/* <button className="menu-button">
                Open Menu
            </button> */}

            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="28" viewBox="0 0 7 28" fill="none">
                <path d="M6.226 3.112C6.22613 3.5208 6.14574 3.92563 5.98942 4.30337C5.8331 4.68111 5.60391 5.02435 5.31493 5.31352C5.02595 5.60268 4.68285 5.83209 4.30522 5.98865C3.92758 6.14522 3.52281 6.22587 3.114 6.226C2.7052 6.22613 2.30037 6.14574 1.92263 5.98942C1.54489 5.8331 1.20165 5.60391 0.912483 5.31493C0.623322 5.02596 0.393911 4.68285 0.237347 4.30522C0.0807826 3.92758 0.000131474 3.52281 1.60828e-07 3.114C-0.000265038 2.28838 0.327457 1.49647 0.91107 0.912484C1.49468 0.328496 2.28638 0.000265377 3.112 1.60849e-07C3.93762 -0.000265056 4.72953 0.327456 5.31352 0.91107C5.8975 1.49468 6.22573 2.28638 6.226 3.112Z" fill="#FBFBFB" />
                <path d="M3.11395 16.9081C4.83266 16.9081 6.22595 15.5148 6.22595 13.7961C6.22595 12.0774 4.83266 10.6841 3.11395 10.6841C1.39524 10.6841 0.00195312 12.0774 0.00195312 13.7961C0.00195312 15.5148 1.39524 16.9081 3.11395 16.9081Z" fill="#FBFBFB" />
                <path d="M3.11395 27.5941C4.83266 27.5941 6.22595 26.2008 6.22595 24.4821C6.22595 22.7634 4.83266 21.3701 3.11395 21.3701C1.39524 21.3701 0.00195312 22.7634 0.00195312 24.4821C0.00195312 26.2008 1.39524 27.5941 3.11395 27.5941Z" fill="#FBFBFB" />
            </svg>

            {isOpen && (
                <div className="menu">
                
                    <div className="menu-item" >
                        <div className="refreshBtn" onClick={handleRefreshSelection}>
                            {/* <span className="material-symbols-outlined">refresh</span> */}
                            <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="refresh"><g data-name="Layer 2"><path d="M20.3 13.43a1 1 0 0 0-1.25.65A7.14 7.14 0 0 1 12.18 19 7.1 7.1 0 0 1 5 12a7.1 7.1 0 0 1 7.18-7 7.26 7.26 0 0 1 4.65 1.67l-2.17-.36a1 1 0 0 0-1.15.83 1 1 0 0 0 .83 1.15l4.24.7h.17a1 1 0 0 0 .34-.06.33.33 0 0 0 .1-.06.78.78 0 0 0 .2-.11l.09-.11c0-.05.09-.09.13-.15s0-.1.05-.14a1.34 1.34 0 0 0 .07-.18l.75-4a1 1 0 0 0-2-.38l-.27 1.45A9.21 9.21 0 0 0 12.18 3 9.1 9.1 0 0 0 3 12a9.1 9.1 0 0 0 9.18 9A9.12 9.12 0 0 0 21 14.68a1 1 0 0 0-.7-1.25z" data-name="refresh"></path></g></svg>
                            <span className="list-span-text">
                                Refresh
                            </span>
                        </div>
                    </div>
                </div>
            )}


        </div>
    );
}

export default ListMenu;



