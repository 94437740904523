import { Button, Modal } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { IconModalIns } from "../../assets/css/icons";
import "./WindowMessage.css";

function WindowMessage(props) {
  return (
    <Modal
      className="cnfModal"
      open={props.show}
      onClose={props.handleClose}
      closeAfterTransition
      keepMounted={props.keepMounted}
      
    >
      <div className="cnfModalBody">
        <div className="modalIcon">
          <div className="modalDiv">
          <IconModalIns/>
          </div>
        </div>
        {props.fromSubmit ? (
          <div className="cnfModalText">{props.windowMessage}?</div>
        ) : 
        props.fromNewForm ? (
          <div className="cnfModalText">{props.windowMessage}</div>
        ) :(
          <div className="cnfModalText">
            Are you sure you want to delete this {props.windowMessage}?
          </div>
        )}
        <div className={props.fromNewForm ? "cnfmodalButtonNewForm" : "cnfmodalButton"}>
          <Button variant="contained" color={props.fromViewFiles ?"primary" : "error" }onClick={props.handleClose || props.onClose}>
            {props.fromViewFiles ? "Cancel" : "No"}
          </Button>
          <Button variant="contained" color={props.fromViewFiles && "error"} onClick={props.handleConfirm}>
          {props.fromViewFiles ? "Delete" : "Yes"}
          </Button>
          </div>
        <div className={props.fromNewForm ? "cnfmodalButtonMobNewForm" : "cnfmodalMobButton"}>
          <Button  
          color={props.fromViewFiles ? "primary" :"secondary"}
          onClick={props.handleClose || props.onClose}
          style={{fontWeight:"600",
          color:"#474747",
          fontSize:"16px",
          fontFamily:"'Poppins', sans-serif "
        }}
          >
            {props.fromViewFiles ? "Cancel" : "No"}
          </Button>
          <div class="vertical-line"></div>
          <Button 
           color="error" onClick={props.handleConfirm}
           style={{fontWeight:"600",
           fontSize:"16px",
           fontFamily:"'Poppins', sans-serif "
          }}

           >
          {props.fromViewFiles ? "Delete" : "Yes"}
          </Button>
          </div>
      </div>
    </Modal>
  );
}

export default WindowMessage;
