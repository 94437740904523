import React from "react";
import { useHistory } from "react-router";

import css from "./osha300A.module.scss";
function Osha300ACard(props) {
    const history = useHistory();
    const data = props.data
    const viewDetails = () => {
      let path = `/osha_individual/${data.Establishment_Name}/${data.Year}/${data.Total_number_of_cases_with_days_away_from_work}`;
      history.push({
      pathname: path,
      data: data,
      fromOsha300A:true
    });
    }
  return (
    <div className={css.oshacardMain} onClick={viewDetails}>
      <div className={css.oshaCardHeader}>
        <div className={css.oshaEstName}>{data.Establishment_Name}</div>
        <div className={css.oshaFormYear}>
          <div className={css.oshaForm}>300A</div>
          <div className={css.oshaYear}>{data.Year}</div>
        </div>
      </div>

      <div className={css.oshaFormDetails}>
        <div className={css.oshaFormRow1}>
          <div className={css.oshaWork}>
            <div className={css.oshaBoxHeaders}>Number of Employees</div>
            <div className={css.oshaBoxdata}>{data.Annual_average_number_of_employees}</div>
          </div>
          <div className={css.oshaWork}>
            <div className={css.oshaBoxHeaders}>Days away from work</div>
            <div className={css.oshaBoxdata}>{data.Total_number_of_days_away_from_work}</div>
          </div>
        </div>
        <div className={css.oshaFormRow2}>
          <div className={css.oshaWork}>
            <div className={css.oshaBoxHeaders}>Total working Hours</div>
            <div className={css.oshaBoxdata}>{data.Total_hours_worked_by_all_employees_last_year}</div>
          </div>
          <div className={css.oshaWork}>
          <div className={css.oshaBoxHeaders}>No of Deaths</div>
          <div className={css.oshaBoxdata}>{data.Total_number_of_deaths}</div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Osha300ACard;
