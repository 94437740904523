import React from 'react'
import { Card } from 'react-bootstrap'
import "./UserList.css"
import NavHome from "../../Nav/navHome";
import getTableStyles from '../../../tablestyles';
import DataTable from 'react-data-table-component';
import { 
  Button,
  CircularProgress,
  Box
} from '@mui/material';
import { useHistory } from 'react-router';
import { GetAllUser,DeleteUser,EditUser } from '../../services/api-servics';
import PageLoader from "../../services/pageloader";
import { useState } from 'react';
import { useEffect } from 'react';
import WindowMessage from '../../GlobalComponents/WindowMessage';
import MainWrapper from '../../GlobalComponents/MainWrapper';

function UserList() {
  const [userData,setUserData]=useState([])
  const [loading, setLoading] = useState(false);
  const [refreshTable, setRefreshtable] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getUserList()
  }, [])
  
  useEffect(() => {
    getUserList()
    setRefreshtable(false)
  }, [refreshTable])


  const getUserList = (e) => {
    setLoading(true);
    GetAllUser({}).then(async (rsp) => {
      if (rsp.data.data) {
      const usersData= rsp.data.data
      setUserData(usersData)
      }
      setLoading(false);
    })
  }

  const editUserRole = (e) => {
    EditUser({ email: e.email }).then(async (rsp) => {
      if(rsp.data.data){
        const data= rsp.data.data
      let path = "/edit_user";
      history.push({
        pathname: path,
        state:{
          selectedRole:data.user_role,
          userName:data.name,
          userCompany:data.company,
          userDesignation:data.designation,
          userEmail:data.email,
          userPassword:data.password,
          userContactNo:data.mobNo,
          editUser:"true"
        }
      });
    }
    });
  };
  const deleteUser = (e) => {
    DeleteUser({email:e.email}).then(async (rsp) => {
      setRefreshtable(true);
    })
  }
  const history = useHistory();
  const WrappedCell = (children, width) => (
    <div style={{ maxWidth: width, wordWrap: "break-word" }}>{children}</div>
  );
  const handleEdit = (item) => {
    editUserRole(item)
    // let path = "/yearly_details";
    // history.push({
    //   pathname: path,
    // })
}

const handleDelete = (item) => {
  setItemIdToDelete(item);
  setShowModal(true);
};
const handleConfirm = () => {
  if (itemIdToDelete) {
    deleteUser(itemIdToDelete);
  }
  handleClose();
};

const handleClose = () => {
  setItemIdToDelete(null);
  setShowModal(false);
};
const handleAddUser = () => {
    let path = "/add_user";
    history.push({
      pathname: path,
    })
}

  const addActionButtons = (item) => {
    return {
      ...item,
      edit: (
        <Button 
        onClick={() => handleEdit(item)}
        variant="contained"
        size="small"
        >
          Edit
        </Button>
      ),
      delete: (
        <Button 
        onClick={() => handleDelete(item)}
        variant="contained"
         size="small"
        >
          Delete
        </Button>
      ),
    };
  }
  const newData = userData.map(addActionButtons);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      width: "150px",
    },

    {
      name: "Email",
      selector: (row) => row.email,
    //   width: "170px",
    },

    {
      name: "Company",
      selector: (row) => row.company,
      cell: (row) => WrappedCell(row.company, "170px"),
      width: "170px",
    },
    {
      name: "Manager",
      selector: (row) => row.manager,
      cell: (row) => WrappedCell(row.manager, "150px"),
      width: "150px",
    },
    {
      name: "Contact Number",
      selector: (row) => row.contactnumber,
      width: "150px",
    },
    {
      name: "Role",
      selector: (row) => row.role,
      width: "150px",
    },
    {
      name: "Edit",
      selector: (row) => row.edit,
      width: "150px",
      style: {
        display:"flex",
        justifyContent: 'center',
      },
    },
    {
      name: "Delete",
      selector: (row) => row.delete,
      width: "150px",
      style: {
        display:"flex",
        justifyContent: 'center',
      },
    },
]
  return (
    <>
    <WindowMessage
    show={showModal}
    handleClose={handleClose}
    windowMessage="User"
    handleConfirm={handleConfirm}
    />
    <MainWrapper>
    {/* {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) */}
         {loading ? <PageLoader showLoader={loading} />
        :(
        <Card>
        <Card.Header className="cardHeader">
          <div className="establishmentText">User Details</div>
          <Button
          variant="contained"
          size="small"
          onClick={handleAddUser}
          >
            Add
          </Button>
        </Card.Header>
        <Card.Body>
        <DataTable
              data={newData}
              columns={columns}
              customStyles={getTableStyles}
              striped
              />
        </Card.Body>
      </Card>
      )
        }
    </MainWrapper>
  </>
  )
}

export default UserList