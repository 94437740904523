import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Autocomplete,
  Modal,
  createTheme,
} from "@mui/material";
import { Card, Row, Col } from "react-bootstrap";
import {
  stateList,
  establishmentDetails,
  EditEmployeeDetails,
} from "../services/api-servics";
import "./NewForm.css";
import UploadPDF from "./Upload";
import Box from "@mui/material/Box";
import Context from "../Context";
import CsTextField from "../GlobalComponents/CsTextField";
import WindowMessage from "../GlobalComponents/WindowMessage";
import MainWrapper from "../GlobalComponents/MainWrapper";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import MainContainer from "../MobileUI/GlobalComponents/MainContainer";
import css from "./newform.module.scss";
import MobTextField from "../MobileUI/GlobalComponents/MobTextField";
import FormButton from "../MobileUI/GlobalComponents/FormButton";
import { IconCalendar, IconChevRight } from "../../assets/css/icons";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ProgressBar from "../MobileUI/GlobalComponents/ProgressBar";
const Styles = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container input {
    font-family: "Roboto", sans-serif;
  }

  .react-datepicker__header {
    background-color: #1976d2;
    color: #fff;
  }

  .react-datepicker__day {
    color: #333;
  }

  .react-datepicker__day--selected {
    background-color: #1976d2;
    color: #fff;
  }
`;


function NewForm(props) {
  const { formDataState, setformDataState } = useContext(Context);
  const [stateLists, setstateLists] = useState([]);
  const [establishmentOption, setestablishmentOption] = useState([]);
  const [denied, setDenied] = useState(false);
  const [errorDobMessage, setErrorDobMessage] = useState("");
  const [activeEntityResponse, setactiveEntityResponse] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState("");
  const [uploadData, setuploadData] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [doberror, setDoberror] = useState(false);
  const [hirederror, setHirederror] = useState(false);
  const [errorHiredMessage, setErrorHiredMessage] = useState("");
  const [dateHandle, setdateHandle] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [progress,setProgress] = useState(0);
  const [errors, setErrors] = useState({
    employeeName: "",
    jobTitle: "",
    street: "",
    dob: "",
    city: "",
    dateHired: "",
    state: "",
    gender: "",
    zip: "",
    empId: "",
    establishment: "",
  });
  const currentDate = new Date();
  const currentYear = new Date().getFullYear();
  const dobYear = new Date(currentYear - 18, 0, 1);
  const dob = formDataState.dob !== "" ? dayjs(formDataState.dob) : null;
  const dateHired = formDataState.dateHired !== "" ? dayjs(formDataState.dateHired) : null;
  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          width: "100%",
          padding: "8px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          boxSizing: "border-box",
        },
      },
    },
  });

  useEffect(() => {
    const interval = 10; // Update interval in milliseconds
    const duration = 50; // Total duration in milliseconds
    const steps = duration / interval;
    let currentStep = 0;

    const progressIncrement = 33 / steps;

    const progressInterval = setInterval(() => {
      const currentProgress = Math.min(progressIncrement * (currentStep+1), 33);

      setProgress(currentProgress);
      currentStep++
      if (currentStep >= steps) {
        clearInterval(progressInterval);
      }
    }, interval);

    // Cleanup function to clear the interval
    return () => clearInterval(progressInterval);
  }, []);

  useEffect(() => {
    if (props.location.type === "edit") {
      EmployeeEditDetails();
    }

    getStateList();
  }, []);

  useEffect(() => {
    if (dateHandle === true) {
      setformDataState({ ...formDataState, dateHired: "" });
      setdateHandle(false);
    }
  }, [dateHandle]);

  useEffect(() => {
    if (props.location.type === "edit" && editMode && stateLists) {
      EmployeeEditDetails();
    }
  }, [editMode && stateLists]);

  const incidentoptions = [
    { value: "y", label: "Yes" },
    { value: "n", label: "No" },
  ];

  const handlersp = (rsp) => {
    setIsModalOpen(false);
    setuploadData(rsp);
  };
  const getStateList = (e) => {
    stateList({}).then(async (rsp) => {
      if (rsp.data.States) {
        const stateOptions = rsp.data.States.map((s) => s);
        setstateLists(stateOptions);
      }
    });

    establishmentDetails({}).then(async (rsp) => {
      if (rsp.data.data) {
        const stateOptions = rsp.data.data.map((s) => s);
        setestablishmentOption(stateOptions);
        if (rsp.data.data.length === 0) {
          setShowModal(true);
        }
      }
    });
  };

  const handleUpload = () => {
    setIsModalOpen(true);
  };

  const handleZipCode = (event) => {
    const inputValue = event.target.value.replace(/\D/g, "").slice(0, 5);
    setformDataState({ ...formDataState, zip: inputValue });
  };
  const handleEIN = (event) => {
    const inputValue = event.target.value.replace(/\D/g, "").slice(0, 9);
    setformDataState({ ...formDataState, empId: inputValue });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEmptyClose = () => {
    setShowModal(false);
    let path = "/mainboard";
    history.push(path);
  };
  const handleConfirmEst = () => {
    let path = "/view_establishment";
    history.push(path);
  };
  const EmployeeEditDetails = () => {
    const incident_id = props.location.incident_id;
    const establishment_id = props.location.establishment_id;
    EditEmployeeDetails({ incident_id, establishment_id }).then(async (rsp) => {
      setEditMode(true);
      const data = rsp.data.data;
      const getState = stateLists.find((obj) => obj.code === data.state.value);
      const estType = establishmentOption.find(
        (obj) => obj.establishmentID === data.est_id
      );
      const workRelatedOpt = incidentoptions.find(
        (obj) => obj.value === data.work_related
      );
      const emergencyRoom = incidentoptions.find(
        (obj) => obj.value === data.emergency_room
      );
      setactiveEntityResponse(data);
      if (rsp.data.data) {
        const value =
          data.incident_outcome.value === "Job transfer or restriction"
            ? data.days_restricted
            : data.incident_outcome.value === "Days away from work"
              ? data.days_away
              : "";
        setformDataState({
          ...formDataState,
          // Assuming data contains fields like full_name, street, city, gender, etc.
          //new form values
          employeeName: data.full_name,
          jobTitle: data.job_title,
          street: data.street,
          dob: data.date_of_birth,
          city: data.city,
          dateHired: data.date_hired,
          state: getState,
          gender: data.gender,
          zip: data.zip_code,
          empId: data.emp_id,
          establishment: estType,

          //incident Details
          workOption: workRelatedOpt,
          WorkTime: data.time_employee_began_work,
          incidentDate: data.date_of_incident,
          incidentPlace: data.incident_place,
          incidentTime: data.time_of_incident,
          incidentCause: data.incident_outcome,
          deathTime: data.death_date,
          value: value,

          //incident Description
          incident: data.incident_category,
          injurySummary: data.incident_summary,
          injuryDetails: data.injury_info,
          object: data.object_info,
          preIncidentActivity: data.occurrence_info,
          reason: data.material_info,
          Physician: data.physician_name,
          stateInfo: data.state_info,
          location: data.treatment_info,
          zipInfo: data.zip_info == 0 ? "" : data.zip_info,
          streetInfo: data.street_info,
          emergencyRoomOption: emergencyRoom,
          cityInfo: data.city_info,
          hospitalizedOption: data.hospital_info,
          type: "edit",
          treatmentInfo: data.treatment_info,
          incidentId: props.location.incident_id,
        });
      }
    });
  };

  const history = useHistory();

  const handleClick = () => {
    setDenied(true);
    if (validateForm()) {
      setDenied(false);
      let path = "/new-form/incident_details";
      history.push(path, { formDataState: formDataState });
    }
  };
  const valiadteZip = (zip) => {
    const zipCodeRegex = /^\d{5}(?:-\d{4})?$/;
    return zipCodeRegex.test(zip);
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Validate Employee Name
    if (!formDataState.employeeName) {
      newErrors.employeeName = "Employee Name is required";
      setSnackbarMessage("Employee Name is required");
      isValid = false;
    } else {
      newErrors.employeeName = "";
    }

    // Validate Job Title
    if (!formDataState.jobTitle) {
      newErrors.jobTitle = "Job Title is required";
      setSnackbarMessage("Job Title is required");
      isValid = false;
    } else {
      newErrors.jobTitle = "";
    }

    // Validate Street
    if (!formDataState.street) {
      newErrors.street = "Street is required";
      setSnackbarMessage("Street is required");
      isValid = false;
    } else {
      newErrors.street = "";
    }

    // Validate Date of Birth
    if (!formDataState.dob) {
      newErrors.dob = "Date of Birth is required";
      setSnackbarMessage("Date of Birth is required");
      isValid = false;
    } else {
      const dobDate = new Date(formDataState.dob);
      const currentDate = new Date();
      const minAgeDate = new Date();
      minAgeDate.setFullYear(currentDate.getFullYear() - 18);

      if (dobDate > minAgeDate) {
        newErrors.dob = "Age must be at least 18 years";
        setSnackbarMessage("Age must be at least 18 years");
        isValid = false;
      } else {
        newErrors.dob = "";
      }
    }

    // Validate City
    if (!formDataState.city) {
      newErrors.city = "City is required";
      setSnackbarMessage("City is required");
      isValid = false;
    } else {
      newErrors.city = "";
    }

    // Validate Date Hired
    if (!formDataState.dateHired || hirederror) {
      newErrors.dateHired = "Date Hired is required or Incorrect";
      setSnackbarMessage("Date Hired is required or Incorrect");
      isValid = false;
    } else {
      newErrors.dateHired = "";
    }

    // Validate State
    if (!formDataState.state) {
      newErrors.state = "State is required";
      setSnackbarMessage("State is required");
      isValid = false;
    } else {
      newErrors.state = "";
    }

    // Validate Gender
    if (!formDataState.gender) {
      newErrors.gender = "Gender is required";
      setSnackbarMessage("Gender is required");
      isValid = false;
    } else {
      newErrors.gender = "";
    }

    // Validate ZIP Code
    if (!valiadteZip(formDataState.zip)) {
      newErrors.zip = "ZIP Code must be 5 digits";
      setSnackbarMessage("ZIP Code must be 5 digits");
      isValid = false;
    } else {
      newErrors.zip = "";
    }

    // Validate Employee ID
    if (!formDataState.empId) {
      newErrors.empId = "Employee ID is required";
      setSnackbarMessage("Employee ID is required");
      isValid = false;
    } else {
      newErrors.empId = "";
    }

    // Validate Establishment Type
    if (!formDataState.establishment) {
      newErrors.establishment = "Establishment Type is required";
      setSnackbarMessage("Establishment Type is required");
      isValid = false;
    } else {
      newErrors.establishment = "";
    }

    setErrors(newErrors);

    if (!isValid) {
      setSnackbarOpen(true);
    }

    return isValid;
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const isDobValid = (dateString) => {
    const enteredDate = new Date(dateString);
    const currentDate = new Date();

    // Calculate the date 18 years ago
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(currentDate.getFullYear() - 18);

    // Compare the entered date with 18 years ago
    return enteredDate <= eighteenYearsAgo;
  };

  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 18);

  const isDateHiredValid = (dateString) => {
    const enteredDate = new Date(dateString);
    const currentDate = new Date(formDataState.dob);
    return enteredDate >= currentDate;
  };
  const isDateChanged = (dateString) => {
    const enteredDate = new Date(dateString);
    const currentDate = new Date(formDataState.dob);
    return enteredDate !== currentDate;
  };
  const handleDateHeird = (e) => {
    const value = e;
    const isValid = isDateHiredValid(value);
    const inputDate = new Date(value);
    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate
      .getDate()
      .toString()
      .padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    if (isValid) {
      setHirederror(false);
      setErrorHiredMessage("");
      setformDataState({
        ...formDataState,
        dateHired: formattedDate,
      });
    } else {
      setHirederror(true);
      setErrorHiredMessage(
        "You must select a date later than your date of birth."
      );
      setformDataState({
        ...formDataState,
        dateHired: formattedDate,
      });
    }
  };

  const handleDateChange = (e) => {
    const newErrors = { ...errors };
    newErrors.dob = "";
    const value = e;
    const isValid = isDobValid(value);
    const inputDate = new Date(value);
    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate
      .getDate()
      .toString()
      .padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setformDataState({ ...formDataState, dateHired: "" });
    if (isValid) {
      setdateHandle(true);
      setDoberror(false);
      setErrorDobMessage("");
      setformDataState({ ...formDataState, dob: formattedDate });
    } else {
      setdateHandle(true);
      setDoberror(true);
      setErrorDobMessage("You must be at least 18 years old.");
      setformDataState({ ...formDataState, dob: formattedDate });
    }
  };

  const options = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  const windowPopupMessage = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        fontWeight: "800",
      }}
    >
      {" "}
      <div>The configuration of the establishment has not been completed.</div>
      <div>Would you like to proceed with configuring these details?</div>
    </div>
  );
  return (
    <>
      {/* -----------------Desktop UI----------------- */}
      <MediaQuery minDeviceWidth={1024}>
        <MainWrapper>
          {showModal && (
            <WindowMessage
              show={showModal}
              onClose={handleEmptyClose}
              windowMessage={windowPopupMessage}
              handleConfirm={handleConfirmEst}
              keepMounted
              fromNewForm
            />
          )}
          <Card>
            <Card.Header className="cardHeader">
              <div className="managerText">
                Incident Report-Employee Details
              </div>
              <div className="searchArea" style={{ gap: "10px" }}>
                <Button
                  variant="contained"
                  disabled={formDataState.type === "edit"}
                  onClick={handleUpload}
                >
                  Import Form-301
                </Button>
                {
                  <Modal
                    open={isModalOpen}
                    // onClose={handleCloseModal}
                    keepMounted = {!isModalOpen}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={{
                        display:"flex",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                      }}
                    >
                      <UploadPDF handlersp={handlersp} hide={setIsModalOpen} device={'Desktop'} />
                    </Box>
                  </Modal>
                }
              </div>
            </Card.Header>
            <Row className="row_text">
              <Col md={6}>
                <CsTextField
                  className="text_area"
                  label="Employee Name"
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={
                    denied &&
                    formDataState.employeeName === "" &&
                    errors.employeeName.length > 0
                  }
                  helperText={
                    denied &&
                    formDataState.employeeName === "" &&
                    errors.employeeName.length > 0 &&
                    "Employee Name is Required"
                  }
                  value={formDataState.employeeName}
                  size="small"
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      employeeName: e.target.value,
                    })
                  }
                />
              </Col>
              <Col md={6}>
                <CsTextField
                  label="Job Title"
                  required
                  fullWidth
                  value={formDataState.jobTitle}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={
                    denied &&
                    formDataState.jobTitle === "" &&
                    errors.jobTitle.length > 0
                  }
                  helperText={
                    denied &&
                    formDataState.jobTitle === "" &&
                    errors.jobTitle.length > 0 &&
                    "Job Title is Required"
                  }
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      jobTitle: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row className="row_text">
              <Col md={6}>
                <CsTextField
                  label="Street"
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={
                    denied &&
                    formDataState.street === "" &&
                    errors.street.length > 0
                  }
                  helperText={
                    denied &&
                    formDataState.street === "" &&
                    errors.street.length > 0 &&
                    "Street is Required"
                  }
                  value={formDataState.street}
                  size="small"
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      street: e.target.value,
                    })
                  }
                />
              </Col>
              <Col md={6}>
                <Styles>
                  <ReactDatePicker
                    selected={formDataState.dob}
                    style={{ padding: "0px 0px 0px 0px", fontSize: "8px" }}
                    onChange={handleDateChange}
                    popperPlacement="bottom"
                    required={true}
                    placeholderText="dd-mm-yyyy"
                    dateFormat="dd-MMM-yyyy"
                    icon="fa fa-calendar-o"
                    showIcon
                    maxDate={dobYear}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    customInput={
                      <CsTextField
                        label="Date Of Birth"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        size="small"
                        error={
                          doberror
                            ? doberror
                            : denied &&
                            formDataState.dob === "" &&
                            errors.dob.length > 0
                        }
                        helperText={
                          errorDobMessage
                            ? errorDobMessage
                            : denied &&
                            formDataState.dob === "" &&
                            errors.dob.length > 0 &&
                            "Dob is Required"
                        }
                        required
                      />
                    }
                    shouldCloseOnSelect={false}
                  />
                </Styles>
              </Col>
            </Row>
            <Row className="row_text">
              <Col md={6}>
                <CsTextField
                  label="City"
                  required
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formDataState.city}
                  error={
                    denied &&
                    formDataState.city === "" &&
                    errors.city.length > 0
                  }
                  helperText={
                    denied &&
                    formDataState.city === "" &&
                    errors.city.length > 0 &&
                    "City is Required"
                  }
                  onChange={(e) =>
                    setformDataState({ ...formDataState, city: e.target.value })
                  }
                />
              </Col>
              <Col md={6}>
                <Styles>
                  <ReactDatePicker
                    selected={formDataState.dateHired}
                    style={{ padding: "0px 0px 0px 0px", fontSize: "8px" }}
                    onChange={handleDateHeird}
                    popperPlacement="bottom"
                    required={true}
                    dateFormat="dd-MMM-yyyy"
                    icon="fa fa-calendar-o"
                    showIcon
                    maxDate={new Date()}
                    placeholderText="dd-mm-yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    customInput={
                      <CsTextField
                        label="Date Hired"
                        required
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={
                          hirederror
                            ? hirederror
                            : denied &&
                            formDataState.dob === "" &&
                            errors.dob.length > 0
                        }
                        helperText={
                          errorHiredMessage
                            ? errorHiredMessage
                            : denied &&
                            formDataState.dateHired === "" &&
                            errors.dateHired.length > 0 &&
                            "Date Hired is Required"
                        }
                      />
                    }
                    shouldCloseOnSelect={false}
                  />
                </Styles>
              </Col>
            </Row>
            <Row className="row_text">
              <Col md={6}>
                <Autocomplete
                  options={stateLists}
                  getOptionLabel={(option) => option.name}
                  value={formDataState.state}
                  onChange={(event, newValue) => {
                    setformDataState({ ...formDataState, state: newValue });
                  }}
                  renderInput={(params) => (
                    <CsTextField
                      {...params}
                      label="State"
                      variant="outlined"
                      fullWidth
                      size="small"
                      // size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      error={
                        denied &&
                        formDataState.state === null &&
                        errors.state.length > 0
                      }
                      helperText={
                        denied &&
                        formDataState.state === null &&
                        errors.state.length > 0 &&
                        "State is Required"
                      }
                    />
                  )}
                  clearIcon={null}
                />
              </Col>
              <Col md={6}>
                <Autocomplete
                  options={options}
                  value={formDataState.gender}
                  onChange={(event, newValue) => {
                    setformDataState({ ...formDataState, gender: newValue });
                  }}
                  renderInput={(params) => (
                    <CsTextField
                      {...params}
                      label="Gender"
                      variant="outlined"
                      fullWidth
                      size="small"
                      error={
                        denied &&
                        (formDataState.gender === "" ||
                          formDataState.gender === null) &&
                        errors.gender.length > 0
                      }
                      helperText={
                        denied &&
                        (formDataState.gender === "" ||
                          formDataState.gender === null) &&
                        errors.gender.length > 0 &&
                        "Gender is Required"
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  )}
                  clearIcon={null}
                />
              </Col>
            </Row>
            <Row className="row_text">
              <Col md={6}>
                <CsTextField
                  label="Zip"
                  required
                  fullWidth
                  value={formDataState.zip}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={
                    denied && formDataState.zip === "" && errors.zip.length > 0
                  }
                  helperText={
                    denied &&
                    formDataState.zip === "" &&
                    errors.zip.length > 0 &&
                    "Zip is Required"
                  }
                  onChange={handleZipCode}
                />
              </Col>
              <Col md={6}>
                <CsTextField
                  label="Employee ID"
                  required
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 9,
                  }}
                  value={formDataState.empId}
                  onChange={handleEIN}
                  error={
                    denied &&
                    formDataState.empId === "" &&
                    errors.empId.length > 0
                  }
                  helperText={
                    denied &&
                    formDataState.empId === "" &&
                    errors.empId.length > 0 &&
                    "Employee Number is Required"
                  }
                />
              </Col>
            </Row>
            <Row className="row_text">
              <Col md={6}>
                <Autocomplete
                  options={establishmentOption}
                  getOptionLabel={(option) =>
                    option.establishmentName ? option.establishmentName : ""
                  } // Provide a default empty string if establishmentName is undefined
                  value={formDataState.establishment}
                  onChange={(event, newValue) => {
                    setformDataState({
                      ...formDataState,
                      establishment: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <CsTextField
                      {...params}
                      label="Establishment"
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      freeSolo={false}
                      disabled={formDataState.type === "edit"}
                      required
                      error={
                        denied &&
                        (formDataState.establishment === "" ||
                          formDataState.establishment === null) &&
                        errors.establishment.length > 0
                      }
                      helperText={
                        denied &&
                        (formDataState.establishment === "" ||
                          formDataState.establishment === null) &&
                        errors.establishment.length > 0 &&
                        "Establishment type is Required"
                      }
                    />
                  )}
                  clearIcon={null}
                />
              </Col>
            </Row>
            <div style={{ alignSelf: "end", padding: "20px" }}>
              <Button
                variant="contained"
                // disabled={disabled}
                onClick={(e) => handleClick(e)}
              >
                Next
              </Button>
            </div>
          </Card>
        </MainWrapper>
      </MediaQuery>

      {/* -----------------Mobile UI----------------------  */}
      <MediaQuery maxDeviceWidth={1023}>
        {showModal && (
          <WindowMessage
            show={showModal}
            onClose={handleEmptyClose}
            windowMessage={windowPopupMessage}
            handleConfirm={handleConfirmEst}
            keepMounted
            fromNewForm
          />
        )}
        <MainContainer>
          <div className={css.formMain}>
            <div className={css.formHeader}>
              Incident Report-Employee Details
            </div>
            <div className={css.formFieldGroup}>
              <div className={css.importButton}>
                <FormButton
                  disabled={formDataState.type === "edit"}
                  onClick={handleUpload}
                >
                  Import Form 301
                </FormButton>
                {
                  <Modal
                    open={isModalOpen}
                    // onClose={handleCloseModal}
                    keepMounted = {!isModalOpen}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        border: "2px solid #989191",
                        boxShadow: 24,
                        padding:'20px',
                        borderRadius:'10px',
                      }}
                    >
                      <UploadPDF handlersp={handlersp} hide={setIsModalOpen} device={'Mobile'} />
                    </Box>
                  </Modal>
                }
              </div>
              <div className={css.formFields}>
                <label className={css.formFieldLabels}>
                  Employee Name<sup>*</sup>
                </label>
                <div>
                  <MobTextField
                    fullWidth
                    helperText={
                      denied &&
                      formDataState.employeeName === "" &&
                      errors.employeeName.length > 0 &&
                      <div>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        Employee Name is Required
                      </div>
                    }
                    value={formDataState.employeeName}
                    onChange={(e) =>
                      setformDataState({
                        ...formDataState,
                        employeeName: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className={css.formFields}>
                <label className={css.formFieldLabels}>
                  Street<sup>*</sup>
                </label>
                <div>
                  <MobTextField
                    fullWidth
                    helperText={
                      denied &&
                      formDataState.street === "" &&
                      errors.street.length > 0 &&
                      <div>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        Street is Required
                      </div>
                    }
                    value={formDataState.street}
                    size="small"
                    onChange={(e) =>
                      setformDataState({
                        ...formDataState,
                        street: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className={css.formFields}>
                <label className={css.formFieldLabels}>
                  City<sup>*</sup>
                </label>
                <div>
                  <MobTextField
                    value={formDataState.city}
                    helperText={
                      denied &&
                      formDataState.city === "" &&
                      errors.city.length > 0 &&
                      <div>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        City is Required
                      </div>
                    }
                    onChange={(e) =>
                      setformDataState({ ...formDataState, city: e.target.value })
                    }
                    fullWidth />
                </div>
              </div>
              <div className={css.formFields}>
                <label className={css.formFieldLabels}>
                  State<sup>*</sup>
                </label>
                <div>
                  <Autocomplete
                    options={stateLists}
                    getOptionLabel={(option) => option.name}
                    value={formDataState.state}
                    onChange={(event, newValue) => {
                      setformDataState({ ...formDataState, state: newValue });
                    }}
                    renderInput={(params) => (
                      <MobTextField
                        {...params}
                        fullWidth
                        helperText={
                          denied &&
                          formDataState.state === null &&
                          errors.state.length > 0 &&
                          <div>
                            <span className="infoSign">
                              <i class="fa fa-info-circle" aria-hidden="true"></i>
                              &nbsp;
                            </span>
                            State is Required
                          </div>
                        }
                      />
                    )}
                    clearIcon={null}
                  />
                </div>
              </div>
              <div className={css.formFields}>
                <label className={css.formFieldLabels}>
                  Zip<sup>*</sup>
                </label>
                <div>
                  <MobTextField
                    value={formDataState.zip}
                    helperText={
                      denied &&
                      formDataState.zip === "" &&
                      errors.zip.length > 0 &&
                      <div>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        Zip is Required
                      </div>
                    }
                    onChange={handleZipCode}
                    fullWidth />
                </div>
              </div>
              <div className={css.formFields}>
                <label className={css.formFieldLabels}>
                  Establishment<sup>*</sup>
                </label>
                <div>
                  <Autocomplete
                    options={establishmentOption}
                    getOptionLabel={(option) =>
                      option.establishmentName ? option.establishmentName : ""
                    } // Provide a default empty string if establishmentName is undefined
                    value={formDataState.establishment}
                    onChange={(event, newValue) => {
                      setformDataState({
                        ...formDataState,
                        establishment: newValue,
                      });
                    }}
                    renderInput={(params) => (
                      <MobTextField
                        {...params}
                        fullWidth
                        freeSolo={false}
                        disabled={formDataState.type === "edit"}
                        helperText={
                          denied &&
                          (formDataState.establishment === "" ||
                            formDataState.establishment === null) &&
                          errors.establishment.length > 0 &&
                          <div>
                            <span className="infoSign">
                              <i class="fa fa-info-circle" aria-hidden="true"></i>
                              &nbsp;
                            </span>
                            Establishment type is Required
                          </div>
                        }
                      />
                    )}
                    clearIcon={null}
                  />
                </div>
              </div>
              <div className={css.formFields}>
                <label className={css.formFieldLabels}>
                  Job Title<sup>*</sup>
                </label>
                <div>
                  <MobTextField
                    fullWidth
                    value={formDataState.jobTitle}
                    helperText={
                      denied &&
                      formDataState.jobTitle === "" &&
                      errors.jobTitle.length > 0 &&
                      <div>
                      <span className="infoSign">
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                        &nbsp;
                      </span>
                      Job Title is Required
                    </div>
                    }
                    onChange={(e) =>
                      setformDataState({
                        ...formDataState,
                        jobTitle: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className={css.formFields}>
                <label className={css.formFieldLabels}>
                  Date of Birth<sup>*</sup>
                </label>
                <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  fullWidth
                  value={dob}
                  maxDate={dayjs(dobYear)}
                  onChange={handleDateChange}
                  slots={{ textField: (props) => <MobTextField
                  {...props}
                  {...props}
                  InputProps={{
                    endAdornment: (
                      <IconCalendar/>
                    )}}
                    helperText={
                    <div>
                      {errorDobMessage
                            ? <span className="infoSign">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            &nbsp;{errorDobMessage}
                          </span>
                            : denied &&
                            formDataState.dob === "" &&
                            errors.dob.length > 0 &&
                            <span className="infoSign">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            &nbsp;Dob is Required
                          </span>
                        }
                    </div>}
                  /> }}
                  
                />
                </LocalizationProvider>
                </div>
              </div>
              <div className={css.formFields}>
                <label className={css.formFieldLabels}>
                  Date Hired<sup>*</sup>
                </label>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  fullWidth
                  value={dateHired}
                  onChange={handleDateHeird}
                  maxDate={dayjs(new Date())}
                  slots={{ textField:  (props) => 
                  <MobTextField 
                  {...props} 
                  InputProps={{
                    endAdornment: (
                      <IconCalendar/>
                    )}}
                  helperText={<div>
                      {errorHiredMessage
                            ? <span className="infoSign">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            &nbsp;{errorHiredMessage}
                          </span>
                            : denied &&
                            formDataState.dateHired === "" &&
                            errors.dateHired.length > 0 &&
                            <span className="infoSign">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            &nbsp;Date Hired is Required
                          </span>
                        }
                    </div>}
                  
                  /> }}
                  />
                </LocalizationProvider>
                </div>
              </div>
              <div className={css.formFields}>
                <label className={css.formFieldLabels}>
                  Gender<sup>*</sup>
                </label>
                <div>
                  <Autocomplete
                  options={options}
                  value={formDataState.gender}
                  onChange={(event, newValue) => {
                    setformDataState({ ...formDataState, gender: newValue });
                  }}
                  renderInput={(params) => (
                    <MobTextField
                      {...params}
                      fullWidth
                      helperText={
                        denied &&
                        (formDataState.gender === "" ||
                          formDataState.gender === null) &&
                        errors.gender.length > 0 &&
                        <div>
                        <span className="infoSign">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;
                        </span>
                        Gender is Required
                      </div>
                      }
                    />
                  )}
                  clearIcon={null}
                />
                </div>
              </div>
              <div className={css.formFields}>
                <label className={css.formFieldLabels}>
                  Employee ID<sup>*</sup>
                </label>
                <div>
                  <MobTextField 
                  fullWidth
                  inputProps={{
                    maxLength: 9,
                  }}
                  value={formDataState.empId}
                  onChange={handleEIN}
                  helperText={
                    denied &&
                    formDataState.empId === "" &&
                    errors.empId.length > 0 &&
                    <div>
                    <span className="infoSign">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      &nbsp;
                    </span>
                    Employee Number is Required
                  </div>
                  }
                  />
                </div>
              </div>
              <div className={css.formFields}>
                <ProgressBar value={progress} />
              </div>
              <div className={css.formButtons}>
                <FormButton
                  onClick={(e) => handleClick(e)}
                >
                  Next &ensp;
                  <IconChevRight />
                </FormButton>
              </div>
            </div>
          </div>
        </MainContainer>
      </MediaQuery>
    </>
  );
}

export default NewForm;
