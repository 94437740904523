import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@mui/material';
const useStyles = makeStyles((theme) => ({
  customButton: {
    // backgroundColor: '#9747FF !important', // Change background color
    background: `linear-gradient(90deg, #8756D4 -8.33%, #AE88EA 100%)`,
    color: "#FFF !important",
    padding: " 6px 10px",
    fontFamily: "Poppins, sans-serif !important",
    fontSize: "13px !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    textTransform: "none !important",
    // height:"2rem !important",
    "&:active": {
      background: `linear-gradient(90deg, #8756D4 100%, #AE88EA -8.33%)`,
      transitionDuration: "0.1s", // Disable transition on active state
    },
    "&:disabled": {
      background: "grey !important", // Set the background color for disabled state
    },
  },
}));
function FormButton(props) {
    const classes = useStyles();
    return (
      <Button
      size="small"
      style={{height:props.height,width:props.width }}
        className={classes.customButton}
        {...props}
      >
        {props.children}
      </Button>
    )
}

export default FormButton