
import React from 'react'
import Loading from '../../assets/images/loader.gif';
export default function pageLoader(props) {
    return (
        <>
        { props.showLoader?
        <div className="loader-wrapper video-loader">
            <div className="loader-inner">
             
                <img src={Loading}></img>
            </div>
        </div>:""}
        </>
    )
}








