import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

const Button = ({ onClick, className, disabled, children, style,icon  }) => {
  return (
    <button 
      className={styles[className]}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
     {icon && <img src={icon} />}
      {children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object // Accepts an object of inline styles
};

Button.defaultProps = {
  onClick: () => {},
  disabled: false,
  className: '',
  style: {} // Default empty style object
};

export default Button;
