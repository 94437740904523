const getTableStyles = {
    table: {
      style: {
        borderRadius: "10px 10px 0 0",
        overflow: "hidden",
        color: "#ffffff",
      },
    },
    header: {
      style: {
        minHeight: "56px",
        color: "#ffffff",
        wordWrap: 'break-word'
      },
    },
    headRow: {
      style: {
        backgroundColor: "#003E6B",
        fontWeight: 500,

        color: "#ffffff",
      },
    },
    headCells: {
      style: {
        color: "#ffffff",
        fontSize: "14px",
        fontWeight: 500,
        "&:hover": {
          color: "#ffffff",
        },
        borderLeft: "1px solid rgba(255, 255, 255, 0.167)",
        // borderRight:"1px solid ",
      },
      activeSortStyle: {
        color: "#20A6D2",
        "&:hover, &:hover:not(:focus)": {
          color: "#20A6D2",
        },
      },
      inactiveSortStyle: {
        "&:hover": {
          color: "#20A6D2",
        },
      },
    },
    rows: {
      style: {
        borderBottom: "none",
        "&:not(:last-of-type)": {
          borderBottom: "none",
        },
      },
      stripedStyle: {
        backgroundColor: "rgba(32,166,210,0.1)",
      },
    },
    cells: {
      style: {
        paddingTop: "10px",
        paddingBottom: "10px",
        color: "#001B4A",
        border: "none",
      },
    },
    sortFocus: {
      style: {
        color: "#a0a",
      },
    },
    pagination: {
      style: {
        paddingTop: "20px",
        backgroundColor: "transparent",
        border: "none",
        borderColor: `rgba(32,166,210,0.5)`,
      },
      pageButtonsStyle: {
        backgroundColor: "#ffffff",
        marginLeft: "6px",
        borderRadius: "10px",
        border: "1px solid rgba(32,166,210,0.5)",
        borderColor: `rgba(32,166,210,0.5)`,
        color: "#20A6D2",
        fill: "#20A6D2",
      },
    },
  };
  export default getTableStyles;