import React from "react";
import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router";
import {
  IconGeneralInformation,
  IconLogout,
  IconOshaInsider,
  IconProfile,
} from "../../../assets/css/icons";
import ChatBotBtn from "../../ChatBot";
import ChatBot from "../../ChatBot/ChatBot";
import css from "./maincontainer.module.scss";
import NavMob from "./NavMob/NavMob";
import PageLoader from "../../services/pageloader";
import Context from "../../Context";
import { useContext } from "react";
import { Menu, MenuItem } from "@mui/material";
function MainContainer(props) {
  const { formDataState, setformDataState } = useContext(Context);
  const [isChatEnabled, setIsChatEnabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuCss = {
    color: 'var(--majantha, #8756D4)',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16.728px',
    letterSpacing: '0.7px',
  }
  const handleChatBot = () => {
    setIsChatEnabled(!isChatEnabled);
  };
  const handleLoading = () => {
    setLoading(!loading);
  };
  const handleMenuClick = (event) => {
    setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
  };
  const logout = async () => {
    localStorage.removeItem("personal-safety");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    history.push("/");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const history = useHistory();
  const handleUserSettings = () => {
    setformDataState({
      ...formDataState,
      selectedMenu: "user",
    });
    history.push("/profile_settings", {
      fromHomeMoB: true,
    });
  };
  return (
    <>
      {props.isHeader ? (
        <div className={css.contentWrapper}>
          <div className={css.oshaInsiderIconDiv}>
            <IconOshaInsider className={css.oshaInsiderIcon} />
            <div className={css.profileIconDiv} onClick={handleMenuClick}>
              <IconProfile className={css.profileIcon} />
              <Menu
                id="main-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {!props.fromProfileSettings && 
                <MenuItem
                  onClick={() => {
                    handleUserSettings();
                  }}
                  sx={menuCss}
                >
                  <IconGeneralInformation />
                  &nbsp;Profile Settings
                </MenuItem>}
                <MenuItem
                sx={menuCss}
                  onClick={() => {
                    logout();
                  }}
                >
                  <IconLogout/>
                  &nbsp;Logout
                </MenuItem>
              </Menu>
            </div>
          </div>
          <div className={css.mainCardDiv}>
            <div className={css.behindCard}></div>
            {
              <ChatBot
                hide={isChatEnabled}
                setHide={setIsChatEnabled}
                props={props}
              />
            }
            <Card className={css.detailCardDiv}>
              {loading ? <PageLoader showLoader={loading} /> : props.children}
            </Card>
            <NavMob
              className={css.Nav}
              handleChatBot={handleChatBot}
              handleLoading={handleLoading}
            />
          </div>
        </div>
      ) : props.isDrawer ? (
        <div className={css.mainContentWrapper}>
          <div className={css.mainBody}>
            {
              <ChatBot
                hide={isChatEnabled}
                setHide={setIsChatEnabled}
                props={props}
              />
            }
            <Card className={css.mainContentCardDiv}>{props.children}</Card>
          </div>
        </div>
      ) : (
        <div className={css.mainContentWrapper}>
          <div className={css.mainBody}>
            {
              <ChatBot
                hide={isChatEnabled}
                setHide={setIsChatEnabled}
                props={props}
              />
            }
            <Card className={css.mainContentCardDiv}>
              {loading ? <PageLoader showLoader={loading} /> : props.children}
            </Card>
          </div>
          <NavMob
            className={css.Nav}
            handleChatBot={handleChatBot}
            handleLoading={handleLoading}
          />
        </div>
      )}
    </>
  );
}

export default MainContainer;
