import React from "react";
import { Card } from "react-bootstrap";

import "./process_tab.css";
import DataTable from "react-data-table-component";
import getTableStyles from "../../tablestyles";
import { useState } from "react";
import {
  Autocomplete,
  IconButton,
  TextField,
} from "@mui/material";
import { SearchRounded } from "@material-ui/icons";
import ChatBotBtn from "../ChatBot";
import { useEffect } from "react";
import MainWrapper from "../GlobalComponents/MainWrapper";
function ExtractedData(props) {
  const [selectedValue, setSelectedValue] = useState({
    value: "300",
    label: "300",
  });
  useEffect(()=>{
      setSearchYearText("")
      setSearchEstText("")
      setFilteredData300(props.location.state.data300);
      setFilteredData300a(props.location.state.data300a);
    
  },[selectedValue])
  const [searchYearText, setSearchYearText] = useState("");
  const [searchEstText, setSearchEstText] = useState("");
  const [filteredData300, setFilteredData300] = useState(props.location.state.data300);
  const [filteredData300a, setFilteredData300a] = useState(props.location.state.data300a);

  const filterOptions = [
    { value: "300", label: "300" },
    { value: "301", label: "301" },
    { value: "300a", label: "300a" },
  ];
  const WrappedCell = (children, width) => (
    <div style={{ maxWidth: width, wordWrap: "break-word" }}>{children}</div>
  );
  const WrappedHeader = (children, width) => (
    <div
      style={{ maxWidth: width, wordWrap: "break-word", textAlign: "center" }}
    >
      {children}
    </div>
  );
  const handleYearSearch = () => {
    const data300 = props.location.state.data300;
    const data300a = props.location.state.data300a;
    const newData =
      selectedValue.value === "300a"
        ? data300a
        : selectedValue.value === "300"
        ? data300
        : [];
    const filteredItem = newData.filter((item) => {
      const columns =
        selectedValue.value === "300a"
          ? columns300a
          : selectedValue.value === "300"
          ? columns300
          : [];
      return columns.some((column) => {
        if (column.customFilter) {
          return column.customFilter(searchYearText, item);
        }
      });
    });
    if(selectedValue.value === "300"){
      setFilteredData300(filteredItem);
    }
    else if(selectedValue.value === "300a"){
      
      setFilteredData300a(filteredItem);
    }
  };
  const handleEstSearch = () => {
    const data300 = props.location.state.data300;
    const data300a = props.location.state.data300a;
    const newData =
      selectedValue.value === "300a"
        ? data300a
        : selectedValue.value === "300"
        ? data300
        : [];
    const filteredItem = newData.filter((item) => {
      const columns =
        selectedValue.value === "300a"
          ? columns300a
          : selectedValue.value === "300"
          ? columns300
          : [];
      return columns.some((column) => {
        if (column.customFilter) {
          return column.customFilter(searchEstText, item);
        }
      });
    });
    if(selectedValue.value === "300"){
      setFilteredData300(filteredItem);
    }
    else if(selectedValue.value === "300a"){
      setFilteredData300a(filteredItem);
    }
  };
 
  const columns300 = [
    {
      name: WrappedHeader("Serial No.", "110px"),
      selector: (row, index) => index + 1,
      width: "110px",
      center: true,
      sortable: true,
    },

    {
      name: WrappedHeader("Employee Name", "150px"),
      selector: (row) => row.EmployeeName,
      width: "150px",
      center: true,
      sortable: true,
    },

    {
      name: "Job Title",
      selector: (row) => row.JobTitle,
      width: "250px",
      center: true,
      sortable: true,
    },
    {
      name: "Incident Date",
      selector: (row) => row.InjuryDate,
      width: "150px",
      center: true,
      sortable: true,
      customFilter: (filterValue, row) =>
      row.Year?.toString().toLowerCase().includes(filterValue?.toLowerCase()),
    },
    {
      name: "Event Location",
      selector: (row) => row.EventLocation,
      width: "200px",
      center: true,
      sortable: true,
    },
    {
      name: WrappedHeader("Incident Category", "150px"),
      selector: (row) => row.IllnessClassification,
      width: "150px",
      center: true,
      sortable: true,
    },
    {
      name: "Incident Summary",
      selector: (row) => row.InjuryDescription,
      width: "170px",
      center: true,
      sortable: true,
    },
    {
      name: WrappedHeader("Incident Outcome", "150px"),
      selector: (row) => row.CaseClassification,
      width: "150px",
      center: true,
      sortable: true,
    },
  ];

  const columns301 = [
    {
      name: WrappedHeader("Serial No.", "110px"),
      selector: (row, index) => index + 1,
      width: "110px",
      center: true,
      sortable: true,
    },

    {
      name: "Employee Name",
      selector: (row) => row.Full_Name,
      width: "170px",
      center: true,
      sortable: true,
    },

    {
      name: "Incident Date",
      selector: (row) => row.Date_of_injury_or_illness,
      width: "150px",
      center: true,
      sortable: true,
    },
    {
      name: "Incident Time",
      selector: (row) => row.Time_of_event,
      width: "250px",
      center: true,
      sortable: true,
    },
    {
      name: "Incident Summary",
      selector: (row) => row.What_was_the_injury_or_illness_,
      width: "350px",
      center: true,
      sortable: true,
    },
  ];

  const columns300a = [
    {
      name: WrappedHeader("Serial No.", "110px"),
      selector: (row, index) => index + 1,
      width: "110px",
      center: true,
      sortable: true,
    },

    {
      name: "Establishment Name",
      selector: (row) => row.Establishment_Name,
      width: "200px",
      center: true,
      sortable: true,
      cell: (row) => WrappedCell(row.Establishment_Name, "170px"),
      customFilter: (filterValue, row) =>
        row.Establishment_Name?.toLowerCase().includes(filterValue.toLowerCase())
    },

    {
      name: WrappedHeader("Industry Description", "180px"),
      selector: (row) => row.Industry_description,
      width: "180px",
      cell: (row) => WrappedCell(row.Industry_description, "170px"),
      center: true,
      sortable: true,
    },
    {
      name: "Number Of Employees",
      selector: (row) => row.Annual_average_number_of_employees,
      width: "200px",
      center: true,
      sortable: true,
    },
    {
      name: "Total Working Hours",
      selector: (row) => row.Total_hours_worked_by_all_employees_last_year,
      width: "200px",
      center: true,
      sortable: true,
    },
    {
      name: "Year",
      selector: (row) => row.Year,
      width: "80px",
      center: true,
      sortable: true,
      customFilter: (filterValue, row) =>
      row.Year?.toString().toLowerCase().includes(filterValue?.toLowerCase()),
    },
    {
      name: WrappedHeader("Total Number Of Days Away From Work", "200px"),
      selector: (row) => row.Total_number_of_days_away_from_work,
      width: "200px",
      center: true,
      sortable: true,
    },
    {
      name: WrappedHeader("Total Number Of Deaths", "200px"),
      selector: (row) => row.Total_number_of_deaths,
      width: "200px",
      center: true,
      sortable: true,
    },
  ];

  return (
    <>
      <MainWrapper>
        <Card>
          <Card.Header className="cardHeader">
            <div className="establishmentText">Extracted Data</div>
            <div style={{ display: "flex", gap: "5px" }}>
              <div>
                <Autocomplete
                  options={filterOptions}
                  getOptionLabel={(option) => option.label}
                  value={selectedValue}
                  onChange={(event, newValue) => {
                    setSelectedValue(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Form"
                      variant="outlined"
                      fullWidth
                      size="small"
                      style={{ width: "150px" }}
                    />
                  )}
                  clearIcon={null}
                />
              </div>
             {selectedValue.value !== "301" && ( <div>
                <TextField
                  label="Year"
                  variant="outlined"
                  fullWidth
                  value={searchYearText}
                  size="small"
                  style={{ width: "150px" }}
                  onChange={(e) => setSearchYearText(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleYearSearch} edge="end">
                        <SearchRounded />
                      </IconButton>
                    ),
                  }}
                />
              </div>)}
              {selectedValue.value === "300a" && 
                (
                  <div>
                <TextField
                  label="Establishment"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={searchEstText}
                  onChange={(e) => setSearchEstText(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleEstSearch} edge="end">
                        <SearchRounded />
                      </IconButton>
                    ),
                  }}
                />
              </div>
                )
            }
            </div>
          </Card.Header>
          <Card.Body>
            {selectedValue.value === "300" ? (
              <DataTable
                data={filteredData300}
                columns={columns300}
                customStyles={getTableStyles}
                striped
                pagination
                paginationPerPage={10}
              />
            ) : selectedValue.value === "301" ? (
              <DataTable
                data={props.location.state.data301}
                columns={columns301}
                customStyles={getTableStyles}
                striped
                pagination
                paginationPerPage={10}
              />
            ) : selectedValue.value === "300a" ? (
              <DataTable
                data={filteredData300a}
                columns={columns300a}
                customStyles={getTableStyles}
                striped
                pagination
                paginationPerPage={10}
              />
            ) : (
              <DataTable
                data={props.location.state.data300}
                columns={columns300}
                customStyles={getTableStyles}
                striped
                pagination
                paginationPerPage={10}
              />
            )}
          </Card.Body>
          <Card.Footer className="ioCardFooter"></Card.Footer>
        </Card>
      </MainWrapper>
      <ChatBotBtn fromExtractedData />
    </>
  );
}

export default ExtractedData;
