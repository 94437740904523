import React, { useState, useEffect, useContext } from "react";
import { Form, Card, Row, Col } from "react-bootstrap";
import "./NewForm.css";
import NavHome from "../Nav/navHome";
import {
  Select,
  Button,
  Autocomplete,
  IconButton,
  Tooltip,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { categoryList, SummaryFetch } from "../services/api-servics";
import { makeStyles } from "@material-ui/core/styles";
import { Snackbar, Alert } from "@mui/material";
import Context from "../Context";
import { showToaster } from "../services/helper";
import CsTextField from "../GlobalComponents/CsTextField";
import MainWrapper from "../GlobalComponents/MainWrapper";
import MediaQuery from "react-responsive";
import MainContainer from "../MobileUI/GlobalComponents/MainContainer";
import FormButton from "../MobileUI/GlobalComponents/FormButton";
import MobTextField from "../MobileUI/GlobalComponents/MobTextField";
import ButtonOutline from "../MobileUI/GlobalComponents/ButtonOutline";
import css from "./incidentDetails.module.scss";
import ProgressBar from "../MobileUI/GlobalComponents/ProgressBar";
import { IconChevLeft, IconChevRight, IconSummary } from "../../assets/css/icons";
const useStyles = makeStyles((theme) => ({
  blinkText: {
    animation: '$blinkAnimation 1s infinite',
  },
  '@keyframes blinkAnimation': {
    '0%': { opacity: 0.5 },
    '25%': { opacity: 0.5 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0.5 },
  },
}));
function Description(props) {
  const classes = useStyles();
  const [categoryOption, setcategoryOption] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [empty,setEmpty] = useState(false)
  const [isHovered, setIsHovered] = useState(false);
  const { formDataState, setformDataState } = useContext(Context);
  const [denied,setDenied] = useState(false);
  const [loader,setLoader] = useState(false);
  const screenSize = window.screen.width
  const [errors, setErrors] = useState({
    incident: "",
    injurySummary: "",
    injuryDetails: "",
    object: "",
    preIncidentActivity: "",
    reason: "",
    Physician: "",
    state: "",
    location: "",
    zip: "",
    street: "",
    emergencyRoomOption: "",
    city: "",
    hospitalizedOption: "",
  });
  const [progress, setProgress] = useState(66);
  useEffect(() => {
      const interval = 10; // Update interval in milliseconds
      const duration = 400; // Total duration in milliseconds
      const steps = duration / interval;
      let currentStep = 0;
  
      const progressIncrement = 100 / steps;
  
      const progressInterval = setInterval(() => {
        const currentProgress = Math.min(
          progressIncrement * (currentStep + 1),
          100
        );
  
        setProgress(currentProgress);
        currentStep++;
        if (currentStep >= steps) {
          clearInterval(progressInterval);
        }
      }, interval);
  
      // Cleanup function to clear the interval
      return () => clearInterval(progressInterval);
    }, []);

  useEffect(() => {
    getcategoryList();
  }, []);
  useEffect(() => {
    const truthy = !(
      formDataState.injuryDetails.trim() ||
      formDataState.preIncidentActivity.trim() ||
      formDataState.reason.trim() ||
      formDataState.object.trim()
    )
    if(truthy){
        setEmpty(true);
      }
      else if(truthy=== false){
        setEmpty(false);
      }
  }, [formDataState.injuryDetails ||
    formDataState.preIncidentActivity||
    formDataState.reason||
    formDataState.object]);

  const getcategoryList = (e) => {
    categoryList({}).then(async (rsp) => {
      if (rsp.data.data) {
        const categoryOptions = rsp.data.data.map((s) => s);
        setcategoryOption(categoryOptions);
      }
    });
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleZipCode = (event) => {
    const inputValue = event.target.value.replace(/\D/g, "").slice(0, 5);
    setformDataState({ ...formDataState, zipInfo: inputValue });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Validate Incident
    if (!formDataState.incident) {
      newErrors.incident = "Select any incident from list";
      setSnackbarMessage("Select any incident from list");
      isValid = false;
    } else {
      newErrors.incident = "";
    }

    // Validate Injury Summary
    if (!formDataState.injurySummary) {
      newErrors.injurySummary = "Summarize The Injury Or Illness is required";
      setSnackbarMessage("Summarize The Injury Or Illness is required");
      isValid = false;
    } else {
      newErrors.injurySummary = "";
    }

    // Validate Injury Details
    if (!formDataState.injuryDetails) {
      newErrors.injuryDetails = "Detail How The Injury Or Illness is required";
      setSnackbarMessage("Detail How The Injury Or Illness is required");
      isValid = false;
    } else {
      newErrors.injuryDetails = "";
    }

    // Validate Object
    if (!formDataState.object) {
      newErrors.object = "Specify The Harming Object Or Substance is required";
      setSnackbarMessage("Specify The Harming Object Or Substance is required");
      isValid = false;
    } else {
      newErrors.object = "";
    }

    // Validate Pre-Incident Activity
    if (!formDataState.preIncidentActivity) {
      newErrors.preIncidentActivity =
        "Employee Pre-Incident Activity is required";
      setSnackbarMessage("Employee Pre-Incident Activity is required");
      isValid = false;
    } else {
      newErrors.preIncidentActivity = "";
    }

    // Validate Reason
    if (!formDataState.reason) {
      newErrors.reason =
        "Affected Body Part And How It Was Affected is required";
      setSnackbarMessage(
        "Affected Body Part And How It Was Affected is required"
      );
      isValid = false;
    } else {
      newErrors.reason = "";
    }

    setErrors(newErrors);

    if (!isValid) {
      setSnackbarOpen(true);
    }

    return isValid;
  };

  const history = useHistory();
  const handleClick = () => {
    setDenied(true)
    if (validateForm()) {
      setDenied(false)
      let path = "/submit_page";
      history.push(path, { formDataState: formDataState });
    }
  };

  // let path = "/new-form/incident_details";
  const handleBackClick = () => {
    window.history.back();
  };
  const summaryData = formDataState.injuryDetails+". "+formDataState.preIncidentActivity+". "+formDataState.reason+". "+formDataState.object+". "
  const handleFetchSummary = () => {
    if(empty){
      screenSize > 1023 && showToaster("Fill the any one the above fields","warning")
    }
    else{
    setLoader(true)
    SummaryFetch({content:summaryData}).then(async (rsp) => {
    setLoader(false)
     if(rsp){
      const summary=rsp.data.summary
      setformDataState({...formDataState,injurySummary:summary})
     }
    });
  }
  }
  const Options = [
    { label: "Yes", value: "Y" },
    { label: "No", value: "N" },
  ];
  return (
    <>
      <MediaQuery minDeviceWidth={1024}>
        <MainWrapper>
          <Card>
            <Card.Header className="cardHeader">
              <div className="managerText">
                Incident Report-Injury/Illness Description *
              </div>
            </Card.Header>
            <Row className="row_text">
              <Col md={6}>
                <Autocomplete
                  options={categoryOption}
                  //  getOptionLabel={(option) => option.label}
                  value={formDataState.incident}
                  onChange={(event, newValue) => {
                    setformDataState({ ...formDataState, incident: newValue });
                  }}
                  renderInput={(params) => (
                    <CsTextField
                      {...params}
                      label="1. Is The Incident Any Of The Following: (Pick One)"
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      disabled={formDataState.type === "edit"}
                      error={
                        denied &&
                        formDataState.incident === "" &&
                        errors.incident.length > 0
                      }
                      helperText={
                        denied &&
                        formDataState.incident === "" &&
                        errors.incident.length > 0 &&
                        "Select any incident from list"
                      }
                    />
                  )}
                  clearIcon={null}
                  disabled={formDataState.type === "edit"}
                />
              </Col>
              <Col md={6}>
                <CsTextField
                  label="4. Affected Body Part And How It Was Affected"
                  required
                  className={isHovered && empty && classes.blinkText}
                  // error={isHovered && empty}
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formDataState.reason}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      reason: e.target.value,
                    })
                  }
                  error={
                    isHovered
                      ? isHovered && empty
                      : denied &&
                        formDataState.reason === "" &&
                        errors.reason.length > 0
                  }
                  helperText={
                    denied &&
                    formDataState.reason === "" &&
                    errors.reason.length > 0 &&
                    "Affected Body Part And How It Was Affected is required"
                  }
                />
              </Col>
            </Row>
            <Row className="row_text">
              <Col md={6}>
                <CsTextField
                  className={isHovered && empty && classes.blinkText}
                  // error={isHovered && empty }
                  label="2. Detail How The Injury Or Illness Occurred"
                  required
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formDataState.injuryDetails}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      injuryDetails: e.target.value,
                    })
                  }
                  error={
                    isHovered
                      ? isHovered && empty
                      : denied &&
                        formDataState.injuryDetails === "" &&
                        errors.injuryDetails.length > 0
                  }
                  helperText={
                    denied &&
                    formDataState.injuryDetails === "" &&
                    errors.injuryDetails.length > 0 &&
                    "Detail How The Injury Or Illness is required"
                  }
                />
              </Col>
              <Col md={6}>
                <CsTextField
                  label="5. Specify The Harming Object Or Substance"
                  required
                  className={isHovered && empty && classes.blinkText}
                  // error={isHovered && empty }
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formDataState.object}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      object: e.target.value,
                    })
                  }
                  error={
                    isHovered
                      ? isHovered && empty
                      : denied &&
                        formDataState.object === "" &&
                        errors.object.length > 0
                  }
                  helperText={
                    denied &&
                    formDataState.object === "" &&
                    errors.object.length > 0 &&
                    "Specify The Harming Object Or Substance is required"
                  }
                />
              </Col>
            </Row>
            <Row className="row_text">
              <Col md={6}>
                <CsTextField
                  className={isHovered && empty && classes.blinkText}
                  label="3. Employee Pre-Incident Activity"
                  required
                  // error={isHovered && empty }
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formDataState.preIncidentActivity}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      preIncidentActivity: e.target.value,
                    })
                  }
                  error={
                    isHovered
                      ? isHovered && empty
                      : denied &&
                        formDataState.preIncidentActivity === "" &&
                        errors.preIncidentActivity.length > 0
                  }
                  helperText={
                    denied &&
                    formDataState.preIncidentActivity === "" &&
                    errors.preIncidentActivity.length > 0 &&
                    "Employee Pre-Incident Activity is required"
                  }
                />
              </Col>
              <Col md={6} className="summaryDescript">
                <CsTextField
                  label="6. Summarize The Injury Or Illness"
                  required
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 500,
                  }}
                  value={formDataState.injurySummary}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      injurySummary: e.target.value,
                    })
                  }
                  error={
                    denied &&
                    formDataState.injurySummary === "" &&
                    errors.injurySummary.length > 0
                  }
                  helperText={
                    denied &&
                    formDataState.injurySummary === "" &&
                    errors.injurySummary.length > 0 &&
                    "Summarize The Injury Or Illness is required"
                  }
                />
                {empty ? (
                  <Tooltip
                    title="fill any of the above fields"
                    arrow
                    placement="top"
                  >
                    <IconButton
                      color="primary"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={handleFetchSummary}
                    >
                      <i class="fa fa-book" aria-hidden="true"></i>
                    </IconButton>
                  </Tooltip>
                ) : (
                  <IconButton color="primary" onClick={handleFetchSummary}>
                    <i class="fa fa-book" aria-hidden="true"></i>
                  </IconButton>
                )}
              </Col>
            </Row>
            <Row className="row_text">
              <div>Treatment Details (optional)</div>
            </Row>
            <Row className="row_text">
              <Col md={6}>
                <CsTextField
                  className="text_area"
                  label="Name Of The Physician Or Other Health Professional"
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formDataState.Physician}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      Physician: e.target.value,
                    })
                  }
                />
              </Col>
              <Col md={6}>
                <CsTextField
                  label="State"
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formDataState.stateInfo}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      stateInfo: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row className="row_text">
              <Col md={6}>
                <CsTextField
                  className="text_area"
                  label="Off-Site Treatement Location: Specify Facility Briefly"
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formDataState.location}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      location: e.target.value,
                    })
                  }
                />
              </Col>
              <Col md={6}>
                <CsTextField
                  label="Zip"
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formDataState.zipInfo}
                  onChange={handleZipCode}
                />
              </Col>
            </Row>
            <Row className="row_text">
              <Col md={6}>
                <CsTextField
                  className="text_area"
                  label="Street"
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formDataState.streetInfo}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      streetInfo: e.target.value,
                    })
                  }
                />
              </Col>
              <Col md={6}>
                <Autocomplete
                  options={Options}
                  //  getOptionLabel={(option) => option.label}
                  value={formDataState.emergencyRoomOption}
                  onChange={(event, newValue) => {
                    setformDataState({
                      ...formDataState,
                      emergencyRoomOption: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <CsTextField
                      {...params}
                      label="Was The Employee Treated In An Emergency Room"
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  clearIcon={null}
                  disabled={formDataState.type === "edit"}
                />
              </Col>
            </Row>
            <Row className="row_text">
              <Col md={6}>
                <CsTextField
                  className="text_area"
                  label="City"
                  size="small"
                  fullWidth
                  value={formDataState.cityInfo}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      cityInfo: e.target.value,
                    })
                  }
                />
              </Col>
              <Col md={6}>
                <Autocomplete
                  options={Options}
                  //  getOptionLabel={(option) => option.label}
                  value={formDataState.hospitalizedOption}
                  onChange={(event, newValue) => {
                    setformDataState({
                      ...formDataState,
                      hospitalizedOption: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <CsTextField
                      {...params}
                      label="Was The Employee Hospitalized Overnight As An In-Patient"
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  clearIcon={null}
                  disabled={formDataState.type === "edit"}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    margin: "40px",
                  }}
                >
                  <Button variant="contained" onClick={handleBackClick}>
                    Back
                  </Button>
                </div>
              </Col>
              <Col md={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "40px",
                  }}
                >
                  <Button variant="contained" onClick={handleClick}>
                    Next
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </MainWrapper>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={1023}>
        <MainContainer>
          <div className={css.incidentMain}>
            <div className={css.incidentHeader}>
            Incident Report-Injury/Illness Description
            </div>
            <div className={css.incidentFieldGroup}>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                1.Is the Incident any of the following:(Pick one)<sup>*</sup>
                </label>
                <div>
                <Autocomplete
                  options={categoryOption}
                  //  getOptionLabel={(option) => option.label}
                  value={formDataState.incident}
                  onChange={(event, newValue) => {
                    setformDataState({ ...formDataState, incident: newValue });
                  }}
                  renderInput={(params) => (
                    <MobTextField
                      {...params}
                      fullWidth
                      disabled={formDataState.type === "edit"}
                      helperText={
                        denied &&
                        formDataState.incident === "" &&
                        errors.incident.length > 0 &&
                        <div>
                        <span className="infoSign">
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                          ></i>
                          &nbsp;
                        </span>
                        Select any incident from list
                      </div>
                      }
                    />
                  )}
                  clearIcon={null}
                  disabled={formDataState.type === "edit"}
                />
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                2.Detail how the Injury or Illness Occurred?<sup>*</sup>
                </label>
                <div>
                <MobTextField
                className={isHovered && empty && classes.blinkText}
                  fullWidth
                  value={formDataState.injuryDetails}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      injuryDetails: e.target.value,
                    })
                  }
                  helperText={
                    denied &&
                    formDataState.injuryDetails === "" &&
                    errors.injuryDetails.length > 0 &&
                    <div>
                        <span className="infoSign">
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                          ></i>
                          &nbsp;
                        </span>
                        Detail How The Injury Or Illness is required
                      </div>
                  }
                />
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                3.Employee Pre-incident Activity<sup>*</sup>
                </label>
                <div>
                <MobTextField
                className={isHovered && empty && classes.blinkText}
                  fullWidth
                  value={formDataState.preIncidentActivity}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      preIncidentActivity: e.target.value,
                    })
                  }
                  helperText={
                    denied &&
                    formDataState.preIncidentActivity === "" &&
                    errors.preIncidentActivity.length > 0 &&
                    <div>
                    <span className="infoSign">
                      <i
                        class="fa fa-info-circle"
                        aria-hidden="true"
                      ></i>
                      &nbsp;
                    </span>
                    Employee Pre-Incident Activity is required
                  </div>
                  }
                />
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                4.Affected Body part and how it was affected?<sup>*</sup>
                </label>
                <div>
                <MobTextField
                className={isHovered && empty && classes.blinkText}
                  fullWidth
                  value={formDataState.reason}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      reason: e.target.value,
                    })
                  }
                  helperText={
                    denied &&
                    formDataState.reason === "" &&
                    errors.reason.length > 0 &&
                    <div>
                    <span className="infoSign">
                      <i
                        class="fa fa-info-circle"
                        aria-hidden="true"
                      ></i>
                      &nbsp;
                    </span>
                    Affected Body Part And How It Was Affected is required
                  </div>
                  }
                />
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                5.Specify the Harming Object or Substance?<sup>*</sup>
                </label>
                <div>
                <MobTextField
                className={isHovered && empty && classes.blinkText}
                  fullWidth
                  value={formDataState.object}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      object: e.target.value,
                    })
                  }
                  helperText={
                    denied &&
                    formDataState.object === "" &&
                    errors.object.length > 0 &&
                    <div>
                    <span className="infoSign">
                      <i
                        class="fa fa-info-circle"
                        aria-hidden="true"
                      ></i>
                      &nbsp;
                    </span>
                    Specify The Harming Object Or Substance is required
                  </div>
                  }
                />
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                6.Summarize the Injury or Illness<sup>*</sup>
                </label>
                <div>
                <MobTextField
                  fullWidth
                  inputProps={{
                    maxLength: 500,
                  }}
                  value={formDataState.injurySummary}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      injurySummary: e.target.value,
                    })
                  }
                  helperText={
                    denied &&
                    formDataState.injurySummary === "" &&
                    errors.injurySummary.length > 0 &&
                    <div>
                    <span className="infoSign">
                      <i
                        class="fa fa-info-circle"
                        aria-hidden="true"
                      ></i>
                      &nbsp;
                    </span>
                    Summarize The Injury Or Illness is required
                  </div>
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {empty ? (
                  <Tooltip
                    title="fill any of the above fields"
                    arrow
                    placement="top"
                  >
                    <IconSummary
                      color="primary"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={handleFetchSummary}
                    />
                  </Tooltip>
                ) : (
                  !loader ? <IconSummary color="primary" onClick={handleFetchSummary}/> :
                  <CircularProgress style={{ color: '#8756d4' }} size={20} />
                )}
                      </InputAdornment>
                    ),
                  }}
                />
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.descriptionOptionalLabels}>
                Treatment Details (Optional)
                </label>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                Name of the Physician or Health Professional
                </label>
                <div>
                <MobTextField
                  fullWidth
                  value={formDataState.Physician}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      Physician: e.target.value,
                    })
                  }
                />
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                Off-Site Treatment Location: Specify Facility Briefly
                </label>
                <div>
                <MobTextField
                  fullWidth
                  value={formDataState.location}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      location: e.target.value,
                    })
                  }
                />
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                Street
                </label>
                <div>
                <MobTextField
                  fullWidth
                  value={formDataState.streetInfo}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      streetInfo: e.target.value,
                    })
                  }
                />
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                City
                </label>
                <div>
                <MobTextField
                  fullWidth
                  value={formDataState.cityInfo}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      cityInfo: e.target.value,
                    })
                  }
                />
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                State
                </label>
                <div>
                <MobTextField
                  fullWidth
                  value={formDataState.stateInfo}
                  onChange={(e) =>
                    setformDataState({
                      ...formDataState,
                      stateInfo: e.target.value,
                    })
                  }
                />
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                Zip
                </label>
                <div>
                <MobTextField
                  fullWidth
                  value={formDataState.zipInfo}
                  onChange={handleZipCode}
                />
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                Was the Employee treated in an Emergency Room
                </label>
                <div>
                <Autocomplete
                  options={Options}
                  value={formDataState.emergencyRoomOption}
                  onChange={(event, newValue) => {
                    setformDataState({
                      ...formDataState,
                      emergencyRoomOption: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <MobTextField
                      {...params}
                      fullWidth
                    />
                  )}
                  clearIcon={null}
                  disabled={formDataState.type === "edit"}
                />
                </div>
              </div>
              <div className={css.incidentFields}>
                <label className={css.incidentFieldLabels}>
                Was the Employee Hospitalized overnight as an In-Patient
                </label>
                <div>
                <Autocomplete
                  options={Options}
                  value={formDataState.hospitalizedOption}
                  onChange={(event, newValue) => {
                    setformDataState({
                      ...formDataState,
                      hospitalizedOption: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <MobTextField
                      {...params}
                      fullWidth
                    />
                  )}
                  clearIcon={null}
                  disabled={formDataState.type === "edit"}
                />
                </div>
              </div>
              <div className={css.incidentFields}>
                <ProgressBar value={progress} />
              </div>
              <div className={css.incidentButtons}>
                <ButtonOutline
                  onClick={handleBackClick}
                  disabled={props.location.state.frormConfirm}
                >
                  <IconChevLeft /> &ensp;Previous
                </ButtonOutline>
                <FormButton onClick={handleClick}>
                  Next &ensp;
                  <IconChevRight />
                </FormButton>
              </div>
            </div>
          </div>
        </MainContainer>
      </MediaQuery>
    </>
  );
}
export default Description;
