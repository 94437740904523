import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import "./Establishment.css";
import NavHome from "../../Nav/navHome";
import getTableStyles from "../../../tablestyles";
import DataTable from "react-data-table-component";
import {
  Button,
  CircularProgress,
  Box
} from "@mui/material";
import { useHistory } from "react-router";
import {
  EstablishmentList,
  EditEstablishment,
  DeleteEstablishment,
} from "../../services/api-servics";
import { useState } from "react";
import WindowMessage from "../../GlobalComponents/WindowMessage";
import PageLoader from "../../services/pageloader";
import MainWrapper from "../../GlobalComponents/MainWrapper";
import MediaQuery from "react-responsive";
import ManageEstablishment from "../SettingsMenu/ManageEstablishment";
function Establishment() {
  const [esData, setEsData] = useState([]);
  const [refreshTable, setRefreshtable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const role = localStorage.getItem("role");
  useEffect(() => {
    getEstablishmentList();
  }, []);

  useEffect(() => {
    getEstablishmentList();
    setRefreshtable(false);
  }, [refreshTable]);

  // const useStyles = makeStyles((theme) => ({
  //   modal: {},
  //   paper: {
  //     backgroundColor: theme.palette.background.paper,
  //     boxShadow: theme.shadows[5],
  //     padding: theme.spacing(2, 4, 3),
  //   },
  // }));

  const getEstablishmentList = (e) => {
    setLoading(true);
    EstablishmentList({}).then(async (rsp) => {
      if (rsp.data.data) {
        const estData = rsp.data.data;
        setEsData(estData);
      }
      setLoading(false);
    });
  };
  const editEstablishmentList = (e) => {
    EditEstablishment({ establishmentID: e.establishmentID }).then(
      async (rsp) => {
        if (rsp.data.data) {
          const data = rsp.data.data;
          let path = "/edit_establishment";
          history.push({
            pathname: path,
            state: {
              estName: data.establishment_name,
              emailId: data.email_info,
              street: data.street_name,
              city: data.city_name,
              estCompany: data.company_name,
              zipCode: data.zip_info,
              industry: data.industry_info,
              einNo: data.EIN_no,
              naicsCode: data.naics_info,
              estType: data.establishment_type,
              estStateCode: data.state_code,
              estStateLabel: data.state_label,
              establishmentID: e.establishmentID,
              editEst: "true",
            },
          });
        }
      }
    );
  };
  const delEstablishment = (e) => {
    DeleteEstablishment({ establishmentID: e.establishmentID }).then(
      async (rsp) => {
        // let path = "/view_establishment";
        //   history.push({
        //     pathname:path
        //   })
        setRefreshtable(true);
      }
    );
  };
  const history = useHistory();
  const WrappedCell = (children, width) => (
    <div style={{ maxWidth: width, wordWrap: "break-word" }}>{children}</div>
  );
  const handleyear = (item) => {
    let path = "/yearly_details";
    history.push({
      pathname: path,
      state: item,
    });
  };
  const handleEdit = (item) => {
    editEstablishmentList(item);
  };
  const handleDelete = (item) => {
    setItemIdToDelete(item);
    setShowModal(true);
  };
  const handleConfirm = () => {
    if (itemIdToDelete) {
      delEstablishment(itemIdToDelete);
    }
    handleClose();
  };

  const handleClose = () => {
    setItemIdToDelete(null);
    setShowModal(false);
  };

  const addActionButtons = (item) => {
    return {
      ...item,
      yearlyDetails: (
        <Button
          onClick={() => handleyear(item)}
          variant="contained"
          size="small"
        >
          Yearly Details
        </Button>
      ),
      edit: (
        <Button
          onClick={() => handleEdit(item)}
          variant="contained"
          size="small"
        >
          Edit
        </Button>
      ),
      delete: (
        <Button
          onClick={() => handleDelete(item)}
          variant="contained"
          size="small"
        >
          Delete
        </Button>
      ),
    };
  };
  const newData = esData.map(addActionButtons);

  const columns = [
    {
      name: "Establishment ID",
      selector: (row) => row.establishmentID,
      width: "150px",
      style: {
        display: "flex",
        justifyContent: "center",
      },
    },

    {
      name: "Establishment Name",
      selector: (row) => row.establishmentName,
      //   width: "170px",
    },

    {
      name: "NAICS",
      selector: (row) => row.naics,
      width: "150px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "250px",
    },
    {
      name: "Yearly Details",
      selector: (row) => row.yearlyDetails,
      width: "200px",
      style: {
        display: "flex",
        justifyContent: "center",
      },
    },
    {
      name: "Edit",
      selector: (row) => row.edit,
      width: "120px",
      style: {
        display: "flex",
        justifyContent: "center",
      },
    },
    {
      name: "Delete",
      selector: (row) => row.delete,
      width: "120px",
      style: {
        display: "flex",
        justifyContent: "center",
      },
    },
  ];
  const columns1 = [
    {
      name: "Establishment ID",
      selector: (row) => row.establishmentID,
      width: "150px",
      style: {
        display: "flex",
        justifyContent: "center",
      },
    },

    {
      name: "Establishment Name",
      selector: (row) => row.establishmentName,
      //   width: "170px",
    },

    {
      name: "NAICS",
      selector: (row) => row.naics,
      width: "150px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "250px",
    },
  ];

  const handleAdd = () => {
    let path = "/add_establishment";
    history.push({
      pathname: path,
    });
  };

  return (
    <>
      <WindowMessage
      show={showModal}
      handleClose={handleClose}
      windowMessage="Establishment"
      handleConfirm={handleConfirm}
      />
      <MediaQuery minDeviceWidth={1024}>
      <MainWrapper>
        {/* {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box> */}
           {loading ? (<PageLoader showLoader={loading} />
        ) : (
          <Card>
            <Card.Header className="cardHeader">
              <div className="establishmentText">Establishment Details</div>

              {role!=="admin" && 
              (<Button onClick={handleAdd} variant="contained">
                Add
              </Button>)}
            </Card.Header>
            <Card.Body>
              <DataTable
                data={newData}
                columns={role==="admin"?columns1:columns}
                customStyles={getTableStyles}
                striped
              />
            </Card.Body>
          </Card>
        )}
      </MainWrapper>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={1023}>
        <ManageEstablishment/>
      </MediaQuery>
    </>
  );
}

export default Establishment;
