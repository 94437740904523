import React, { useEffect, useRef, useState } from "react";
import ChatTypeSelection from "./ChatTypeSelection";
import "./style.css";
import { Messages } from "./Message";
import { axiosInstance } from "../../lib/axiosInstance";
import ListMenu from "./ListMenu";
import FormatDate from "../../lib/FormatDate";
import axios from "axios";
import MediaQuery from "react-responsive";
import css from "./chatMob.module.scss"
import MainContainer from "../MobileUI/GlobalComponents/MainContainer";
import { Card } from "react-bootstrap";
import MobTextField from "../MobileUI/GlobalComponents/MobTextField";
import { IconBack, IconChat, IconSend } from "../../assets/css/icons";
import { IconButton, InputAdornment } from "@mui/material";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  dots: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  dot: {
    marginRight: theme.spacing(1),
    animation: '$dot-animation 1.5s infinite',
    fontSize: '24px',
    fontWeight:"600",
    color:"#292929"
  },
  '@keyframes dot-animation': {
    '0%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(-5px)' },
    '100%': { transform: 'translateY(0)' },
  },
}));

export default function ChatBot({ hide, setHide, props }) {
  const fmsg = {
    type: "reply",
    chatType: "db",
    message: "Welcome to the Database Chatbot. Start by asking a question!",
    timeStamp: FormatDate(),
  }
  const classes = useStyles();
  const [btnSelected, setBtnSelected] = useState(true);
  const [selectedChatType, setSelectedChatType] = useState("");
  const [liveText, setLiveText] = useState("");
  const [messages, setMessages] = useState([fmsg]);
  const [busy, setBusy] = useState(false);
  const lastMsgRef = useRef(null);
  const history = useHistory();
  const today = new Date();
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const formattedDate = today.toLocaleDateString('en-US', options);
  useEffect(() => {
    // handleDB_Selection();
  }, []);

  const handleRefreshSelection = () => {
    setBtnSelected(false);
    setMessages([]);
    try {
      axiosInstance
        .post("/refresh/")
        .then((_response) => { })
        .catch((err) => console.error("[Error Refresh /refresh/]", err?.message));
    } catch (error) {
      console.error("[Error - Refresh /refresh/]: ", error?.message);
    }
  };
  const handleLiveText = (event) => {
    setLiveText(event.target.value);
  };
  const handleChatBotToggle = () => {
    setHide(!hide);
  };

  const navigateToHome = () => {
    setHide(!hide);
  }

  const handleDB_Selection = () => {
    setSelectedChatType("db");
    setMessages((msg) => {
      const _messages = [...msg];
      _messages.push({
        type: "reply",
        chatType: "db",
        message: "Welcome to the Database Chatbot. Start by asking a question!",
        timeStamp: FormatDate(),
      });
      return [..._messages];
    });
  };

  const handleMsgSubmit = (event) => {
    if (event.key === "Enter") event.preventDefault();
    if (liveText === "" || liveText.trim() === "") {
      setLiveText("");
      return;
    }
    const _liveText = liveText.trim();

    setMessages((msg) => {
      const _messages = [...msg];
      _messages.push({
        type: "query",
        chatType: selectedChatType,
        message: _liveText,
        timeStamp: FormatDate(),
      });
      return [..._messages];
    });
    setLiveText("");
    setBusy(true);
    //API Call
    let APIResponse;
    try {
      const postData = { query: _liveText, type_: props.fromExtractedData ? 'pearson' : 'osha' };
      axios
        .post(
          `https://chatdb-sandbox.techvantagesystems.com/pearson/chatbot/`, postData)
        .then((response) => {
          const { data } = response;
          APIResponse = data;
          if (data?.status) {
            setMessages((msg) => {
              const _messages = [...msg];
              _messages.push({
                type: "reply",
                chatType: selectedChatType,
                message: data?.data?.response,
                timeStamp: FormatDate(),
              });
              return [..._messages];
            });
          } else {
            setMessages((msg) => {
              const _messages = [...msg];
              _messages.push({
                type: "reply",
                chatType: selectedChatType,
                message: data?.message,
                timeStamp: FormatDate(),
              });
              return [..._messages];
            });
          }
          setBusy(false);
        })
        .catch((error) => {
          setMessages((msg) => {
            const _messages = [...msg];
            _messages.push({
              type: "reply",
              chatType: selectedChatType,
              message:
                "We're experiencing temporary technical issues with our chat service. Please try again later. We apologize for the inconvenience and appreciate your patience.",
              // message: APIResponse?.message,
              timeStamp: FormatDate(),
            });
            return [..._messages];
          });
          setBusy(false);
          console.error("[Error - API /chatbot]: ", error?.message);
        });
    } catch (error) {
      console.error("[Error - API /chatbot]: ", error?.message);
    }
  };

  useEffect(() => {
    lastMsgRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [messages]);
  return (
    <>
      <MediaQuery minDeviceWidth={1024}>

        <div className={`${hide ? "chatBotContainer_Hide" : "chatBotContainer"}`}>
          <div className="chatBotTitle">
            <div className="chatBot_headerWrapper">
              {/* <span className="material-symbols-outlined">smart_toy</span> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="30" viewBox="0 0 49 36" fill="none">
                <path d="M37.6249 35.1291C37.7907 35.2458 37.9887 35.3082 38.1915 35.3077C38.3943 35.3072 38.592 35.2438 38.7572 35.1262C38.9246 35.004 39.0516 34.8347 39.1212 34.6391L39.5416 33.3478C39.6492 33.024 39.8307 32.7297 40.0718 32.4883C40.313 32.2468 40.607 32.0649 40.9306 31.9568L42.2388 31.5336C42.4323 31.4636 42.5988 31.3346 42.7151 31.1649C42.8313 30.9952 42.8914 30.7933 42.8868 30.5877C42.8822 30.382 42.8133 30.183 42.6896 30.0186C42.5659 29.8543 42.3938 29.7328 42.1975 29.6715L40.9043 29.2502C40.5803 29.1427 40.2858 28.9612 40.0442 28.7201C39.8026 28.479 39.6205 28.1849 39.5124 27.8611L39.0873 26.5557C39.0189 26.3634 38.8922 26.1972 38.7249 26.0802C38.5576 25.9633 38.358 25.9014 38.1538 25.9031C37.9497 25.9049 37.7512 25.9702 37.586 26.09C37.4207 26.2099 37.2969 26.3782 37.2318 26.5717L36.802 27.8883C36.6943 28.2034 36.5165 28.4899 36.2818 28.7261C36.0472 28.9624 35.762 29.1422 35.4477 29.252L34.1404 29.6724C33.9482 29.741 33.7821 29.8678 33.6651 30.0351C33.5482 30.2024 33.4863 30.4019 33.4879 30.606C33.4896 30.8101 33.5548 31.0086 33.6744 31.174C33.794 31.3394 33.9621 31.4634 34.1555 31.5289L35.4477 31.9474C35.773 32.0556 36.0685 32.2386 36.3104 32.4816C36.5523 32.7246 36.7338 33.021 36.8405 33.3469L37.2656 34.6551C37.3333 34.8469 37.4593 35.0124 37.6249 35.1291Z" fill="url(#paint0_linear_48_82)" />
                <path d="M29.4292 8.17261H12.3398C10.0983 8.17261 8.28113 9.98976 8.28113 12.2313V20.3609C13.8708 22.4569 22.2537 23.2326 33.4879 22.1645V12.2313C33.4879 9.98976 31.6708 8.17261 29.4292 8.17261Z" fill="url(#paint1_linear_48_82)" />
                <path d="M32.5267 25.9028C32.0994 25.796 12.7902 25.6646 8.28113 24.4075C8.28113 26.2019 10.5597 27.5049 11.699 27.9322H15.3305V32.2045C15.3305 33.3153 15.9001 33.1658 16.185 32.9522L21.8458 27.9322H29.6428C31.1023 27.6256 32.9539 26.0096 32.5267 25.9028Z" fill="url(#paint2_linear_48_82)" />
                <path d="M5.71763 13.1927V10.9497L3.58144 12.1246C-5.81771 19.1313 5.46889 24.0478 12.447 25.6856C19.7574 26.4252 20.7212 26.3048 29.3227 26.3264C36.4994 26.184 40.3948 24.7637 41.4984 24.194C55.0845 17.7001 47.1593 12.2315 41.4984 10.3089V5.82294C40.4731 1.55062 36.2292 0.624911 34.2355 0.696094H20.2436C16.9111 0.696094 16.078 4.11399 16.078 5.82294C18.3922 5.78733 24.3236 5.73749 29.5359 5.82294C34.7481 5.90838 36.336 9.77485 36.4784 11.6974V20.4557C39.64 20.4557 41.1424 17.7499 41.4984 16.397V12.4451C42.8656 12.7014 44.4891 13.8336 45.1299 14.3676C50.5985 19.4944 39.8607 22.6987 33.8082 23.66C16.8257 24.194 13.7283 23.8736 5.71763 20.6693C-0.690883 18.1059 3.04741 14.6168 5.71763 13.1927Z" fill="url(#paint3_linear_48_82)" />
                <defs>
                  <linearGradient id="paint0_linear_48_82" x1="38.1875" y1="25.9031" x2="38.1875" y2="35.3077" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#2B65D6" />
                    <stop offset="1" stop-color="#6FB5F7" />
                  </linearGradient>
                  <linearGradient id="paint1_linear_48_82" x1="20.8845" y1="8.17261" x2="20.8845" y2="33.1024" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B0C2FF" />
                    <stop offset="1" stop-color="#2839D1" />
                  </linearGradient>
                  <linearGradient id="paint2_linear_48_82" x1="20.8845" y1="8.17261" x2="20.8845" y2="33.1024" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B0C2FF" />
                    <stop offset="1" stop-color="#2839D1" />
                  </linearGradient>
                  <linearGradient id="paint3_linear_48_82" x1="24.5" y1="0.692383" x2="24.5" y2="26.3264" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#3BA8F7" />
                    <stop offset="1" stop-color="#21DFD4" />
                  </linearGradient>
                </defs>
              </svg>
              <h3>Database ChatBot</h3>
            </div>
            <div className="chatBot_headerBtnWrapper">

              {/* <ListMenu setBtnSelected={setBtnSelected} setMessages={setMessages} handleRefreshSelection={handleRefreshSelection} /> */}
              <div className="min-btn" onClick={handleChatBotToggle}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_i_50_102)">
                    <circle cx="10" cy="10" r="10" fill="#FFB801" />
                  </g>
                  <path d="M5 9H15C15.55 9 16 9.45 16 10C16 10.55 15.55 11 15 11H5C4.45 11 4 10.55 4 10C4 9.45 4.45 9 5 9Z" fill="#474747" />
                  <defs>
                    <filter id="filter0_i_50_102" x="0" y="-1" width="20" height="21" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="-1" />
                      <feGaussianBlur stdDeviation="5.05" />
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.71 0" />
                      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_50_102" />
                    </filter>
                  </defs>
                </svg>

              </div>


            </div>
          </div>
          <div className="chatContainer">
            {!btnSelected && (
              <ChatTypeSelection
                btnSelected={btnSelected}
                setBtnSelected={setBtnSelected}
                selectedChatType={selectedChatType}
                setSelectedChatType={setSelectedChatType}
                messages={messages}
                setMessages={setMessages}
              />
            )}
            <div
              className={`${btnSelected ? "messageDisplay" : "messageDisplay_Hide"
                }`}
            >
              <ul>
                {messages.map((message, index) => {
                  return (
                    <Messages
                      key={index}
                      type={message.type}
                      message={message.message}
                      timeStamp={message.timeStamp}
                    ></Messages>
                  );
                })}
                {busy && (
                  <li className="reply">
                    <p>...</p>
                  </li>
                )}
                <li style={{ opacity: 0 }} ref={lastMsgRef}></li>
              </ul>
            </div>
            <div className={`${btnSelected ? "inputArea" : "inputArea_Hide"}`}>
              <div className="inputWrapper">
                <textarea
                  name=""
                  id="inputFiled"
                  value={liveText}
                  placeholder="Type your message"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") handleMsgSubmit(event);
                  }}
                  onChange={handleLiveText}
                />
                <div className="sendIcon">
                  <svg onClick={handleMsgSubmit} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path d="M2.68008 28L30.6667 16L2.68008 4L2.66675 13.3333L22.6667 16L2.66675 18.6667L2.68008 28Z" fill="#9E9E9E" />
                  </svg>

                  {/* <span
                  className="material-symbols-outlined"
                  onClick={handleMsgSubmit}
                >
                  send
                </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>

      </MediaQuery>






      <MediaQuery maxDeviceWidth={1023}>
        <div className={`${hide ? "chatBotContainer_Hide" : "chatBotContainer"}`}>
          
          <div className={css.mainContentWrapper}>
            <div className={css.mainBody}>
              <Card className={css.mainContentCardDiv}>
                <Card.Header className={css.Cardheader}>
                  <div className={css.iconBack} onClick={navigateToHome}><IconButton  onClick={navigateToHome}><IconBack/></IconButton></div>
                  <div class={css.headerText}> <IconChat fill ="rgb(41, 41, 41)"/><div>Database ChatBot</div></div>
                </Card.Header>
                <Card.Body className={css.chatBody}>
                  <div className={css.chatDate}>
                  {formattedDate}
                  </div>
                  <div className="chatContainer">
            {!btnSelected && (
              <ChatTypeSelection
                btnSelected={btnSelected}
                setBtnSelected={setBtnSelected}
                selectedChatType={selectedChatType}
                setSelectedChatType={setSelectedChatType}
                messages={messages}
                setMessages={setMessages}
              />
            )}
            <div
              className={`${btnSelected ? "messageDisplay" : "messageDisplay_Hide"
                }`}
            >
              <ul>
                {messages.map((message, index) => {
                  return (
                    <Messages
                      key={index}
                      type={message.type}
                      message={message.message}
                      timeStamp={message.timeStamp}
                    ></Messages>
                  );
                })}
                {busy && (
                  <li className="reply">
                    <p>
                              <div className={classes.dots}>
                                <div className={classes.dot} style={{ animationDelay: '0s' }}>.</div>
                                <div className={classes.dot} style={{ animationDelay: '0.5s' }}>.</div>
                                <div className={classes.dot} style={{ animationDelay: '1s' }}>.</div>
                              </div>
                            </p>
                  </li>
                )}
                <li style={{ opacity: 0 }} ref={lastMsgRef}></li>
              </ul>
            </div>
          </div>
                </Card.Body>
                <Card.Footer className={css.Cardfooter}>
                  
            <div className={`${btnSelected ? css.inputArea : css.inputAreaHide}`}>
              <div className={css.inputWrapper}>
                <MobTextField
                  name=""
                  id="inputFiled"
                  value={liveText}
                  placeholder="Type your message"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") handleMsgSubmit(event);
                  }}
                  onChange={handleLiveText}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleMsgSubmit}
                          edge="end"
                          style={{ color: "rgba(135, 86, 212, 1)" }}
                        >
                          <IconSend/>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
                </Card.Footer>
              </Card>
            </div>

          </div>
        </div>
      </MediaQuery>
    </>
  );
}

