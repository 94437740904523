// UserContext.js

import React, { createContext, useState } from "react";

// Create a context
const UserContext = createContext();

// Create a provider for components to consume and update the context
export const UserProvider = ({ children }) => {
  const [formDataState, setformDataState] = useState({
    type: "new",
    employeeName: "",
    jobTitle: "",
    street: "",
    dob: "",
    city: "",
    dateHired: "",
    state: null,
    gender: "",
    zip: "",
    empId: "",
    establishment: "",
    workOption: "",
    WorkTime: "",
    incidentDate: "",
    incidentPlace: "",
    incidentTime: "",
    incidentCause: "",
    deathTime: "",
    value: "",
    incident: "",
    injurySummary: "",
    injuryDetails: "",
    object: "",
    preIncidentActivity: "",
    reason: "",
    Physician: "",
    stateInfo: "",
    location: "",
    zipInfo: "",
    streetInfo: "",
    emergencyRoomOption: "",
    cityInfo: "",
    hospitalizedOption: "",
    treatmentInfo: "",
    incidentId: "",
    userMail:"",
    userToken:"",
    userRole:"",
    incident_id:null,
    est_id:null,
    selectedMenu:null

  });

  return (
    <UserContext.Provider value={{ formDataState, setformDataState }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
