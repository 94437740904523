import React from "react";
import MainContainer from "../../MobileUI/GlobalComponents/MainContainer";
import css from "./individualdetails.module.scss";
function IndividualDetails(props) {
    const location = props.location
    const data= location.data
    const formHeader = location.fromOsha300A ? "Form 300A" : location.fromOsha300 ? "Form 300" : "Form 301"
  return (
    <MainContainer>
      <div className={css.mainContainer}>
        <div className={css.individualHeader}>EXTRACTED DATA</div>
        <div className={css.formName}>{formHeader}</div>
        <div className={css.individualDetails}>
          {location.fromOsha300A ? 
          <>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Establishment Name <div>:</div></div>
            <div className={css.cardDetailColR}>{data.Establishment_Name}</div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Industry Description <div>:</div></div>
            <div className={css.cardDetailColR}>{data.Industry_description}</div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Number of Employees <div>:</div></div>
            <div className={css.cardDetailColR}>{data.Annual_average_number_of_employees}</div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Total working hours <div>:</div></div>
            <div className={css.cardDetailColR}>{data.Total_hours_worked_by_all_employees_last_year}</div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Days away from work <div>:</div></div>
            <div className={css.cardDetailColR}>{data.Total_number_of_days_away_from_work}</div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Number of deaths <div>:</div></div>
            <div className={css.cardDetailColR}>{data.Total_number_of_deaths}</div>
          </div>
          </>
           : 
           location.fromOsha300 ? 
           <>
           <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Unique ID <div>:</div></div>
            <div className={css.cardDetailColR}>{data.CaseNo}</div>
          </div>
           <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Employee Name <div>:</div></div>
            <div className={css.cardDetailColR}>{data.EmployeeName}</div>
          </div>
           <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Job Title <div>:</div></div>
            <div className={css.cardDetailColR}>{data.JobTitle}</div>
          </div>
           <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Incident Date <div>:</div></div>
            <div className={css.cardDetailColR}>{data.InjuryDate}</div>
          </div>
           <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Event Location <div>:</div></div>
            <div className={css.cardDetailColR}>{data.EventLocation}</div>
          </div>
           <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Incident category<div>:</div></div>
            <div className={css.cardDetailColR}>{data.IllnessClassification}</div>
          </div>
           <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Incident Summary<div>:</div></div>
            <div className={css.cardDetailColR}>{data.InjuryDescription}</div>
          </div>
           <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Incident outcome<div>:</div></div>
            <div className={css.cardDetailColR}>{data.CaseClassification}</div>
          </div>
           </>
           : 
           location.fromOsha301 ? 
           <>
           <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Unique ID <div>:</div></div>
            <div className={css.cardDetailColR}>{data.CaseNo}</div>
          </div>
           <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Employee Name<div>:</div></div>
            <div className={css.cardDetailColR}>{data.Full_Name}</div>
          </div>
           <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Incident Date <div>:</div></div>
            <div className={css.cardDetailColR}>{data.Date_of_injury_or_illness}</div>
          </div>
           <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Incident Time <div>:</div></div>
            <div className={css.cardDetailColR}>{data.Time_of_event}</div>
          </div>
           <div className={css.dataRows}>
            <div className={css.cardDetailColL}>Incident Summary <div>:</div></div>
            <div className={css.cardDetailColR}>{data.What_happened}</div>
          </div>
           </> 
           :""
           }
        </div>
      </div>
    </MainContainer>
  );
}

export default IndividualDetails;
