import React, { useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
// import Navbar from '../Component/Nav/navbar';
import NavHome from '../Component/Nav/navHome';

const PrivateRoute = ({component: Component, ...rest}) => {
    const location=useLocation()
    const history = useHistory();
    useEffect(()=>{
        if(localStorage.getItem('personal-safety') && localStorage.getItem("accessToken") && location.pathname==="/"){
            history.push("/mainboard");
        }
    },[location.pathname==="/"])
    
    return (
        
        <Route {...rest} render={props => (
            localStorage.getItem('personal-safety') && localStorage.getItem("accessToken") ?
            <>
            <MediaQuery minDeviceWidth={1024}>
                <NavHome/>
            </MediaQuery>
                <Component {...props} {...rest}/>
                </>
            : <Redirect to="/" />
        )} />
        
    );
};


export default PrivateRoute;