import React from 'react'
import { Button, Modal } from "@mui/material";
import "./WindowMessage.css";
function SuccessMessage(props) {
  return (
    <Modal
    className="cnfModal"
    open={props.show}
    onClose={props.handleClose}
    closeAfterTransition
  >
    <div className="cnfModalBody">
      
        <div className="cnfModalText">
          Profile Updated Successfully
        </div>
      <div className="cnfmodalButton">
        <Button variant="contained" onClick={props.handleConfirm}>
          OK
        </Button>
      </div>
    </div>
  </Modal>
  )
}

export default SuccessMessage