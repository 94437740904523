import React from "react";
import { useContext } from "react";
import { IconEmployeeDetails, IconIncidentDetails, IconInjuryDescription, IconTreatmentDetails } from "../../assets/css/icons";
import Context from "../Context";
import MobTextField from "../MobileUI/GlobalComponents/MobTextField";
import css from "./submit.module.scss";
import FormButton from "../MobileUI/GlobalComponents/FormButton";
function SubmitMob(props) {
  const { formDataState, setformDataState } = useContext(Context);
  const awayFromWork =
    formDataState.incidentCause.value === "Days away from work"
      ? formDataState.value
      : "none";
  const jobTransfer =
    formDataState.incidentCause.value === "Job transfer or restriction"
      ? formDataState.value
      : "none";
  const getValueOrDefault = (value, defaultValue = "none") => {
    return value !== undefined && value !== null && value !== ""
      ? value
      : defaultValue;
  };
  const truncateText = (text) => {
    if (text.length <= 50) {
      return text;
    }
    return text.slice(0, 50) + "...";
  };

  return (
    <div className={css.mainContainer}>
      <div className={css.submitHeader}>Employee Profile</div>

      {/* Employee Details */}
      <div className={css.submitSubHeader}>
        <IconEmployeeDetails /> Employee Details
      </div>
      <div className={css.submitDetails}>
        <>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              FullName<div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(formDataState.employeeName)}
            </div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              Street <div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(formDataState.street)}
            </div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              State <div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(formDataState.state?.name)}
            </div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              City <div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(formDataState.city)}
            </div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              Zip <div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(+formDataState.zip)}
            </div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              Job Title <div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(formDataState.jobTitle)}
            </div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              DOB <div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(formDataState.dob)}
            </div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              Date Hired <div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(formDataState.dateHired)}
            </div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              Gender <div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(formDataState.gender.value)}
            </div>
          </div>
        </>
      </div>
      {/* Employee Details */}

      {/* Incident Details */}
      <div className={css.submitSubHeader}>
        <IconIncidentDetails /> Incident Details
      </div>
      <div className={css.submitDetails}>
        <>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              Is It Work-Related<div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(formDataState.workOption.label)}
            </div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              Date Of Incident<div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(formDataState.incidentDate)}
            </div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              Place<div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(formDataState.incidentPlace)}
            </div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              Time Of Incident<div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(formDataState.incidentTime)}
            </div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              Time Of Employee Began Work<div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(formDataState.WorkTime)}
            </div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              Incident Cause<div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(formDataState.incidentCause.value)}
            </div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              Date Of Death<div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(formDataState.deathTime)}
            </div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              Days Away From Work<div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(awayFromWork)}
            </div>
          </div>
          <div className={css.dataRows}>
            <div className={css.cardDetailColL}>
              Job Transfer Or Days Restricted<div>:</div>
            </div>
            <div className={css.cardDetailColR}>
              {getValueOrDefault(jobTransfer)}
            </div>
          </div>
        </>
      </div>
      {/* Incident Details */}

      {(formDataState.incidentCause.value === "Near miss" ||
        formDataState.incidentCause.value ===
          "Minor injury that required only first aid treatment") &&
      formDataState.workOption.label === "Yes" ? null : formDataState.workOption
          .label === "No" ? null : (
        <>
          <div className={css.submitSubHeader}>
            <IconInjuryDescription /> Injury/Illness Description
          </div>
          <div className={css.submitDetails}>
            <>
              <div className={css.dataRows}>
                <div className={css.cardDetailColL}>
                  Incident Category<div>:</div>
                </div>
                <div className={css.cardDetailColR}>
                  {getValueOrDefault(formDataState.incident.value)}
                </div>
              </div>
              <div className={css.dataRows}>
                <div className={css.cardDetailColL}>
                  Employee's Pre-Incident Activity<div>:</div>
                </div>
                <div className={css.cardDetailColR}>
                  {getValueOrDefault(
                    truncateText(formDataState.preIncidentActivity)
                  )}
                </div>
              </div>
              <div className={css.dataRows}>
                <div className={css.cardDetailColL}>
                  Detail How The Injury Occurred<div>:</div>
                </div>
                <div className={css.cardDetailColR}>
                  {getValueOrDefault(truncateText(formDataState.injuryDetails))}
                </div>
              </div>
              <div className={css.dataRows}>
                <div className={css.cardDetailColL}>
                  Define Injury, Affected Body Part, And How It Was Affected
                  <div>:</div>
                </div>
                <div className={css.cardDetailColR}>
                  {getValueOrDefault(truncateText(formDataState.reason))}
                </div>
              </div>
              <div className={css.dataRows}>
                <div className={css.cardDetailColL}>
                  Specify The Harming Object Or Substance<div>:</div>
                </div>
                <div className={css.cardDetailColR}>
                  {getValueOrDefault(truncateText(formDataState.object))}
                </div>
              </div>
              <div className={css.summaryRow}>
                <div className={css.injurySummary}>
                  Summarized Injury Details
                </div>
                <div className={css.summaryDetails}>
                  {getValueOrDefault(formDataState.injurySummary)}
                </div>
              </div>
            </>
          </div>

          {/* --------------------------------------------------------------------------------------------------------- */}
          <div className={css.submitSubHeader}>
            <IconTreatmentDetails /> Treatment Details
          </div>
          <div className={css.submitDetails}>
            <>
              <div className={css.dataRows}>
                <div className={css.cardDetailColL}>
                  Physician Name<div>:</div>
                </div>
                <div className={css.cardDetailColR}>
                  {getValueOrDefault(formDataState.Physician)}
                </div>
              </div>
              <div className={css.dataRows}>
                <div className={css.cardDetailColL}>
                  Facility<div>:</div>
                </div>
                <div className={css.cardDetailColR}>
                  {getValueOrDefault(formDataState.location)}
                </div>
              </div>
              <div className={css.dataRows}>
                <div className={css.cardDetailColL}>
                  Street<div>:</div>
                </div>
                <div className={css.cardDetailColR}>
                  {getValueOrDefault(formDataState.streetInfo)}
                </div>
              </div>
              <div className={css.dataRows}>
                <div className={css.cardDetailColL}>
                  City<div>:</div>
                </div>
                <div className={css.cardDetailColR}>
                  {getValueOrDefault(formDataState.cityInfo)}
                </div>
              </div>
              <div className={css.dataRows}>
                <div className={css.cardDetailColL}>
                  State<div>:</div>
                </div>
                <div className={css.cardDetailColR}>
                  {getValueOrDefault(formDataState.stateInfo)}
                </div>
              </div>
              <div className={css.dataRows}>
                <div className={css.cardDetailColL}>
                  Zip<div>:</div>
                </div>
                <div className={css.cardDetailColR}>
                  {getValueOrDefault(formDataState.zipInfo)}
                </div>
              </div>
              <div className={css.dataRows}>
                <div className={css.cardDetailColL}>
                  Was The Employee Treated In An Emergency Room?<div>:</div>
                </div>
                <div className={css.cardDetailColR}>
                  {getValueOrDefault(formDataState.emergencyRoomOption?.label)}
                </div>
              </div>
              <div className={css.dataRows}>
                <div className={css.cardDetailColL}>
                  Was The Employee Hospitalized Overnight As An In-Patient?
                  <div>:</div>
                </div>
                <div className={css.cardDetailColR}>
                  {getValueOrDefault(formDataState.hospitalizedOption.label)}
                </div>
              </div>
            </>
          </div>
        </>
      )}
      
      {(formDataState.incidentCause.value === "Near miss" ||
        formDataState.incidentCause.value ===
          "Minor injury that required only first aid treatment") &&
      formDataState.workOption.label === "Yes" ? (
        <div className={css.aiSummary}>
          <div className={css.aiDesc}>
            Describe Injury Or Illness, Parts Of Body Affected, And
            Object/Substance That Directly Injured Or Made Person ill
          </div>
          <div>
            <MobTextField
              onChange={props.handleOshaDesc}
              value={
                formDataState.type === "edit"
                  ? formDataState.injurySummary
                  : console.log()
              }
              type="text"
              size="small"
            />
          </div>
          <div>
            <FormButton
              fullWidth
              onClick={
                formDataState.type === "edit" ? props.handleSubmit : props.handleOshacheck
              }
            >
              Submit
            </FormButton>
          </div>
        </div>
      ) :
      formDataState.workOption.label === "No" ? (
        <div className={css.aiSummary}>
        <div className={css.aiDesc}>
          Describe Injury Or Illness, Parts Of Body Affected, And
          Object/Substance That Directly Injured Or Made Person ill
        </div>
        <div>
          <MobTextField 
          onChange={props.handleOshaDesc}
          value={formDataState.type==="edit" ? formDataState.injurySummary : console.log()}
          type="text"
          />
        </div>
        <div>
          <FormButton 
          fullWidth
          onClick={
            formDataState.type === "edit"
              ? props.handleSubmit
              : props.handleOshacheck
          }
          >
            Submit
          </FormButton>
        </div>
      </div>
      )
      :(
        <div className={css.submit}>
        <FormButton
        onClick={props.handleSubmit}
        fullWidth
        >
        Submit
      </FormButton>
      </div>
      )}
    </div>
  );
}

export default SubmitMob;
