import React, { useState } from "react";
import styles from "./settingsMenu.module.scss";
import NavMob from "../../MobileUI/GlobalComponents/NavMob/NavMob";
import Button from "../../MobileUI/GlobalComponents/Button";
import MainContainer from "../../MobileUI/GlobalComponents/MainContainer";
import { useHistory } from "react-router";
import addIcon from "../../../assets/Icons/add.svg"
import {
  IconTwofactor,
  IconSettingSpanner,
  IconLock,
  IconOshaInsider,
  IconProfile,
  IconGeneralInformation,
  IconLogout,
} from "../../../assets/css/icons";
import MainMenu from "./MainMenu";
import PasswordSettings from "./PasswordSettings";
import UserSettings from "./UserSettings";
import TwoFactorMenu from "./TwoFactorMenu";
import ManageEstablishment from "./ManageEstablishment";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useContext } from "react";
import Context from "../../Context";
import ChatBot from '../../ChatBot/ChatBot';
import PageLoader from "../../services/pageloader";
function SettingsMenu(props) {
  const [isChatEnabled, setIsChatEnabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleChatBot = () => {
    setIsChatEnabled(!isChatEnabled);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const { formDataState, setformDataState } = useContext(Context);
  const menuCss = {
    color: "var(--majantha, #8756D4)",
    fontFamily: "Poppins, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "16.728px",
    letterSpacing: "0.7px",
  };
  const handleMenuClick = (event) => {
    setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
  };
  const logout = async () => {
    localStorage.removeItem("personal-safety");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    history.push("/");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const history = useHistory();
  const handleUserSettings = () => {
    setformDataState({
      ...formDataState,
      selectedMenu: "user",
    });
    history.push("/profile_settings", {
      fromHomeMoB: true,
    });
  };
  const [item, setItem] = useState("mainMenu");
  const menuSelector = () => {
    switch (item) {
      case "mainMenu":
        return <MainMenu menuChange={setItem} />;
      case "passwordSettings":
        return <PasswordSettings />;
      case "twoFactorMenu":
        return <TwoFactorMenu />;
      case "userDetails":
        return <UserSettings />;
      default:
        return <MainMenu menuChange={setItem} />;
    }
  };

  const handleClick = () => {
    let path = "/add_user";
    history.push({
      pathname: path,
    });
  };
  const handleLoading = () => {
    setLoading(!loading);
  };

  return (
    <div className={styles.mainWrapper}>
      {
              <ChatBot
                hide={isChatEnabled}
                setHide={setIsChatEnabled}
                props={props}
              />
            }
      <div className={styles.mainHeading}>
        <IconOshaInsider />
        <IconButton onClick={handleMenuClick}>
          <IconProfile />
        </IconButton>
        <Menu
          id="main-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {!props.fromProfileSettings && (
            <MenuItem
              onClick={() => {
                handleUserSettings();
              }}
              sx={menuCss}
            >
              <IconGeneralInformation />
              &nbsp;Profile Settings
            </MenuItem>
          )}
          <MenuItem
            sx={menuCss}
            onClick={() => {
              logout();
            }}
          >
            <IconLogout />
            &nbsp;Logout
          </MenuItem>
        </Menu>
      </div>
      <div
        className={styles.contentWrapper}
        style={item === "userDetails" ? { backgroundColor: "#fff" } : {}}
      >
        {
        loading ? <PageLoader showLoader={loading} /> : 
        <>
        <div className={styles.headBar}>
          <div
            className={styles.subHeading}
            onClick={() => {
              setItem("mainMenu");
            }}
          >
            {" "}
            <IconSettingSpanner /> Settings
          </div>
          <div className={styles.subHeadingPad}>
            {item === "userDetails" && (
              <>
                <span>User Information</span>
                <Button onClick={handleClick} className="add" icon={addIcon}>
                  Add
                </Button>
              </>
            )}
          </div>
        </div>
        <div className={styles.settings}>{menuSelector()}</div>
        </>
}
      </div>
      <div className={styles.footer}>
        {" "}
        <NavMob   handleChatBot={handleChatBot} handleLoading={handleLoading}/>
      </div>
    </div>
  );
}

export default SettingsMenu;
