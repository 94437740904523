import { Button } from "@mui/base";
import {
  Checkbox,
  Drawer,
  FormGroup,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useEffect } from "react";
import { IconFilter, IconOshaInsiderViolet } from "../../../assets/css/icons";
import MainContainer from "../../MobileUI/GlobalComponents/MainContainer";
import { makeStyles } from "@material-ui/core/styles";
import css from "./osha300A.module.scss";
import { useState } from "react";
import Osha300ACard from "./Osha300ACard";
import { ExtractedDataYear } from "../../services/api-servics";
const transparentButtonStyle = makeStyles({
  transparentButton: {
    backgroundColor: "transparent",
    border: "none",
    color: "var(--majantha, #8756D4)",
    fontFamily: "'Poppins', sans-serif !important",
    fontSize: "13px !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    textTransform: "none !important",
    "&:active": {
      background: `linear-gradient(90deg, #8756D4 100%, #AE88EA -8.33%) !important`,
      transitionDuration: "0.1s", // Disable transition on active state
      color: "#FFF",
    },
    "&:disabled": {
      backgroundColor: "#e3e3e3 !important", // Set the background color for disabled state
    },
  },
});
function Osha300A(props) {
  const data = props.location.state.data300A;
  const [filterData, setfilterdData] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const transparentButtonClasses = transparentButtonStyle();
  // drawer states
  const [anchor, setAnchor] = useState("");
  const [yearList, setYearList] = useState(true);
  const [establishmentsList, setEstablishmentsList] = useState(false);
  const [years, setYears] = useState([]);
  const [establishments, setEstablishments] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedEsts, setEsts] = useState([]);
  // drawer states

  useEffect(() => {
    ExtractedDataYears();
  }, []);

  useEffect(() => {
    setfilterdData(data);
  }, [data]);
  const ExtractedDataYears = (e) => {
    ExtractedDataYear({}).then(async (rsp) => {
      const data = rsp.data;
      if (data) {
        setYears(data.data300a_year);
        setEstablishments(data.data300a_est);
      }
    });
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setfilterdData(data);
  };
  const handleApply = () => {
    const filteredData = data.filter((item) => {
      if (selectedYears.length === 0 && selectedEsts.length === 0) {
        return true;
      }
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(item.Year);
      const establishmentMatch =
        selectedEsts.length === 0 ||
        selectedEsts.includes(item.Establishment_Name);

      return yearMatch && establishmentMatch;
    });
    setfilterdData(filteredData);
    setDrawerOpen(false);
  };
  const handleMenuItemClick = (e) => {
    if (e === "year") {
      setYearList(true);
      setEstablishmentsList(false);
    } else if (e === "est") {
      setYearList(false);
      setEstablishmentsList(true);
    }
  };
  const handleDrawerOpen = (data) => {
    setAnchor(data === "left" ? "left" : "right");
    setDrawerOpen(true);
  };
  const handleCheckboxChange = (data) => {
    if (yearList) {
      setSelectedYears((prevSelectedYears) => {
        if (prevSelectedYears.includes(data)) {
          return prevSelectedYears.filter(
            (selectedYear) => selectedYear !== data
          );
        } else {
          return [...prevSelectedYears, data];
        }
      });
    } else if (establishmentsList) {
      setEsts((prevSelectedEstablishments) => {
        if (prevSelectedEstablishments.includes(data)) {
          return prevSelectedEstablishments.filter(
            (selectedEstablishments) => selectedEstablishments !== data
          );
        } else {
          return [...prevSelectedEstablishments, data];
        }
      });
    }
  };
  const handleClearAll = () => {
    setSelectedYears([]);
    setEsts([]);
  };

  return (
    <MainContainer>
      <div className={css.oshaBoardHeader}>
        <div className={css.oshaBoardIconOsha}>
          <IconOshaInsiderViolet />
        </div>
        <div className={css.oshaboardFilterPanel}>
          <div
            className={css.oshaboardFilter}
            onClick={() => {
              handleDrawerOpen("right");
            }}
          >
            <IconFilter />
          </div>
        </div>

        <div className={css.oshaextractedData}>EXTRACTED DATA</div>

        {/* filter Drawer */}
        <Drawer
          anchor={anchor}
          open={isDrawerOpen}
          PaperProps={{
            sx: {
              boxShadow: "red",
            },
          }}
          transitionDuration={{ enter: 400, exit: 400 }}
          onClose={handleDrawerClose}
        >
          <MainContainer isDrawer>
            <div className={css.drawerMain}>
              <div className={css.oshaDrawerHeader}>
                <div className={css.oshaIconOsha}>
                  <IconOshaInsiderViolet />
                </div>
                <div className={css.drwerHeaders}>
                  <div className={css.drawerText}>Filters</div>
                  <div className={css.drawerText} onClick={handleClearAll}>
                    CLEAR ALL
                  </div>
                </div>
              </div>
              {anchor === "right" && (
                <div className={css.drawerLeftMenus}>
                  <div className={css.drawerLeft}>
                    <List className={css.drawerList}>
                      <ListItemButton
                        selected={yearList}
                        onClick={() => handleMenuItemClick("year")}
                        style={
                          yearList
                            ? {
                                background: "var(--Lavender-ambient, #E5A8FB)",
                                border:
                                  "var(--none, 1px) solid var(--Grey, #E5E5E5)",
                              }
                            : {
                                border:
                                  "var(--none, 1px) solid var(--Grey, #E5E5E5)",
                              }
                        }
                      >
                        <ListItemText
                          disableTypography
                          className={
                            yearList
                              ? css.drawerOptionButtonSelected
                              : css.drawerOptionButton
                          }
                          primary="Year"
                        />
                      </ListItemButton>
                      <ListItemButton
                        selected={establishmentsList}
                        onClick={() => handleMenuItemClick("est")}
                        style={
                          establishmentsList
                            ? {
                                background: "var(--Lavender-ambient, #E5A8FB)",
                                border:
                                  "var(--none, 1px) solid var(--Grey, #E5E5E5)",
                              }
                            : {
                                border:
                                  "var(--none, 1px) solid var(--Grey, #E5E5E5)",
                              }
                        }
                      >
                        <ListItemText
                          disableTypography
                          className={
                            establishmentsList
                              ? css.drawerOptionButtonSelected
                              : css.drawerOptionButton
                          }
                          primary="Establishment"
                        />
                      </ListItemButton>
                    </List>
                  </div>
                  <div className={css.drawerRight}>
                    <FormGroup>
                      {yearList &&
                        years.map((year) => (
                          <div key={year} className={css.drawerCheckbox}>
                            <span>{year}</span>
                            <Checkbox
                              checked={selectedYears.includes(year)}
                              onChange={() => handleCheckboxChange(year)}
                            />
                          </div>
                        ))}
                      {establishmentsList &&
                        establishments.map((establishments) => (
                          <div
                            key={establishments}
                            className={css.drawerCheckbox}
                          >
                            <span>{establishments}</span>
                            <Checkbox
                              checked={selectedEsts.includes(establishments)}
                              onChange={() =>
                                handleCheckboxChange(establishments)
                              }
                            />
                          </div>
                        ))}
                    </FormGroup>
                  </div>
                </div>
              )}

              <div className={css.drawerButtonDiv}>
                {anchor === "right" && (
                  <>
                    <div className={css.closeButton}>
                      <Button
                        fullWidth
                        className={transparentButtonClasses.transparentButton}
                        onClick={handleDrawerClose}
                      >
                        Close
                      </Button>
                    </div>
                    <div className={css.applyButton}>
                      <Button
                        fullWidth
                        className={transparentButtonClasses.transparentButton}
                        onClick={handleApply}
                        disabled={
                          selectedYears.length === 0 &&
                          selectedEsts.length === 0
                        }
                      >
                        Apply
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </MainContainer>
        </Drawer>
        {/* filter Drawer */}
      </div>
      <div className={css.oshaCard}>
        {filterData.length > 0 ? (
          filterData?.map((item,index) => {
            return <Osha300ACard key={index} data={item} />;
          })
        ) : (
          <div className={css.nodataText}>There is no data available</div>
        )}
      </div>
    </MainContainer>
  );
}

export default Osha300A;
