import React, { useState, useEffect, useContext } from "react";
// import Navbar from "../Nav/navbar";
// import NavHome from "../Nav/navHome";
import PageLoader from "../services/pageloader";
import { showToaster } from "../services/helper";
import { getSettings, processSettings,ExtractedDataTable } from "../services/api-servics";
import { useHistory } from "react-router-dom";
// import { Progress } from "flowbite-react";
import "./process_tab.css";
import MediaQuery from "react-responsive";
// import ChatBotBtn from "../../Component/ChatBot/index";
import { Card, Col, Row } from "react-bootstrap";
import { Button, InputLabel, TextField } from "@mui/material";
import MainWrapper from "../GlobalComponents/MainWrapper";
import MainContainer from "../MobileUI/GlobalComponents/MainContainer"
import { IconOshaInsiderViolet } from "../../assets/css/icons";
// import {mobButton} from "../MobileUI/GlobalComponents/Button";
import Context from "../Context";
import style from "./process.module.scss";
import MobButton from "../../Component/MobileUI/GlobalComponents/Button";
export default function Process_tab(props) {
  const [pageLoad, setPageLoad] = useState(false);
  const [settingsData, setSettingData] = useState(undefined);
  const history = useHistory();
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState("");
  const [progressBar, setProgressBar] = useState(0);
  const [progressBarStart, setProgressBarStart] = useState(false);
  const { formDataState, setformDataState } = useContext(Context);
  const [extData,setExtData] = useState([])
  let isMounted = true;
  useEffect(() => {
    getSettingsDetails();
    setformDataState({
      ...formDataState,
      selectedMenu:"archive"
    })
  }, []);

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  const getExtractedDataTable = (e) => {
    ExtractedDataTable({}).then(async (rsp) => {
        const data300 = rsp.data.data300;
        const data301 = rsp.data.data301;
        const data300a = rsp.data.data300a;
        let path = "/extracted_data";
        history.push({
          pathname: path,
          state:{
            data300:data300,
            data301:data301,
            data300a:data300a,
          }
        });
    });
  };

  const startFileProcessing = async () => {
    try {
      setProgressBarStart(true);
      const response = await fetch(
        "https://pearsonoshaextract-sandbox.techvantagesystems.com/process-files/",
        {
          method: "POST",
        }
      );

      const reader = response.body.getReader();
      let accumulatedProgress = "";

      while (true) {
        const { done, value } = await reader.read();

        if (!isMounted) {
          break;
        }

        if (done) {
          break;
        }

        const newProgress = value ? new TextDecoder().decode(value) : "";
        if (newProgress == "data: done") {
          showToaster("Processing  sucessfully ", "success");
          setProgress("");
          break;
        } else {
          const match = newProgress.match(/(\d+)\/(\d+)/);
          let totalDoc;
          let currentDoc;
          if (match) {
            totalDoc = match[2];
            currentDoc = match[1];
            let setData = (100 / totalDoc) * currentDoc;
            setProgressBar(setData);
            if (currentDoc == totalDoc) {
              showToaster("Processing  sucessfully ", "success");
              setProgress("");
              setProgressBar(0);
              setProgressBarStart(false);
              break;
            }
          }
          accumulatedProgress = newProgress;
          setProgress(accumulatedProgress);
        }
      }
    } catch (error) {
      console.error("Error with fetch:", error);
    }
  };

  const getSettingsDetails = async () => {
    try {
      setPageLoad(true);
      await getSettings().then(async (response) => {
        if (response !== "" && response !== undefined && response !== null) {
          setPageLoad(false);

          if (response.data.status == true) {
            setSettingData(response.data.settings);
          }
        } else {
          setPageLoad(false);
        }
      });
    } catch (e) {
      setPageLoad(false);
    }
  };
  const processData = () => {
    try {
      setPageLoad(true);
      processSettings(settingsData).then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.status == 200
        ) {
          setData(JSON.stringify(response.data));
          // history.push("/home");
          showToaster("Processing  sucessfully ", "success");
          setPageLoad(false);
        } else {
          showToaster("Something went wrong", "warning");
          setPageLoad(false);
        }
      });
    } catch (e) {
      setPageLoad(false);
    }
  };

  // useEffect(() => {
  //   const eventSource = new EventSource(
  //     "https://pearsonoshaextract-sandbox.techvantagesystems.com/process-files/"
  //   );

  //   eventSource.onmessage = (event) => {
  //     const data = JSON.parse(event.data);

  //     // Handle the data as needed (update state, UI, etc.)
  //   };

  //   eventSource.onerror = (error) => {
  //     console.error("EventSource failed:", error);
  //     eventSource.close();
  //   };

  //   return () => {
  //     // Clean up the event source when the component unmounts
  //     eventSource.close();
  //   };
  // }, []);
const handleDashboard = () =>{
  let path = "/view_archive";
    history.push({
      pathname: path,
      state:{
        fromProcessLog:true
      }
    });
}

const handleOsha300A = () => {
  let path = "/extracted_data/osha300A";
    history.push({
      pathname: path,
      state:{
        data300A:props?.location?.state?.data300a
      }
    });
}
const handleOsha301 = () => {
  let path = "/extracted_data/osha-301";
    history.push({
      pathname: path,
      state:{
        data301:props?.location?.state?.data301
      }
    });
}
const handleOsha300 = () => {
  let path = "/extracted_data/osha300";
    history.push({
      pathname: path,
      state:{
        data300:props?.location?.state?.data300
      }
    });
}

const handleExtractedData = () => {
  getExtractedDataTable()
}
  return (
    <>
    {/* -----------------Mob UI----------------- */}
    <MediaQuery maxDeviceWidth={1023}>

        <MainContainer >
            <div className={style.contentParent}>
              <div className={style.contentHeader}>
                <IconOshaInsiderViolet />
              </div>
              <div className={style.subHead}>EXTRACTED DATA</div>
              <div className={style.contentArea}>
                
                  <MobButton onClick={handleOsha300} className={'extractButton'}>
                    View Form 300
                  </MobButton>

                  <MobButton onClick={handleOsha301} className={'extractButton'}>
                  View Form 301
                  </MobButton>

                  <MobButton onClick={handleOsha300A} className={'extractButton'}>
                  View Form 300A
                  </MobButton>
              </div>
            </div>
            
        </MainContainer>
    </MediaQuery>
     {/* -----------------Desk UI----------------- */}
     <MediaQuery minDeviceWidth={1024}>

      <PageLoader showLoader={pageLoad} />
      <MainWrapper>
        <Card>
          <Card.Header className="cardHeader">
            <div className="establishmentText">Process Log</div>
            <div className="cardHeaderButtons">
              <div>
                <Button 
                variant="contained" 
                size="small"
                onClick={handleExtractedData}
                >
                  Extracted Data
                </Button>
              </div>
              <div>
                <Button 
                variant="contained" 
                size="small"
                onClick={handleDashboard}
                >
                  Dashboard
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Row className="ioFieldsRows">
              <Col md={6}>
                <TextField
                  label="Input Folder Path"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={
                    settingsData !== undefined && settingsData.input_folder
                  }
                  // onChange={handleEmailId}
                  size="small"
                  style={{ backgroundColor: "#ebebeb" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Col>
              <Col md={6}>
                <TextField
                  label="Frequency"
                  variant="outlined"
                  fullWidth
                  type="email"
                  value={settingsData !== undefined && settingsData.frequency}
                  style={{ backgroundColor: "#ebebeb" }}
                  // onChange={handleEmailId}
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Col>
            </Row>
            <Row className="cardBody">
              <Col md={6}>
                <TextField
                  label="Output Folder Path"
                  variant="outlined"
                  fullWidth
                  type="text"
                  defaultValue="Output Folder Path"
                  value={
                    settingsData !== undefined && settingsData.output_folder
                  }
                  style={{ backgroundColor: "#ebebeb", color: "black" }}
                  // onChange={handleEmailId}
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
              </Col>
              <Col md={6} style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
              <InputLabel 
              style={{ color: 'red' }}
              >
                <i class="fa fa-info-circle" aria-hidden="true"></i> You can update the path in settings page
                </InputLabel>
              </Col>
              
            </Row>
          </Card.Body>
          <Card.Footer className="ioCardFooter">
            <div>
              <Button
                variant="contained"
                size="small"
                onClick={() => startFileProcessing()}
              >
                Process
              </Button>
            </div>
          </Card.Footer>
          <div className="processProgress">
            {progressBarStart ? (
              <div style={{ width: "100%" }}>
                <h2 className="pro">Progress: {progressBar.toFixed(2)}%</h2>
                <progress className="pros_bar" value={progressBar} max="100" />
                <pre className="pro_text">{progress}</pre>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Card>
      </MainWrapper>

      {/* <ChatBotBtn /> */}
      </MediaQuery>
    </>
  );
}
