import { IconButton } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { IconDelete, IconEditMob } from "../../../assets/css/icons";
import WindowMessage from "../../GlobalComponents/WindowMessage";
import FormButton from "../../MobileUI/GlobalComponents/FormButton";
import { DeleteEstablishment, EditEstablishment } from "../../services/api-servics";
import css from "./manageEstablishment.module.scss";
function EstablishmentCard(props) {
  const data = props.item;
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const role = localStorage.getItem("role");
  
  const history = useHistory();
  
  const handleyear = (item) => {
    let path = "/yearly_details";
    history.push({
      pathname: path,
      state: item,
    });
  };
  const handleDelete = (item) => {
    setItemIdToDelete(item);
    setShowModal(true);
  };
  const handleClose = () => {
    setItemIdToDelete(null);
    setShowModal(false);
  };
  const handleConfirm = () => {
    if (itemIdToDelete) {
      delEstablishment(itemIdToDelete);
    }
    handleClose();
  };
  const delEstablishment = (e) => {
    DeleteEstablishment({ establishmentID: e.establishmentID }).then(
      async (rsp) => {
        props.setRefreshtable(true);
      }
    );
  };
  const editEstablishmentList = (e) => {
    EditEstablishment({ establishmentID: e.establishmentID }).then(
      async (rsp) => {
        if (rsp.data.data) {
          const data = rsp.data.data;
          let path = "/edit_establishment";
          history.push({
            pathname: path,
            state: {
              estName: data.establishment_name,
              emailId: data.email_info,
              street: data.street_name,
              city: data.city_name,
              estCompany: data.company_name,
              zipCode: data.zip_info,
              industry: data.industry_info,
              einNo: data.EIN_no,
              naicsCode: data.naics_info,
              estType: data.establishment_type,
              estStateCode: data.state_code,
              estStateLabel: data.state_label,
              establishmentID: e.establishmentID,
              editEst: "true",
            },
          });
        }
      }
    );
  };
const handleEdit = (item) => {
    editEstablishmentList(item);
  };
  return (
    <>
      <WindowMessage
        show={showModal}
        handleClose={handleClose}
        windowMessage="Establishment"
        handleConfirm={handleConfirm}
      />
      <div className={css.estCard}>
        {role !== "admin" && <div className={css.estCardDeleteEdit}>
          <IconButton onClick={() => handleDelete(data)}>
            <IconDelete/>
          </IconButton>

          <IconButton onClick={() => handleEdit(data)}>
            <IconEditMob />
          </IconButton>
        </div>}
        <div className={css.estCardDetails}>
          <div className={css.estRows}>
            <div className={css.estCardDetailColL}>Establishment ID</div> 
            <div className={css.estCardDetailColR}>{data.establishmentID}</div>
          </div>
          <div className={css.estRows}>
            <div className={css.estCardDetailColL}>Establishment Name</div>
            <div className={css.estCardDetailColR}>
              {data.establishmentName}
            </div>
          </div>
          <div className={css.estRows}>
            <div className={css.estCardDetailColL}>NAICS</div>
            <div className={css.estCardDetailColR}>{data.naics}</div>
          </div>
          <div className={css.estRows}>
            <div className={css.estCardDetailColL}>Email</div>
            <div className={css.estCardDetailColR}>{data.email}</div>
          </div>
          {role !== "admin" && <div className={css.estYearlyButton}>
            <FormButton height="24px" onClick={() => handleyear(data)}>
              Yearly Details
            </FormButton>
          </div>}
        </div>
      </div>
    </>
  );
}

export default EstablishmentCard;
